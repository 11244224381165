import ApiService from "../../helpers/ApiHelper";

class BlogService {

    static getBlogs() {
        return ApiService.request(
            "/api/v1/blogs/",
            "get",
            null

        )
    }
    static addBlog(payload) {
        return ApiService.request(
            "/api/v1/blogs/",
            "post",
            payload

        )
    }

    static removeBlog(id) {
        return ApiService.request(
            `/api/v1/blogs/${id}`,
            "delete",
            null
        )
    }

    static editBlog(blog){
        return ApiService.request(
            `/api/v1/blogs/${blog.id}`,
            "patch",
            blog,
        )
    }
}

export default BlogService;
