import React, { Component } from "react";
import { Row, Col, Card, CardBody} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  getInitiatedBookings
} from "../../store/initiatedBookings/actions";
import { connect } from "react-redux";
import InitiatedBookingsList from "./List/InitiatedBookingsList";
import Paging from "../../components/Pagination/Pagination";
import {DatePicker, Space} from "antd";
import moment from "moment";

class InitiatedBookings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      currentDate: moment(new Date())
    }
  }

  componentDidMount() {
    console.log('da')
    this.props.getInitiatedBookings({currentPage: this.state.currentPage, currentDate: this.state.currentDate.format('YYYY-MM-DD')});
  }
  setPage = (currentPage) => {
    this.props.getInitiatedBookings({currentPage, currentDate: this.state.currentDate.format('YYYY-MM-DD')});
    this.setState({currentPage, currentDate: this.state.currentDate})
  }
  setDatePickFrom = (date) => {
    this.props.getInitiatedBookings({currentPage: 1, currentDate: date.format('YYYY-MM-DD')});
    this.setState({currentPage: 1, currentDate: date})
  }
  render() {
    const { initiatedList } = this.props;
    console.log(initiatedList)
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Initiated Bookings" breadcrumbItem="Initiated Bookings" />
            <Row>
              <Col>
                <Space className='pb-1'>
                  <DatePicker
                    placeholder="Current Date"
                    onChange={(date, dateString) => this.setDatePickFrom(moment(dateString))}
                    style={{
                      width: "130px",
                      marginRight: "15px",
                    }}
                  />
                </Space>
                <Space direction="horizental float-sm-right" style={{alignItems: 'center'}}>
                  <div><b>Total:</b> {initiatedList?.total}</div>
                </Space>
                <Card>
                  <Paging setPage={this.setPage} pages={initiatedList?.pages} currentPage={this.state.currentPage}/>
                  <CardBody>
                    <InitiatedBookingsList
                      list={initiatedList?.bookingSteps}
                      history={this.props.history}
                    />
                  </CardBody>
                  <Paging setPage={this.setPage} pages={initiatedList?.pages} currentPage={this.state.currentPage}/>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { InitiatedBookings } = state;
  return InitiatedBookings;
};

export default
connect(mapStatetoProps, {
  getInitiatedBookings,
})(InitiatedBookings);
