import React from 'react';
import Specializations from "../../../components/Profile/Specialization/specializations";
import SpecializationsType from "../../../components/Profile/Specialization/specializations-type";
import OtherQualifications from "../../../components/Profile/Specialization/other-qualifications";
import SpecializationPractice from "../../../components/Profile/Specialization/specialization-practice";
import {Divider} from "antd";
const dateFormatYear = 'YYYY'

const ProfessionalUniversity = ({user, form}) => {
  return (
    <>
      <Specializations user={user} form={form} dateFormatYear={dateFormatYear} />
      <Divider className='fields-divider'/>
      <SpecializationsType user={user} form={form}/>
      <Divider className='fields-divider'/>
      <OtherQualifications dateFormatYear={dateFormatYear} />
      <Divider className='fields-divider'/>
      <SpecializationPractice dateFormatYear={dateFormatYear} />
    </>
  );
};

export default ProfessionalUniversity;
