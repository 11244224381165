import ApiService from "../../helpers/ApiHelper";

class AdminService {

  static addAdmin(payload) {
    return ApiService.request(
      "/api/v1/admins/",
      "post",
      payload
    )
  }

  static removeAdmin(id) {
    return ApiService.request(
      `/api/v1/users/${id}`,
      "delete",
      null
    )
  }

  static editAdmin(admin) {
    return ApiService.request(
      `/api/v1/users/${admin.id}`,
      "patch",
      admin
    )
  }

  static statisticData = ({dateFrom, dateTo}) => {
    return ApiService.request(`/api/v1/admins/statistics/date/${dateFrom}/${dateTo}`,
      "get",
      {})
  }
}

export default AdminService;
