import React from 'react';
import {Col, Image, Row} from "antd";

const IdDocumentPreview = ({idCard}) => {

  return (
    <div style={{width: '100%', margin: '0 auto'}}>
      <Row type="flex" justify="space-around">
        {idCard ? Object.values(idCard?.document).map((doc, i) => {
          return <Col xs={8}><Image src={doc?.document} alt={doc?.fileName} style={{width: '100%', marginInline: '7px', marginTop: '5px', height: '200px', padding: '1px'}} preview={true}/></Col>
        }) : ''}
      </Row>
    </div>
  );
};

export default IdDocumentPreview;
