export const ProfessionalActionTypes = {
    PROFESSIONAL_LIST_GET: 'PROFESSIONAL_LIST_GET',
    PROFESSIONAL_LIST_GET_SUCCESSFUL: 'PROFESSIONAL_LIST_GET_SUCCESSFUL',
    PROFESSIONAL_LIST_GET_ERROR: 'PROFESSIONAL_LIST_GET_ERROR',


    PROFESSIONAL_ADD: 'PROFESSIONAL_ADD',
    PROFESSIONAL_ADD_SUCCESSFUL: 'PROFESSIONAL_ADD_SUCCESSFUL',
    PROFESSIONAL_ADD_ERROR: 'PROFESSIONAL_ADD_ERROR',

    PROFESSIONAL_ADD_MODAL: 'PROFESSIONAL_ADD_MODAL',
    PROFESSIONAL_ADD_MODAL_SUCCESS: 'PROFESSIONAL_ADD_MODAL_SUCCESS',

    PROFESSIONAL_SHOW_REMOVE_MODAL: 'PROFESSIONAL_SHOW_REMOVE_MODAL',
    PROFESSIONAL_SHOW_REMOVE_MODAL_SUCCESS: 'PROFESSIONAL_SHOW_REMOVE_MODAL_SUCCESS',

    PROFESSIONAL_REMOVE: "PROFESSIONAL_REMOVE",
    PROFESSIONAL_REMOVE_SUCCESS: 'PROFESSIONAL_REMOVE_SUCCESS',
    PROFESSIONAL_REMOVE_ERROR: 'PROFESSIONAL_REMOVE_ERROR',

    PROFESSIONAL_APPROVE: 'PROFESSIONAL_APPROVE',
    PROFESSIONAL_EDIT: 'PROFESSIONAL_EDIT',
    PROFESSIONAL_EDIT_SUCCESS: 'PROFESSIONAL_EDIT_SUCCESS',
    PROFESSIONAL_EDIT_ERROR: 'PROFESSIONAL_EDIT_ERROR',

    DISABLE: 'DISABLE',
    REMOVE: 'REMOVE'
}




