import {AvField, AvForm} from "availity-reactstrap-validation";
import {FormGroup, Label} from "reactstrap";
import React from "react";
import {DatePicker, Select} from "antd";
import dayjs from 'dayjs';
import moment from "moment";

const GeneralTab = ({couponCode, description, type, amount, validDateTo, validDateFrom, serviceValidDateTo, handleChange}) => {
  const discountOptions = ['Fixed cash discount', 'Fixed percent discount']
  return <AvForm className="needs-validation row">
    <FormGroup className="col-12">
      <Label htmlFor="validationCustom01">Coupon code</Label>
      <AvField
        name="couponCode"
        placeholder="Coupon code"
        type="text"
        errorMessage="Please write Coupon code"
        className="form-control"
        onChange={(e) => {
          e.target.value = e.target.value.toUpperCase().replace(/[^\w-]+/g, '')
          handleChange(e)
        }}
        value={couponCode}
        validate={{required: {value: true}}}
        id="validationCustom01"
      />
    </FormGroup>
    <FormGroup className="col-12">
      <Label htmlFor="validationCustom01">Description </Label>
      <AvField
        name='description'
        placeholder="Description"
        type="textarea"
        rows={2}
        errorMessage="Please write description"
        className="form-control"
        onChange={handleChange}
        value={description}
        validate={{required: {value: true}}}
        id="validationCustom02"
      />
    </FormGroup>
    <FormGroup className="col-md-12 col-lg-6">
      <Label htmlFor="validationCustom02">Discount Type</Label>
      <Select
        placeholder="Discount Type"
        name="type"
        value={type}
        onChange={(e) => handleChange({target: {name: 'type', value: e}})}
        className="w-100"
        options={discountOptions
          .map((item, i) => ({
            key: i,
            value: i,
            label: item,
          }))}
      />
    </FormGroup>
    <FormGroup className="col-md-12 col-lg-6">
      <Label htmlFor="validationCustom02">Coupon amount</Label>
      <AvField
        name="amount"
        placeholder="Coupon amount"
        type="text"
        errorMessage="Please write Coupon amount"
        className="form-control"
        onChange={handleChange}
        value={amount}
        validate={{required: {value: true}}}
        id="validationCustom01"
      />
    </FormGroup>
    <FormGroup className="col-md-12 col-lg-6">
      <Label htmlFor="validationCustom02">Coupon Valid Date From</Label>
      <DatePicker
        placeholder="Date From"
        className='w-100'
        value={(validDateFrom ? dayjs(validDateFrom) : null)}
        formar={'DD-MM-YYYY'}
        onChange={(date, dateString) => {
          handleChange({target: {name: 'validDateFrom', value: dateString}})
        }}
      />
    </FormGroup>
    <FormGroup className="col-md-12 col-lg-6">
      <Label htmlFor="validationCustom02">Coupon Valid Date To</Label>
      <DatePicker
        placeholder="Date To"
        className='w-100'
        value={(validDateTo ? dayjs(validDateTo) : null)}
        formar={'DD-MM-YYYY'}
        onChange={(date, dateString) => {
          handleChange({target: {name: 'validDateTo', value: dateString}})
        }}
      />
    </FormGroup>
    <FormGroup className="col-12 ">
      <Label htmlFor="validationCustom02">Service Valid To</Label>
      <DatePicker
        placeholder="Service Valid To"
        className='w-100'
        value={(serviceValidDateTo ? dayjs(serviceValidDateTo) : null)}
        formar={'DD-MM-YYYY'}
        onChange={(date, dateString) => {
          handleChange({target: {name: 'serviceValidDateTo', value: dateString}})
        }}
      />
    </FormGroup>
  </AvForm>
}

export default GeneralTab
