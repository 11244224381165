export const UserMetaHeadActionTypes = {
    USERS_PROFESSIONAL_META_HEAD_GET: 'USERS_PROFESSIONAL_META_HEAD_GET',
    USERS_PROFESSIONAL_META_HEAD_GET_SUCCESSFUL: 'USERS_PROFESSIONAL_META_HEAD_GET_SUCCESSFUL',
    USERS_PROFESSIONAL_META_HEAD_GET_ERROR: 'USERS_PROFESSIONAL_META_HEAD_GET_ERROR',

    USERS_PROFESSIONAL_META_HEAD_CREATE: 'USERS_PROFESSIONAL_META_HEAD_CREATE',
    USERS_PROFESSIONAL_META_HEAD_CREATE_SUCCESSFUL: 'USERS_PROFESSIONAL_META_HEAD_CREATE_SUCCESSFUL',
    USERS_PROFESSIONAL_META_HEAD_CREATE_ERROR: 'USERS_PROFESSIONAL_META_HEAD_CREATE_ERROR',

}




