import {Modal} from "reactstrap";
import React from "react";
import CouponsTabs from "./CouponsTabs/CouponsTabs";

const AddCoupons = ({currentCoupon, isNewCouponModalOpen, onCancel}) => {
  return <Modal isOpen={isNewCouponModalOpen} toggle={onCancel}>
    <div className="modal-header">
      <h5 className="modal-title mt-0">Add coupon</h5>
      <button
        type="button"
        onClick={onCancel}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
      <CouponsTabs couponEdit={currentCoupon} onCancel={onCancel}/>
    </div>
  </Modal>
}
export default AddCoupons
