export const TranslationTypes = {
    TRANSLATION_GET: 'TRANSLATION_GET',
    TRANSLATION_SUCCESSFUL: 'TRANSLATION_SUCCESSFUL',
    TRANSLATION_ERROR: 'TRANSLATION_ERROR',

    TRANSLATION_GET_TYPE: 'TRANSLATION_GET_TYPE',
    TRANSLATION_TYPE_SUCCESSFUL: 'TRANSLATION_TYPE_SUCCESSFUL',
    TRANSLATION_TYPE_ERROR: 'TRANSLATION_TYPE_ERROR',

    TRANSLATION_ADD: 'TRANSLATION_ADD',
    TRANSLATION_ADD_SUCCESSFUL: 'TRANSLATION_ADD_SUCCESSFUL',
    TRANSLATION_ADD_ERROR: 'TRANSLATION_ADD_ERROR',

    TRANSLATION_EDIT: 'TRANSLATION_EDIT',
    TRANSLATION_EDIT_SUCCESSFUL: 'TRANSLATION_EDIT_SUCCESSFUL',
    TRANSLATION_EDIT_ERROR: 'TRANSLATION_EDIT_ERROR',

    TRANSLATION_REMOVE: 'TRANSLATION_REMOVE',
    TRANSLATION_REMOVE_SUCCESSFUL: 'TRANSLATION_REMOVE_SUCCESSFUL',
    TRANSLATION_REMOVE_ERROR: 'TRANSLATION_REMOVE_ERROR',
}




