import {LogsActionTypes} from "./actionTypes";

const initialState = {
  LogsListError: null, message: null,
}

const logReducer = (state = initialState, action) => {
  switch (action.type) {
    case LogsActionTypes.AUDIT_LOG_LIST_GETS:
     return  {
        ...state,
        logsList: null,
        logsListError: null
      }

    case LogsActionTypes.AUDIT_LOG_LIST_GETS_SUCCESSFUL:
      return  {
        ...state,
        logsList: action.payload,
        logsListError: null
      }

    case LogsActionTypes.AUDIT_LOG_LIST_GETS_ERROR:
     return {
        ...state,
        logsList: null,
        logsListError: action.payload
      }

    case LogsActionTypes.AUDIT_LOG_LIST_GET:
     return  {
        ...state,
        logsList: null,
        logsListError: null
      }

    case LogsActionTypes.AUDIT_LOG_LIST_GET_SUCCESSFUL:
      return  {
        ...state,
        logsList: [action.payload],
        logsListError: null
      }

    case LogsActionTypes.AUDIT_LOG_LIST_GET_ERROR:
      return  {
        ...state,
        logsList: null,
        logsListError: action.payload
      }
    default:
      return state
  }
}

export default logReducer
