import React, {Component, useEffect, useRef, useState} from "react";

import {connect} from "react-redux";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth
} from "../../store/actions";

// Layout Related Components
import Sidebar from "./Sidebar";
import Footer from "./Footer";

const Layout = ({
                  toggleRightSidebar,
                  changeSidebarType,
                  changeSidebarTheme,
                  changeLayoutWidth,
                  changeTopbarTheme,
                  location,
                  children,
                  leftSideBarType,
                  leftSideBarTheme,
                  layoutWidth,
                  topbarTheme,
                  showRightSidebar
                }) => {
  const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
  const isInitialMount = useRef(true)
  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    // Scroll Top to 0
    window.scrollTo(0, 0);
    const currentage = capitalizeFirstLetter(location);
    document.title =
      currentage + " | My Pet - Dashboard";
    if (isInitialMount.current) {
      if (leftSideBarTheme) {
        changeSidebarTheme(leftSideBarTheme);
      }

      if (layoutWidth) {
        changeLayoutWidth(layoutWidth);
      }

      if (leftSideBarType) {
        changeSidebarType(leftSideBarType);
      }
      if (topbarTheme) {
        changeTopbarTheme(topbarTheme);
      }

      if (showRightSidebar) {
        toggleRightSidebar();
      }
      isInitialMount.current = false
    }
  }, [location])

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      changeSidebarType("condensed", this.state.isMobile);
    } else if (leftSideBarType === "condensed") {
      changeSidebarType("default", this.state.isMobile);
    }
  };

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Sidebar theme={leftSideBarTheme}
                 type={leftSideBarType}
                 isMobile={isMobile}/>
        <div className="main-content">
          {children}
        </div>
        <Footer/>
      </div>
    </React.Fragment>
  );
}


const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth
})(Layout)

