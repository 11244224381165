import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import axios from "axios";
import baseURL from "config";
import FAQForm from "./FAQForm";

const VIEWS = {
  LIST: "LIST",
  ADD_NEW: "ADD_NEW",
  EDIT: "EDIT",
};

const questionCategories = {
  Booking: "Booking",
  Pricing: "Pricing",
  Services: "Services",
  Profiles: "Profiles",
};

const HeaderButton = (props) => {
  return (
    <Button
      {...props}
      color={props?.color || "success"}
      size={props?.size || "md"}
      style={{
        maxWidth: "150px",
      }}
    >
      {props.children}
    </Button>
  );
};

const Category = (props) => {
  return (
    <button
      style={{
        height: "30px",
        borderRadius: "16px",
        border: props.active ? "1px solid black" : "1px solid lightgray",
        width: "100px",
        height: "30px",
        backgroundColor: props.active ? "#ADD8E6" : "white",
      }}
      {...props}
    />
  );
};

const Arrows = ({ onIncrement, onDecrement, disabled }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "3px" }}>
      <button
        disabled={disabled}
        onClick={onIncrement}
        style={{
          maxHeight: "22px",
          borderRadius: "4px 4px 0 0",
        }}
      >
        ↑
      </button>
      <button
        disabled={disabled}
        onClick={onDecrement}
        style={{
          maxHeight: "22px",
          borderRadius: "0 0 4px 4px",
        }}
      >
        ↓
      </button>
    </div>
  );
};

export default function FAQ() {
  const [view, setView] = useState(VIEWS.LIST);
  const [activeCategory, setActiveCategory] = useState(
    questionCategories.Booking
  );
  const [selectedItem, setSelectedItem] = useState(null);
  const [faqs, setFaqs] = useState([]);

  const getFAQS = async () => {
    try {
      const questions = await axios.get(`${baseURL}/api/v1/faq`);
      setFaqs(questions?.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getFAQS();
  }, []);

  const editItemView = (item) => {
    setView(VIEWS.EDIT);
    setSelectedItem(item);
  };

  const deleteItem = async (item) => {
    if (
      window.confirm(
        `You are about to delete the following item: ${item.question.en}`
      )
    ) {
      try {
        await axios.delete(`${baseURL}/api/v1/faq/${item._id}`);
        const questions = await axios.get(`${baseURL}/api/v1/faq`);
        setFaqs(questions?.data);
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log("cancel action");
    }
  };

  const reorder = async (item, direction) => {
    const allQuestionsInCurrentCategory = faqs
      .filter((question) => question.category === item.category)
      .sort((question) => question.order);

    let currentItemIndex = allQuestionsInCurrentCategory.findIndex(
      (question) => question._id === item._id
    );

    if (direction === "increment") {
      if (currentItemIndex === 0) {
        return;
      } else {
        try {
          let newOrder =
            allQuestionsInCurrentCategory[currentItemIndex - 1].order + 1;
          let body = { ...item, order: newOrder };
          await axios.patch(`${baseURL}/api/v1/faq/${item._id}`, body);
          const questions = await axios.get(`${baseURL}/api/v1/faq`);
          setFaqs(questions?.data);
        } catch (e) {
          console.log(e);
        }
      }
    }

    if (direction === "decrement") {
      if (currentItemIndex === allQuestionsInCurrentCategory.length - 1) {
        return;
      } else {
        try {
          let newOrder =
            allQuestionsInCurrentCategory[currentItemIndex + 1].order - 1;
          let body = { ...item, order: newOrder };
          await axios.patch(`${baseURL}/api/v1/faq/${item._id}`, body);
          const questions = await axios.get(`${baseURL}/api/v1/faq`);
          setFaqs(questions?.data);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const submitNewForm = async (formData) => {
    let body = { ...formData, order: selectedItem?.order || 0 };
    try {
      await axios.post(`${baseURL}/api/v1/faq`, body);
      const questions = await axios.get(`${baseURL}/api/v1/faq`);
      setFaqs(questions?.data);
      setView(VIEWS.LIST);
      setActiveCategory(formData.category);
    } catch (e) {
      console.log(e);
    }
  };

  const editForm = async (formData) => {
    let body = { ...formData, order: selectedItem?.order || 0 };
    try {
      await axios.patch(`${baseURL}/api/v1/faq/${selectedItem._id}`, body);
      const questions = await axios.get(`${baseURL}/api/v1/faq`);
      setFaqs(questions?.data);
      setView(VIEWS.LIST);
      setActiveCategory(formData.category);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div style={{ padding: "10px" }}>
      {view === VIEWS.LIST && (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <HeaderButton onClick={() => setView(VIEWS.ADD_NEW)}>
            Add new FAQ
          </HeaderButton>
          <div style={{ margin: "0 auto", display: "flex", gap: "8px" }}>
            <Category
              active={activeCategory === questionCategories.Booking}
              onClick={() => setActiveCategory(questionCategories.Booking)}
            >
              {questionCategories.Booking}
            </Category>
            <Category
              active={activeCategory === questionCategories.Pricing}
              onClick={() => setActiveCategory(questionCategories.Pricing)}
            >
              {questionCategories.Pricing}
            </Category>
            <Category
              active={activeCategory === questionCategories.Services}
              onClick={() => setActiveCategory(questionCategories.Services)}
            >
              {questionCategories.Services}
            </Category>
            <Category
              active={activeCategory === questionCategories.Profiles}
              onClick={() => setActiveCategory(questionCategories.Profiles)}
            >
              {questionCategories.Profiles}
            </Category>
          </div>
          {faqs
            .filter((item) => item.category === activeCategory)
            .map((item) => (
              <div
                key={item?._id}
                style={{
                  padding: "20px 0",
                  borderBottom: "1px solid black",
                  display: "flex",
                  gap: "8px",
                }}
              >
                <h5 style={{ flex: 1 }}>{item.question.en}</h5>
                <Arrows
                  onIncrement={() => reorder(item, "increment")}
                  onDecrement={() => reorder(item, "decrement")}
                />
                <Button onClick={() => editItemView(item)} color="primary">
                  edit
                </Button>
                <Button onClick={() => deleteItem(item)} color="danger">
                  delete
                </Button>
              </div>
            ))}
        </div>
      )}
      {view === VIEWS.ADD_NEW && (
        <div>
          <HeaderButton onClick={() => setView(VIEWS.LIST)} color="link">
            {"< Back"}
          </HeaderButton>
          <h5 style={{marginTop: '10px'}}>
            <b>Add New Item</b>
          </h5>
          <FAQForm onSubmit={submitNewForm} activeCategory={activeCategory} />
        </div>
      )}
      {view === VIEWS.EDIT && (
        <div>
          <HeaderButton
            color="link"
            onClick={() => {
              setView(VIEWS.LIST);
              setSelectedItem(null);
            }}
          >
            {"< Back"}
          </HeaderButton>
          <h5 style={{marginTop: '10px'}}>
            <b>Edit item:</b> <u>{selectedItem?.question?.en}</u>
          </h5>
          <FAQForm item={selectedItem} onSubmit={editForm} />
        </div>
      )}
    </div>
  );
}
