import ApiService from "../../helpers/ApiHelper";

class ParentService {

    static getParents({type}) {
        return ApiService.request(
          `/api/v1/users/${type? `role/${type}` : type}`,
          "get",
          {}
        )
    }

    static addParent(payload) {
        return ApiService.request(
            "/api/v1/users/",
            "post",
            payload
        )
    }

    static removeParent(id) {
        return ApiService.request(
            `/api/v1/users/${id}`,
            "delete",
            null
        )
    }

    static editParent(parent){
        return ApiService.request(
            `/api/v1/users/${parent.id}`,
            "patch",
            parent
        )
    }
}

export default ParentService;
