import {Form, Input, Row, Col, Divider, Button, DatePicker} from 'antd';
import DynamicFieldSet from "../../../pages/Professionals/List/DynamicFieldSet";
import React from "react";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import DocumentUpload from "../../../helpers/DocumentsUpload";
import dayjs from "dayjs";

const Specializations = ({user, dateFormatYear, form}) => {

  const {metadata} = user;

  return (<Row gutter={24}>
    <Col span={24}>
      <Form.List
        name={['metadata', 'professionalQualifications']}
        // rules={[
        //   {
        //     validator: async (_, professionalQualifications) => {
        //       if (
        //         !professionalQualifications ||
        //         professionalQualifications.length < 1
        //       ) {
        //         return Promise.reject(
        //           new Error('Please write university'),
        //         )
        //       }
        //     },
        //   },
        // ]}
      >
        {(fields, {add, remove}, {errors}) => (
          <Row gutter={12} align='middle'>
            {fields.map(({key, name, ...restField}, index) => (
              <Col key={key} xs={22}>
                <Row gutter={12} align='middle'>
                  <Col xs={24}>
                    <Form.Item
                      {...restField}
                      name={[name, 'university']}
                      label={'University'}
                      className='input-box'
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: (
                      //       'University'
                      //     ),
                      //   },
                      // ]}

                    >
                      <Input
                        className='input'
                        placeholder={('University')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={11} md={8}>
                    <Form.Item
                      {...restField}
                      name={[name, 'from']}
                      label={('Start Year')}
                      className='input-box'
                    >
                      <DatePicker
                        style={{width: '100%'}}
                        className='input'
                        picker='year'
                        format={dateFormatYear}
                        defaultValue={dayjs().subtract(4, 'y')}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={11} md={8}>
                    <Form.Item
                      {...restField}
                      name={[name, 'to']}
                      label={('End Year')}
                      className='input-box'

                    >
                      <DatePicker
                        style={{width: '100%'}}
                        className='input'
                        picker='year'
                        iso
                        format={dateFormatYear}
                        defaultValue={dayjs()}
                        onChange={(date) => {
                          console.log(date)
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={11} md={8}>
                    <Form.Item
                      {...restField}
                      name={[name, 'off']}
                      label={('Graduation year')}
                      className='input-box'

                    >
                      <DatePicker
                        style={{width: '100%'}}
                        className='input'
                        picker='year'
                        iso
                        format={dateFormatYear}
                        defaultValue={dayjs()}
                        onChange={(date) => {
                          console.log(date)
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={22}>

                    <DynamicFieldSet
                      fieldKey={['metadata', 'professionalQualifications', restField.fieldKey]}
                      keyName={[name]}
                      subKey={'degree'}
                      title={'Degree'}
                      documentTitle={'Document Degree'}
                      documents={[metadata?.professionalQualifications[index]]}
                      form={form}/>
                  </Col>

                  {fields.length > 1 &&
                    <Col xs={2}>
                      <Button icon={
                        <MinusCircleOutlined
                          className='btn btn-remove'

                        />} onClick={() => remove(name)}/>
                    </Col>}
                  {fields.length > 1 && fields.length - 1 !== index && (
                    <Divider className='fields-divider'/>
                  )}
                </Row>
              </Col>
            ))}
            <Col xs={2}>
              <Form.Item>
                <Button icon={<PlusOutlined/>} onClick={() => add()}/>
                <Form.ErrorList errors={errors}/>
              </Form.Item>
            </Col>
          </Row>

        )}
      </Form.List>
    </Col>
    <Col span={12}>

    </Col>


  </Row>)
}

export default Specializations
