export const CouponsActionTypes = {
    COUPONS_LIST_GET: 'COUPONS_LIST_GET',
    COUPONS_LIST_GET_SUCCESSFUL: 'COUPONS_LIST_GET_SUCCESSFUL',
    COUPONS_LIST_GET_ERROR: 'COUPONS_LIST_GET_ERROR',

    COUPON_GET: 'COUPON_GET',
    COUPON_GET_SUCCESSFUL: 'COUPON_GET_SUCCESSFUL',
    COUPON_GET_ERROR: 'COUPON_GET_ERROR',

    COUPON_CREATE: 'COUPON_CREATE',
    COUPON_CREATE_SUCCESSFUL: 'COUPON_CREATE_SUCCESSFUL',
    COUPON_CREATE_ERROR: 'COUPON_CREATE_ERROR',

    COUPON_UPDATE: 'COUPON_UPDATE',
    COUPON_UPDATE_SUCCESSFUL: 'COUPON_UPDATE_SUCCESSFUL',
    COUPON_UPDATE_ERROR: 'COUPON_UPDATE_ERROR',

    COUPON_DISABLE: 'COUPON_DISABLE',
    COUPON_DISABLE_SUCCESSFUL: 'COUPON_DISABLE_SUCCESSFUL',
    COUPON_DISABLE_ERROR: 'COUPON_DISABLE_ERROR',
}




