import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import React, {useState} from "react";
import {ProfessionalActionTypes} from "../../../store/professionals/actionTypes";
import {Button} from "reactstrap";
import RemoveList from "../../../components/RemoveList/RemoveList";
import moment from "moment";
import EditTranslation from "./TranslationEdit";

const TranslationList = ({
                           lists,
                           disableService
                         }) => {
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [data, setData] = useState(null)
  const removeModalOpen = (service) => {
    setIsRemoveModalOpen(!isRemoveModalOpen)

    setData(service)
  }

  const tableRowElement = (lists) => {
    if (lists && lists.length) {
      return lists.map((listItem, i) => {
        if(listItem.translations) {
          return <Tr key={i}>
            <Td>{listItem.key}</Td>
            <Td>{listItem.typeOfService}</Td>
            <Td>{listItem.translations.en}</Td>
            <Td>{listItem.translations.bg}</Td>
            <Td>{listItem.active ? 'Yes' : 'No'}</Td>
            <Td>{moment(listItem.createdAt).format('DD-MM-YYYY')}</Td>
            <Td>
              <div className="button-items d-flex">

                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    setData(listItem);
                    setIsEditModalOpen(!isEditModalOpen)
                  }}
                >
                  <i className="bx bxs-edit font-size-16 align-middle"></i>
                </button>
                <Button color={listItem.active ? 'danger' : 'success'} className='ml-1'
                        onClick={() => removeModalOpen(listItem)}>{listItem.active ? <i
                  className="bx bxs-dislike font-size-16 align-middle"></i> : <i
                  className="bx bxs-like font-size-16 align-middle"></i>}</Button>
              </div>
            </Td>
          </Tr>
        }
      })
    }
  }

  return <div className="table-rep-plugin">
    <div className="table-responsive mb-0" data-pattern="priority-columns">
      <Table
        id="tech-companies-1"
        className="table table-striped table-bordered"
      >
        <Thead>
          <Tr>
            <Th data-priority="1">Key</Th>
            <Th data-priority="3">Type</Th>
            <Th data-priority="3">Translation EN</Th>
            <Th data-priority="1">Translation BG</Th>
            <Th data-priority="3">Active</Th>
            <Th data-priority="3">Created At</Th>
            <Th data-priority="3">Actions</Th>
          </Tr>
        </Thead>
        <Tbody>{tableRowElement(lists)}</Tbody>
      </Table>
    </div>
    <RemoveList isRemoveModalOpen={isRemoveModalOpen}
                title={`Do you want to ${data?.active ? 'deactivate' : 'activate'} the service?`}
                subTitle={data?.active ? 'Deactivate' : 'Activate'}
                data={data?._id}
                status={'DISABLE'}
                onCancel={() => setIsRemoveModalOpen(false)}
                onSubmit={(id) => {
                  disableService({id})
                  setIsRemoveModalOpen(false)
                }}/>
    {isEditModalOpen && <EditTranslation isEditTranslationModalOpen={isEditModalOpen} onCancel={() => setIsEditModalOpen(false)}
                     translation={data}/>}
  </div>
}

export default TranslationList
