import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
    getProfessionalsError,
    getProfessionalsSuccessful,
    addProfessionalError,
    addProfessionalSuccessful,
    showAddProfessionalModalSuccess,
    removeProfessionalError,
    removeProfessionalSuccess,
    getProfessionals,
    editProfessionalError,
    editProfessionalSuccess,
    approveProfessionalSuccess,
    approveProfessionalError
} from "./actions";
import ProfessionalService from "./service";
import { ProfessionalActionTypes } from "./actionTypes";

function* get({payload}) {
    try {
        const response = yield call(ProfessionalService.getProfessionals, payload);
        yield put(getProfessionalsSuccessful(response));
    } catch (error) {
        yield put(getProfessionalsError(error));
    }
}

function* addProfessional({ payload: professional }) {
    try {
        const response = yield call(ProfessionalService.addProfessional, professional);
        yield put(addProfessionalSuccessful(response));
        yield put(getProfessionals({type: professional.role}));


    } catch (error) {
        yield put(addProfessionalError(error));
    }
}

function* showAddProfessional({ payload: showModal }) {
    yield put(showAddProfessionalModalSuccess(showModal));
}

function* removeProfessional({ payload: professionalId, type }) {
    try {
        const response = yield call(ProfessionalService.removeProfessional, professionalId);
        yield put(removeProfessionalSuccess(response));
        yield put(getProfessionals({type}));

    } catch (error) {
        yield put(removeProfessionalError(error));
    }
}

function* edit({ payload: professional }) {
    try {
        const response = yield call(ProfessionalService.editProfessional, professional);
        yield put(editProfessionalSuccess(response));
        yield put(getProfessionals({type: professional.role}));
    } catch (error) {
        yield put(editProfessionalError(error));
    }
}

function* approve({ payload: professional }) {
    try {
        console.log(professional)
        const response = yield call(ProfessionalService.approveProfessional, professional);
        yield put(approveProfessionalSuccess(response));
        yield put(getProfessionals({type: professional.role}));
    } catch (error) {
        yield put(approveProfessionalError(error));
    }
}

export function* watchGetProfessional() {
    yield takeEvery(ProfessionalActionTypes.PROFESSIONAL_LIST_GET, get);
}

export function* watchAddProfessional() {
    yield takeEvery(ProfessionalActionTypes.PROFESSIONAL_ADD, addProfessional);
}

export function* watchShowAddProfessionalModal() {
    yield takeEvery(ProfessionalActionTypes.PROFESSIONAL_ADD_MODAL, showAddProfessional);
}

export function* watchRemoveProfessional() {
    yield takeEvery(ProfessionalActionTypes.PROFESSIONAL_REMOVE, removeProfessional);
}

export function* watchEditProfessional() {
    yield takeEvery(ProfessionalActionTypes.PROFESSIONAL_EDIT, edit);
}
export function* watchApproveProfessional() {
    yield takeEvery(ProfessionalActionTypes.PROFESSIONAL_APPROVE, approve);
}

function* professionalsSaga() {
    yield all([
        fork(watchGetProfessional),
        fork(watchAddProfessional),
        fork(watchShowAddProfessionalModal),
        fork(watchRemoveProfessional),
        fork(watchEditProfessional),
        fork(watchApproveProfessional)
    ]);
}

export default professionalsSaga;
