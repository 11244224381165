import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, CardHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  getAccountants
} from "../../store/accountant/actions";
import { connect } from "react-redux";
import AccountantList from "./List/AccountantList";


class Accountants extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // this.props.getAccountants();
  }

  render() {
    const { accountantList } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Accountants" breadcrumbItem="Accountants" />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <AccountantList
                      list={accountantList?.transactions}
                      history={this.props.history}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Accountant } = state;
  return Accountant;
};

export default
  connect(mapStatetoProps, {
    getAccountants,
  })(Accountants);
