import { ProfessionalActionTypes } from "./actionTypes";


const initialState = {
    professionalListError: null, message: null, isLoadingAddProfessional: false, isAddNewProfessionalOpen: false
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case ProfessionalActionTypes.PROFESSIONAL_LIST_GET:
            state = {
                ...state,
                professionalList: null,
                professionalListError: null
            }
            break;
        case ProfessionalActionTypes.PROFESSIONAL_LIST_GET_SUCCESSFUL:
            state = {
                ...state,
                professionalList: action.payload,
                professionalListError: null
            }
            break;
        case ProfessionalActionTypes.PROFESSIONAL_LIST_GET_ERROR:
            state = {
                ...state,
                professionalList: null,
                professionalListError: action.payload
            }
            break;
        case ProfessionalActionTypes.PROFESSIONAL_ADD_MODAL:
            state = {
                ...state,
                isAddNewProfessionalOpen: action.payload,
            }
            break;
        case ProfessionalActionTypes.PROFESSIONAL_ADD_MODAL_SUCCESS:
            state = {
                ...state,
                isAddNewProfessionalOpen: action.payload,
            }
            break;
        case ProfessionalActionTypes.PROFESSIONAL_ADD:
            state = {
                ...state,
                isLoadingAddProfessional: true
            }
            break;
        case ProfessionalActionTypes.PROFESSIONAL_ADD_SUCCESSFUL:
            state = {
                ...state,
                isLoadingAddProfessional: false,
                isAddNewProfessionalOpen: false,
            }
            break;
        case ProfessionalActionTypes.PROFESSIONAL_ADD_ERROR:
            state = {
                ...state,
                isLoadingAddProfessional: false,
                isAddNewProfessionalOpen: false,
            }
            break;
        case ProfessionalActionTypes.PROFESSIONAL_REMOVE_SUCCESS:
            state = {
                ...state,
                professionalList: state?.professionalList?.users.filter(professional => professional._id !== action.payload),
                isLoadingAddProfessional: false,
                isAddNewProfessionalOpen: false,
            }
            break;
        case ProfessionalActionTypes.PROFESSIONAL_REMOVE_ERROR:
            state = {
                ...state,
                isLoadingAddProfessional: false,
                isAddNewProfessionalOpen: false,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default account;
