import ApiService from "../../helpers/ApiHelper";

class TranslationService {

    static getTranslations({page, search, type='normal'}) {
        return ApiService.request(
            `/api/v1/services/all?type=${type}`,
            "get",
          {}

        )
    }
    static getTypeTranslations({type='normal'}) {
        return ApiService.request(
            `/api/v1/services/type-services?type=${type}`,
            "get",
          {}

        )
    }
    static addTranslations(data) {
        return ApiService.request(
            `/api/v1/services`,
            "post",
            data

        )
    }
    static editTranslations(data) {
        return ApiService.request(
            `/api/v1/services/${data._id}`,
            "put",
            data

        )
    }
    static unActiveTranslations({id}) {
        return ApiService.request(
            `/api/v1/services/${id}`,
            "delete",
          {}

        )
    }

}

export default TranslationService;
