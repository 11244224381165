import ApiService from "../../helpers/ApiHelper";

class AccountantService {

    static getAccountants(payload) {
        return ApiService.request(
            "/api/v2/admins/refunds/",
            "get",
            payload

        )
    }

}

export default AccountantService;
