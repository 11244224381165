export const userStatus = (status) => {
  let value;
  switch (status) {
    case "Yes":
    case true:
      value = "Yes";
      break;
    case "No":
    case false:
      value = "No";
      break;
    default:
      value = "Pending";
  }
  return value;
}

