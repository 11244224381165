import {FormGroup, Label} from "reactstrap";
import {AvForm} from "availity-reactstrap-validation";
import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {connect} from "react-redux";

const RestrictionTab = ({
                          services,
                          excludeServices,
                          professional,
                          excludeProfessional,
                          forProfessionals,
                          forParents,
                          translationList,
                          categoriesOptions,
                          handleChange,
                          getFiltratedParentsOptions,
                          getFiltratedProfessionalOptions,
                          usersProfessionalList,
                          usersParentList
                        }) => {
  const [professionalOptions, setProfessionalOptions] = useState(usersProfessionalList)
  const [parentsOptions, setParentOptions] = useState(usersParentList)
  useEffect(() => {
    if (usersProfessionalList || forProfessionals) {
      setProfessionalOptions([...usersProfessionalList, ...forProfessionals])
    }
    if (usersParentList || forParents) {
      setParentOptions([...usersParentList, ...forParents])
    }
  }, [usersProfessionalList, usersParentList])
  console.log(translationList)
  return <AvForm className="needs-validation row">
    <FormGroup className="col-md-12 col-lg-6">
      <Label htmlFor="validationCustom02">Professional</Label>
      <Select
        mode="multiple"
        placeholder="Professional"
        placement={'bottomLeft'}
        name="professional"
        value={professional}
        loading={categoriesOptions.length <= 0}
        onChange={(e) => handleChange({target: {name: 'professional', value: e}})}
        className="w-100"
        options={categoriesOptions?.map((item, i) => ({
          key: i,
          value: item.toUpperCase(),
          label: item,
        }))}
      />
    </FormGroup>
    <FormGroup className="col-md-12 col-lg-6">
      <Label htmlFor="validationCustom02">Exclude Professional</Label>
      <Select
        mode="multiple"
        placement={'bottomLeft'}
        placeholder="Exclude Professional"
        name="excludeProfessional"
        value={excludeProfessional}
        loading={categoriesOptions.length <= 0}
        onChange={(e) => handleChange({target: {name: 'excludeProfessional', value: e}})}
        className="w-100"
        options={categoriesOptions?.map((item, i) => ({
          key: i,
          value: item.toUpperCase(),
          label: item,
        }))}
      />
    </FormGroup>
    <FormGroup className="col-md-12 col-lg-6">
      <Label htmlFor="validationCustom02">Services</Label>
      <Select
        mode="multiple"
        placeholder="Services"
        placement={'bottomLeft'}
        name="services"
        value={services}
        loading={!translationList}
        onChange={(e) => handleChange({target: {name: 'services', value: e}})}
        className="w-100"
      >
        {translationList && translationList.translations.map((translation) => {
          return <Select.Option value={translation._id}
                                key={translation._id}>{translation.translations.en}</Select.Option>

        })}
      </Select>

    </FormGroup>

    <FormGroup className="col-md-12 col-lg-6">
      <Label htmlFor="validationCustom02">Exclude Services</Label>
      <Select
        mode="multiple"
        placeholder="Exclude Services"
        placement={'bottomLeft'}
        name="excludeServices"
        value={excludeServices}
        loading={!translationList}
        onChange={(e) => handleChange({target: {name: 'excludeServices', value: e}})}
        className="w-100"
      >
        {translationList && translationList.translations.map((translation) => {
          return <Select.Option value={translation._id}
                                key={translation._id}>{translation.translations.en}</Select.Option>

        })}
      </Select>
    </FormGroup>
    <FormGroup className="col-md-12 col-lg-6">
      <Label htmlFor="validationCustom01">For Professionals </Label>
      <Select
        mode="multiple"
        placeholder="For Professionals"
        placement={'bottomLeft'}
        name={'forProfessional'}
        listHeight={200}
        className="w-100"
        type={'text'}
        onChange={(e) => handleChange({target: {name: 'forProfessionals', value: e}})}
        onSearch={(e) => getFiltratedProfessionalOptions(e)}
        value={Array.isArray(forProfessionals) && typeof forProfessionals[0] !== 'string' ? forProfessionals.map(item => `${item.name} ${item.surname} ${item.email} ${item._id}`) : forProfessionals}
        options={professionalOptions?.map((item, i) => ({
          key: i,
          value: `${item.role} ${item.name} ${item.surname} ${item.email} ${item._id} `,
          label: `${item.role} ${item.name} ${item.surname} ${item.email}`,
        }))}
      />
    </FormGroup>
    <FormGroup className="col-md-12 col-lg-6">
      <Label htmlFor="validationCustom01">For Parents </Label>
      <Select
        mode="multiple"
        placeholder="For Parents"
        placement={'bottomLeft'}
        name={'forParents'}
        listHeight={200}
        className="w-100"
        type={'text'}
        onChange={(e) => handleChange({target: {name: 'forParents', value: e}})}
        onSearch={(e) => getFiltratedParentsOptions(e)}
        value={Array.isArray(forParents) && typeof forParents[0] !== 'string' ? forParents.map(item => `${item.name} ${item.surname} ${item.email}  ${item._id}`) : forParents}
        options={parentsOptions?.map((item, i) => ({
          key: i,
          value: `${item.role} ${item.name} ${item.surname} ${item.email} ${item._id} `,
          label: `${item.role} ${item.name} ${item.surname} ${item.email}`,
        }))}
      />
    </FormGroup>
  </AvForm>
}

const mapStateToProps = (state) => {
  const {Translations} = state
  return Translations
}

export default connect(mapStateToProps)(RestrictionTab)
