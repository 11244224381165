import { ParentActionTypes } from "./actionTypes";

export const getParents = (payload = {}) => {
  return {
    type: ParentActionTypes.PARENT_LIST_GET,
    payload: payload,
  };
};

export const getParentsSuccessful = (payload) => {
  return {
    type: ParentActionTypes.PARENT_LIST_GET_SUCCESSFUL,
    payload: payload,
  };
};

export const getParentsError = (payload) => {
  return {
    type: ParentActionTypes.PARENT_LIST_GET_ERROR,
    payload,
  };
};

export const addParent = (parent) => {
  return {
    type: ParentActionTypes.PARENT_ADD,
    payload: parent,
  };
};

export const addParentSuccessful = (payload) => {
  return {
    type: ParentActionTypes.PARENT_ADD_SUCCESSFUL,
    payload,
  };
};

export const addParentError = (payload) => {
  return {
    type: ParentActionTypes.PARENT_ADD_ERROR,
    payload,
  };
};
export const showAddParentModal = (showModal) => {
  return {
    type: ParentActionTypes.PARENT_ADD_MODAL,
    payload: showModal,
  };
};
export const showAddParentModalSuccess = (showModal) => {
  return {
    type: ParentActionTypes.PARENT_ADD_MODAL_SUCCESS,
    payload: showModal,
  };
};

export const removeParent = (parent) => {
  return {
    type: ParentActionTypes.PARENT_REMOVE,
    payload: parent,
  };
};
export const removeParentSuccess = (parent) => {
  return {
    type: ParentActionTypes.PARENT_REMOVE_SUCCESS,
    payload: parent,
  };
};
export const removeParentError = (parent) => {
  return {
    type: ParentActionTypes.PARENT_REMOVE_ERROR,
    payload: parent,
  };
};
export const editParent = (parent) => {
  return {
    type: ParentActionTypes.PARENT_EDIT,
    payload: parent,
  };
};
export const editParentSuccess = (parent) => {
  return {
    type: ParentActionTypes.PARENT_EDIT_SUCCESS,
    payload: parent,
  };
};
export const editParentError = (parent) => {
  return {
    type: ParentActionTypes.PARENT_EDIT_ERROR,
    payload: parent,
  };
};
