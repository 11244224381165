import React, { useState, useEffect } from "react";

const questionCategories = {
  Booking: "Booking",
  Pricing: "Pricing",
  Services: "Services",
  Profiles: "Profiles",
};

export default function FAQForm({ item, onSubmit, activeCategory }) {
  const [formData, setFormData] = useState(
    item
      ? {
          category: item.category,
          question: {
            en: item?.question?.en,
            bg: item?.question?.bg,
          },
          answer: {
            en: item?.answer?.en,
            bg: item?.answer?.bg,
          },
        }
      : {
          category: activeCategory
            ? activeCategory
            : questionCategories.Booking,
          question: {
            en: "",
            bg: "",
          },
          answer: {
            en: "",
            bg: "",
          },
        }
  );
  const [dirtyFields, setDirtyFields] = useState({
    form: false,
    category: false,
    question: {
      en: false,
      bg: false,
    },
    answer: {
      en: false,
      bg: false,
    },
  });

  const submitForm = () => {
    if (
      formData.question.en &&
      formData.question.bg &&
      formData.answer.en &&
      formData.answer.bg &&
      formData.category
    ) {
      onSubmit(formData);
    } else {
      alert("Please fill in all values including tranlations!");
    }
  };

  //Check for dirty fields
  useEffect(() => {
    let initalData = item
      ? item
      : {
          category: activeCategory
            ? activeCategory
            : questionCategories.Booking,
          question: {
            en: "",
            bg: "",
          },
          answer: {
            en: "",
            bg: "",
          },
        };

    let dirtyFields = {
      form:
        initalData.question.en !== formData.question.en ||
        initalData.question.bg !== formData.question.bg ||
        initalData.answer.en !== formData.answer.en ||
        initalData.answer.bg !== formData.answer.bg ||
        initalData.category !== formData.category
          ? true
          : false,
      category: initalData.category !== formData.category ? true : false,
      question: {
        en: initalData.question.en !== formData.question.en ? true : false,
        bg: initalData.question.bg !== formData.question.bg ? true : false,
      },
      answer: {
        en: initalData.answer.en !== formData.answer.en ? true : false,
        bg: initalData.answer.bg !== formData.answer.bg ? true : false,
      },
    };

    setDirtyFields(dirtyFields);
  }, [formData]);

  return (
    <>
      <div
        style={{
          marginTop: "10px",
          padding: "10px 0",
          maxWidth: "300px",
        }}
      >
        <label for="category">
          <b>Category: </b>
        </label>
        <select
          name="category"
          style={{
            height: "40px",
            marginLeft: "15px",
            backgroundColor: dirtyFields?.category ? "#F0EAD6" : "white",
            borderRadius: "6px",
            color: "#556ee6ue",
            minWidth: "100px",
            border: "2px solid",
          }}
          value={formData.category}
          onChange={(e) =>
            setFormData({
              ...formData,
              category: e.target.value,
            })
          }
        >
          <option value={questionCategories.Booking}>
            {questionCategories.Booking}
          </option>
          <option value={questionCategories.Pricing}>
            {questionCategories.Pricing}
          </option>
          <option value={questionCategories.Services}>
            {questionCategories.Services}
          </option>
          <option value={questionCategories.Profiles}>
            {questionCategories.Profiles}
          </option>
        </select>
      </div>
      <div
        style={{
          marginTop: "10px",
          padding: "15px",
          border: "1px solid gray",
          boxShadow: "2px 2px",
          borderRadius: "0 8px 0 8px",
        }}
      >
        <h5 style={{ color: "red" }}>Question</h5>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <label for="question-en"> English:</label>
          <textarea
            id="question-en"
            name="question-en"
            value={formData?.question?.en}
            style={{
              backgroundColor: dirtyFields?.question?.en ? "#F0EAD6" : "white",
            }}
            onChange={(e) =>
              setFormData({
                ...formData,
                question: { ...formData.question, en: e.target.value },
              })
            }
          />
          <br></br>
          <label for="question-bg"> Bulgarian:</label>
          <textarea
            id="question-bg"
            name="question-bg"
            value={formData?.question?.bg}
            style={{
              backgroundColor: dirtyFields?.question?.bg ? "#F0EAD6" : "white",
            }}
            onChange={(e) =>
              setFormData({
                ...formData,
                question: { ...formData.question, bg: e.target.value },
              })
            }
          />
        </div>
      </div>
      <div
        style={{
          marginTop: "30px",
          padding: "15px",
          border: "1px solid gray",
          boxShadow: "2px 2px",
          borderRadius: "0 8px 0 8px",
        }}
      >
        <h5 style={{ color: "green" }}>Answer</h5>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <label for="answer-en"> English:</label>
          <textarea
            id="answer-en"
            name="answer-en"
            rows={5}
            value={formData?.answer?.en}
            style={{
              backgroundColor: dirtyFields?.answer?.en ? "#F0EAD6" : "white",
            }}
            onChange={(e) =>
              setFormData({
                ...formData,
                answer: { ...formData.answer, en: e.target.value },
              })
            }
          />
          <br></br>
          <label for="answer-bg"> Bulgarian:</label>
          <textarea
            id="answer-bg"
            name="answer-bg"
            rows={5}
            value={formData?.answer?.bg}
            style={{
              backgroundColor: dirtyFields?.answer?.bg ? "#F0EAD6" : "white",
            }}
            onChange={(e) =>
              setFormData({
                ...formData,
                answer: { ...formData.answer, bg: e.target.value },
              })
            }
          />
        </div>
      </div>
      <button
        disabled={!dirtyFields.form}
        style={{
          height: "40px",
          backgroundColor: dirtyFields.form ? "#4CAF50" : "lightgray",
          color: "white",
          fontWeight: "bold",
          borderRadius: "5px",
          margin: "15px 0",
          width: "100%",
        }}
        onClick={submitForm}
      >
        Submit
      </button>
    </>
  );
}
