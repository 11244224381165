import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, CardHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  getPets,
  addPet,
  showAddPetModal,
  removePet,
  editPet
} from "../../store/pet/actions";
import { connect } from "react-redux";
import PetList from "./List/PetList";
import AddPet from "./AddPet";
import {userRols} from "../../helpers/userRols";

class Pets extends Component {
  constructor(props) {
    super(props);

    this.onNewPetModal = this.onNewPetModal.bind(this);
    this.onNewPetSubmit = this.onNewPetSubmit.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.props.getPets({role: userRols.parent});
  }

  onNewPetModal() {
    this.props.showAddPetModal(!this.props.isAddNewPetOpen);
  }

  onNewPetSubmit(pet) {
    this.props.addPet(pet);
  }

  onRemove(pet) {
    this.props.removePet(pet);
  }

  onEdit(pet) {
    this.props.editPet(pet);
  }

  render() {
    const { petList, isLoadingAddPet, isAddNewPetOpen } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Pets" breadcrumbItem="Pets" />

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <PetList
                      list={petList}
                      onRemoveItem={this.onRemove}
                      history={this.props.history}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <AddPet
          isLoading={isLoadingAddPet}
          isNewPetModalOpen={isAddNewPetOpen}
          onSubmit={this.onNewPetSubmit}
          onCancel={this.onNewPetModal}
        />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Pet } = state;
  return Pet;
};

export default connect(mapStatetoProps, {
    getPets,
    addPet,
    showAddPetModal,
    removePet,
    editPet
  })(Pets);
