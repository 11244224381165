import {Pagination} from "antd";
import {useEffect, useState} from "react";

const Paging = ({setPage, pages, currentPage}) => {

  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    if(!totalPages || (pages && totalPages !== pages * 20)) {
      setTotalPages(pages * 20);
    }
  }, [pages]);

  return <Pagination aria-label="Page navigation"
                     className="ml-4 m-3"
                     simple
                     defaultPageSize={20}
                     current={currentPage}
                     total={totalPages}
                     onChange={(page) => setPage(page)} />;
}

export default Paging
