import React from 'react';
import {Form, Input, Row, Col} from 'antd';
import {userRols} from "../../../helpers/userRols";

const HotelFormData = ({role}) => {
  return (
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <Form.Item
          name={["metadata", "siteUrl"]}
          label="Site Url"
          rules={[{required: true, message: 'Please write site URL'}]}
        >
          <Input placeholder="Site Url"/>
        </Form.Item>
      </Col>
      {/*<Col xs={24} md={12}>*/}
      {/*  <Form.Item*/}
      {/*    name={["metadata", "dateOfCourtRegistration"]}*/}
      {/*    label="Date Of Court Registration"*/}
      {/*    rules={[{required: true, message: 'Please write date of court registration'}]}*/}
      {/*  >*/}
      {/*    <Input placeholder="Date Of Court Registration"/>*/}
      {/*  </Form.Item>*/}
      {/*</Col>*/}
      <Col xs={24} md={12}>
        <Form.Item
          name={["metadata", "businessName"]}
          label={userRols.shelter === role ? 'Organization Name' :"Hotel name"}
          rules={[{required: true, message: userRols.shelter === role ? 'Please write organization name' : 'Please write hotel name'}]}
        >
          <Input placeholder="Site Url"/>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default HotelFormData;
