import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import TranslationService from "./service";
import {TranslationTypes} from "./actionTypes";
import {
    addTranslationError,
    addTranslationSuccessful,
    editTranslationError,
    editTranslationSuccessful,
    getTranslation,
    getTranslationError,
    getTranslationSuccessful,
    getTypeTranslationError,
    getTypeTranslationSuccessful,
    removeTranslationError,
    removeTranslationSuccess,
} from "./actions";

function* get({payload}) {
    try {
        const response = yield call(TranslationService.getTranslations, payload);
        yield put(getTranslationSuccessful(response));
    } catch (error) {
        yield put(getTranslationError(error));
    }
}
function* getType({payload}) {
    try {
        const response = yield call(TranslationService.getTypeTranslations, payload);
        yield put(getTypeTranslationSuccessful(response));
    } catch (error) {
        yield put(getTypeTranslationError(error));
    }
}
function* add({payload}) {
    try {
        const response = yield call(TranslationService.addTranslations, payload);
        yield put(addTranslationSuccessful(response));
        yield put(getTranslation({page: 1}))
    } catch (error) {
        yield put(addTranslationError(error));
    }
}
function* edit({payload}) {
    try {
        const response = yield call(TranslationService.editTranslations, payload);
        yield put(editTranslationSuccessful(response));
        yield put(getTranslation({page: 1}))
    } catch (error) {
        yield put(editTranslationError(error));
    }
}

function* unActive({payload}) {
    try {
        const response = yield call(TranslationService.unActiveTranslations, payload);
        yield put(removeTranslationSuccess(response));
        yield put(getTranslation({page: 1}))
    } catch (error) {
        yield put(removeTranslationError(error));
    }
}

export function* watchGetTransaction() {
    yield takeEvery(TranslationTypes.TRANSLATION_GET, get);
}
export function* watchGetTypeTransaction() {
    yield takeEvery(TranslationTypes.TRANSLATION_GET_TYPE, getType);
}
export function* watchAddTransaction() {
    yield takeEvery(TranslationTypes.TRANSLATION_ADD, add);
}
export function* watchEditTransaction() {
    yield takeEvery(TranslationTypes.TRANSLATION_EDIT, edit);
}
export function* watchUnActiveTransaction() {
    yield takeEvery(TranslationTypes.TRANSLATION_REMOVE, unActive);
}



function* translationSaga() {
    yield all([
        fork(watchGetTransaction),
        fork(watchGetTypeTransaction),
        fork(watchAddTransaction),
        fork(watchEditTransaction),
        fork(watchUnActiveTransaction),
    ]);
}

export default translationSaga;
