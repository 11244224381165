import ApiService from "../../helpers/ApiHelper";

class PetService {


    static getPets({role}) {
        return ApiService.request(
            `/api/v1/pets/all?typeRole=${role}`,
            "get",
            null
        )
    }

    static addPet(payload) {
        return ApiService.request(
            "/api/v1/pets/",
            "post",
            payload
        )
    }

    static removePet(id) {
        return ApiService.request(
            `/api/v1/pets/${id}`,
            "delete",
            null
        )
    }

    static editPet(pet) {
        return ApiService.request(
            `/api/v1/pets/${pet.id}`,
            "patch",
            pet
        )
    }

    static adoptedPet(petId) {

        console.log(petId)
        return ApiService.request(
            `/api/v1/pets/shelter/adopt`,
            "patch",
            petId
        )
    }
}

export default PetService;
