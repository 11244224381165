import React, { Component } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ParentRemove from "./ParentRemove";
import moment from "moment";

class ParentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpen: false,
      isRemoveModalOpen: false,
      removeParent: null,
      editParent: null,
      parentListData: [],
    };
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditSubmit = this.onEditSubmit.bind(this);
    this.onRemoveOpen = this.onRemoveOpen.bind(this);
    this.onRemoveSubmit = this.onRemoveSubmit.bind(this);
  }

  onEditOpen(parent) {
    this.setState((prevState) => ({
      isEditModalOpen: !prevState.isEditModalOpen,
      editParent: parent,
    }));
  }

  componentDidMount() {
    //  get users
  }

  onRemoveOpen(parent) {
    this.setState((prevState) => ({
      isRemoveModalOpen: !prevState.isRemoveModalOpen,
      removeParent: parent,
    }));
  }

  onRemoveSubmit(parent) {
    this.props.onRemoveItem(parent);
  }

  onEditSubmit(parent) {
    this.props.onEditItem(parent);
  }

  getParentsList(parentListData) {
    if (parentListData) {
      const parentListView = [];
      parentListData.map((user) => {
        if (user.role === "PARENT") {
          parentListView.push(
            <Tr key={user._id}>
              <Th>{user.name} {user.surname}</Th>
              <Th>{user.email} <br/>
              <div>{user.telephone}</div></Th>
              <Th>{user?.emailSubscriptions.marketing ? 'Yes' : 'No'}</Th>
              <Th>{user.address && Object.values(user?.address).map((addr, i) => {
                return <div key={i}>{addr}</div>
              })}</Th>
              <Th>{moment(user.createdAt).format('DD-MM-YYYY HH:mm')}</Th>
              <Th>{user.verified ? 'Yes' : 'No'}</Th>
              <Th>{user.active ? 'Yes' : 'No'}</Th>
              <Td>
                <div className="button-items">
                  <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => {
                      this.onRemoveOpen(user);
                    }}
                  >
                    <i className="bx bx-trash-alt font-size-16 align-middle"></i>
                  </button>
                </div>
              </Td>
            </Tr>
          );
        }
      });

      return parentListView;
    } else {
      return null;
    }
  }

  render() {
    const { isRemoveModalOpen, removeParent } =
      this.state;
    const {list} = this.props
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered"
          >
            <Thead>
              <Tr>
                <Th data-priority="3">Name </Th>
                <Th data-priority="3">Email</Th>
                <Th data-priority="3">Email Marketing</Th>
                <Th data-priority="3">Address</Th>
                <Th data-priority="1">Creation Date</Th>
                <Th data-priority="1">Verified</Th>
                <Th data-priority="1">Active</Th>
                <Th data-priority="1">Remove</Th>
              </Tr>
            </Thead>
            <Tbody>{this.getParentsList(list?.users)}</Tbody>
          </Table>
        </div>
        <ParentRemove
          data={removeParent}
          onSubmit={this.onRemoveSubmit}
          isRemoveModalOpen={isRemoveModalOpen}
          onCancel={this.onRemoveOpen}
        />
      </div>
    );
  }
}

export default ParentList;
