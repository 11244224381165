import {LogsActionTypes} from "./actionTypes";

export const getLogs = (payload) => {
  return {
    type: LogsActionTypes.AUDIT_LOG_LIST_GETS,
    payload
  }
}
export const getLogsSuccessful = (payload) => {
  return {
    type: LogsActionTypes.AUDIT_LOG_LIST_GETS_SUCCESSFUL,
    payload
  }
}
export const getLogsError = (payload) => {
  return {
    type: LogsActionTypes.AUDIT_LOG_LIST_GETS_ERROR,
    payload
  }
}

export const getLog = (payload) => {
  return {
    type: LogsActionTypes.AUDIT_LOG_LIST_GET,
    payload
  }
}
export const getLogSuccessful = (payload) => {
  return {
    type: LogsActionTypes.AUDIT_LOG_LIST_GET_SUCCESSFUL,
    payload
  }
}
export const getLogError = (payload) => {
  return {
    type: LogsActionTypes.AUDIT_LOG_LIST_GET_ERROR,
    payload
  }
}

