import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';

import PropTypes from "prop-types";

import {Alert, Card, CardBody, Col, Container, Row, Label} from "reactstrap";

// Redux
import {connect} from "react-redux";

import {Formik, Field, Form, ErrorMessage} from "formik";
import * as Yup from "yup";

// actions
import {apiError, loginUser, socialLogin} from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logoPng from "../../assets/images/mypet.svg";


const Login = ({
                   apiError,
                   error,
                   history,
                   loginUser,
                   socialLogin
               }) => {

    const [hidePass, setHidePass] = useState(true)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        apiError("")
    })

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Back !</h5>
                                                <p>Sign in to continue to Groundfloor.</p>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid"/>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div className="auth-logo">
                                        <div className="avatar-md profile-user-wid mb-4">
                        <span
                            style={{
                                backgroundColor: "white",
                            }}
                            className="avatar-title rounded-circle"
                        >
                          <img src={logoPng} alt="" height="26"/>
                        </span>
                                        </div>
                                    </div>
                                    <div className="p-2">
                                        {error && error ? (
                                            <Alert color="danger">
                                                The email or password you entered is incorrect
                                            </Alert>
                                        ) : null}
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                email,
                                                password,
                                            }}
                                            validationSchema={Yup.object().shape({
                                                email: Yup.string().required(
                                                    "Please Enter Your Email"
                                                ),
                                                password: Yup.string().required(
                                                    "Please Enter Valid Password"
                                                ),
                                            })}
                                            onSubmit={values => {
                                                loginUser(values, {history: (history || navigate)}).then(() => {

                                                });
                                            }}
                                        >
                                            {({errors, status, touched}) => (
                                                <Form className="form-horizontal">
                                                    <div className="mb-3">
                                                        <Label for="email" className="form-label">
                                                            Email
                                                        </Label>
                                                        <Field
                                                            name="email"
                                                            type="text"
                                                            className={
                                                                "form-control" +
                                                                (errors.email && touched.email
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                        />
                                                        <ErrorMessage
                                                            name="email"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label for="password" className="form-label">
                                                            Password
                                                        </Label>
                                                        <div className="input-group auth-pass-inputgroup">
                                                            <Field
                                                                name="password"
                                                                type={
                                                                    hidePass ? "password" : "text"
                                                                }
                                                                autoComplete="true"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.password && touched.password
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <button
                                                                className="btn btn-light "
                                                                type="button"
                                                                id="password-addon"
                                                                onClick={() => setHidePass(!hidePass)}

                                                            >
                                                                <i className="mdi mdi-eye-outline"></i>
                                                            </button>
                                                        </div>
                                                        <ErrorMessage
                                                            name="password"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </div>

                                                    {/* <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div> */}

                                                    <div className="mt-3 d-grid">
                                                        <button
                                                            className="btn btn-primary btn-block"
                                                            type="submit"
                                                        >
                                                            Log In
                                                        </button>
                                                    </div>

                                                    <div className="mt-4 text-center">
                                                        <ul className="list-inline"></ul>
                                                    </div>

                                                    {/* <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1" /> Forgot your
                                password?
                              </Link>
                            </div> */}
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </CardBody>
                            </Card>
                            {/* <div className="mt-5 text-center">
                  <p>
                    Don&apos;t have an account ?
                    <Link to="register" className="fw-medium text-primary">
                      Signup Now
                    </Link>
                  </p>
                  <p>
                    © {new Date().getFullYear()} Skote. Crafted with
                    <i className="mdi mdi-heart text-danger" /> by Themesbrand
                  </p>
                </div> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

Login.propTypes = {
    apiError: PropTypes.any,
    error: PropTypes.any,
    history: PropTypes.object,
    loginUser: PropTypes.func,
    socialLogin: PropTypes.func,
};

const mapStateToProps = state => {
    const {error} = state.Login;
    return {error};
};

export default connect(mapStateToProps, {loginUser, apiError, socialLogin})(Login);
