import {InitiatedBookingActionTypes} from "./actionTypes";

const initialState = {
  initiatedListError: null, message: null
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case InitiatedBookingActionTypes.INITIATED_BOOKING_LIST_GET:
      state = {
        ...state,
        initiatedList: null,
        initiatedListError: null
      }
      break;
    case InitiatedBookingActionTypes.INITIATED_BOOKING_LIST_GET_SUCCESSFUL:
      state = {
        ...state,
        initiatedList: action.payload,
        initiatedListError: null
      }
      break;
    case InitiatedBookingActionTypes.INITIATED_BOOKING_LIST_GET_ERROR:
      state = {
        ...state,
        initiatedList: null,
        initiatedListError: action.payload
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
}

export default account;
