import React, {useState, useRef, useEffect} from 'react';
import {Modal} from "reactstrap";
import {Form, Col, Row, Button, Tabs, Checkbox} from 'antd'; // Import relevant components from antd

import ProfessionalAddress from "./ProfessionalAddress";
import ProfessionalPersonalData from "./ProfessionalPersonalData";
import ProfessionalUniversity from "./ProfessionalUniversity";
import IdDocumentPreview from "../../../components/IDDocuments/preview";
import IdCardComponents from "../../../components/IDDocuments/idCardComponents";
import ProfessionalCompanyData from "./ProfessionalCompanyData";
import DynamicFieldSet from "./DynamicFieldSet";

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    DownOutlined, MinusCircleOutlined,
    PullRequestOutlined, StopOutlined
} from "@ant-design/icons";
import {ProfessionalActionTypes} from "../../../store/professionals/actionTypes";
import {userRols} from "../../../helpers/userRols";
import HotelFormData from "../../Hotels/List/HotelFormData";
import ClinicFormData from "../../Clinics/List/ClinicFormData";
import ProfilePictureField from "./ProfilePictureField";


dayjs.extend(utc)
dayjs.extend(timezone)

function ProfessionalEdit({
                              admin,
                              data,
                              isEditModalOpen,
                              onCancel,
                              onSubmit,
                              onUpdate,
                              isLoading,
                              removeOrder,
                              createProfessionalMetaHeadData
                          }) {
    const [form] = Form.useForm();

    const [user, setUser] = useState({Uin: false, Degree: false, vetTerms: data?.vetTerms});
    const [idCard, setIdCard] = useState()
    const [languages, setLanguages] = useState()
    const [defaultValue, setDefaultValue] = useState(null)

    useEffect(() => {
        form.resetFields()
        setDefaultValue({
            ...data,
            metadata: {
                ...data?.metadata,
                idCard: {
                    ...data?.metadata?.idCard,
                    idCardValidTo: dayjs.utc(data?.metadata?.idCard?.idCardValidTo).local().tz(dayjs.tz.guess())
                },
                vat: {
                    ...data?.metadata?.vat,
                    isFirmRegistered: data?.metadata?.vat?.isFirmRegistered?.toString(),
                    isRegistered: data?.metadata?.vat?.isRegistered?.toString(),
                },
                yearsOfPractice: data?.metadata?.yearsOfPractice ? data?.metadata?.yearsOfPractice?.map((item) => ({
                    ...item,
                    from: dayjs.utc(item?.from).local().tz(dayjs.tz.guess()),
                    to: dayjs.utc(item?.to).local().tz(dayjs.tz.guess()),
                    off: dayjs.utc(item?.off).local().tz(dayjs.tz.guess()),
                })) : [{from: dayjs.utc().local().tz(dayjs.tz.guess()), to: dayjs.utc().local().tz(dayjs.tz.guess())}],
                specializations: data?.metadata?.specializations?.length > 0 ? data?.metadata?.specializations : [{specializationName: ''}],
                professionalQualifications: data?.metadata?.professionalQualifications ?
                    data?.metadata?.professionalQualifications?.map((qualification) => ({
                        ...qualification,
                        from: dayjs.utc(qualification?.from).local().tz(dayjs.tz.guess()),
                        to: dayjs.utc(qualification?.to).local().tz(dayjs.tz.guess()),
                        off: dayjs.utc(qualification?.off).local().tz(dayjs.tz.guess()),
                    })) : [{
                        university: '',
                        degree: '',
                        fileName: '',
                        document: null,
                        from: dayjs.utc().local().tz(dayjs.tz.guess()),
                        to: dayjs.utc().local().tz(dayjs.tz.guess()),
                        off: dayjs.utc().local().tz(dayjs.tz.guess()),
                    }],
                otherQualifications: data?.metadata?.otherQualifications && data?.metadata?.otherQualifications?.length > 0 ? data?.metadata?.otherQualifications?.map(
                    (qualification) => ({
                        ...qualification,
                        from: dayjs.utc(qualification?.from).local().tz(dayjs.tz.guess()),
                        to: dayjs.utc(qualification?.to).local().tz(dayjs.tz.guess()),
                    }),
                ) : [{
                    organizationName: '',
                    from: dayjs.utc().local().tz(dayjs.tz.guess()),
                    to: dayjs.utc().local().tz(dayjs.tz.guess())
                }],
            }
        })
        if (data?.metadata?.idCard) {
            setIdCard(data?.metadata?.idCard)
        }
        setLanguages(data?.languages)


    }, [data, form]);
    const checkDisabled = () => {
        return !user?.Uin || !user?.Degree /*|| !user?.ContractCheckBox*/ || !user?.vetTerms
    }

    const onFinish = (values) => {
        const userData = data;
        onUpdate({...userData, ...values, metadata: {...userData.metadata, ...values.metadata}});
        onCancel()
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    console.log(defaultValue)
    const saveButtons = !isLoading ? (
        <div style={{border: 0, display: "flex", alignItems: "center"}}>
            <Row gutter={16}>
                <Col xs={24}>
                    <Row gutter={16}>
                        <Col xs={12} md={4} style={{marginTop: '3px'}}>
                            <Button icon={<CloseCircleOutlined className="font-size-16 align-baseline"/>} type="button"
                                    onClick={() => {
                                        data.status = 'No'
                                        onSubmit(data)
                                        onCancel()
                                    }} className="btn-danger"
                                    style={{minWidth: '100px', display: 'flex', alignItems: 'center'}}>Reject</Button>
                        </Col>

                        <Col xs={12} md={4} style={{marginTop: '3px'}}>
                            <Button icon={<CheckCircleOutlined className="font-size-16 align-baseline"/>}
                                    htmlType="button"
                                    onClick={() => {
                                        data.status = 'Yes'
                                        data.approvedDate = dayjs().format('YYYY-MM-DD')
                                        onSubmit(data)
                                        onCancel()
                                    }} disabled={data?.role === userRols.vet && checkDisabled()}
                                    className={data?.role !== userRols.vet || !checkDisabled() ? "btn-success" : "btn-secondary"}
                                    style={{minWidth: '100px', display: 'flex', alignItems: 'center'}}
                            >Approve</Button>
                        </Col>
                        <Col xs={12} md={4} style={{marginTop: '3px'}}>
                            <Button icon={<MinusCircleOutlined className="font-size-16 align-baseline"/>}
                                    onClick={() => {
                                        data.status = 'Pending'
                                        removeOrder(data,
                                            'Do you want to \'Pending\' this professional?',
                                            'Disable professional',
                                            ProfessionalActionTypes.DISABLE);
                                        onCancel();
                                    }}
                                    className="btn-default"
                                    style={{minWidth: '100px', display: 'flex', alignItems: 'center'}}
                            >Pending</Button>
                        </Col>


                        <Col xs={12} md={4} style={{marginTop: '3px'}}>
                            <Form.Item>
                                <Button icon={<PullRequestOutlined className="font-size-16 align-baseline"/>}
                                        htmlType="submit"
                                        className="btn-warning"
                                        style={{minWidth: '100px', display: 'flex', alignItems: 'center'}}
                                >Update</Button>
                            </Form.Item>
                        </Col>

                        <Col xs={12} md={4} style={{marginTop: '3px'}}>
                            <Button
                                type="button"
                                className="btn-danger waves-effect waves-light"
                                icon={<DeleteOutlined className="font-size-16 align-baseline"/>}
                                onClick={() => {
                                    removeOrder(data,
                                        'Do you want to remove this professional?',
                                        'Remove professional',
                                        ProfessionalActionTypes.REMOVE);
                                }}
                                style={{minWidth: '100px', display: 'flex', alignItems: 'center'}}
                            >
                                Remove
                            </Button>
                        </Col>

                        <Col xs={12} md={4} style={{marginTop: '3px'}}>
                            <Button type="button" icon={<StopOutlined className="font-size-16 align-baseline"/>}
                                    onClick={onCancel}
                                    className="btn-secondary"
                                    style={{minWidth: '100px', display: 'flex', alignItems: 'center'}}>Cancel</Button>
                        </Col>
                    </Row>
                    {data?.role === userRols.vet && <div className="mt-2">
                        {/*<Checkbox onChange={({target}) => setUser({...user, [target.name]: target.checked})} name={"Specialization"}*/}
                        {/*>Specialization</Checkbox>*/}
                        <Checkbox name={"vetTerms"} disabled={true}
                                  checked={user?.vetTerms}>{user.role} Terms</Checkbox>
                        <Checkbox onChange={({target}) => setUser({...user, [target.name]: target.checked})}
                                  name={"Uin"}>UIN</Checkbox>
                        <Checkbox onChange={({target}) => setUser({...user, [target.name]: target.checked})}
                                  name={"Degree"}>Degree</Checkbox>
                    </div>}
                </Col>
            </Row>
        </div>
    ) : <div className="modal-footer">
        <div className="spinner-border text-success m-1" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>

    return (
        <Modal scrollable={true} style={{
            maxHeight: '700px', margin: '0 auto'
        }} size="lg" isOpen={isEditModalOpen} toggle={onCancel}>
            <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row"
                initialValues={defaultValue}
            >
                <div className="modal-header">
                    {saveButtons}
                    <button type="button" onClick={onCancel} className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{padding: '20px', maxHeight: '580px'}}>


                    <Row gutter={16}>
                        <Col xs={24}>
                            <Tabs className="card-tabs" type='card' size="small" tabBarExtraContent={''}>
                                <Tabs.TabPane tab="Profile data" key='profile-data'>
                                    <ProfessionalPersonalData professionalRole={data?.role} languages={languages}/>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Address" key='address'>
                                    <ProfessionalAddress typeAddress={'address'} form={form}/>
                                </Tabs.TabPane>
                                {![userRols.clinic, userRols.hotel, userRols.shelter].includes(data?.role) &&
                                    <Tabs.TabPane tab="Еducation and qualificatios" key='university'>
                                        <ProfessionalUniversity user={data} form={form}/>
                                    </Tabs.TabPane>}
                                {![userRols.shelter].includes(data?.role) &&
                                    <Tabs.TabPane tab="Company data" key='company data'>
                                        <ProfessionalCompanyData
                                            iban={data?.metadata?.IBAN}
                                            feeAmount={data?.metadata?.feeAmount}
                                            vat={data?.metadata?.vat}
                                            adminRole={admin?.role}/>
                                        <DynamicFieldSet
                                            keyName={['metadata', 'UIN']}
                                            subKey={'title'}
                                            title={'UIN'}
                                            documentTitle={'UIN Documents'}
                                            documents={[data?.metadata?.UIN]}
                                            fieldKey={['metadata', 'UIN']}
                                            subKeyTwo={'city'} titleTwo={'City'}
                                            inputTwoVisible={true}
                                            form={form}/>
                                    </Tabs.TabPane>}
                                {![userRols.clinic, userRols.hotel, userRols.shelter].includes(data?.role) &&
                                    <Tabs.TabPane tab="ID card" key='id-card'>
                                        {<IdDocumentPreview idCard={idCard}/>}
                                        {<IdCardComponents/>}
                                        <ProfessionalAddress typeAddress={'address2'} form={form}/>
                                    </Tabs.TabPane>}
                                {[userRols.hotel].includes(data?.role) &&
                                    <Tabs.TabPane tab="Hotel Data" key='hotel-data'>
                                        <HotelFormData/>
                                    </Tabs.TabPane>}
                                {[userRols.shelter].includes(data?.role) &&
                                    <Tabs.TabPane tab="Shelter Data" key='hotel-data'>
                                        <HotelFormData role={data?.role}/>
                                    </Tabs.TabPane>}
                                {[userRols.clinic].includes(data?.role) &&
                                    <Tabs.TabPane tab="Oficial Representatives" key='oficial-representatives'>
                                        <ClinicFormData/>
                                    </Tabs.TabPane>}
                                <Tabs.TabPane tab="Profile Picture" key='profile-picture'>
                                    <ProfilePictureField photo={data?.photo} form={form}/>
                                </Tabs.TabPane>
                            </Tabs>
                        </Col>
                    </Row>

                </div>

            </Form>
        </Modal>
    );
}

export default ProfessionalEdit;
