import React, {Component} from "react";
import {Row, Col, Card, CardBody, Button} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
    getBookings,
} from "../../store/booking/actions";
import {connect} from "react-redux";
import BookingList from "./List/BookingList";
import Paging from "../../components/Pagination/Pagination";
import {Space, Spin} from "antd";
import moment from "moment";
import Search from "../../components/Search/Search";
import Refund from "./Refund";
import axios from "axios";
import baseURL from "../../config";
import fileDownload from "js-file-download";
import LoadingCard from "../../components/LoadingCard/LoadingCard";

class Bookings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: {text: '', filter: '', tagsFilters: {}},
            currentPage: 1,
            currentDate: moment(new Date()),
            filterModalOpen: false,
            refundModalOpen: false,
            refundBooking: {}
        }
        this.options = [
            {keySearch: 'parentID.name_parentID.surname', titleSearch: 'Name'},
            {keySearch: 'price', titleSearch: 'Price'},
            // {keySearch: 'professionalID.role', titleSearch: 'Professional role'},
            {keySearch: 'professionalID.name_professionalID.surname', titleSearch: 'Professional Name'},
            {keySearch: 'time', titleSearch: 'Appointment Date'},

        ]
    }

    componentDidMount() {
        this.props.getBookings({page: 1, bookingsType: 'paid'});
    }

    onRefundOpen = (booking) => {
        this.setState({
            refundBooking: booking,
        });
    }
    setFilterModalOpen = (filterModalOpen) => {
        this.setState({filterModalOpen})
    }

    setRefundModalOpen = (refundModalOpen) => {
        this.setState({refundModalOpen})
    }
    refreshPage = () => {
        this.setState({search: {filter: '', text: '', tagsFilters: {}}})
        this.props.getBookings({page: 1, bookingsType: 'paid'});
    }

    setPage = (currentPage) => {
        this.setState({currentPage})
        this.props.getBookings({search: this.state.search, page: currentPage, bookingsType: 'paid'});
    }

    onSubmit = (e, search) => {
        e.preventDefault()
        if ((search.text && search.filter) || Object.keys(search.tagsFilters).length) {
            this.props.getBookings({search, page: 1, bookingsType: 'paid'});
        } else {
            this.props.getBookings({page: 1, bookingsType: 'paid'});
        }
        this.setState({currentPage: 1, search, filterModalOpen: false})
    }

    downloadBookingsToExel = (e) => {
        e.preventDefault();
        const filename = new Date().toISOString() + ".xml";
        const query = `?search=${JSON.stringify(this.state.search)}`
        axios
            .post(`${baseURL}/api/v1/bookings/export-appointments/${query}`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            .then((res) => {
                fileDownload(res.data, filename);
                console.log(res, filename, res);
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        const {search, currentPage, filterModalOpen, refundModalOpen, refundBooking} = this.state
        const {bookingList, loadingBookings} = this.props;
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Bookings" breadcrumbItem="Bookings"/>

                        <Row>
                            <Col>
                                {!loadingBookings ? <Card>
                                    <div className="d-inline-flex ant-row-space-between align-items-center">
                                        <Paging setPage={this.setPage} currentPage={currentPage}
                                                pages={(!(search.text || Object.keys(search.tagsFilters).length) ? bookingList?.pages : 1)}/>
                                        <Space direction="horizontal" className="mr-4">
                                            <Space direction="horizontal" className="mr-4">
                                                <Button type={'button'}
                                                        className='btn btn-info waves-effect waves-light search-btn'
                                                        onClick={this.downloadBookingsToExel}>Download Parents</Button>
                                            </Space>
                                            <Button type={'button'}
                                                    className='btn btn-info waves-effect waves-light search-btn'
                                                    onClick={() => this.setFilterModalOpen(!filterModalOpen)}><i
                                                className="bx bx-filter-alt align-middle"></i></Button>
                                            {(search.text || Object.keys(search.tagsFilters).length > 0) &&
                                                <Button type={'button'}
                                                        className='btn btn-dark waves-effect waves-light search-btn'
                                                        onClick={this.refreshPage}><i
                                                    className='bx bx-revision align-middle'></i></Button>}
                                        </Space>
                                    </div>
                                    <CardBody>
                                        <BookingList
                                            list={bookingList?.bookings}
                                            onRemoveItem={this.onRemove}
                                            onEditItem={this.onEdit}
                                            history={this.props.history}
                                            onRefundOpen={this.onRefundOpen}
                                            setRefundModalOpen={this.setRefundModalOpen}
                                        />
                                    </CardBody>
                                    <Paging setPage={this.setPage} currentPage={this.state.currentPage}
                                            pages={(!(search.text || Object.keys(search.tagsFilters).length) ? bookingList?.pages : 1)}/>
                                </Card> : <LoadingCard />}
                            </Col>
                        </Row>
                    </div>
                </div>
                <Search searchModalOpen={filterModalOpen}
                        options={this.options}
                        filterData={['time']}
                        searchVar={search}
                        tags={bookingList?.tags}
                        onSubmit={this.onSubmit}
                        onCancel={() => this.setFilterModalOpen(false)}/>
                {refundModalOpen && <Refund isNewBookingModalOpen={refundModalOpen}
                                            data={refundBooking?.refund}
                                            onCancel={() => this.setRefundModalOpen(false)}
                                            isLoading={refundBooking?.refund.length}
                                            handleChange={this.handleChange}/>}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {Booking} = state;
    return Booking;
};

export default connect(mapStateToProps, {
    getBookings,

})(Bookings);
