import React, {Component, useEffect} from 'react';
import { connect } from 'react-redux';

import { logoutUser } from '../../store/actions';
import {useNavigate} from "react-router-dom";

const Logout = ({logoutUser}) => {
    const history = useNavigate()
    /**
     * Redirect to login
     */
    useEffect(() => {
        // emit the event
        logoutUser(history);
    });

        return <React.Fragment />;
}

export default
    connect(
        null,
        { logoutUser }
    )(Logout);
