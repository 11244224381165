import React, {useEffect} from 'react';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Card, CardBody, Col, Row} from "reactstrap";
import ShelterPetList from "./List/ShelterPetList";
import {connect} from "react-redux";
import {addPet, adoptedShelterPet, editPet, getPets, removePet, showAddPetModal} from "../../store/pet/actions";
import {userRols} from "../../helpers/userRols";

const ShelterPet = ({petList, adoptedShelterPet, getPets, removePet}) => {

    useEffect(() => {
        getPets({role: userRols.shelter})
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Pets" breadcrumbItem="Pets" />

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <ShelterPetList
                                        petListData={petList}
                                        onRemoveItem={removePet}
                                        adoptedShelterPet={adoptedShelterPet}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { Pet } = state;
    return Pet;
};

export default connect(mapStateToProps, {
    getPets,
    addPet,
    showAddPetModal,
    removePet,
    editPet,
    adoptedShelterPet
})(ShelterPet);