import React, {useEffect, useState} from 'react';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import AddTranslation from "./AddTranslation";
import {connect} from "react-redux";
import TranslationList from "./List/TranslationList";
import {getTranslation, removeTranslation} from "../../store/translations/actions";
import Paging from "../../components/Pagination/Pagination";
import {Space} from "antd";
import Search from "../../components/Search/Search";

const Translations = ({ translationList, isLoadingAddTranslation, removeTranslation, getTranslation }) => {
  const [onAddNewTranslationsModal, setOnAddNewTranslationsModal] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [search, setSearch] = useState({filter: '', text: '', tagsFilters: {}})
  const options = [
    {keySearch: 'key', titleSearch: 'Key'},
    {keySearch: 'translations.en', titleSearch: 'Translations En'},
    {keySearch: 'translations.en', titleSearch: 'Translations Bg'},
    {keySearch: 'createdAt', titleSearch: 'Created at'},

  ]

  useEffect(() => {
    getTranslation({page: currentPage})
  }, [])

  const onSubmit = (e, search) => {
    e.preventDefault()
    if ((search.text && search.filter) || Object.keys(search.tagsFilters).length) {
      getTranslation({search, page: 1})
    } else {
      getTranslation({page: currentPage})
    }
    setCurrentPage(1)
    setSearch(search)
    setFilterModalOpen(false)
  }
  const refreshPage = () => {
    setSearch({filter: '', text: '', tagsFilters: {}})
    getTranslation({page: 1})
  }
  const setPage = (currentPage) => {
    setCurrentPage(currentPage)
    getTranslation({page: currentPage})
  }
  const onNewTranslationSubmit = () => {
    console.log('submit')
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Service Translation" breadcrumbItem="Service Translation"/>

          <Row>
            <Col>
              <Card>
                {/*<div className="d-inline-flex ant-row-space-between align-items-center">*/}
                {/*  <Paging setPage={setPage} currentPage={currentPage} pages={translationList?.pages}/>*/}
                {/*  <Space direction="horizontal" className="mr-4">*/}
                {/*    <Button type={'button'} className='btn btn-info waves-effect waves-light search-btn'*/}
                {/*            onClick={() => setFilterModalOpen(!filterModalOpen)}><i*/}
                {/*      className="bx bx-filter-alt align-middle"></i></Button>*/}
                {/*    {(search.text || Object.keys(search.tagsFilters).length > 0) &&*/}
                {/*      <Button type={'button'} className='btn btn-dark waves-effect waves-light search-btn'*/}
                {/*              onClick={refreshPage}><i*/}
                {/*        className='bx bx-revision align-middle'></i></Button>}*/}
                {/*  </Space>*/}
                {/*</div>*/}
                <CardHeader>
                  <Button
                    color="success"
                    className="btn btn-success waves-effect waves-light"
                    onClick={() => setOnAddNewTranslationsModal(!onAddNewTranslationsModal)}
                  >
                    {" "}
                    <i className=""> </i> Add Service Translation
                  </Button>
                </CardHeader>
                <CardBody>
                  {translationList && translationList?.translations && <TranslationList
                    lists={translationList?.translations}
                    disableService={(e)=> removeTranslation(e)}
                  />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <AddTranslation
        isLoading={isLoadingAddTranslation}
        isNewTranslationModalOpen={onAddNewTranslationsModal}
        onSubmit={onNewTranslationSubmit}
        onCancel={() => setOnAddNewTranslationsModal(false)}
      />
      {/*<Search searchModalOpen={filterModalOpen}*/}
      {/*        options={options}*/}
      {/*        filterData={['createdAt']}*/}
      {/*        searchVar={search}*/}
      {/*        tags={translationList?.translations}*/}
      {/*        onSubmit={onSubmit}*/}
      {/*        onCancel={() => setFilterModalOpen(false)}/>*/}
    </React.Fragment>
  );
}
const mapStateToProps = ({Translations}) => {

  return Translations
}

export default connect(mapStateToProps, {getTranslation, removeTranslation})(Translations);
