import ApiService from "../../helpers/ApiHelper";

class PaymentService {

    static addPayment(payload) {
        return ApiService.request(
            "/users/",
            "post",
            payload
        )
    }

    static removePayment(id) {
        return ApiService.request(
            `/users/${id}`,
            "delete",
            null
        )
    }
}

export default PaymentService;