import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
    getReceivedError,
    getReceivedSuccessful,
} from "./actions";
import ReceivedService from "./service";
import { ReceivedActionTypes } from "./actionTypes";

function* getAll() {
    try {
        const response = yield call(ReceivedService.getReceiveds, {});
        yield put(getReceivedSuccessful(response));
    } catch (error) {
        yield put(getReceivedError(error));
    }
}

export function* watchGetReceiveds() {
    yield takeEvery(ReceivedActionTypes.RECEIVED_LIST_GET, getAll);
}

function* receivedsSaga() {
    yield all([
        fork(watchGetReceiveds),
    ]);
}

export default receivedsSaga;
