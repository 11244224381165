import {connect} from "react-redux";
import {getStatistic} from "../../store/admin/actions";
import {Button, ButtonGroup, CardBody} from "reactstrap";
import {DatePicker, Space} from "antd";
import React, {useEffect, useState} from "react";
import moment from "moment/moment";

const HeaderHelperFilter = ({getStatistic}) => {
  const [datePickFrom, setDatePickFrom] = useState()
  const [datePickTo, setDatePickTo] = useState()

  useEffect(() => {
    getStatistics(moment(new Date()).subtract(7, 'd').format('YYYY-MM-DD'),  moment(new Date()).format('YYYY-MM-DD'))
  }, [])

  const setDaysToSearch = (num, attr) => {
    const dateFrom = moment(new Date()).subtract(num, attr).format('YYYY-MM-DD')
    const dateTo = moment(new Date()).format('YYYY-MM-DD')

    getStatistics(dateFrom, dateTo)
  }

  const renderActiveButtons = () => {
    const buttons = [{
      name: '7 days',
      num: 7,
      attr: 'd'
    }, {
      name: '4 week',
      num: 4,
      attr: 'w'
    }, {
      name: '10 week',
      num: 10,
      attr: 'w'
    }]
    return buttons.map((button, i) => {
      return <ButtonGroup key={i}>
        <Button onClick={(e) => {
          setDaysToSearch(button.num, button.attr)
          const query = document.querySelector(`.btn-primary`)
          query.className = 'btn btn-secondary btn-10px'

          if (e.target.id === `button${i}`) {
            e.target.className = "btn btn-primary btn-10px"
          }

        }} color={i === 0 ? 'primary' : "secondary"} size="10px" id={`button${i}`}>
          {button.name}
        </Button>
      </ButtonGroup>
    })

  }
  const onSubmit = () => {
    const dateFrom = datePickFrom.toString().split('-')
    const dateFromFormat = `${dateFrom[2]}-${dateFrom[1]}-${dateFrom[0]}`
    const dateTo = datePickTo.toString().split('-')
    const dateToFormat = `${dateTo[2]}-${dateTo[1]}-${dateTo[0]}`

    getStatistics(dateFromFormat, dateToFormat)
  }

  function getStatistics(dateFrom, dateTo) {
    getStatistic({dateFrom, dateTo})
  }

  return  <CardBody>
    <Space direction="horizental">
      {renderActiveButtons()}
    </Space>
    {/*Render Breadcrumb*/}

    <Space direction="horizental float-sm-right" style={{alignItems: 'center'}}>
      <DatePicker
        placeholder="From"
        onChange={(date, dateString) => {
          setDatePickFrom(dateString)
        }}
        defaultValue={datePickFrom}
        format="DD-MM-YYYY"
        style={{
          width: "130px",
          marginRight: "15px",
        }}
      />
      <DatePicker
        placeholder="To"
        onChange={(date, dateString) => {
          setDatePickTo(dateString)
        }}
        format="DD-MM-YYYY"
        style={{
          width: "130px",
          marginRight: "15px",
        }}
      />
      <ButtonGroup>
        <Button onClick={onSubmit} color="primary" size="10px">
          Search
        </Button>
      </ButtonGroup>
    </Space>
  </CardBody>
}

export default connect(() => {}, {getStatistic})(HeaderHelperFilter)
