import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
    getPetsError,
    getPetsSuccessful,
    addPetError,
    addPetSuccessful,
    showAddPetModalSuccess,
    removePetError,
    removePetSuccess,
    getPets,
    editPetError,
    editPetSuccess, adoptedShelterPetSuccess, adoptedShelterPetError
} from "./actions";
import PetService from "./service";
import { PetActionTypes } from "./actionTypes";
import {userRols} from "../../helpers/userRols";

function* get({payload: type}) {
    try {
        const response = yield call(PetService.getPets, {role: type.role});
        yield put(getPetsSuccessful(response));
    } catch (error) {
        yield put(getPetsError(error));
    }
}

function* addPet({ payload: pet }) {
    try {
        const response = yield call(PetService.addPet, pet);
        yield put(addPetSuccessful(response));
        yield put(getPets());
        
    } catch (error) {
        yield put(addPetError(error));
    }
}

function* showAddPet({ payload: showModal }) {
    yield put(showAddPetModalSuccess(showModal));
}

function* removePet({ payload: petId }) {
    try {
        const response = yield call(PetService.removePet, petId);
        yield put(removePetSuccess(response));
        yield put(getPets());
    } catch (error) {
        yield put(removePetError(error));
    }
}

function* edit({ payload: pet }) {
    try {
        const response = yield call(PetService.editPet, pet);
        yield put(editPetSuccess(response));
        yield put(getPets());
    } catch (error) {
        yield put(editPetError(error));
    }
}

function* adopted({ payload: petId }) {
    try {
        const response = yield call(PetService.adoptedPet, {petId});
        yield put(adoptedShelterPetSuccess(response));
        yield put(getPets({role: userRols.shelter}));
    } catch (error) {
        yield put(adoptedShelterPetError(error));
    }
}

export function* watchGetPet() {
    yield takeEvery(PetActionTypes.PET_LIST_GET, get);
}

export function* watchAddPet() {
    yield takeEvery(PetActionTypes.PET_ADD, addPet);
}

export function* watchShowAddPetModal() {
    yield takeEvery(PetActionTypes.PET_ADD_MODAL, showAddPet);
}

export function* watchRemovePet() {
    yield takeEvery(PetActionTypes.PET_REMOVE, removePet);
}

export function* watchEditPet() {
    yield takeEvery(PetActionTypes.PET_EDIT, edit);
}
export function* watchAdoptedPet() {
    yield takeEvery(PetActionTypes.PET_SHELTER_ADOPTED, adopted);
}

function* petsSaga() {
    yield all([
        fork(watchGetPet),
        fork(watchAddPet),
        fork(watchShowAddPetModal),
        fork(watchRemovePet),
        fork(watchEditPet),
        fork(watchAdoptedPet)
    ]);
}

export default petsSaga;
