import React, { Component } from 'react'
import { Row, Col, Label, FormGroup, Modal } from 'reactstrap'

import { AvForm, AvField } from 'availity-reactstrap-validation'
import FileUpload from "../../components/FileUpload/FileUpload";
import PaymentService from 'store/blog/service';
import axios from 'axios';
import baseURL from '../../config';

class AddPayment extends Component {
  constructor(props) {
    super(props)
    this.onCancel = this.onCancel.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    const newPayment = {
      technology: '',
    }
    this.state = { newPayment }
  }

  onCancel() {
    const { newPayment } = this.state
    newPayment.technology = ''
    this.state.value = []
    this.props.onCancel()
  }

  onSubmit(e, newPayment) {
    e.preventDefault() 
    axios.post(`${baseURL}/api/v1/blogs/`, this.state.newPayment)
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleChange(e) {
    const { name, value } = e.target
    const { newPayment } = this.state
    newPayment[name] = value
    this.setState({ newPayment })
  }

  onFileUploaded(file) {
    if (file.type == 'video') {
      this.setState({ video_url: file.url })
    } else if (file.type == 'image') {
      this.setState({ image: file })
    } 
  }

  render() {
    const { newPayment } = this.state
    return (
      <Modal isOpen={this.props.isNewPaymentModalOpen} toggle={this.onCancel}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add blog</h5>
          <button
            type="button"
            onClick={this.onCancel}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <AvForm className="needs-validation">
            <FormGroup>
              <Label htmlFor="validationCustom01">Technology </Label>
              <AvField
                name="technology"
                placeholder="Technology"
                type="text"
                errorMessage="Please write technology name"
                className="form-control"
                onChange={this.handleChange}
                value={newPayment.title}
                validate={{ required: { value: true } }}
                id="validationCustom01"
              />
            </FormGroup>
          </AvForm>
        </div>

        {this.props.isLoading && (
          <div className="modal-footer">
            <div className="spinner-border text-success m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        {!this.props.isLoading && (
          <div className="modal-footer">
            <button
              type="button"
              onClick={this.onCancel}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.onSubmit}
              className="btn btn-success waves-effect waves-light"
            >
              Add
            </button>
          </div>
        )}
      </Modal>
    )
  }
}

export default AddPayment
