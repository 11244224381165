import React from 'react';
import {FormGroup, Label} from "reactstrap";
import GenerateQRCode from "../../../components/QRCode/GenerateQRCode";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {AutoComplete, Col, Radio, Row} from "antd";

const PetWolkersMetadata = ({user, setUser}) => {
  const {metadata} = user;
  user.id = user._id;
  const handleChange = (e, currentMetadata, key) => {
    if (!e.target) {
      e = {
        target: {
          value: e
        }
      }
    }
    if(key.name && key.targetName && !currentMetadata[key.name]) {
      currentMetadata[key.name] = {[key.targetName]: e.target.value}
    } else if(key.name && key.targetName) {
      currentMetadata[key.name][key.targetName]= e.target.value
    }
    else {
      currentMetadata[key] = e.target.value
    }
    setUser({metadata: {...metadata, ...currentMetadata}})
  }
  const addMetadata = (e, currentMetadata, spec) => {
    e.preventDefault()
    let newSpec = {}
    Object.keys(spec).map((key, i) => {
      newSpec[key] = ''
    })
    currentMetadata.push({...newSpec})
    setUser({metadata})
  }
  const removeMetadata = (e, currentMetadata, currentDocument, index) => {
    e.preventDefault();
    delete currentMetadata[index];
    setUser({metadata});
  }
  const renderFieldsWithDocument = (documents, key, title, documentTitle) => {
    if (documents && typeof documents[0] !== 'undefined' && documents[0].document) {
      return documents.map((item, i) => {
        return <FormGroup className="col-12 row" key={i}>
          <FormGroup className="col-6">
            <Label htmlFor="validationCustom02">{title}</Label>
            <AvField
              name={key}
              placeholder={title}
              type="text"
              errorMessage={"Please write " + title}
              className="form-control"
              onChange={(e) => handleChange(e, item, key)}
              value={item[key]}
              validate={{required: {value: true}}}
              id="validationCustom02"
            />
          </FormGroup>
          <FormGroup className="col-6">
            <Label htmlFor="validationCustom02">{documentTitle}</Label>
            <div className="row">
              <FormGroup className="col-6 c-pointer">
                {/*TODO: Add documents or replace */}
                {/*<FileUpload*/}
                {/*  onFileUploaded={(e) => onFileUploaded(e)}*/}
                {/*></FileUpload>*/}
              </FormGroup>
              {item.document && <FormGroup className="col-4 ">
                <a href={item.document} className={'font-size-14 text-primary'}
                   target="_blank" rel="noreferror">document <i
                  className="bx bxs-down-arrow font-size-16 align-middle"></i></a>
              </FormGroup>}
              <FormGroup className="col-1 ">
                {i > 0 ? <button className="btn btn-danger d-flex justify-content-center"
                                 onClick={(e) => removeMetadata(e, documents, item.document, i)}><i
                    className="bx bx-x font-size-16 font-weight-bolder"></i></button> :
                  <button className="btn btn-success d-flex justify-content-center"
                          onClick={(e) => addMetadata(e, documents, item)}><i
                    className="bx bx-plus font-size-16 font-weight-bolder"></i></button>}
              </FormGroup>
            </div>
          </FormGroup>

        </FormGroup>
      })
    }
  }
  return (
    <AvForm className="needs-validation row">
      <FormGroup className="col-sm-12 col-md-6">
        <Label htmlFor="validationCustom02">University</Label>
        <AvField
          name="university"
          placeholder="University"
          type="text"
          errorMessage="Please write university"
          className="form-control"
          onChange={(e) => handleChange(e, metadata, e.target.name)}
          value={metadata?.professionalQualifications ? metadata?.professionalQualifications[0]?.university : null}
          validate={{required: {value: true}}}
          id="validationCustom02"
        />
      </FormGroup>
      {renderFieldsWithDocument(metadata?.professionalQualifications, 'degree', 'Degree', 'University Documents')}
      <FormGroup className="col-sm-12 ">
        <Label htmlFor="validationCustom02">Description</Label>
        <AvField
          name="bio"
          placeholder="Company Description"
          type="textarea"
          rows={8}
          errorMessage="Please write degree"
          className="form-control"
          onChange={(e) => handleChange(e, metadata, e.target.name)}
          value={user.companyDescription}
          validate={{required: {value: true}}}
          id="validationCustom02"
        />
      </FormGroup>
      <FormGroup className="col-sm-12 col-md-6">
        <Col xs={12}>
          <Label htmlFor="validationCustom02">is VAT</Label>
        </Col>
        <Radio.Group value={user.metadata?.vat?.isRegistered}
                     name='isRegistered'
                     onChange={(e) => handleChange(e, metadata, {name: 'vat', targetName: e.target.name})}>
          <Row gutter={24} className='checkbox-pref-com ant-checkbox-rtl mb-2'>
            <Col xs={12}>
              <Radio value={true} className='input'>
                Yes
              </Radio>
            </Col>
            <Col xs={12}>
              <Radio value={false} className='input'>
                No
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </FormGroup>
      {user.metadata?.vat?.isRegistered && <FormGroup className="col-sm-12 col-md-6">
        <Label htmlFor="validationCustom02">VAT</Label>
        <AvField
          name="vatNumber"
          placeholder="BGXXXXXXXXX"
          type="text"
          errorMessage="Please write VAT"
          className="form-control"
          onChange={(e) => handleChange(e, metadata, {name: 'vat', targetName: e.target.name})}
          value={user.metadata.vat?.vatNumber}
          validate={{required: {value: true}}}
          id="validationCustom02"
        />
      </FormGroup>}
      <FormGroup className="col-12">

      <Label htmlFor="validationCustom02">QR Code</Label>
      <GenerateQRCode user={user}
                      QRMetadata={metadata?.QRMetadata}
                      setUserMetaQRCode={setUser}/>
    </FormGroup>
    </AvForm>
  );
}

export default PetWolkersMetadata;
