import React, {Component} from "react";
import {Row, Col, Card, CardBody} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import {connect} from "react-redux";
import {
  addProfessional, approveProfessional, editProfessional,
  getProfessionals,
  removeProfessional,
  showAddProfessionalModal
} from "../../store/professionals/actions";
import PetSitterList from "./List/PetSitterList";
import {userRols} from "../../helpers/userRols";

class PetSitters extends Component {
  constructor(props) {
    super(props);

    this.onNewClinicModal = this.onNewClinicModal.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.props.getProfessionals({type: userRols.petSitter});
  }

  onNewClinicModal() {
    this.props.showAddClinicModal(!this.props.isAddNewClinicOpen);
  }

  onRemove(petSitter) {
    this.props.removeProfessional(petSitter, userRols.petSitter);
  }

  onEdit(petSitter) {
    this.props.editProfessional(petSitter);
  }

  render() {
    const {professionalList, approveProfessional} = this.props;
    const titleRole = professionalList?.users ? professionalList?.users[0].role?.replace(/_+/g, ' ') : ''
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title={titleRole} breadcrumbItem={titleRole}/>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <PetSitterList
                      list={professionalList?.users}
                      onRemoveItem={this.onRemove}
                      onEditItem={this.onEdit}
                      onApproveItem={approveProfessional}
                      history={this.props.history}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = ({Professional}) => {
  return Professional;
};

export default connect(mapStatetoProps, {
  getProfessionals,
  addProfessional,
  approveProfessional,
  showAddProfessionalModal,
  removeProfessional,
  editProfessional
})(PetSitters);
