import {UserMetaHeadActionTypes} from "./actionTypes";

export const getUsersProfessionalMetaHeadData = (payload) => {
    return {
        type: UserMetaHeadActionTypes.USERS_PROFESSIONAL_META_HEAD_GET,
        payload: payload
    }
}

export const getProfessionalMetaHeadDataSuccessful = (payload) => {
    return {
        type: UserMetaHeadActionTypes.USERS_PROFESSIONAL_META_HEAD_GET_SUCCESSFUL,
        payload: payload
    }
}

export const getProfessionalMetaHeadDataError = (payload) => {
    return {
        type: UserMetaHeadActionTypes.USERS_PROFESSIONAL_META_HEAD_GET_ERROR,
        payload,
    }
}

export const createProfessionalMetaHeadData = (payload) => {
    return {
        type: UserMetaHeadActionTypes.USERS_PROFESSIONAL_META_HEAD_CREATE,
        payload: payload
    }
}

export const createProfessionalMetaHeadDataSuccessful = (payload) => {
    return {
        type: UserMetaHeadActionTypes.USERS_PROFESSIONAL_META_HEAD_CREATE_SUCCESSFUL,
        payload: payload
    }
}

export const createProfessionalMetaHeadDataError = (payload) => {
    return {
        type: UserMetaHeadActionTypes.USERS_PROFESSIONAL_META_HEAD_CREATE_ERROR,
        payload,
    }
}

