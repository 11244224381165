import React, {useEffect, useRef, useState} from "react";


// MetisMenu
import MetisMenu from "metismenujs";
import {Link, useLocation} from "react-router-dom";

//i18n
import {withNamespaces} from "react-i18next";

import logoLightPng from "../../assets/images/mypet.svg";
import {userRols} from "../../helpers/userRols";

const SidebarContent = ({t, userRole}) => {
  const isInitialMount = useRef(true);
  const location = useLocation();
  const [role, setRole] = useState(userRole)
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      initMenu()
      activeNavigationLinks()
    }
  }, [location, userRole])


  const initMenu = () => {
    new MetisMenu(document.getElementById("side-menu"));

  }

  const activeNavigationLinks = () => {
    if (window.location.href !== `${window.location.origin}/login`) {
      const paths = location.pathname.split('/').filter(Boolean)
      const hrefs = [...document.querySelectorAll('a.waves-effect')]
      if (hrefs.find((q) => q.href === window.location.href)) {
        if (paths.length > 1) {
          const indexCurrentPath = hrefs.findIndex((q) => q.href === window.location.href)
          if (indexCurrentPath > 1 && indexCurrentPath < 5) {
            openNavigationSideMenu(hrefs, 1, indexCurrentPath)
          } else if (indexCurrentPath > 5 && indexCurrentPath < 8) {
            openNavigationSideMenu(hrefs, 5, indexCurrentPath)
          }
        } else {
          openNavigationSideMenu(hrefs, 0, [1, 5])
        }
        hrefs.filter((q) => q.href === window.location.href)[0].className += ' mm-active'
      }
    }
  }

  const openNavigationSideMenu = (hrefs, indexOpenLink, indexCurrentPath) => {
    if ([userRols.admin, userRols.superAdmin].includes(role)) {
      if (indexOpenLink > 0) {
        hrefs[indexOpenLink].ariaExpanded = true
        hrefs[indexOpenLink].parentElement.className = ' mm-active'
        hrefs[indexCurrentPath].parentElement.parentElement.className += ' mm-show'
      } else {
        indexCurrentPath.map((ind) => {
          hrefs[ind].ariaExpanded = false
          hrefs[ind].parentElement.className = ''
          const currentHref = [...hrefs[ind].parentElement.children][1]
          if (currentHref) {
            currentHref.className = 'mm-collapse'
          }
        })
      }
    }
  }

  const activateParentDropdown = (item) => {
    const target = document.getElementsByClassName('mm-active')
    if ([...target].length) {
      [...target].map(tr => {
        tr.className = tr.className.replace(/ mm-active/g, '')
      })
    }

    item.target.className += ' mm-active'
  };

  const showDropDown = ({target}) => {
    console.log(document.querySelectorAll('.mm-show'));
    target.parentElement.parentElement.childNodes[1].className = target.parentElement.parentElement.childNodes[1].className.split(' ').includes('mm-show') ? 'mm-collapse' : 'mm-collapse mm-show'
  }


  return (
    <React.Fragment>

      <div className="navbar-brand-box">
        <Link to="/" className="logo logo-light" onClick={() => activateParentDropdown('')}>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="80"/>
            </span>
        </Link>
      </div>

      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu" >
          <li className="menu-title">{t("Home")}</li>
          <li>
            <Link to="/dashboard" className="waves-effect" onClick={(e) => {
              activateParentDropdown(e, true)
            }}>
              <span>{t("Dashboard")}</span>
            </Link>
          </li>

          <li>
            <Link to="#profesional" className="waves-effect">
              <span>{t("Professionals >>")}</span>
            </Link>

            <ul>
              <li>
                <Link to="/professional/vets" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Vets")}</span>
                </Link>
              </li>
              <li>
                <Link to="/professional/clinics" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Clinics")}</span>
                </Link>
              </li>
              <li>
                <Link to="/professional/groomers" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Groomers")}</span>
                </Link>
              </li>
              <li>
                <Link to="/professional/hotels" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Hotels")}</span>
                </Link>
              </li>
              <li>
                <Link to="/professional/pet-sitters" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Pet Sitters")}</span>
                </Link>
              </li>
              <li>
                <Link to="/professional/pet-walkers" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Pet Walker")}</span>
                </Link>
              </li>
              {[userRols.admin, userRols.superAdmin].includes(role) && <li>
                <Link to="/professional/services" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Services")}</span>
                </Link>
              </li>}
            </ul>

          </li>

          {[userRols.admin, userRols.superAdmin].includes(role) && <li>
            <Link to="#pet-owners" className="waves-effect">
              <span>{t("Pet Owners >>")}</span>
            </Link>

            <ul>
              <li>
                <Link to="/pet-owners/parents" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Parents")}</span>
                </Link>
              </li>
              <li>
                <Link to="/pet-owners/pets" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Pets")}</span>
                </Link>
              </li>
            </ul>
          </li>}
          {[userRols.admin, userRols.superAdmin].includes(role) && <li>
            <Link to="#pet-owners" className="waves-effect">
              <span>{t("Shelters >>")}</span>
            </Link>

            <ul>
              <li>
                <Link to="/shelters" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Shelter")}</span>
                </Link>
              </li>
              <li>
                <Link to="/shelters/pets" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Pets")}</span>
                </Link>
              </li>
            </ul>
          </li>}

          {[userRols.admin, userRols.superAdmin].includes(role) && <li>
            <Link to="#bookings" className="waves-effect">
              <span>{t("Bookings >>")}</span>
            </Link>
            <ul>
              <li>
                <Link to="/bookings/" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Bookings")}</span>
                </Link>
              </li>
              <li>
                <Link to="/bookings/pending" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Pending Bookings")}</span>
                </Link>
              </li>
              <li>
                <Link to="/bookings/subscription" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Subscription Bookings")}</span>
                </Link>
              </li>
              <li>
                <Link to="/bookings/coupons" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Coupons / Vouchers")}</span>
                </Link>
              </li>
              <li>
                <Link to="/bookings/initiated-bookings" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>{t("Initiated Bookings")}</span>
                </Link>
              </li>
            </ul>
          </li>}

          {[userRols.admin, userRols.superAdmin].includes(role) && <li>
            <Link to="#accounting" className="waves-effect">
              <span>{t("Accouting >>")}</span>
            </Link>
            <ul>
              <li>
                <Link to="/accounting/report" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>Transactions Report</span>
                </Link>
              </li>
              <li>
                <Link to="/accounting/invoicing" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>For Invoicing</span>
                </Link>
              </li>
              <li>
                <Link to="/accounting/profpayments" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>Mark Payments</span>
                </Link>
              </li>
              <li>
                <Link to="/accounting/invoices" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>Issued Invoices</span>
                </Link>
              </li>
              <li>
                <Link to="/accounting/accountants" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>NAP File Export</span>
                </Link>
              </li>
              <li>
                <Link to="/accounting/received" className="waves-effect" onClick={(e) => {
                  activateParentDropdown(e)
                }}>
                  <span>Received</span>
                </Link>
              </li>

            </ul>
          </li>}


          {[userRols.admin, userRols.superAdmin].includes(role) && <li>
            <Link to="/blog" className="waves-effect" onClick={(e) => {
              activateParentDropdown(e, true)
            }}>
              <span>{t("Blogs")}</span>
            </Link>
          </li>}

          {[userRols.admin, userRols.superAdmin].includes(role) && <li>
            <Link to="/admins" className="waves-effect" onClick={(e) => {
              activateParentDropdown(e, true)
            }}>
              <span>{t("Manage Admins")}</span>
            </Link>
          </li>}
          {[userRols.admin, userRols.superAdmin].includes(role) && <li>
            <Link to="/faq" className="waves-effect" onClick={(e) => {
              activateParentDropdown(e, true)
            }}>
              <span>{t("FAQ")}</span>
            </Link>
          </li>}
          {[userRols.admin, userRols.superAdmin].includes(role) && <li>
            {(role === userRols.superAdmin) && <Link to="/logs" className="waves-effect" onClick={(e) => {
              activateParentDropdown(e, true)
            }}>
              <span>{t("Audit Log")}</span>
            </Link>}
          </li>}
          <li>
            <Link to="/logout" className="waves-effect" onClick={(e) => {
              activateParentDropdown(e, true)
            }}>
              <span>{t("Logout")}</span>
            </Link>
          </li>

        </ul>
      </div>
    </React.Fragment>
  );
}

export default withNamespaces()(SidebarContent)
