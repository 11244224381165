import { PetActionTypes } from "./actionTypes";

export const getPets = (payload) => {
  return {
    type: PetActionTypes.PET_LIST_GET,
    payload: payload,
  };
};

export const getPetsSuccessful = (payload) => {
  return {
    type: PetActionTypes.PET_LIST_GET_SUCCESSFUL,
    payload: payload,
  };
};

export const getPetsError = (payload) => {
  return {
    type: PetActionTypes.PET_LIST_GET_ERROR,
    payload,
  };
};

export const addPet = (pet) => {
  return {
    type: PetActionTypes.PET_ADD,
    payload: pet,
  };
};

export const addPetSuccessful = (payload) => {
  return {
    type: PetActionTypes.PET_ADD_SUCCESSFUL,
    payload,
  };
};

export const addPetError = (payload) => {
  return {
    type: PetActionTypes.PET_ADD_ERROR,
    payload,
  };
};
export const showAddPetModal = (showModal) => {
  return {
    type: PetActionTypes.PET_ADD_MODAL,
    payload: showModal,
  };
};
export const showAddPetModalSuccess = (showModal) => {
  return {
    type: PetActionTypes.PET_ADD_MODAL_SUCCESS,
    payload: showModal,
  };
};

export const removePet = (pet) => {
  return {
    type: PetActionTypes.PET_REMOVE,
    payload: pet,
  };
};
export const removePetSuccess = (pet) => {
  return {
    type: PetActionTypes.PET_REMOVE_SUCCESS,
    payload: pet,
  };
};
export const removePetError = (pet) => {
  return {
    type: PetActionTypes.PET_REMOVE_ERROR,
    payload: pet,
  };
};
export const editPet = (pet) => {
  return {
    type: PetActionTypes.PET_EDIT,
    payload: pet,
  };
};
export const editPetSuccess = (pet) => {
  return {
    type: PetActionTypes.PET_EDIT_SUCCESS,
    payload: pet,
  };
};
export const editPetError = (pet) => {
  return {
    type: PetActionTypes.PET_EDIT_ERROR,
    payload: pet,
  };
};
export const adoptedShelterPet = (petId) => {
  return {
    type: PetActionTypes.PET_SHELTER_ADOPTED,
    payload: petId,
  };
};
export const adoptedShelterPetSuccess = (pet) => {
  return {
    type: PetActionTypes.PET_SHELTER_ADOPTED_SUCCESS,
    payload: pet,
  };
};
export const adoptedShelterPetError = (pet) => {
  return {
    type: PetActionTypes.PET_SHELTER_ADOPTED_ERROR,
    payload: pet,
  };
};
