export const PaymentsReportActionTypes = {
    PROFREPORTS_LIST_GET: 'PROFREPORTS_LIST_GET',
    PROFREPORTS_LIST_GET_SUCCESSFUL: 'PROFREPORTS_LIST_GET_SUCCESSFUL',
    PROFREPORTS_LIST_GET_ERROR: 'PROFREPORTS_LIST_GET_ERROR',

    PROFREPORT_LIST_GET: 'PROFREPORT_LIST_GET',
    PROFREPORT_LIST_GET_SUCCESSFUL: 'PROFREPORT_LIST_GET_SUCCESSFUL',
    PROFREPORT_LIST_GET_ERROR: 'PROFREPORT_LIST_GET_ERROR',

    PROFREPORTS_GET_FILTERED: 'PROFREPORTS_GET_FILTERED',
    PROFREPORTS_GET_FILTERED_SUCCESSFUL: 'PROFREPORTS_GET_FILTERED_SUCCESSFUL',
    PROFREPORTS_GET_FILTERED_ERROR: 'PROFREPORTS_GET_FILTERED_ERROR',

    PROFREPORTS_CREATE: 'PROFREPORTS_CREATE',
    PROFREPORTS_CREATE_SUCCESS: 'PROFREPORTS_CREATE_SUCCESS',
    PROFREPORTS_CREATE_ERROR: 'PROFREPORTS_CREATE_ERROR'
}




