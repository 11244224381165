import React, { Component } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Moment from "moment";
import PaymentRemove from "./PaymentRemove";
import PaymentEdit from "./PaymentEdit";
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import axios from 'axios';
import baseURL from "../../../config";

class PaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpen: false,
      isRemoveModalOpen: false,
      removePayment: null,
      editPayment: null,
      paymentListData: []
    };
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditSubmit= this.onEditSubmit.bind(this);
    this.onRemoveOpen = this.onRemoveOpen.bind(this);
    this.onRemoveSubmit = this.onRemoveSubmit.bind(this);
  }

  onEditOpen(payment) {
    this.setState((prevState) => ({
      isEditModalOpen: !prevState.isEditModalOpen,
      editPayment: payment,
    }));
  }

  componentDidMount(){
    //  get payments 
    axios.get(`${baseURL}/api/v1/payment/`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
        let response = res?.data
        this.setState({paymentListData: response})
        console.log(response)
    })
    .catch((error) =>{
        console.log(error)
    })  
  }


  onRemoveOpen(payment) {
    this.setState((prevState) => ({
      isRemoveModalOpen: !prevState.isRemoveModalOpen,
      removePayment: payment,
    }));
  }

  onRemoveSubmit(payment) {
    this.props.onRemoveItem(payment);
  }

  onEditSubmit(payment) {
    this.props.onEditItem(payment);
  }

  getPaymentsList(paymentListData) {
    if (paymentListData) {

      const paymentListView = [];
      paymentListData.data?.paymets.map((payment) => {
        paymentListView.push(
          <Tr key={payment._id}>
            <Th>{payment.technology}</Th>
            <Td>
              <div className="button-items">
                <button
                  type="button"
                  className="btn btn-danger waves-effect waves-light"
                  onClick={() => {
                    this.onRemoveOpen(payment);
                  }}
                >
                  <i className="bx bx-trash-alt font-size-16 align-middle"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    this.onEditOpen(payment);
                  }}
                >
                  <i className="bx bx-edit font-size-16 align-middle"></i>
                </button>
              </div>
            </Td>
          </Tr>
        );
      });

      return paymentListView;
    } else {
      return null;
    }
  }

  render() {
    const {
      isEditModalOpen,
      isRemoveModalOpen,
      removePayment,
      editPayment,
    } = this.state;
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered"
          >
            <Thead>
              <Tr>
                <Th data-priority="1">Payment Type</Th>
              </Tr>
            </Thead>
            <Tbody>{this.getPaymentsList(this.state.paymentListData)}</Tbody>
          </Table>
        </div>
        <PaymentRemove
          data={removePayment}
          onSubmit={this.onRemoveSubmit}
          isRemoveModalOpen={isRemoveModalOpen}
          onCancel={this.onRemoveOpen}
        />
        {editPayment && (
          <PaymentEdit
            data={editPayment}
            onSubmit={this.onEditSubmit}
            isEditModalOpen={isEditModalOpen}
            onCancel={this.onEditOpen}
          />
        )}
      </div>
    );
  }
}

export default PaymentList;
