import React, { Component } from "react";
import { Row, Col, Label, FormGroup, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import FileUpload from "../../../components/FileUpload/FileUpload";

class PaymentEdit extends Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onFileUploaded = this.onFileUploaded.bind(this);
    this.handleChange = this.handleChange.bind(this);

    let payment = {
      id: "",
      technology: "",

    };
    this.state = { payment };
  }

  componentDidMount() {
    const paymentData = this.props.data;
    let { payment } = this.state;
    payment = {
      id: paymentData._id,
      technology: paymentData.technology,
    }
    this.setState({ payment });
  }

  onCancel() {
    this.props.onCancel();
  }

  onFileUploaded(file) {

    if (file.type == "video") {
        this.setState({video_url: file.url});
    } else if (file.type == "image") {
        this.setState({image: file.url});
    }
}

  onSubmit() {
    const { payment } = this.state;
    this.props.onSubmit(payment);
    this.props.onCancel();
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { payment } = this.state;
    payment[name] = value;
    this.setState({ payment });
  }

  render() {
    const { payment } = this.state;
    return (
      <Modal isOpen={this.props.isEditModalOpen} toggle={this.onCancel}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Payment</h5>
          <button
            type="button"
            onClick={this.onCancel}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <AvForm className="needs-validation">
            <FormGroup>
              <Label htmlFor="validationCustom01">Technology</Label>
              <AvField
                name="technology"
                placeholder="Technology"
                type="text"
                errorMessage="Please write technology name"
                className="form-control"
                onChange={this.handleChange}
                value={payment.technology}
                validate={{ required: { value: true } }}
                id="validationCustom01"
              />
            </FormGroup>
          </AvForm>
        </div>

        {this.props.isLoading && (
          <div className="modal-footer">
            <div className="spinner-border text-success m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        {!this.props.isLoading && (
          <div className="modal-footer">
            <button
              type="button"
              onClick={this.onCancel}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.onSubmit}
              className="btn btn-success waves-effect waves-light"
            >
              Accept
            </button>
          </div>
        )}
      </Modal>
    );
  }
}

export default PaymentEdit;
