import { AccountantActionTypes } from "./actionTypes";


const initialState = {
    accountantListError: null, message: null, isLoadingAddAccountant: false, isAddNewAccountantOpen: false
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case AccountantActionTypes.ACCOUNTANT_LIST_GET:
            state = {
                ...state,
                accountantList: null,
                accountantListError: null
            }
            break;
        case AccountantActionTypes.ACCOUNTANT_LIST_GET_SUCCESSFUL:
            state = {
                ...state,
                accountantList: action.payload,
                accountantListError: null
            }
            break;
        case AccountantActionTypes.ACCOUNTANT_LIST_GET_ERROR:
            state = {
                ...state,
                accountantList: null,
                accountantListError: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default account;
