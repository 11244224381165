import React, {Component} from "react";
import {Row, Col, Card, CardBody, Button} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {getTransactions, getFilteredTransactions, createInvoice } from "../../store/invoicing/actions";
import {connect} from "react-redux";
import {ReportList} from "./List/ReportList";
import Paging from "../../components/Pagination/Pagination";
import {Space} from "antd";
import moment from "moment";


class ProfReport extends Component {

  transactions = [];
  accrued = 0;
  forInvoice = 0;
  refundings = 0;


  constructor(props) {
    super(props);
    this.issueInvoice = this.issueInvoice.bind(this);
    this.addTransactionForInvoice = this.addTransactionForInvoice.bind(this);
    this.addSums = this.addSums.bind(this);
    this.getAccrued = this.getAccrued.bind(this);
    this.getForInvoice = this.getForInvoice.bind(this);
    this.getRefunds = this.getRefunds.bind(this);
    this.state = {
      search: {text: '', filter: '', tagsFilters: {}},
      currentPage: 1,
      currentDate: moment(new Date()),
      filterModalOpen: false
    }
    this.options = [
      {keySearch: 'parentID.name_parentID.surname', titleSearch: 'Name'},
      {keySearch: 'price', titleSearch: 'Price'},
      {keySearch: 'professionalID.role', titleSearch: 'Professional role'},
      {keySearch: 'professionalID.name_professionalID.surname', titleSearch: 'Professional Name'},
      {keySearch: 'time', titleSearch: 'Appointment Date'},
    ]
  }

  componentDidMount() {
    this.props.getTransactions({page: 1});
  }

  setFilterModalOpen = (filterModalOpen) => {
    this.setState({filterModalOpen})
  }

  refreshPage = () => {
    this.setState({search: {filter: '', text: '', tagsFilters: {}}})
    this.props.getTransactions({page: 1});
  }

  setPage = (currentPage) => {
    this.setState({currentPage})
    this.props.getTransactions({page: currentPage});
  }

  onSubmit = (e, search) => {
    e.preventDefault()
    if ((search.text && search.filter) || Object.keys(search.tagsFilters).length) {
      this.props.getTransactions({search, page: 1});
    } else {
      this.props.getTransactions({page: 1});
    }
    this.setState({currentPage: 1, search, filterModalOpen: false})
  }

  addTransactionForInvoice(transactionId) {
    if( this.transactions.includes(transactionId) ){
      this.transactions.splice(this.transactions.indexOf(transactionId), 1);
    }else{
      this.transactions.push(transactionId);
    }
  }

  addSums(accrued, fee, refund) {
    this.accrued += accrued;
    this.forInvoice += fee;
    this.refundings += refund;
  }

  getAccrued(){
    return this.accrued;
  }
  getForInvoice(){
    return this.forInvoice;
  }

  getRefunds(){
    return this.refundings;
  }

  issueCreateInvoice() {
    this.props.createInvoice(this.transactions);
    this.transactions = [];
    this.refreshPage();
  }


  issueInvoice( professionalId) {
    this.accrued = 0;
    this.forInvoice = 0;
    this.refundings = 0;
    this.props.getFilteredTransactions(professionalId);
  }

  render() {
    const {search, currentPage, filterModalOpen} = this.state
    const {ReportList} = this.props;

    // return (
    //   <React.Fragment>
    //     <div className="page-content">
    //       Payments to professionals are coming here
    //     </div>
    //   </React.Fragment>
    // );

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Transactions" breadcrumbItem="Reporting by Contragent"/>

            <Row>
              <Col>
                <Card>
                  <div className="d-inline-flex ant-row-space-between align-items-center">
                    <Paging setPage={this.setPage} currentPage={currentPage} pages={ProfReport?.pages}/>
                  </div>
                  <CardBody>

                  Payments Report by contragents will be here
                  {/* <ForPaymentsList
                      list={ForPaymentsList?.payments}
                      btn={ForPaymentsList?.invoiceButton}
                      history={this.props.history}
                      issueInvoice={this.issueInvoice}
                      addTransactionForInvoice={this.addTransactionForInvoice}
                      addSums={this.addSums}
                      getAccrued={this.getAccrued}
                      getForInvoice={this.getForInvoice}
                      getRefunds={this.getRefunds}
                    /> */}

                  </CardBody>
                  <Paging setPage={this.setPage} currentPage={this.state.currentPage} pages={ProfReport?.pages}/>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );


  }
}

const mapStateToProps = ({ProfReport}) => {
  return ProfReport;
}

export default connect(mapStateToProps, {
  getTransactions, getFilteredTransactions, createInvoice
})(ProfReport);
