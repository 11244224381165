import ApiService from "../../helpers/ApiHelper";

class InvoiceService {

    static getTransactions(payload) {

      const query = `?search=${JSON.stringify(payload.search)?JSON.stringify(payload.search):'1'}&page=${payload.page}`

      let url = `/api/v1/admins/invoices${query}`;
      return ApiService.request(url, "get", null);
    }

    static getInvoices(payload) {
      const query = `?search=${JSON.stringify(payload.search)?JSON.stringify(payload.search):'1'}&page=${payload.page}`
      let url = `/api/v1/admins/doneinvoices${query}`;
      return ApiService.request(url, "get", null);
    }

    static downloadInvoice(invoiceid) {
      let url = `/api/v1/admins/downloadinvoice/${invoiceid}`;
      return ApiService.request(url, "get", null);
    }

    static getFilteredTransactions(payload) {
      let url = `/api/v1/admins/invoices`;
      return ApiService.request(url, "post", payload);
    }

    static createInvoice(payload) {
      let url = `/api/v1/admins/createinvoice`;
      return ApiService.request(url, "post", payload);
    }

}

export default InvoiceService;
