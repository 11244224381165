import {FormGroup, Label} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import React from "react";

const LimitTab = ({couponLimit, limitPerUser, limitPerProfessional, handleChange}) => {

  return <AvForm className="needs-validation row">
    <FormGroup className="col-md-12 col-lg-6">
      <Label htmlFor="validationCustom01">Usage limit per coupon</Label>
      <AvField
        name="couponLimit"
        placeholder="Coupon limit"
        type="text"
        className="form-control"
        onChange={handleChange}
        value={couponLimit.toString()}
        id="validationCustom01"
      />
    </FormGroup>
    <FormGroup className="col-md-12 col-lg-6">
      <Label htmlFor="validationCustom01">Usage limit per user</Label>
      <AvField
        name="limitPerUser"
        placeholder="Coupon limit user"
        type="text"
        className="form-control"
        onChange={handleChange}
        value={limitPerUser.toString()}
        id="validationCustom01"
      />
    </FormGroup>
    <FormGroup className="col-md-12 col-lg-6">
      <Label htmlFor="validationCustom01">Usage limit per professional</Label>
      <AvField
        name="limitPerProfessional"
        placeholder="Coupon limit user"
        type="text"
        className="form-control"
        onChange={handleChange}
        value={limitPerProfessional.toString()}
        id="validationCustom01"
      />
    </FormGroup>
  {/*  TODO: Text*/}
  </AvForm>

}

export default LimitTab
