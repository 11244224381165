import React, {Component} from "react";
import {Row, Col, Card, CardBody, Button} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { getInvoices } from "../../store/invoicing/actions";
import {connect} from "react-redux";
import InvoicingList from "./List/getAllInvoicesList";
import Paging from "../../components/Pagination/Pagination";
import {Space} from "antd";
import moment from "moment";
import Search from "../../components/Search/Search";


class Invoices extends Component {

  constructor(props) {
    super(props);
    this.state = {
      search: {text: '', filter: '', tagsFilters: {}},
      currentPage: 1,
      currentDate: moment(new Date()),
      filterModalOpen: false
    }
    this.options = [
      {keySearch: 'amount', titleSearch: 'Amount'},
      {keySearch: 'professionalID.role', titleSearch: 'Professional role'},
      {keySearch: 'professionalID.name_professionalID.surname', titleSearch: 'Professional Name'},
      {keySearch: 'time', titleSearch: 'Invoice Date'},
    ]
  }

  componentDidMount() {
    this.props.getInvoices({page: 1});
  }

  setFilterModalOpen = (filterModalOpen) => {
    this.setState({filterModalOpen})
  }

  refreshPage = () => {
    this.setState({search: {filter: '', text: '', tagsFilters: {}}})
    this.props.getInvoices({page: 1});
  }

  setPage = (currentPage) => {
    this.setState({currentPage})
    this.props.getInvoices({page: currentPage});
  }

  onSubmit = (e, search) => {
    e.preventDefault()
    if ((search.text && search.filter) || Object.keys(search.tagsFilters).length) {
      this.props.getInvoices({search, page: 1});
    } else {
      this.props.getInvoices({page: 1});
    }
    this.setState({currentPage: 1, search, filterModalOpen: false})
  }

  
  render() {
    const {search, currentPage, filterModalOpen} = this.state;
    const {invoicingList} = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Invoices" breadcrumbItem="Invoices"/>

            <Row>
              <Col>
                <Card>
                  <div className="d-inline-flex ant-row-space-between align-items-center">
                    <Paging setPage={this.setPage} currentPage={currentPage} pages={invoicingList?.pages}/>

                    {/*
                    <Space direction="horizontal" className="mr-4">
                      <Button type={'button'} className='btn btn-info waves-effect waves-light search-btn'
                              onClick={() => this.setFilterModalOpen(!filterModalOpen)}>
                        <i className="bx bx-filter-alt align-middle"></i></Button>
                      {(search.text || Object.keys(search.tagsFilters).length > 0) &&
                        <Button type={'button'} className='btn btn-dark waves-effect waves-light search-btn'
                                onClick={this.refreshPage}><i
                          className='bx bx-revision align-middle'></i></Button>}
                    </Space>
                      */}

                  </div>
                  <CardBody>
                    <InvoicingList
                      list={invoicingList?.payments}
                      onRemoveItem={this.onRemove}
                      onEditItem={this.onEdit}
                      history={this.props.history}
                    />
                  </CardBody>
                  <Paging setPage={this.setPage} currentPage={this.state.currentPage} pages={invoicingList?.pages}/>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Search searchModalOpen={filterModalOpen}
                options={this.options}
                filterData={['time']}
                searchVar={search}
                tags={invoicingList?.tags}
                onSubmit={this.onSubmit}
                onCancel={() => this.setFilterModalOpen(false)}/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {Invoices} = state;
  return Invoices;
};

export default connect(mapStateToProps, {
  getInvoices,
})(Invoices);
