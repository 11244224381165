import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  AUTO_LOGIN,
  AUTO_LOGIN_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  token: null,
  user: JSON.parse(localStorage.getItem('authUser')) || null,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload.user,
        token: action.payload.token,
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;
    case AUTO_LOGIN:
      state = {
        ...state,
      };
    case AUTO_LOGIN_SUCCESS:
      state = {
        ...state,
        user: action.payload?.user,
        token: action.payload?.token,
      };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, token: null, user: null };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
