import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  getParents,
  addParent,
  showAddParentModal,
  removeParent,
  editParent,
} from "../../store/parent/actions";
import { connect } from "react-redux";
import ParentList from "./List/ParentList";
import AddParent from "./AddParent";
import {Space} from "antd";
import axios from "axios";
import baseURL from "../../config";
import fileDownload from "js-file-download";

class Parents extends Component {
  constructor(props) {
    super(props);

    this.onNewParentModal = this.onNewParentModal.bind(this);
    this.onNewParentSubmit = this.onNewParentSubmit.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.props.getParents({type: 'PARENT'});
  }

  onNewParentModal() {
    this.props.showAddParentModal(!this.props.isAddNewParentOpen);
  }

  onNewParentSubmit(parent) {
    this.props.addParent(parent);
  }

  onRemove(parent) {
    this.props.removeParent(parent);
  }

  onEdit(parent) {
    this.props.editParent(parent);
  }
  downloadUsersToExel = (e) => {
    e.preventDefault();
    const filename = new Date().toISOString() + ".xml";
    axios
      .post(`${baseURL}/api/v2/users/export-parents`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        fileDownload(res.data, filename);
        console.log(res, filename, res);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    const { parentList, isLoadingAddParent, isAddNewParentOpen } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Parents" breadcrumbItem="Parents" />

            <Row>
              <Col>
                <Card>
                  <div className="d-inline-flex ant-row-space-between align-items-center">
                    <Space direction="horizontal" className="mr-4">
                      <Button type={'button'} className='btn btn-info waves-effect waves-light search-btn'
                              onClick={this.downloadUsersToExel}>Download Parents</Button>
                    </Space>
                  </div>
                  <CardBody>
                    <ParentList
                      list={parentList}
                      onRemoveItem={this.onRemove}
                      onEditItem={this.onEdit}
                      history={this.props.history}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <AddParent
          isLoading={isLoadingAddParent}
          isNewParentModalOpen={isAddNewParentOpen}
          onSubmit={this.onNewParentSubmit}
          onCancel={this.onNewParentModal}
        />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Parent } = state;
  return Parent;
};

export default connect(mapStatetoProps, {
    getParents,
    addParent,
    showAddParentModal,
    removeParent,
    editParent,
    // downloadUsersToExel
  })(Parents);
