export const metadataSpecializationOptions = [
  {
    label: 'Neurology',
    value: 'neurology',
  },
  {
    label: 'Oncology',
    value: 'oncology',
  },
  {
    label: 'Clinical Laboratory',
    value: 'clinical_laboratory',
  },
  {
    label: 'Microbiology',
    value: 'microbiology',
  },
  {
    label: 'Dentistry',
    value: 'dentistry',
  },
  {
    label: 'Endocrinology',
    value: 'endocrinology',
  },
  {
    label: 'Image diagnostics',
    value: 'image_diagnostics',
  },
  {
    label: 'Anesthesiology',
    value: 'anesthesiology',
  },
  {
    label: 'Parasitology',
    value: 'parasitology',
  },
  {
    label: 'Obstetrics and Gynecology',
    value: 'obstetrics_gynecology',
  },
  {
    label: 'Cardiology',
    value: 'cardiology',
  },
  {
    label: 'Dermatology',
    value: 'dermatology',
  },
  {
    label: 'Surgery',
    value: 'surgery',
  },
  {
    label: 'Orthopedics',
    value: 'orthopedics',
  },
  {
    label: 'Ophthalmology',
    value: 'ophthalmology',
  },
  {
    label: 'Zoo psychology',
    value: 'zoo_psychology',
  },
  {
    label: 'Exotic pets',
    value: 'exotic_animals',
  },
  {
    label: 'General practitioner',
    value: 'general_practitioner',
  },
  {
    label: 'Abdominal surgery',
    value: 'abdominal_surgery'
  },
  {
    label: 'Thoracic surgery',
    value: 'thoracic_surgery'
  },
  {
    label: 'Pathology',
    value: 'pathology'
  },
  {
    label: 'Haematology',
    value: 'haematology'
  },
  {
    label: 'Allergology',
    value: 'allergology'
  },
  {
    label: 'Nephrology',
    value: 'nephrology'
  },
  {
    label: 'Infectious diseases',
    value: 'infectious_diseases'
  },
  {
    label: 'Rheumatology',
    value: 'rheumatology'
  },
  {
    label: 'Fish diseases',
    value: 'fish_diseases'
  },
  {
    label: 'Bird diseases',
    value: 'bird_diseases'
  },
  {
    label: 'Livestock diseases',
    value: 'livestock_diseases'
  },
  {
    label: 'Companion animal diseases',
    value: 'companion_animal_diseases'
  },
  {
    label: 'Clinical pharmacology',
    value: 'clinical_pharmacology'
  },
  {
    label: 'Emergency medicine',
    value: 'emergency_medicine'
  },
  {
    label: 'Bee diseases',
    value: 'bee_diseases'
  },
  {
    label: 'Immunology ',
    value: 'immunology'
  },
  {
    label: 'Internal diseases',
    value: 'internal_diseases'
  },
  {
    label: 'Neurology and neurosurgery',
    value: 'neurology_and_neurosurgery'
  },
  {
    label: 'Nutrition',
    value: 'nutrition'
  },
  {
    label: 'Radiotherapy',
    value: 'radiotherapy'
  },
  {
    label: 'Reptile diseases',
    value: 'reptile_diseases'
  },
  {
    label: 'Sports medicine and rehabilitation',
    value: 'sports_medicine_and_rehabilitation'
  },
  {
    label: 'Toxicology',
    value: 'toxicology'
  },
  {
    label: 'Horse diseases',
    value: 'horse_diseases'
  }

]
