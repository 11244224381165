import ApiService from "../../helpers/ApiHelper";

class TransactionService {

    static getTransactionId({bookingId, stripePaymentId}) {
        return ApiService.request(
            `/api/v2/transaction/${bookingId}/${stripePaymentId}`,
            "get",
          {}

        )
    }

    static refund(payload) {
        return ApiService.request(
            "/api/v2/admins/refunds/refundTransaction",
            "post",
            payload
        )
    }

}

export default TransactionService;
