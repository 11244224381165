import {TransactionTypes} from "./actionTypes";

export const getTransaction = (payload) => {
    return {
        type: TransactionTypes.TRANSACTION_GET,
        payload: payload
    }
}

export const getTransactionSuccessful = (payload) => {
    return {
        type: TransactionTypes.TRANSACTION_SUCCESSFUL,
        payload: payload
    }
}

export const getTransactionError = (payload) => {
    return {
        type: TransactionTypes.TRANSACTION_ERROR,
        payload,
    }
}


export const refundPayment = (refund) => {
    return {
        type: TransactionTypes.REFUND,
        payload: refund,
    };
};
export const refundSuccess = (refund) => {
    return {
        type: TransactionTypes.REFUND_SUCCESS,
        payload: refund,
    };
};
export const refundError = (refund) => {
    return {
        type: TransactionTypes.REFUND_ERROR,
        payload: refund,
    };
};
