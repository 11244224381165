import { PaymentsActionTypes } from "./actionTypes";

export const getTransactions = (payload) => {
  return {
    type: PaymentsActionTypes.PROFPAY_LIST_GET,
    payload: payload,
  };
};
export const getTransactionsSuccessful = (payload) => {
  return {
    type: PaymentsActionTypes.PROFPAY_LIST_GET_SUCCESSFUL,
    payload: payload,
  };
};
export const getTransactionsError = (payload) => {
  return {
    type: PaymentsActionTypes.PROFPAY_LIST_GET_ERROR,
    payload,
  };
};


export const getInvoices = (payload) => {
  return {
    type: PaymentsActionTypes.PROF_LIST_GET,
    payload: payload,
  };
};
export const getInvoicesSuccessful = (payload) => {
  return {
    type: PaymentsActionTypes.PROF_LIST_GET_SUCCESSFUL,
    payload: payload,
  };
};
export const getInvoicesError = (payload) => {
  return {
    type: PaymentsActionTypes.PROF_LIST_GET_ERROR,
    payload,
  };
};


export const getFilteredTransactions = (payload) => {
  return {
    type: PaymentsActionTypes.PROFPAY_GET_FILTERED,
    payload: payload,
  };
};
export const getFilteredTransactionsSuccessful = (payload) => {
  return {
    type: PaymentsActionTypes.PROFPAY_GET_FILTERED_SUCCESSFUL,
    payload: payload,
  };
};
export const getFilteredTransactionsError = (payload) => {
  return {
    type: PaymentsActionTypes.PROFPAY_GET_FILTERED_ERROR,
    payload,
  };
};




export const createInvoice = (transaction) => {
  return {
    type: PaymentsActionTypes.PROFPAY_CREATE,
    payload: transaction,
  };
};
export const createInvoiceSuccess = (transaction) => {
  return {
    type: PaymentsActionTypes.PROFPAY_CREATE_SUCCESS,
    payload: transaction,
  };
};
export const createInvoiceError = (transaction) => {
  return {
    type: PaymentsActionTypes.PROFPAY_CREATE_ERROR,
    payload: transaction,
  };
};
