import React, {Component} from "react";
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import moment from "moment";
import i18next from "i18next";


class InvoicingList extends Component {
  constructor(props) {
    super(props);
    this.currentRefundAmount = 0;
  }

  currentRefundAmount = 0;

  accumRefunds( booking_refunds ){
    this.currentRefundAmount = 0;
    if( booking_refunds && booking_refunds[0] ){
      booking_refunds.forEach((refund) => {
        this.currentRefundAmount += (refund.refund.amount/100);
      });
    }
    return this.currentRefundAmount;
  }

  tableRowElement(bookings) {
    if (bookings) {
      const {btn} = this.props;
      this.currentRefundAmount = 0;
      return bookings.map((booking) => {
        this.accumRefunds(booking.refunds);
        this.props.addSums(booking.price,booking.hpnAmount, this.currentRefundAmount );
        let trns = i18next.t(booking.booking?.description);

        if( trns.length==0 ){
          trns = booking.booking?.meetingType;
        }
        
        if( this.currentRefundAmount.toFixed(2) == Number(booking.price).toFixed(2) ){
          return;
        }

        return (
          <Tr key={booking._id}>
            <Td>
              {btn && (
                <input
                  type="checkbox"
                  onClick={() => this.props.addTransactionForInvoice(booking._id)}
                />
              )}
              {!btn && (
                <input
                  type="checkbox"
                  onClick={() => this.props.issueInvoice(booking.professional?._id)}
                />
              )}
            </Td>
            <Td>{booking.professional?.name} {booking.professional?.surname}</Td>
            <Td>{trns}</Td>
            <Td>{booking.bookingState}</Td>
            <Td>{Number(booking.price).toFixed(2)}</Td>
            <Td>{this.currentRefundAmount.toFixed(2)}</Td>
            <Td>{Number(booking.hpnAmount).toFixed(2)}</Td>
            <Td>{booking.hpnCommision}</Td>
            <Td></Td>
            <Td>{moment(booking.createdAt).format('DD MMM YYYY')}</Td>
          </Tr>
        );
      });
    }
  }

  render() {
    const {list} = this.props;
    const {btn} = this.props;
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">

        {!btn && (
          <h2 className="text-center">CHOOSE A CONTRAGENT by booking</h2>
        )}

        {btn && list[0] && (
          <span className="text-left">
              <b>The invoice will be issued to:</b><br/>
              {list[0].professional.metadata.companyName && (
                <div>Получател / Recipient: <b> { list[0].professional.metadata.companyName } { list[0].professional.metadata.formOfEstablishment}</b></div>
              )}
              {!list[0].professional.metadata.companyName &&(
                <div>Получател / Recipient: <b>{ `${list[0].professional.name} ${list[0].professional.surname}` }</b></div>
              )}

              <div>ДДС / VAT: <b>{ list[0].professional.metadata.vat?.vatNumber || '9999999999' }</b></div>
              <div>ЕИК / UIN: <b>{ list[0].professional.metadata.taxNumber || '' }</b>
              { list[0].professional.metadata.vat?.accountablePerson ? `МОЛ / MRP: ${list[0].professional.metadata?.vat?.accountablePerson}` : '' }
              </div>

              <div>Услуги от / Service by: <b>{` ${list[0].professional.name} ${list[0].professional.surname}` }</b></div>
              <br/>
          </span>
        )}

          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered"
          >
            <Thead>
              <Tr>
                <Th data-priority="1"></Th>
                <Th data-priority="1">Professional</Th>
                <Th data-priority="3">Service</Th>
                <Th data-priority="3">Status</Th>
                <Th data-priority="1">Service Price</Th>
                <Th data-priority="1">Refund</Th>
                <Th data-priority="3">HPN Price</Th>
                <Th data-priority="3">Fee type</Th>
                <Th data-priority="3"></Th>
                <Th data-priority="3">Booked At</Th>
              </Tr>
            </Thead>
            <Tbody>{this.tableRowElement(list)}</Tbody>
            <Thead>
              <Tr>
                <Th data-priority="1"></Th>
                <Th data-priority="1"></Th>
                <Th data-priority="3"></Th>
                <Th data-priority="3"></Th>
                <Th data-priority="1">{btn?this.props.getAccrued().toFixed(2):''}</Th>
                <Th data-priority="1">{btn?this.props.getRefunds().toFixed(2):''}</Th>
                <Th data-priority="3">{btn?this.props.getForInvoice().toFixed(2):''}</Th>
                <Th data-priority="3"></Th>
                <Th data-priority="3"></Th>
                <Th data-priority="3"></Th>
              </Tr>
            </Thead>
          </Table>
        </div>

      </div>
    );
  }
}

export default InvoicingList;
