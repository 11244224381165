import axios from "axios";
import baseURL from "../../config";

const handleAcceptedFiles = (file, filename, professionalId, title, description, createProfessionalMetaHeadData) => {
  const formData = new FormData();
  formData.append("file", file, filename);

  axios({
    crossdomain: true,
    method: "post",
    url: `${baseURL}/api/v1/upload`,
    data: formData,
    headers: {
      "Access-Control-Allow-Origin": "http://localhost:4242/api/v1/upload",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      if (response.data.imageUrl) {
        createProfessionalMetaHeadData({
          professionalId, metadataHead: {
            metaThumbnail: response.data.imageUrl,
            metaTitle: title,
            metaDescription: description
          }
        })
      } else {
        console.log(response)
      }
    })
    .catch((error) => {
      console.log(error)
    });
};

export default handleAcceptedFiles;
