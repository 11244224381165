import React, {useEffect, useState} from 'react'

import {DeleteModal} from '../DeleteReasonsModal'
import dayjs from 'dayjs'
import axios from "axios";
import baseURL from "../../config";
import formatTime from "../../helpers/formatTime";

const WorkScheduleDates = ({professionalId, setError}) => {
  const [workSchedules, setWorkSchedules] = useState([])
  const [popConfirmVisible, setPopConfirmVisible] = useState(false)
  const [forDisable, setForDisable] = useState({slots: [], day: ''})
  useEffect(() => {
    getWorkSchedulesDates()
  }, [])

  async function getWorkSchedule() {
    try {
      const data = await axios({
        crossdomain: true,
        method: "GET",
        url: `${baseURL}/api/v1/work-schedule/date-range/${professionalId}`,
        headers: {
          "Access-Control-Allow-Origin": `http://localhost:4242/api/v1/work-schedule/date-range/${professionalId}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(res => res.data)
      return data
    } catch (e) {
      if (e.request.status === 416) {
        setError({type: 'error', message: e.request.response})
      } else {
        setError({type: 'error', message: 'Something went wrong, please contact office support.'})
      }
    }
  }

  const getWorkSchedulesDates = async () => {
    try {
      const schedule = await getWorkSchedule(professionalId)
      console.log(schedule)
      setWorkSchedules(schedule.workScheduleDateRangeSlots)
    } catch (e) {
      setError({type: 'error', message: e.message})
    }
  }

  async function removeWorkScheduleDate(professionalId, day) {
    await axios({
      crossdomain: true,
      method: "DELETE",
      url: `${baseURL}/api/v1/work-schedule/remove-date/${professionalId}?date=${day}`,
      headers: {
        "Access-Control-Allow-Origin": `http://localhost:4242/api/v1/work-schedule/remove-date${professionalId}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then(res => res.data)
  }

  const removeDay = async ({slots, day}) => {
    if (slots.find(slot => slot.isBooked)) {
      setError({
        type: 'error',
        message: 'This day you have reservations, please reschedule them before removing the day from your schedule!',
      })
    } else if (day) {
      try {
        await removeWorkScheduleDate(professionalId, day)
        setError({type: 'success', message: 'This day successfully removed from your schedule!'})
        setWorkSchedules([])
        getWorkSchedulesDates()
      } catch (e) {
        setError({type: 'error', message: e.message})

      }

    }
  }

  const renderDates = () => {
    if (workSchedules) {
      return workSchedules.map((ws, i) => {
        return <div key={i}
                    className={`alert${ws.slots.find(slot => slot.isBooked) ? ' alert-danger' : ' alert-success'} d-flex justify-content-center c-pointer`}
                    onClick={() => {
                      if (ws.slots.find(slot => slot.isBooked)) {
                        setError({
                          type: 'error',
                          message: 'This day you have reservations, please reschedule them before removing the day from your schedule!',
                        })
                        return
                      }
                      setPopConfirmVisible(true)
                      setForDisable({slots: ws.slots, day: dayjs(ws.forDate).format('YYYY-MM-DD')})
                    }}>
          <h5>{dayjs(ws.forDate).format('DD.MM.YYYY')} {formatTime(ws.slots[0].from)}/{formatTime(ws.slots[ws.slots.length - 1].from)}</h5>
        </div>
      })
    }
  }

  return (
    <div>
      {renderDates()}
      <DeleteModal title={'Ask Confirm Removed Work Schedule Day'}
                   buttonName={'Confirm appointment'}
                   open={popConfirmVisible}
                   disableReasons={true}
                   close={() => {
                     setPopConfirmVisible(false)
                     setForDisable({slots: [], day: ''})
                   }}
                   setReasons={() => removeDay(forDisable)}/>
    </div>
  )
}

export default WorkScheduleDates
