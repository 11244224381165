import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
    getBlogsError,
    getBlogsSuccessful,
    addBlogError,
    addBlogSuccessful,
    showAddBlogModalSuccess,
    removeBlogError,
    removeBlogSuccess,
    getBlogs,
    editBlogError,
    editBlogSuccess
} from "./actions";
import BlogService from "./service";
import { BlogActionTypes } from "./actionTypes";

function* get() {
    try {
        const response = yield call(BlogService.getBlogs, {});
        yield put(getBlogsSuccessful(response));
    } catch (error) {
        yield put(getBlogsError(error));
    }
}

function* addBlog({ payload: blog }) {
    try {
        const response = yield call(BlogService.addBlog, blog);
        yield put(addBlogSuccessful(response));
        yield put(getBlogs());

    } catch (error) {
        yield put(addBlogError(error));
    }
}

function* showAddBlog({ payload: showModal }) {
    yield put(showAddBlogModalSuccess(showModal));
}

function* removeBlog({ payload: blogId }) {
    try {
        const response = yield call(BlogService.removeBlog, blogId);
        yield put(removeBlogSuccess(response));
        yield put(getBlogs());
    } catch (error) {
        yield put(removeBlogError(error));
    }
}

function* edit({ payload: blog }) {
    try {
        const response = yield call(BlogService.editBlog, blog);
        yield put(editBlogSuccess(response));
        yield put(getBlogs());
    } catch (error) {
        yield put(editBlogError(error));
    }
}

export function* watchGetBlog() {
    yield takeEvery(BlogActionTypes.BLOG_LIST_GET, get);
}

export function* watchAddBlog() {
    yield takeEvery(BlogActionTypes.BLOG_ADD, addBlog);
}

export function* watchShowAddBlogModal() {
    yield takeEvery(BlogActionTypes.BLOG_ADD_MODAL, showAddBlog);
}

export function* watchRemoveBlog() {
    yield takeEvery(BlogActionTypes.BLOG_REMOVE, removeBlog);
}

export function* watchEditBlog() {
    yield takeEvery(BlogActionTypes.BLOG_EDIT, edit);
}

function* blogsSaga() {
    yield all([
        fork(watchGetBlog),
        fork(watchAddBlog),
        fork(watchShowAddBlogModal),
        fork(watchRemoveBlog),
        fork(watchEditBlog)
    ]);
}

export default blogsSaga;
