import React, {useEffect, useState} from "react";
import {
  Container,
} from "reactstrap";
import {
  Statistic,
  Card,
  Row,
  Col,
  Divider,
} from "antd";

//Import antd and axios
import PetDetailedStatistics from "./statistics/PetDetailedStatistics";
import {connect} from "react-redux";
import HeaderHelperFilter from "./header-helper-filter";
import {userRols} from "../../helpers/userRols";

const Dashboard = ({statisticData, user}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [statistic, setStatistic] = useState({
    totalPARENT: 0,
    notApprovedPARENT: 0,
    totalVET: 0,
    notApprovedVET: 0,
    totalCLINIC: 0,
    notApprovedCLINIC: 0,
    totalGROOMER: 0,
    notApprovedGROOMER: 0,
    totalPET_SITTER: 0,
    notApprovedPET_SITTER: 0,
    totalSHELTER: 0,
    notApprovedSHELTER: 0,
    totalPET_WALKER: 0,
    notApprovedPET_WALKER: 0,
    totalPet: 0,
    totalPetsDeleted: 0,
    pets: []
  })


  useEffect(() => {
    if(statisticData) {
      setStatistic(statisticData)
    }
  }, [statisticData])

  const onModalStatisticsOpen = () => {
    setIsOpen(!isOpen);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Card>
          <Container fluid>
            {[userRols.admin, userRols.superAdmin].includes(user?.role) && <HeaderHelperFilter/>}
            <Row justify="space-around" gutter={[16, 24]} className="statistic-row" xs={24}>
              {[userRols.admin, userRols.superAdmin].includes(user?.role) && <Col span={24}>
                <Divider orientation="center"></Divider>
                <Row justify="space-around" gutter={[16, 24]} className="statistic-row" xs={24}>
                  <Col span={12}>
                    <Card className="statistic-card" role="button" bordered onClick={onModalStatisticsOpen}>
                      <Statistic value={statistic.totalPet} title="Pets"/>
                      <div className="verified-email">
                        <span>Deleted:</span> {statistic.totalPetsDeleted}
                      </div>
                    </Card>
                  </Col> <Col span={12}>
                  <Card className="statistic-card" bordered>
                    <Statistic value={statistic.totalPARENT} title="Parents"/>
                    <div className="verified-email">
                      <span>For Verification:</span> {statistic.notApprovedPARENT}</div>
                  </Card>
                </Col>
                </Row>
              </Col>}
              <Col span={24}>
                <Divider orientation="center">Professionals</Divider>
                <Row justify="space-around" gutter={[16, 24]} className="statistic-row" xs={24}>
                  <Col className='col-6 col-md-3'>
                    <Card className="statistic-card" bordered>
                      <Statistic value={statistic.totalVET} title="Vets"/>
                      <div className="approval-email">
                        <span>For Approval:</span> {statistic.notApprovedVET}</div>
                    </Card>
                  </Col>
                  <Col className='col-6 col-md-3'>
                    <Card className="statistic-card" bordered>
                      <Statistic value={statistic.totalCLINIC} title="Clinics"/>
                      <div className="approval-email">
                        <span>For Approval:</span> {statistic.notApprovedCLINIC}</div>
                    </Card>
                  </Col>
                  <Col className='col-6 col-md-3'>
                    <Card className="statistic-card" bordered>
                      <Statistic value={statistic.totalGROOMER} title="Groomers"/>
                      <div className="approval-email">
                        <span>For Approval:</span> {statistic.notApprovedGROOMER}</div>
                    </Card>
                  </Col>
                   <Col className='col-6 col-md-3'>
                    <Card className="statistic-card" bordered>
                      <Statistic value={statistic.totalPET_SITTER} title="Pet sitter"/>
                      <div className="approval-email">
                        <span>For Approval:</span> {statistic.notApprovedPET_SITTER}</div>
                    </Card>
                  </Col>
                   <Col className='col-6 col-md-3'>
                    <Card className="statistic-card" bordered>
                      <Statistic value={statistic.totalPET_WALKER} title="Pet walker"/>
                      <div className="approval-email">
                        <span>For Approval:</span> {statistic.notApprovedPET_WALKER}</div>
                    </Card>
                  </Col>
                   <Col className='col-6 col-md-3'>
                    <Card className="statistic-card" bordered>
                      <Statistic value={statistic.totalSHELTER} title="Shelter"/>
                      <div className="approval-email">
                        <span>For Approval:</span> {statistic.notApprovedSHELTER}</div>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>

          </Container>
        </Card>
        <PetDetailedStatistics isModalStatisticOpen={isOpen} onCancel={() => setIsOpen(!isOpen)}
                               petsArr={statistic.pets} totalPetsDeleted={statistic.totalPetsDeleted}/>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const {Admin, Login} = state
  return {...Admin, ...Login}
}

export default connect(mapStateToProps)(Dashboard);
