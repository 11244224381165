import {InitiatedBookingActionTypes} from "./actionTypes";

export const getInitiatedBookings = (payload) => {
  return {
    type: InitiatedBookingActionTypes.INITIATED_BOOKING_LIST_GET,
    payload: payload,
  };
};

export const getInitiatedBookingsSuccessful = (payload) => {
  return {
    type: InitiatedBookingActionTypes.INITIATED_BOOKING_LIST_GET_SUCCESSFUL,
    payload: payload,
  };
};

export const getInitiatedBookingsError = (payload) => {
  return {
    type: InitiatedBookingActionTypes.INITIATED_BOOKING_LIST_GET_ERROR,
    payload,
  };
};
