import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
    getTransactionError,
    getTransactionSuccessful, refundError, refundSuccess,
} from "./actions";
import TransactionService from "./service";
import {TransactionTypes} from "./actionTypes";
import {getBookings} from "../booking/actions";

function* get({payload}) {
    try {
        const response = yield call(TransactionService.getTransactionId, payload);
        yield put(getTransactionSuccessful(response));
    } catch (error) {
        yield put(getTransactionError(error));
    }
}

function* refund({payload}) {
    try {
        const response = yield call(TransactionService.refund, payload);
        yield put(refundSuccess(response));
        yield put(getBookings({page: 1}));
    } catch (error) {
        yield put(refundError(error));
    }
}

export function* watchGetTransaction() {
    yield takeEvery(TransactionTypes.TRANSACTION_GET, get);
}

export function* watchRefund() {
    yield takeEvery(TransactionTypes.REFUND, refund);
}



function* transactionsSaga() {
    yield all([
        fork(watchGetTransaction),
        fork(watchRefund)
    ]);
}

export default transactionsSaga;
