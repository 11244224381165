import React, { Component } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import BlogRemove from "./BlogRemove";
import BlogEdit from "./BlogEdit";
import { Image } from "antd";

function decodeHTMLEntities(text) {
  var entities = [
    ["amp", "&"],
    ["apos", "'"],
    ["#x27", "'"],
    ["#x2F", "/"],
    ["#39", "'"],
    ["#47", "/"],
    ["lt", "<"],
    ["gt", ">"],
    ["nbsp", " "],
    ["quot", '"'],
  ];

  for (var i = 0, max = entities.length; i < max; ++i)
    text = text.replace(
      new RegExp("&" + entities[i][0] + ";", "g"),
      entities[i][1]
    );

  return text;
}

class BlogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpen: false,
      isRemoveModalOpen: false,
      removeBlog: null,
      editBlog: null,
    };
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditSubmit = this.onEditSubmit.bind(this);
    this.onRemoveOpen = this.onRemoveOpen.bind(this);
    this.onRemoveSubmit = this.onRemoveSubmit.bind(this);
  }

  onEditOpen(blog) {
    this.setState((prevState) => ({
      isEditModalOpen: !prevState.isEditModalOpen,
      editBlog: blog,
    }));
  }

  componentDidMount() {
  }

  onRemoveOpen(blog) {
    this.setState((prevState) => ({
      isRemoveModalOpen: !prevState.isRemoveModalOpen,
      removeBlog: blog,
    }));
  }

  onRemoveSubmit(blog) {
    this.props.onRemoveItem(blog);
  }

  onEditSubmit(blog) {
    this.props.onEditItem(blog);
  }

  getBlogsList(blogListData) {
    if (blogListData) {
      return blogListData.map((blog) => {
        let decodedOutput = decodeHTMLEntities(blog.description);
        return <Tr key={blog._id}>
            <Th>{blog.title}</Th>
            <Th>{blog?.authorName}</Th>
            <Th>
              <div
                key={blog.description}
                dangerouslySetInnerHTML={{ __html: decodedOutput }}
              ></div>
            </Th>
            <Th>{blog.category}</Th>
            <Th>
              {blog.media ? (
                <Image src={blog.media} alt="" className="avatar-sm" />
              ) : (
                ""
              )}
            </Th>
            <Td>
              <div className="button-items d-flex">
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    this.onEditOpen(blog);
                  }}
                >
                  <i className="bx bx-edit font-size-16 align-middle"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-danger waves-effect waves-light"
                  onClick={() => {
                    this.onRemoveOpen(blog);
                  }}
                >
                  <i className="bx bx-trash-alt font-size-16 align-middle"></i>
                </button>
              </div>
            </Td>
          </Tr>
      });
    } else {
      return null;
    }
  }

  render() {
    const { isEditModalOpen, isRemoveModalOpen, removeBlog, editBlog } =
      this.state;
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered"
          >
            <Thead>
              <Tr>
                <Th data-priority="1">Title </Th>
                <Th data-priority="1">Author Name</Th>
                <Th data-priority="3">Description</Th>
                <Th data-priority="3">Category</Th>
                <Th data-priority="6">Media</Th>
                <Th data-priority="6">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>{this.getBlogsList(this.props.list)}</Tbody>
          </Table>
        </div>
        <BlogRemove
          data={removeBlog}
          onSubmit={this.onRemoveSubmit}
          isRemoveModalOpen={isRemoveModalOpen}
          onCancel={this.onRemoveOpen}
        />
        {editBlog && (
          <BlogEdit
            data={editBlog}
            onSubmit={this.onEditSubmit}
            isEditModalOpen={isEditModalOpen}
            onCancel={this.onEditOpen}
          />
        )}
      </div>
    );
  }
}

export default BlogList;
