import React from 'react'
import { Form, Input } from 'antd'
import i18next from 'i18next'

function IdNumber() {

	return <div className='textarea-holder'><Form.Item
		name={['metadata', 'idCard', 'idNumber']}
		label={'Personal identification number'}
		className='input-box'
	>
		<Input
			className='input'
		/>
	</Form.Item>
	</div>
}

export default IdNumber
