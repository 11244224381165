import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, CardHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  getBlogs,
  addBlog,
  showAddBlogModal,
  removeBlog,
  editBlog
} from "../../store/blog/actions";
import { connect } from "react-redux";
import BlogList from "./List/BlogList";
import AddBlog from "./AddBlog";

class Blogs extends Component {
  constructor(props) {
    super(props);

    this.onNewBlogModal = this.onNewBlogModal.bind(this);
    this.onNewBlogSubmit = this.onNewBlogSubmit.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.props.getBlogs();
  }

  onNewBlogModal() {
    this.props.showAddBlogModal(!this.props.isAddNewBlogOpen);
  }

  onNewBlogSubmit(blog) {
    this.props.addBlog(blog);
    this.props.createLog({actionModel: 'add_blog', description: `create blog ${blog.title}`})
  }

  onRemove(blog) {
    this.props.removeBlog(blog);

  }

  onEdit(blog) {
    this.props.editBlog(blog);
  }

  render() {
    const { blogList, isLoadingAddBlog, isAddNewBlogOpen } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Blogs" breadcrumbItem="Blogs" />

            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <Button
                      color="success"
                      className="btn btn-success waves-effect waves-light"
                      onClick={this.onNewBlogModal}
                    >
                      {" "}
                      <i className=""> </i> Add Blog
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <BlogList
                      list={blogList?.blogs}
                      onRemoveItem={this.onRemove}
                      onEditItem={this.onEdit}
                      history={this.props.history}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <AddBlog
          isLoading={isLoadingAddBlog}
          isNewBlogModalOpen={isAddNewBlogOpen}
          onSubmit={this.onNewBlogSubmit}
          onCancel={this.onNewBlogModal}
        />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Blog } = state;
  return Blog;
};

export default
  connect(mapStatetoProps, {
    getBlogs,
    addBlog,
    showAddBlogModal,
    removeBlog,
    editBlog,
  })(Blogs);
