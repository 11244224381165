import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
    getBookingsError,
    getBookingsSuccessful,
    addBookingError,
    addBookingSuccessful,
    showAddBookingModalSuccess,
    removeBookingError,
    removeBookingSuccess,
    getBookings,
    editBookingError,
    editBookingSuccess
} from "./actions";
import BookingService from "./service";
import { BookingActionTypes } from "./actionTypes";

function* get({payload}) {
    try {
        const response = yield call(BookingService.getBookings, payload);
        yield put(getBookingsSuccessful(response));
    } catch (error) {
        yield put(getBookingsError(error));
    }
}

function* addBooking({ payload: booking }) {
    try {
        const response = yield call(BookingService.addBooking, booking);
        yield put(addBookingSuccessful(response));
        yield put(getBookings());

    } catch (error) {
        yield put(addBookingError(error));
    }
}

function* showAddBooking({ payload: showModal }) {
    yield put(showAddBookingModalSuccess(showModal));
}

function* removeBooking({ payload: bookingId }) {
    try {
        const response = yield call(BookingService.removeBooking, bookingId);
        yield put(removeBookingSuccess(response));
        yield put(getBookings());
    } catch (error) {
        yield put(removeBookingError(error));
    }
}

function* edit({ payload: booking }) {
    try {
        const response = yield call(BookingService.editBooking, booking);
        yield put(editBookingSuccess(response));
        yield put(getBookings());
    } catch (error) {
        yield put(editBookingError(error));
    }
}

export function* watchGetBooking() {
    yield takeEvery(BookingActionTypes.BOOKING_LIST_GET, get);
}

export function* watchAddBooking() {
    yield takeEvery(BookingActionTypes.BOOKING_ADD, addBooking);
}

export function* watchShowAddBookingModal() {
    yield takeEvery(BookingActionTypes.BOOKING_ADD_MODAL, showAddBooking);
}

export function* watchRemoveBooking() {
    yield takeEvery(BookingActionTypes.BOOKING_REMOVE, removeBooking);
}

export function* watchEditBooking() {
    yield takeEvery(BookingActionTypes.BOOKING_EDIT, edit);
}

function* bookingsSaga() {
    yield all([
        fork(watchGetBooking),
        fork(watchAddBooking),
        fork(watchShowAddBookingModal),
        fork(watchRemoveBooking),
        fork(watchEditBooking)
    ]);
}

export default bookingsSaga;
