export const TransactionTypes = {
    TRANSACTION_GET: 'TRANSACTION_GET',
    TRANSACTION_SUCCESSFUL: 'TRANSACTION_SUCCESSFUL',
    TRANSACTION_ERROR: 'TRANSACTION_ERROR',

    REFUND: 'REFUND',
    REFUND_SUCCESS: 'REFUND_SUCCESS',
    REFUND_ERROR: 'REFUND_ERROR'
}




