import {UserMetaHeadActionTypes} from "./actionTypes";

const initialState = {
    usersMetaHeadError: null, message: null, isLoadingAddUsers: false, isAddNewUsersOpen: false, usersProfessionalMetaHead: null
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case UserMetaHeadActionTypes.USERS_PROFESSIONAL_META_HEAD_GET:
            state = {
                ...state,
                usersProfessionalMetaHead: null,
                usersMetaHeadError: null
            }
            break;
        case UserMetaHeadActionTypes.USERS_PROFESSIONAL_META_HEAD_GET_SUCCESSFUL:
            state = {
                ...state,
                usersProfessionalMetaHead: action.payload,
                usersMetaHeadError: null
            }
            break;
        case UserMetaHeadActionTypes.USERS_PROFESSIONAL_META_HEAD_GET_ERROR:
            state = {
                ...state,
                usersProfessionalMetaHead: null,
                usersMetaHeadError: action.payload
            }
            break;
        case UserMetaHeadActionTypes.USERS_PROFESSIONAL_META_HEAD_CREATE:
            state = {
                ...state,
                usersMetaHeadError: null
            }
            break;
        case UserMetaHeadActionTypes.USERS_PROFESSIONAL_META_HEAD_CREATE_SUCCESSFUL:
            state = {
                ...state,
                message: action.payload,
                usersMetaHeadError: null
            }
            break;
        case UserMetaHeadActionTypes.USERS_PROFESSIONAL_META_HEAD_CREATE_ERROR:
            state = {
                ...state,
                message: null,
                usersMetaHeadError: action.payload
            }
            break;

    }
    return state;
}

export default account;
