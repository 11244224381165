import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
    getParentsError,
    getParentsSuccessful,
    addParentError,
    addParentSuccessful,
    showAddParentModalSuccess,
    removeParentError,
    removeParentSuccess,
    getParents,
    editParentError,
    editParentSuccess
} from "./actions";
import ParentService from "./service";
import { ParentActionTypes } from "./actionTypes";

function* get({payload}) {
    try {
        const response = yield call(ParentService.getParents, payload);
        yield put(getParentsSuccessful(response));
    } catch (error) {
        yield put(getParentsError(error));
    }
}

function* addParent({ payload: parent }) {
    try {
        const response = yield call(ParentService.addParent, parent);
        yield put(addParentSuccessful(response));
        yield put(getParents());

    } catch (error) {
        yield put(addParentError(error));
    }
}

function* showAddParent({ payload: showModal }) {
    yield put(showAddParentModalSuccess(showModal));
}

function* removeParent({ payload: parentId }) {
    try {
        const response = yield call(ParentService.removeParent, parentId);
        yield put(removeParentSuccess(response));
        yield put(getParents());
    } catch (error) {
        yield put(removeParentError(error));
    }
}

function* edit({ payload: parent }) {
    try {
        const response = yield call(ParentService.editParent, parent);
        yield put(editParentSuccess(response));
        yield put(getParents());
    } catch (error) {
        yield put(editParentError(error));
    }
}

export function* watchGetParent() {
    yield takeEvery(ParentActionTypes.PARENT_LIST_GET, get);
}

export function* watchAddParent() {
    yield takeEvery(ParentActionTypes.PARENT_ADD, addParent);
}

export function* watchShowAddParentModal() {
    yield takeEvery(ParentActionTypes.PARENT_ADD_MODAL, showAddParent);
}

export function* watchRemoveParent() {
    yield takeEvery(ParentActionTypes.PARENT_REMOVE, removeParent);
}

export function* watchEditParent() {
    yield takeEvery(ParentActionTypes.PARENT_EDIT, edit);
}

function* parentsSaga() {
    yield all([
        fork(watchGetParent),
        fork(watchAddParent),
        fork(watchShowAddParentModal),
        fork(watchRemoveParent),
        fork(watchEditParent)
    ]);
}

export default parentsSaga;
