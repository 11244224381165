import ApiService from "../../helpers/ApiHelper";

class UsersService {

    static getUsersByParams({role, searchChars = ''}) {

        return ApiService.request(
          `/api/v2/users/${role}/${searchChars}`,
          "get",
          {}

        )
    }

  static getParentsExel() {
    return ApiService.request(
      `/api/v2/users/export-parents`,
      "get",
      {}

    )
  }
}

export default UsersService;
