import {Button, Modal} from "reactstrap";
import {DatePicker, Input, Select} from "antd";
import moment from "moment/moment";
import React, {useEffect, useState} from "react";
import i18next from "i18next";

const {Option} = Select
const Search = ({
                  searchVar= {text: '', filter: '', tagsFilters: {}},
                  tags = [],
                  options = [{keySearch: '', titleSearch: ''}],
                  filterData = [''],
                  searchModalOpen = false,
                  onSubmit = (e, search) => {
                    console.log(e)
                  },
                  onCancel = (e) => {
                    console.log(e)
                  },
                }) => {

  const [currentDate, setCurrentDate] = useState(moment(new Date()))
  const [search, setSearch] = useState(searchVar)

  useEffect(() => {
    setSearch(searchVar)
  }, [searchVar])

  const onSearch = (param) => {
    setSearch({...search, text: param})
  }
  const onSelectTags = (key, value) => {

    if (search.tagsFilters &&
      Object.keys(search.tagsFilters).find(t => t === key) &&
      !search.tagsFilters[key].find(t => t === value)) {
      search.tagsFilters[key].push(value)
    } else if (
      Object.keys(search.tagsFilters).find(t => t === key) &&
      search.tagsFilters[key].find(t => t === value)) {
      search.tagsFilters[key] = search.tagsFilters[key].filter(t => t !== value)
      if(!search.tagsFilters[key].length) {
        delete search.tagsFilters[key]
      }
    } else {
      search.tagsFilters[key] = [value];
    }
    setSearch({...search, tagsFilters: search.tagsFilters})
  }
  const onSelectBy = (param) => {
    search.filter = param
    setSearch({
      ...search, filter: param, text: filterData.find(fd => fd === param) ? currentDate : ''
    })
  }
    const renderOptions = () => {
      return options.map((option, i) => {
        return <Option value={option.keySearch} key={i}>{option.titleSearch}</Option>
      })
    }

    function renderTags(tags) {
      return Object.keys(tags).map((key, i) => {
        const keyFilter = key.replace('_', '')

        return <div key={i}>
          <h5>{`${key.charAt(0).toUpperCase()}${key.substring(1, key.length)}`.replace('_', ' ').replace('Model', '')}</h5>
          {tags[key].filter(Boolean).map((value, index) => {
            return <button key={index} type={'button'}
                           className={`btn btn-${search.tagsFilters[keyFilter] && search.tagsFilters[keyFilter].find(t => t === value) ? 'success' : 'secondary'} m-1`}
                           onClick={() => onSelectTags(keyFilter, value)}>{i18next.t(value)}</button>
          })}
        </div>
      })
    }
    return <Modal isOpen={searchModalOpen} toggle={onCancel} className='search'>
      {searchModalOpen && <form onSubmit={(e) => onSubmit(e, search)} className="d-block pb-2">
        <h4 className={"text-center my-3"}>Search</h4>

        <div className="d-flex align-items-center justify-content-center">
          {(!filterData.includes(search.filter) ? <Input
            value={search.text}
            placeholder="Search"
            style={{
              width: "168px",
              marginRight: "15px",
            }}
            disabled={!search.filter}
            onChange={(event) => onSearch(event.target.value)}
          /> : <DatePicker
            autocomplete={false}
            placeholder="Current Date"
            disabled={!search.filter}
            onChange={(date, dateString) => {
              onSearch(moment(dateString).format('YYYY-MM-DD'))
              setCurrentDate(moment(dateString))
            }}
            style={{
              width: "130px",
              marginRight: "15px",
            }}
          />)}
          <Select
            placeholder="Search By:"
            value={search.filter ? search.filter : null}
            style={{width: 180}}
            onSelect={onSelectBy}
          >
            {renderOptions()}
          </Select>
        </div>
        {(Object.keys(tags).length > 0) && <span>
        <hr/>
        <div className="p-2">
          {renderTags(tags)}
        </div>
      </span>}
        <div className="d-flex align-items-center justify-content-center pt-2">
          <Button type={'submit'} className='btn btn-info waves-effect search-btn px-5'><i
            className="bx bx-search font-size-13 align-middle"></i></Button>
          <Button type={'button'} className='btn btn-danger waves-effect waves-light search-btn search-cansel' onClick={onCancel}><i
            className="bx bx-x font-size-13 align-middle"></i></Button>
        </div>
      </form>}
    </Modal>
  }

  export default Search
