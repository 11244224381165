import React, {useEffect, useState} from 'react';
import {Form, Input, Radio, Row, Col, Typography} from 'antd';
import {userRols} from "../../../helpers/userRols";
import {Label} from "reactstrap";

const {Text} = Typography
const ProfessionalCompanyData = ({
                                   adminRole,
                                   iban,
                                   feeAmount,
                                   vat
                                 }) => {
  const [isRegistered, setIsRegistered] = useState(vat?.isRegistered ? vat?.isRegistered?.toString() : '')
  const [isFirmRegistered, setIsFirmRegistered] = useState(vat?.isFirmRegistered ? vat?.isFirmRegistered?.toString() : '')


  useEffect(() => {
    if (vat) {
      setIsRegistered(vat?.isRegistered?.toString())
      setIsFirmRegistered(vat?.isFirmRegistered?.toString())
    }
  }, [vat]);
  return (
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item label="VAT registered" name={['metadata', 'vat', "isRegistered"]} initialValue={vat?.isRegistered}>
          <Radio.Group
            defaultValue={isRegistered?.toString()?.toBoolean()}
            onChange={({target}) => setIsRegistered(target.value)}
          >
            <Radio value={'true'}>Yes</Radio>
            <Radio value={'false'}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>


      <Col span={12}>
        {isRegistered?.toString()?.toBoolean() ? (<Form.Item
          label="VAT Number"
          name={['metadata', "vat", "vatNumber"]}
          rules={[{required: true, message: 'Please write VAT'}]}
        >
          <Input
            placeholder="BGXXXXXXXXX"
          />
        </Form.Item>) : <Text>No VAT registration</Text>}
      </Col>

      <Col span={12}>
        <Form.Item label="Works for a legal entity?"
                   name={['metadata', 'vat', "isFirmRegistered"]}
                   initialValue={isFirmRegistered?.toString()}

        >
          <Radio.Group
            onChange={({target}) => setIsFirmRegistered(target.value)}
          >
            <Radio value={'true'}>Yes</Radio>
            <Radio value={'false'}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>

      {isFirmRegistered?.toString()?.toBoolean() && (
        <>
          <Col span={12}>
            <Form.Item
              label="Company Name"
              name={['metadata', "companyName"]}
              rules={[{required: true, message: 'Please write VAT'}]}
            >
              <Input
                placeholder="BGXXXXXXXXX"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Accountable Person"
              name={['metadata', 'vat', "accountablePerson"]}
              rules={[{required: true, message: 'Please write Accountable Person'}]}
            >
              <Input
                placeholder="Accountable Person Name"
              />
            </Form.Item>
          </Col>
        </>
      )}

      <Col span={12}>
        <Form.Item
          label="Unified Identification Code (UIC)"
          name={['metadata', 'vat', "udNumber"]}
          rules={[{required: true, message: 'Please write UIC'}]}
        >
          <Input
            placeholder="XXXXXXXXX"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        {adminRole === userRols.superAdmin ? <Form.Item
          label="IBAN"
          name={['metadata', "IBAN"]}
          rules={[{required: true, message: 'Please write IBAN'}]}
        >
          <Input
            placeholder="BGXXXXXXXXX"
          />
        </Form.Item> : <Form.Item
          label="IBAN"
          rules={[{required: true, message: 'Please write IBAN'}]}
        ><Input disabled={true} value={iban}/></Form.Item>}
      </Col>
      <Col span={12}>
        {adminRole === userRols.superAdmin ? <Form.Item
          label="Fee Amount"
          name={['metadata', "feeAmount"]}
          rules={[{required: true, message: 'Please write Fee Amount'}]}
          initialValue={5}
        >
          <Input
            placeholder="Fee"
          />
        </Form.Item> : <Form.Item
          label="Fee Amount"
          rules={[{required: true, message: 'Please write Fee Amount'}]}
        ><Input disabled={true} value={feeAmount}/></Form.Item>}
      </Col>
    </Row>
  );
};

export default ProfessionalCompanyData;
