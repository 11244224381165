import {takeEvery, all, fork, put, call } from "redux-saga/effects";
import {LogsActionTypes} from "./actionTypes";
import LogServices from "./services";
import {
  getLogError,
  getLogsError,
  getLogsSuccessful,
  getLogSuccessful
} from "./actions";

function* gets({payload}) {
  try {
    const response = yield call(LogServices.getLogs, payload);
    yield put(getLogsSuccessful(response));
  } catch (error) {
    yield put(getLogsError(error));
  }
}
function* get({payload: id}) {
  try {
    const response = yield call(LogServices.getLog, id);
    yield put(getLogSuccessful(response));
  } catch (error) {
    yield put(getLogError(error));
  }
}


export function* watchGetLogs() {
  yield takeEvery(LogsActionTypes.AUDIT_LOG_LIST_GETS, gets);
}
export function* watchGetLog() {
  yield takeEvery(LogsActionTypes.AUDIT_LOG_LIST_GET, get);
}

function* LogsSaga() {
  yield all([
    fork(watchGetLogs),
    fork(watchGetLog)
  ])
}

export default LogsSaga
