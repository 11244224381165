import {TranslationTypes} from "./actionTypes";

export const getTranslation = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_GET,
        payload: payload
    }
}

export const getTranslationSuccessful = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_SUCCESSFUL,
        payload: payload
    }
}

export const getTranslationError = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_ERROR,
        payload,
    }
}
export const getTypeTranslation = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_GET_TYPE,
        payload: payload
    }
}

export const getTypeTranslationSuccessful = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_TYPE_SUCCESSFUL,
        payload: payload
    }
}

export const getTypeTranslationError = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_TYPE_ERROR,
        payload,
    }
}
export const addTranslation = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_ADD,
        payload: payload
    }
}

export const addTranslationSuccessful = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_ADD_SUCCESSFUL,
        payload: payload
    }
}

export const addTranslationError = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_ADD_ERROR,
        payload,
    }
}
export const editTranslation = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_EDIT,
        payload: payload
    }
}

export const editTranslationSuccessful = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_EDIT_SUCCESSFUL,
        payload: payload
    }
}

export const editTranslationError = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_EDIT_ERROR,
        payload,
    }
}

export const removeTranslation = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_REMOVE,
        payload
    }

}
export const removeTranslationSuccess = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_REMOVE_SUCCESSFUL,
        payload
    }
}
export const removeTranslationError = (payload) => {
    return {
        type: TranslationTypes.TRANSLATION_REMOVE_ERROR,
        payload
    }
}

