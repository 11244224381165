import React from 'react'
import { Col, Form, Row } from 'antd'
import i18next from 'i18next'
import DocumentUpload from "../../../helpers/DocumentsUpload";

function DocumentPicture() {

	return (
		<Row>
			<Col xs={24} md={12} xl={8}>
				<Form.Item
					name={['metadata', 'idCard', 'document', 'front']}
					label={'Front ID card'}
					className='input-box'
					valuePropName={'document'}
				>
					<DocumentUpload />
				</Form.Item>
			</Col>
			<Col xs={24} md={12} xl={8}>
				<Form.Item
					name={['metadata', 'idCard', 'document', 'back']}
					label={'Back ID card'}
					className='input-box'
					valuePropName={'document'}
				>
					<DocumentUpload />
				</Form.Item>
			</Col>
			<Col xs={24} md={12} xl={8}>
				<Form.Item
					name={['metadata', 'idCard', 'document', 'certificate']}
					label={'Criminal record certificate'}
					className='input-box'
					valuePropName={'document'}
				>
					<DocumentUpload />
				</Form.Item>
			</Col>
		</Row>
	)
}

export default DocumentPicture
