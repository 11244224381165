import { BookingActionTypes } from "./actionTypes";

export const getBookings = (payload) => {
  return {
    type: BookingActionTypes.BOOKING_LIST_GET,
    payload: payload,
  };
};

export const getBookingsSuccessful = (payload) => {
  return {
    type: BookingActionTypes.BOOKING_LIST_GET_SUCCESSFUL,
    payload: payload,
  };
};

export const getBookingsError = (payload) => {
  return {
    type: BookingActionTypes.BOOKING_LIST_GET_ERROR,
    payload,
  };
};

export const addBooking = (booking) => {
  return {
    type: BookingActionTypes.BOOKING_ADD,
    payload: booking,
  };
};

export const addBookingSuccessful = (payload) => {
  return {
    type: BookingActionTypes.BOOKING_ADD_SUCCESSFUL,
    payload,
  };
};

export const addBookingError = (payload) => {
  return {
    type: BookingActionTypes.BOOKING_ADD_ERROR,
    payload,
  };
};
export const showAddBookingModal = (showModal) => {
  return {
    type: BookingActionTypes.BOOKING_ADD_MODAL,
    payload: showModal,
  };
};
export const showAddBookingModalSuccess = (showModal) => {
  return {
    type: BookingActionTypes.BOOKING_ADD_MODAL_SUCCESS,
    payload: showModal,
  };
};

export const removeBooking = (booking) => {
  return {
    type: BookingActionTypes.BOOKING_REMOVE,
    payload: booking,
  };
};
export const removeBookingSuccess = (booking) => {
  return {
    type: BookingActionTypes.BOOKING_REMOVE_SUCCESS,
    payload: booking,
  };
};
export const removeBookingError = (booking) => {
  return {
    type: BookingActionTypes.BOOKING_REMOVE_ERROR,
    payload: booking,
  };
};
export const editBooking = (booking) => {
  return {
    type: BookingActionTypes.BOOKING_EDIT,
    payload: booking,
  };
};
export const editBookingSuccess = (booking) => {
  return {
    type: BookingActionTypes.BOOKING_EDIT_SUCCESS,
    payload: booking,
  };
};
export const editBookingError = (booking) => {
  return {
    type: BookingActionTypes.BOOKING_EDIT_ERROR,
    payload: booking,
  };
};
