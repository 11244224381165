import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
    getPaymentsError,
    getPaymentsSuccessful,
    addPaymentError,
    addPaymentSuccessful,
    showAddPaymentModalSuccess,
    removePaymentError,
    removePaymentSuccess,
    getPayments,
    editPaymentError,
    editPaymentSuccess
} from "./actions";
import PaymentService from "./service";
import { PaymentActionTypes } from "./actionTypes";

function* get() {
    try {
        const response = yield call(PaymentService.getPayments, {});
        yield put(getPaymentsSuccessful(response));
    } catch (error) {
        yield put(getPaymentsError(error));
    }
}

function* addPayment({ payload: payment }) {
    try {
        const response = yield call(PaymentService.addPayment, payment);
        yield put(addPaymentSuccessful(response));
        yield put(getPayments());
        
    } catch (error) {
        yield put(addPaymentError(error));
    }
}

function* showAddPayment({ payload: showModal }) {
    yield put(showAddPaymentModalSuccess(showModal));
}

function* removePayment({ payload: paymentId }) {
    try {
        const response = yield call(PaymentService.removePayment, paymentId);
        yield put(removePaymentSuccess(response));
        yield put(getPayments());
    } catch (error) {
        yield put(removePaymentError(error));
    }
}

function* edit({ payload: payment }) {
    try {
        const response = yield call(PaymentService.editPayment, payment);
        yield put(editPaymentSuccess(response));
        yield put(getPayments());
    } catch (error) {
        yield put(editPaymentError(error));
    }
}

export function* watchGetPayment() {
    yield takeEvery(PaymentActionTypes.PAYMENT_LIST_GET, get);
}

export function* watchAddPayment() {
    yield takeEvery(PaymentActionTypes.PAYMENT_ADD, addPayment);
}

export function* watchShowAddPaymentModal() {
    yield takeEvery(PaymentActionTypes.PAYMENT_ADD_MODAL, showAddPayment);
}

export function* watchRemovePayment() {
    yield takeEvery(PaymentActionTypes.PAYMENT_REMOVE, removePayment);
}

export function* watchEditPayment() {
    yield takeEvery(PaymentActionTypes.PAYMENT_EDIT, edit);
}

function* paymentsSaga() {
    yield all([
        fork(watchGetPayment),
        fork(watchAddPayment),
        fork(watchShowAddPaymentModal),
        fork(watchRemovePayment),
        fork(watchEditPayment)
    ]);
}

export default paymentsSaga;
