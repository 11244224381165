import React, {Component} from "react";
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import PetRemove from "./PetRemove";
import axios from "axios";
import baseURL from "../../../config";
import dayjs from "dayjs";

class PetList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditModalOpen: false,
            isRemoveModalOpen: false,
            removePet: null,
            editPet: null,
            petListData: [],
        };
        this.onEditOpen = this.onEditOpen.bind(this);
        this.onEditSubmit = this.onEditSubmit.bind(this);
        this.onRemoveOpen = this.onRemoveOpen.bind(this);
        this.onRemoveSubmit = this.onRemoveSubmit.bind(this);
    }

    onEditOpen(pet) {
        this.setState((prevState) => ({
            isEditModalOpen: !prevState.isEditModalOpen,
            editPet: pet,
        }));
    }

    // componentDidMount() {
    //     this.props.getPets()
    // }

    // getAllPets = () => {
    //     //  get pets
    //     axios
    //         .get(`${baseURL}/api/v1/pets/all`, {
    //             headers: {
    //                 Authorization: `Bearer ${localStorage.getItem("token")}`,
    //             },
    //         })
    //         .then((res) => {
    //             let response = res?.data;
    //             this.setState({petListData: response});
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }

    onRemoveOpen(pet) {
        this.setState((prevState) => ({
            isRemoveModalOpen: !prevState.isRemoveModalOpen,
            removePet: pet,
        }));
    }

    onRemoveSubmit(pet) {
        this.props.onRemoveItem(pet);
    }

    onEditSubmit(pet) {
        this.props.onEditItem(pet);
    }

    getPetsList(petListData) {
        if (petListData) {
            return petListData?.pets.map((pet, i) => {
                return <Tr key={i}>
                    <Th>{pet?.parentID?.name} {pet?.parentID?.surname}</Th>
                    <Th>{pet?.name}</Th>
                    <Th>{pet?.type}</Th>
                    <Th>{pet?.breed}</Th>
                    <Th>{pet?.gender}</Th>
                    <Th>{dayjs().diff(dayjs(pet?.dateOfBirth), 'y')}</Th>
                    <Th>{pet?.parentID?.email}</Th>
                    <Th>{dayjs(pet?.createdAt).format('DD/MM/YYYY')}</Th>
                    <Td>
                        <div className="button-items">
                            <button
                                type="button"
                                className="btn btn-danger waves-effect waves-light"
                                onClick={() => {
                                    this.onRemoveOpen(pet);
                                }}
                            >
                                <i className="bx bx-trash-alt font-size-16 align-middle"></i>
                            </button>
                        </div>
                    </Td>
                </Tr>
            });
        } else {
            return null;
        }
    }

    render() {
        const {isEditModalOpen, isRemoveModalOpen, removePet, editPet} =
            this.state;
        return (
            <div className="table-rep-plugin">
                <div className="table-responsive mb-0" data-pattern="priority-columns">
                    <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                    >
                        <Thead>
                            <Tr>
                                <Th data-priority="1">Parent Name</Th>
                                <Th data-priority="1">Pet Name</Th>
                                <Th data-priority="3">Type</Th>
                                <Th data-priority="3">Breed</Th>
                                <Th data-priority="3">Gender</Th>
                                <Th data-priority="3">Age</Th>
                                <Th data-priority="1">Email</Th>
                                <Th data-priority="3">Created At</Th>
                                <Th data-priority="3">Remove</Th>
                            </Tr>
                        </Thead>
                        <Tbody>{this.getPetsList(this.props.list)}</Tbody>
                    </Table>
                </div>
                <PetRemove
                    data={removePet}
                    onSubmit={this.onRemoveSubmit}
                    isRemoveModalOpen={isRemoveModalOpen}
                    onCancel={this.onRemoveOpen}
                />
            </div>
        );
    }
}

export default PetList;
