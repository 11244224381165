import React from 'react';
import thumbnail from '../../assets/images/meta-generate/meta-tumn.png'
import {translationPrefix} from "../../helpers/userRols";

const GenerateMetaThumb = ({avatarPicturePath, prefix, name, surname, businessName, uploadRef, setOnChangeMetadata}) => {
  const canvas = document.getElementsByClassName(`generate${name}${surname}`)


  const dataURLtoFile = (blobFile, filename) => {
    const arr = blobFile.split(',')
    const type = arr[0].match(/:(.*?);/)[1]
    const toBase = atob(arr[1])
    let n = toBase.length
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = toBase.charCodeAt(n);
    }
    return new File([u8arr], filename, {type});
  }
  const generateThumb = () => {
    if (avatarPicturePath && name && (surname || businessName)) {
      const ctx = canvas[0].getContext('2d')
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = (e) => {
        ctx.drawImage(img, 0, 0, 1280, 720)
        const avatar = new Image()
        avatar.src = avatarPicturePath
        avatar.crossOrigin = "anonymous";
        avatar.onload = (e) => {
          ctx.save()
          ctx.arc(835, 365, 300, 0, Math.PI * 2, false)
          ctx.clip()
          ctx.drawImage(avatar, 535, 65, 600, 600)
          ctx.restore()
          ctx.save()
          ctx.font = "bolder 55px Arial";
          ctx.fillStyle = '#FF6A39';
          if(businessName && businessName.split(' ').length >= 1) {
            let startY = 350;
            businessName.split(' ').map(name => {
              ctx.fillText(`${name}`, 70, startY);
              startY += 70;
            })
          } else {
            ctx.fillText(`${prefix ? translationPrefix.bg[prefix.toLowerCase()]+ ' ' : ''}${name}`, 70, 350);
            ctx.fillText(`${surname}`, 70, 420);
          }

          const imgToUpload = new Image()
          imgToUpload.crossOrigin = 'Anonymous'
          imgToUpload.onload = (e) => {
            console.log(e)
          }
          const hashName = btoa(`${new Date().getTime()}`).slice(0, -2)
          const filename = `${hashName}.png`
          setOnChangeMetadata(dataURLtoFile(ctx.canvas.toDataURL('image/png'), filename), filename, businessName ? businessName : `${prefix ? translationPrefix.bg[prefix.toLowerCase()] : ''} ${name} ${surname}`)
        }
      }
      img.src = thumbnail


    }
  }
  return (<>{avatarPicturePath ?
      <div onClick={generateThumb} id={`thumbnail${name}${surname}`}  className={'d-none'} /*style={{width: '100%'}} */ ref={uploadRef}>
        <canvas className={`generate${name}${surname}`} width={1280} height={720}/>
      </div> : null
    }
    </>
  );
};

export default GenerateMetaThumb;
