import {takeEvery, all, fork, put, call } from "redux-saga/effects";
import InitiatedBookingService from "./service";
import {getInitiatedBookingsError, getInitiatedBookingsSuccessful} from "./actions";
import {InitiatedBookingActionTypes} from "./actionTypes";
function* get({payload}) {
  try {
    const response = yield call(InitiatedBookingService.getInitiatedBookings, payload);
    yield put(getInitiatedBookingsSuccessful(response));
  } catch (error) {
    yield put(getInitiatedBookingsError(error));
  }
}
export function* watchGetInitiatedBookings() {
  yield takeEvery(InitiatedBookingActionTypes.INITIATED_BOOKING_LIST_GET, get);
}

function* InitiatedBookingSaga() {
  yield all([
    fork(watchGetInitiatedBookings)
  ])
}


export default InitiatedBookingSaga
