export const BlogBgActionTypes = {
    BLOG_BG_LIST_GET: 'BLOG_BG_LIST_GET',
    BLOG_BG_LIST_GET_SUCCESSFUL: 'BLOG_BG_LIST_GET_SUCCESSFUL',
    BLOG_BG_LIST_GET_ERROR: 'BLOG_BG_LIST_GET_ERROR',


    BLOG_BG_ADD: 'BLOG_BG_ADD',
    BLOG_BG_ADD_SUCCESSFUL: 'BLOG_BG_ADD_SUCCESSFUL',
    BLOG_BG_ADD_ERROR: 'BLOG_BG_ADD_ERROR',

    BLOG_BG_ADD_MODAL: 'BLOG_BG_ADD_MODAL',
    BLOG_BG_ADD_MODAL_SUCCESS: 'BLOG_BG_ADD_MODAL_SUCCESS',

    BLOG_BG_SHOW_REMOVE_MODAL: 'BLOG_BG_SHOW_REMOVE_MODAL',
    BLOG_BG_SHOW_REMOVE_MODAL_SUCCESS: 'BLOG_BG_SHOW_REMOVE_MODAL_SUCCESS',

    BLOG_BG_REMOVE: "BLOG_BG_REMOVE",
    BLOG_BG_REMOVE_SUCCESS: 'BLOG_BG_REMOVE_SUCCESS',
    BLOG_BG_REMOVE_ERROR: 'BLOG_BG_REMOVE_ERROR',

    BLOG_BG_EDIT: 'BLOG_BG_EDIT',
    BLOG_BG_EDIT_SUCCESS: 'BLOG_BG_EDIT_SUCCESS',
    BLOG_BG_EDIT_ERROR: 'BLOG_BG_EDIT_ERROR'
}




