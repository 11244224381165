import React, {Component} from "react";
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import moment from "moment";
import { Link } from 'react-router-dom';

import InvoiceService  from "../../../store/invoicing/service";
import baseURL from "../../../config"

class InvoicingList extends Component {
  constructor(props) {
    super(props);
  }

  
    dwInvoice(invoiceid) {
    }


  tableRowElement(bookings) {
    if (bookings) {
      return bookings.map((booking) => {
        const toUrl = baseURL+'/api/v1/admins/downloadinvoice/'+booking._id;
        return (
          <Tr key={booking._id}>
            <Td><Link to={toUrl} onClick={() => this.dwInvoice(booking._id)}>{Number(booking.number)}</Link></Td>
            <Td>{moment(booking.createdAt).format('DD MMM YYYY')}</Td>
            <Td>{booking.amount.toFixed(2)}</Td>
            <Td>{booking.professional?.name} {booking.professional?.surname}</Td>
          </Tr>
        );
      });
    }
  }

  render() {
    const {list} = this.props;
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered"
          >
            <Thead>
              <Tr>
                <Th data-priority="1">Invoice</Th>
                <Th data-priority="1">Invoice Date</Th>
                <Th data-priority="1">Invoiced Amount</Th>
                <Th data-priority="1">Professional</Th>
              </Tr>
            </Thead>
            <Tbody>{this.tableRowElement(list)}</Tbody>
          </Table>
        </div>

      </div>
    );
  }
}

export default InvoicingList;
