import React, {Component, useEffect, useState} from 'react';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Button, Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {connect} from "react-redux";
import { getLog, getLogs} from "../../store/log/actions";
import LogList from "./List/LogList";
import Paging from "../../components/Pagination/Pagination";
import { Space} from "antd";
import Search from "../../components/Search/Search";

const Logs = ({getLogs, logsList}) => {

  const [currentPage, setCurrentPage] = useState(1)
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [search, setSearch] = useState({filter: '', text: '', tagsFilters: {}})
  const options = [
    {keySearch: 'parentID.name_parentID.surname', titleSearch: 'Name'},
    {keySearch: 'ipAddress', titleSearch: 'Ip Address'},
    {keySearch: 'createdAt', titleSearch: 'Created at'},

  ]
  useEffect(() => {

    getLogs({page: currentPage})
  }, [currentPage])

  const refreshPage = () => {
    setSearch({filter: '', text: '', tagsFilters: {}})
    getLogs({page: 1})
  }
  const setPage = (currentPage) => {
    setPage(currentPage)
    getLogs({page: currentPage})
  }

  const onSubmit = (e, search) => {
    e.preventDefault()
    if ((search.text && search.filter) || Object.keys(search.tagsFilters).length) {
      getLogs({search, page: 1})
    } else {
      getLogs({page: currentPage})
    }
    setCurrentPage(1)
    setSearch(search)
    setFilterModalOpen(false)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Logs" breadcrumbItem="Logs"/>

          <Row>
            <Col>
              <Card>
                <div className="d-inline-flex ant-row-space-between align-items-center">
                  <Paging setPage={setCurrentPage} currentPage={currentPage} pages={logsList?.pages}/>
                  <Space direction="horizontal" className="mr-4">
                    <Button type={'button'} className='btn btn-info waves-effect waves-light search-btn'
                            onClick={() => setFilterModalOpen(!filterModalOpen)}><i
                      className="bx bx-filter-alt align-middle"></i></Button>
                    {(search.text || Object.keys(search.tagsFilters).length > 0) &&
                      <Button type={'button'} className='btn btn-dark waves-effect waves-light search-btn'
                              onClick={refreshPage}><i
                        className='bx bx-revision align-middle'></i></Button>}
                  </Space>
                </div>
                <CardBody>

                  <LogList
                    list={logsList?.logs}
                  />
                </CardBody>
                <Paging setPage={setCurrentPage} currentPage={currentPage} pages={logsList?.pages}/>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Search searchModalOpen={filterModalOpen}
              options={options}
              filterData={['createdAt']}
              searchVar={search}
              tags={logsList?.tags}
              onSubmit={onSubmit}
              onCancel={() => setFilterModalOpen(false)}/>
    </React.Fragment>
  );

}

const mapStateToProps = (state) => {
  const {AuditLog, Login} = state
  return {...AuditLog, ...Login}
}

export default connect(mapStateToProps, {
  getLogs,
  getLog,
})(Logs);
