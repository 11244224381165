import React from 'react'
import { Form, Input } from 'antd'
import i18next from 'i18next'


function IdCardNumber() {

	return <div className='textarea-holder'><Form.Item
		name={['metadata', 'idCard', 'idCardNumber']}
		label={'ID card number'}
		className='input-box'
		rules={[
			{ required: true, message: i18next.t('user-form:please_provide_id_card_number') },
			{}
		]}
	>
		<Input
			className='input'
		/>
	</Form.Item>
	</div>
}

export default IdCardNumber
