import React, {Component} from "react";
import {Label, FormGroup, Modal} from "reactstrap";
import {AvForm, AvField} from "availity-reactstrap-validation";
import FileUpload from "../../../components/FileUpload/FileUpload";
import moment from "moment/moment";
import ClinicMetadata from "./ClinicMetadata";
import ProfessionalAddress from "../../Professionals/List/ProfessionalAddress";
import GenerateMetaThumb from "../../../components/MetaThumb/GenerateMetaThumb";
import axios from "axios";
import baseURL from "../../../config";
import handleAcceptedFiles from "../../../components/MetaThumb/UploadMetaheadData";

class ClinicEdit extends Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFileUploaded = this.onFileUploaded.bind(this);
    this.onChangeChecked = this.onChangeChecked.bind(this);
    this.checkDisabled = this.checkDisabled.bind(this);


    let user = {
      id: "",
      name: "",
      surname: "",
      email: "",
      role: "",
      bio: "",
      university: "",
      approved: 'Yes',
      QRMetadata: {},
      verified: "",
      active: "",
      UIN: "",
      contract: "",
      metadata: {}
    };

    this.state = {user};
    this.uploadRef = React.createRef();

  }


  componentDidMount() {
    const userData = this.props.data;
    let {user} = this.state;

    user = {
      id: userData._id,
      name: userData.name,
      surname: userData.surname,
      email: userData.email,
      role: userData.role,
      address: userData.address || {
        country: '',
        city: '',
        address: '',
        postalCode: ''
      },
      photo: userData?.photo,
      businessName: userData.metadata?.businessName,
      companyName: userData.metadata?.companyName,
      regNumber: userData.metadata?.regNumber,
      telephone: userData.telephone,
      taxNumber: userData.metadata?.taxNumber,
      companyDescription: userData.metadata?.companyDescription,
      QRMetadata: userData.metadata?.QRMetadata,
      dateOfCourtRegistration: moment(user.metadata?.dateOfCourtRegistration).format(
        "DD/MM/YYYY"
      ),
      approved: user.approved,
      contract: userData.contract,
      verified: userData.verified,
      active: userData.active,
      ...userData,
      metadata: {
        vat: userData.metadata.vat || {isRegistered: false, vatNumber: ''},
        ...userData.metadata,
      },
    };
    this.setState({user})
  }

  onCancel() {
    this.props.onCancel();
  }

  onSubmit(status) {
    let {user} = this.state;
    user.status = status
    this.props.onSubmit(user);
    this.props.onCancel();
  }

  onUpdate = () => {
    let {user} = this.state;
    this.props.onUpdate(user);
    this.props.onCancel();
    this.uploadRef.current.click()
  }

  onChangeChecked(e) {
    const {name, checked} = e.target;
    const {user} = this.state;
    user[name] = checked;
    this.setState({user});
  };

  handleChange(e) {
    const {name, value} = e.target;
    const {user} = this.state;

    user[name] = value;
    this.setState({user});
  }

  checkDisabled() {
    const {user} = this.state;
    return user?.Specialization == false || user?.Uin == false || user?.Degree == false || user?.ContractCheckBox == false
  }

  onFileUploaded(file) {
    if (file) {
      const {user} = this.state;
      user['contract'] = file.url;
      this.setState({user});
    }
  }

  render() {
    const {user} = this.state;
    const {isEditModalOpen, isLoading} = this.props
    return (
      <Modal scrollable={true} style={{height: 700}} size="lg" isOpen={isEditModalOpen} toggle={this.onCancel}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Approve {user.role}</h5>
          <button
            type="button"
            onClick={this.onCancel}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {/*<div className="modal-body">*/}
        {/*  <AvForm className="needs-validation row">*/}
        {/*    <FormGroup className="col-sm-12 col-md-6">*/}
        {/*      <Label htmlFor="validationCustom01">Name</Label>*/}
        {/*      <AvField*/}
        {/*        name="name"*/}
        {/*        placeholder="Name"*/}
        {/*        type="text"*/}

        {/*        errorMessage="Please write name"*/}
        {/*        className="form-control"*/}
        {/*        onChange={this.handleChange}*/}
        {/*        value={user.name}*/}
        {/*        validate={{required: {value: true}}}*/}
        {/*        id="validationCustom01"*/}
        {/*      />*/}
        {/*    </FormGroup>*/}
        {/*    <FormGroup className="col-sm-12 col-md-6">*/}
        {/*      <Label htmlFor="validationCustom02">Surname</Label>*/}
        {/*      <AvField*/}
        {/*        name="surname"*/}
        {/*        placeholder="Surname"*/}
        {/*        type="text"*/}

        {/*        errorMessage="Please write surname"*/}
        {/*        className="form-control"*/}
        {/*        onChange={this.handleChange}*/}
        {/*        value={user.surname}*/}
        {/*        validate={{required: {value: true}}}*/}
        {/*        id="validationCustom02"*/}
        {/*      />*/}
        {/*    </FormGroup>*/}
        {/*    <FormGroup className="col-sm-12 col-md-6">*/}
        {/*      <Label htmlFor="validationCustom02">Phone</Label>*/}
        {/*      <AvField*/}
        {/*        name="telephone"*/}
        {/*        placeholder="Telephone"*/}
        {/*        type="text"*/}

        {/*        errorMessage="Please write telephone"*/}
        {/*        className="form-control"*/}
        {/*        onChange={this.handleChange}*/}
        {/*        value={user.telephone}*/}
        {/*        validate={{required: {value: true}}}*/}
        {/*        id="validationCustom02"*/}
        {/*      />*/}
        {/*    </FormGroup>*/}
        {/*    <FormGroup className="col-sm-12 col-md-6">*/}
        {/*      <Label htmlFor="validationCustom02">Email</Label>*/}
        {/*      <AvField*/}
        {/*        name="email"*/}
        {/*        placeholder="E-mail"*/}
        {/*        type="email"*/}

        {/*        errorMessage="Please write email"*/}
        {/*        className="form-control"*/}
        {/*        onChange={this.handleChange}*/}
        {/*        value={user.email}*/}
        {/*        validate={{required: {value: true}}}*/}
        {/*        id="validationCustom02"*/}
        {/*      />*/}
        {/*    </FormGroup>*/}
        {/*    <ProfessionalAddress address={user.address} setAddress={this.setState}/>*/}


        {/*    <FormGroup className="col-sm-12 col-md-6 c-pointer">*/}
        {/*      <Label htmlFor="validationCustom02">Contract</Label>*/}
        {/*      <FileUpload*/}

        {/*        onFileUploaded={this.onFileUploaded}*/}
        {/*      ></FileUpload>*/}
        {/*    </FormGroup>*/}
        {/*    <FormGroup className="col-sm-12 col-md-6">*/}
        {/*      <Label htmlFor="validationCustom02">Contract File</Label>*/}
        {/*      {user.contract && <div><a href={user.contract} className={'font-size-20 text-primary'}*/}
        {/*                                target="_blank">Contract <i*/}
        {/*        className="bx bxs-down-arrow font-size-16 align-middle"></i></a></div>}*/}
        {/*    </FormGroup>*/}
        {/*    <FormGroup>*/}
        {/*      <GenerateMetaThumb avatarPicturePath={user?.photo} prefix={user.prefixBy} name={user.name}*/}
        {/*                         surname={user.surname}*/}
        {/*                         businessName={user?.metadata?.businessName}*/}
        {/*                         uploadRef={this.uploadRef}*/}
        {/*                         setOnChangeMetadata={(file, filename, professionalName) => {*/}
        {/*                           handleAcceptedFiles(file, filename, user._id, professionalName, user?.metadata?.bio ? user.metadata.bio : user.metadata.companyDescription, this.props.createProfessionalMetaHeadData)*/}
        {/*                         }}/>*/}
        {/*    </FormGroup>*/}
        {/*  </AvForm>*/}
        {/*    <ClinicMetadata user={user} setUser={this.setState.bind(this)}/>*/}
        {/*</div>*/}

        {/*{isLoading && (*/}
        {/*  <div className="modal-footer">*/}
        {/*    <div className="spinner-border text-success m-1" role="status">*/}
        {/*      <span className="sr-only">Loading...</span>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}

        {/*{!isLoading && (*/}
        {/*  <div className="d-flex ant-row-space-between align-items-center p-3">*/}
        {/*    <div className="left">*/}
        {/*      <button*/}
        {/*        type="button"*/}
        {/*        onClick={() => this.onSubmit('No')}*/}
        {/*        className="btn btn-danger waves-effect waves-light mr-1"*/}
        {/*      >*/}
        {/*        Decline*/}
        {/*      </button>*/}
        {/*      <button*/}
        {/*        type="button"*/}
        {/*        onClick={() => this.onSubmit('Yes')}*/}
        {/*        disabled={this.checkDisabled()}*/}
        {/*        className="btn btn-success waves-effect waves-light"*/}
        {/*      >*/}
        {/*        Approve*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*    <div className="right">*/}
        {/*      <button*/}
        {/*        type="button"*/}
        {/*        onClick={this.onCancel}*/}
        {/*        className="btn btn-secondary waves-effect mr-1"*/}
        {/*        data-dismiss="modal"*/}
        {/*      >*/}
        {/*        Cancel*/}
        {/*      </button>*/}
        {/*      <button*/}
        {/*        type="button"*/}
        {/*        onClick={this.onUpdate}*/}
        {/*        className="btn btn-warning waves-effect waves-light"*/}
        {/*      >*/}
        {/*        Update*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}

      </Modal>
    );
  }
}

export default ClinicEdit;
