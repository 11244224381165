export const PaymentsActionTypes = {
    PROFPAY_LIST_GET: 'PROFPAY_LIST_GET',
    PROFPAY_LIST_GET_SUCCESSFUL: 'PROFPAY_LIST_GET_SUCCESSFUL',
    PROFPAY_LIST_GET_ERROR: 'PROFPAY_LIST_GET_ERROR',

    PROF_LIST_GET: 'PROF_LIST_GET',
    PROF_LIST_GET_SUCCESSFUL: 'PROF_LIST_GET_SUCCESSFUL',
    PROF_LIST_GET_ERROR: 'PROF_LIST_GET_ERROR',

    PROFPAY_GET_FILTERED: 'PROFPAY_GET_FILTERED',
    PROFPAY_GET_FILTERED_SUCCESSFUL: 'PROFPAY_GET_FILTERED_SUCCESSFUL',
    PROFPAY_GET_FILTERED_ERROR: 'PROFPAY_GET_FILTERED_ERROR',

    PROFPAY_CREATE: 'PROFPAY_CREATE',
    PROFPAY_CREATE_SUCCESS: 'PROFPAY_CREATE_SUCCESS',
    PROFPAY_CREATE_ERROR: 'PROFPAY_CREATE_ERROR'
}




