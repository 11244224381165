import { ReceivedActionTypes } from "./actionTypes";


const initialState = {
    receivedListError: null, receivedList: null
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case ReceivedActionTypes.RECEIVED_LIST_GET:
            state = {
                ...state,
                receivedList: null,
                receivedListError: null
            }
            break;
        case ReceivedActionTypes.RECEIVED_LIST_GET_SUCCESSFUL:
            state = {
                ...state,
                receivedList: action.payload,
                receivedListError: null
            }
            break;
        case ReceivedActionTypes.RECEIVED_LIST_GET_ERROR:
            state = {
                ...state,
                receivedList: null,
                receivedListError: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default account;