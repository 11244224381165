import React, { useEffect, useState } from "react";
import { Form, Modal, Pagination } from "antd";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import moment from "moment";
import axios from "axios";
import baseURL from "config";
import { Button } from "reactstrap";
import "./Ratings.css";
import TextArea from "antd/es/input/TextArea";

export default function Ratings({ isOpen, onCancel, user }) {
  const [ratings, setRatings] = useState([]);
  const [paging, setPaging] = useState({ current: 1, total: 1, pageSize: 10 });
  const [edit, setEdit] = useState({ open: false, feedback: null });

  const getFeedback = async (paging) => {
    try {
      const data = await axios.get(
        `${baseURL}/api/v1/feedback/${user._id}?limit=${paging.pageSize}${
          paging.current > 1
            ? "&skip=" + (paging.current - 1) * paging.pageSize
            : ""
        }&creationSort=-1`
      );
      setRatings(data.data.feedbacks);
      setPaging({
        ...paging,
        current:
          data?.data?.current === 0
            ? 1
            : Math.round(Number(data?.data?.current) / paging.pageSize) + 1,
        total: data?.data?.total,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const ratingColor = (rating) => {
    if (rating > 3) {
      return "#34c38f";
    }

    if (rating === 3) {
      return "#f1b44c";
    }

    return "#f46a6a";
  };

  useEffect(() => {
    if (isOpen) getFeedback(paging);
  }, [isOpen]);

  const updatePaging = (page) => {
    getFeedback({ ...paging, current: page });
  };

  const editItem = async (e) => {
    e.preventDefault();

    let rating = e.target.rating.value;
    if (rating < 1 || rating > 5 || typeof Number(rating) !== "number") {
      alert("Rating must be a number between 1 and 5");
      return false;
    } else {
      await axios.patch(`${baseURL}/api/v1/feedback`, {
        rating: e.target.rating.value,
        comment: e.target.comment.value,
        id: edit.feedback._id,
      });
      getFeedback({ ...paging });
      setEdit({ open: false, feedback: null });
    }
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      className="modal-container"
    >
      <div
        style={{ height: "94vh", overflow: "scroll", margin: 0, padding: 0 }}
      >
        <div style={{ padding: "15px" }}>
          <h3>
            User ratings for{" "}
            <span style={{ fontWeight: "bold" }}>
              {user.name} {user.surname}
            </span>
          </h3>
        </div>
        <Table
          id="tech-companies-1"
          className="table table-striped table-bordered"
        >
          <Thead>
            <Tr>
              <Th data-priority="3">Rating [0 - 5]</Th>
              <Th data-priority="3">From</Th>
              <Th data-priority="3">Comment</Th>
              <Th data-priority="3">Booking Time</Th>
              <Th data-priority="3">Review date</Th>
              <Th data-priority="3">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {ratings.map((rating) => (
              <Tr
                key={rating._id}
                style={{
                  backgroundColor:
                    edit?.feedback?._id === rating._id ? "gray" : "inherit",
                }}
              >
                <Td
                  style={{
                    color: ratingColor(rating.rating),
                    fontWeight: "bold",
                  }}
                >
                  {rating.rating}
                </Td>
                <Td>{`${rating?.booking_id?.parentID?.name} ${rating?.booking_id?.parentID?.surname}`}</Td>
                <Td>
                  <p
                    style={{
                      overflow: "scroll",
                      margin: 0,
                      padding: 0,
                      maxWidth: '400px',
                      maxHeight: "100px",
                    }}
                  >
                    {rating?.comment}
                  </p>
                </Td>
                <Td>
                  {moment(rating?.booking_id?.time).format(
                    "dddd, MMMM Do YYYY HH:mm"
                  )}
                </Td>
                <Td>{moment(rating.updatedAt).format("dddd, MMMM Do YYYY")}</Td>
                <Td style={{ display: "flex", gap: "5px" }}>
                  <Button
                    color="warning"
                    onClick={() => setEdit({ open: true, feedback: rating })}
                  >
                    Edit
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Pagination
          defaultCurrent={1}
          current={paging.current}
          onChange={(e) => updatePaging(e)}
          total={paging.total}
          pageSize={paging.pageSize}
        />
      </div>

      {edit?.open && (
        <Modal
          open={edit.open}
          onCancel={() => setEdit({ open: false, feedback: null })}
          onOk={() => setEdit({ open: false, feedback: null })}
          footer={null}
        >
          <form
            onSubmit={editItem}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label for="rating">Rating</label>
            <input
              id="rating"
              name="rating"
              defaultValue={edit?.feedback?.rating || ""}
              min="1"
              max="5"
            ></input>
            <br />

            <label for="comment">Comment</label>
            <TextArea
              id="comment"
              name="comment"
              rows={6}
              defaultValue={edit?.feedback?.comment || ""}
            ></TextArea>
            <br />

            <Button type="Submit" color="primary" style={{ marginTop: "10px" }}>
              Submit
            </Button>
          </form>
        </Modal>
      )}
    </Modal>
  );
}
