import ApiService from "../../helpers/ApiHelper";

class LogServices {

  static getLogs(payload) {
    const query = `?search=${JSON.stringify(payload.search)?JSON.stringify(payload.search):'1'}&page=${payload.page}`
    return ApiService.request(
      `/api/v2/logs/${query}`,
      'get',
      {}
    )
  }

  static getLog(id) {
    return ApiService.request(
      `/api/v2/logs/${id}`,
      'get',
      {}
    )
  }

}

export default LogServices
