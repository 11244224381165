import React, {Component} from 'react';
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import moment from "moment";

class InitiatedBookingsList extends Component {


  getNameOfStep = (step) => {
    return step.split('_').map(name => {
      return `${name.charAt(0)}${name.substring(1).toLowerCase()}`
    }).join(' ')
  }


  getInitiatedBookingsList(listData) {
    if (listData) {
      return listData?.map((list, i) => {
        return <Tr key={i}>
          <Td>{list.parentID ? `${list.parentID.name} ${list.parentID.surname}` : 'Incognito'}</Td>
          <Td>{list.meetingType}</Td>
          <Td>{list.appointmentReason}</Td>
          <Td>{list.professionalID ? `${list.professionalID.name} ${list.professionalID.surname} / ${list.professionalID.role}`: ''}</Td>
          <Td>{this.getNameOfStep(list.bookingStep)}</Td>
          <Td>
            <ul>
              {Object.entries(list.stepsTiming).map((timing, i) => {
                if (new Date(timing[1]).getTime()) {
                  return <li key={i}>{`${timing[0]}: ${moment(timing[1]).format('HH:mm:ss DD-MM-YYYY')}`}</li>
                }
              })}
            </ul>
          </Td>
          <Td>{this.getNameOfStep(list.nextStep)}</Td>
          {/*<Td>{list.bookingStep === 'PAYMENT' ? list?.bookingID?.stripePaymentID : ''}</Th>*/}
        </Tr>
      });
    } else {
      return null;
    }
  }

  render() {
    const {list} = this.props
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered"
          >
            <Thead>
              <Tr>
                <Th data-priority="1">Name</Th>
                <Th data-priority="2">Meeting Type</Th>
                <Th data-priority="2">Reason</Th>
                <Th data-priority="3">Professional Name / Role</Th>
                <Th data-priority="2">Current Step Success</Th>
                <Th data-priority="2">Timing Steps</Th>
                <Th data-priority="2">Current Step Stop</Th>
                {/*<Th data-priority="3">Payment Id</Th>*/}
              </Tr>
            </Thead>
            <Tbody>{this.getInitiatedBookingsList(list)}</Tbody>
          </Table>
        </div>
      </div>
    );
  }
}


export default InitiatedBookingsList;
