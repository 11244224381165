import { PaymentActionTypes } from "./actionTypes";


const initialState = {
    paymentListError: null, message: null, isLoadingAddPayment: false, isAddNewPaymentOpen: false
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case PaymentActionTypes.PAYMENT_LIST_GET:
            state = {
                ...state,
                paymentList: null,
                paymentListError: null
            }
            break;
        case PaymentActionTypes.PAYMENT_LIST_GET_SUCCESSFUL:
            state = {
                ...state,
                paymentList: action.payload,
                paymentListError: null
            }
            break;
        case PaymentActionTypes.PAYMENT_LIST_GET_ERROR:
            state = {
                ...state,
                paymentList: null,
                paymentListError: action.payload
            }
            break;
        case PaymentActionTypes.PAYMENT_ADD_MODAL:
            state = {
                ...state,
                isAddNewPaymentOpen: action.payload,
            }
            break;
        case PaymentActionTypes.PAYMENT_ADD_MODAL_SUCCESS:
            state = {
                ...state,
                isAddNewPaymentOpen: action.payload,
            }
            break;
        case PaymentActionTypes.PAYMENT_ADD:
            state = {
                ...state,
                isLoadingAddPayment: true
            }
            break;
        case PaymentActionTypes.PAYMENT_ADD_SUCCESSFUL:
            state = {
                ...state,
                isLoadingAddPayment: false,
                isAddNewPaymentOpen: false,
            }
            break;
        case PaymentActionTypes.PAYMENT_ADD_ERROR:
            state = {
                ...state,
                isLoadingAddPayment: false,
                isAddNewPaymentOpen: false,
            }
            break;
        case PaymentActionTypes.PAYMENT_REMOVE_SUCCESS:
            state = {
                ...state,
                isLoadingAddPayment: false,
                isAddNewPaymentOpen: false,
            }
            break;
        case PaymentActionTypes.PAYMENT_REMOVE_ERROR:
            state = {
                ...state,
                isLoadingAddPayment: false,
                isAddNewPaymentOpen: false,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default account;