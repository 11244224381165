export const UserActionTypes = {
    USERS_PROFESSIONAL_LIST_GET: 'USERS_PROFESSIONAL_LIST_GET',
    USERS_PROFESSIONAL_LIST_GET_SUCCESSFUL: 'USERS_PROFESSIONAL_LIST_GET_SUCCESSFUL',
    USERS_PROFESSIONAL_LIST_GET_ERROR: 'USERS_PROFESSIONAL_LIST_GET_ERROR',

    USERS_PARENT_LIST_GET: 'USERS_PARENT_LIST_GET',
    USERS_PARENT_LIST_GET_SUCCESSFUL: 'USERS_PARENT_LIST_GET_SUCCESSFUL',
    USERS_PARENT_LIST_GET_ERROR: 'USERS_PARENT_LIST_GET_ERROR',

    USERS_PARENT_LIST_GET_EXEL: 'USERS_PARENT_LIST_GET_EXEL',
    USERS_PARENT_LIST_GET_EXEL_SUCCESSFUL: 'USERS_PARENT_LIST_GET_EXEL_SUCCESSFUL',
    USERS_PARENT_LIST_GET_EXEL_ERROR: 'USERS_PARENT_LIST_GET_EXEL_ERROR'


}




