import React from 'react';
import {Button, Col, DatePicker, Divider, Form, Input, Row, Select} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

const {Option} = Select;
const ClinicFormData = () => {
  return (<Row gutter={24}>
      <Col xs={24} md={12}>
        <Form.Item
          name={['metadata', 'businessName']}
          label={('Clinic Name')}
          className='input-box'
        >
          <Input
            className='input'
            placeholder={'Clinic Name'}
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          name={['metadata', 'dateOfCourtRegistration']}
          label={('Date of current Registration')}
          className='input-box'
        >
          <DatePicker
            style={{width: '100%'}}
            className='input'
            defaultValue={dayjs().add(1, 'd')}
          />
        </Form.Item>
      </Col>
      <Col span={24}>

        <Form.List
          name={['metadata', 'officialRepresentatives']}
        >
          {(fields, {add, remove}, {errors}) => (
            <Row gutter={12} align='middle'>
              {fields.map(({key, name, ...restField}, index) => (
                <Col key={key} xs={22}>
                  <Row gutter={12} align='middle'>
                    <Col xs={24}>
                      <Form.Item
                        {...restField}
                        name={[name, 'role']}
                        label={'Representative role'}
                        className="input-box"
                        rules={[
                          {
                            required: true,
                            message: 'Please provide "Representative role"',
                          },
                        ]}
                      >
                        <Select
                          placeholder={'Representative role'}
                          className="input"
                          bordered={false}
                        >
                          {/* TODO: Get options from provided array list */}
                          <Option value="role-1">
                            {'General Manager'}
                          </Option>
                          <Option value="role-2">
                            {' '}
                            {'Senior executive director'}
                          </Option>
                          <Option value="role-3">
                            {' '}
                            {'Executive director'}
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={11} md={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'name']}
                        label={('Name')}
                        className='input-box'
                      >
                        <Input
                          className='input'
                          placeholder={'Name'}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={11} md={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'surname']}
                        label={('Surname')}
                        className='input-box'
                      >
                        <Input
                          className='input'
                          placeholder={'Surname'}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={11} md={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'email']}
                        label={('Email')}
                        className='input-box'
                      >
                        <Input
                          className='input'
                          placeholder={'Email'}
                        />
                      </Form.Item>
                    </Col>



                    {fields.length > 1 &&
                      <Col xs={2}>
                        <Button icon={
                          <MinusCircleOutlined
                            className='btn btn-remove'

                          />} onClick={() => remove(name)} />
                      </Col>}
                    {fields.length > 1 && fields.length - 1 !== index && (
                      <Divider className='fields-divider'/>
                    )}
                  </Row>
                </Col>
              ))}
              <Col xs={2}>
                <Form.Item>
                  <Button icon={<PlusOutlined/>} onClick={() => add()}/>
                  <Form.ErrorList errors={errors}/>
                </Form.Item>
              </Col>
            </Row>

          )}
        </Form.List>
      </Col>
      <Col span={12}>

      </Col>


    </Row>

  );
};

export default ClinicFormData;
