import { PaymentActionTypes } from "./actionTypes";

export const getPayments = () => {
  return {
    type: PaymentActionTypes.PAYMENT_LIST_GET,
    payload: null,
  };
};

export const getPaymentsSuccessful = (payload) => {
  return {
    type: PaymentActionTypes.PAYMENT_LIST_GET_SUCCESSFUL,
    payload: payload,
  };
};

export const getPaymentsError = (payload) => {
  return {
    type: PaymentActionTypes.PAYMENT_LIST_GET_ERROR,
    payload,
  };
};

export const addPayment = (payment) => {
  return {
    type: PaymentActionTypes.PAYMENT_ADD,
    payload: payment,
  };
};

export const addPaymentSuccessful = (payload) => {
  return {
    type: PaymentActionTypes.PAYMENT_ADD_SUCCESSFUL,
    payload,
  };
};

export const addPaymentError = (payload) => {
  return {
    type: PaymentActionTypes.PAYMENT_ADD_ERROR,
    payload,
  };
};
export const showAddPaymentModal = (showModal) => {
  return {
    type: PaymentActionTypes.PAYMENT_ADD_MODAL,
    payload: showModal,
  };
};
export const showAddPaymentModalSuccess = (showModal) => {
  return {
    type: PaymentActionTypes.PAYMENT_ADD_MODAL_SUCCESS,
    payload: showModal,
  };
};

export const removePayment = (payment) => {
  return {
    type: PaymentActionTypes.PAYMENT_REMOVE,
    payload: payment,
  };
};
export const removePaymentSuccess = (payment) => {
  return {
    type: PaymentActionTypes.PAYMENT_REMOVE_SUCCESS,
    payload: payment,
  };
};
export const removePaymentError = (payment) => {
  return {
    type: PaymentActionTypes.PAYMENT_REMOVE_ERROR,
    payload: payment,
  };
};
export const editPayment = (payment) => {
  return {
    type: PaymentActionTypes.PAYMENT_EDIT,
    payload: payment,
  };
};
export const editPaymentSuccess = (payment) => {
  return {
    type: PaymentActionTypes.PAYMENT_EDIT_SUCCESS,
    payload: payment,
  };
};
export const editPaymentError = (payment) => {
  return {
    type: PaymentActionTypes.PAYMENT_EDIT_ERROR,
    payload: payment,
  };
};
