import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, CardHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  getAdmins,
  addAdmin,
  showAddAdminModal,
  removeAdmin,
  editAdmin
} from "../../store/admin/actions";
import { connect } from "react-redux";
import AdminList from "./List/AdminList";
import AddAdmin from "./AddAdmin";
import axios from "axios";
import baseURL from "../../config";

class Admins extends Component {
  constructor(props) {
    super(props);
    this.onNewAdminModal = this.onNewAdminModal.bind(this);
    this.onNewAdminSubmit = this.onNewAdminSubmit.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.props.getAdmins();
  }

  onNewAdminModal() {
    this.props.showAddAdminModal(!this.props.isAddNewAdminOpen);
  }

  onNewAdminSubmit(admin) {
    this.props.addAdmin(admin);
  }

  onRemove(admin) {
    this.props.removeAdmin(admin);
  }

  onEdit(admin) {
    this.props.editAdmin(admin);
  }
  async migrate(admin) {
    try {
      const res = await axios.get(`${baseURL}/api/v2/users/migrate`);
      console.log(res)
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { adminList, isLoadingAddAdmin, isAddNewAdminOpen } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Admins" breadcrumbItem="Admins" />

            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <Button
                      color="success"
                      className="btn btn-success waves-effect waves-light"
                      onClick={this.onNewAdminModal}
                    >
                      {" "}
                      <i className=""> </i> Add Admin
                    </Button>
                    <Button
                      color="success"
                      className="btn btn-success waves-effect waves-light"
                      onClick={this.migrate}
                    >
                      {" "}
                      <i className=""> </i> Migrate
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <AdminList
                      list={adminList}
                      onRemoveItem={this.onRemove}
                      onEditItem={this.onEdit}
                      history={this.props.history}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <AddAdmin
          isLoading={isLoadingAddAdmin}
          isNewAdminModalOpen={isAddNewAdminOpen}
          onSubmit={this.onNewAdminSubmit}
          onCancel={this.onNewAdminModal}
        />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Admin } = state;
  return Admin;
};

export default connect(mapStatetoProps, {
    getAdmins,
    addAdmin,
    showAddAdminModal,
    removeAdmin,
    editAdmin
  })(Admins);
