import React, {Component} from "react";
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import PetWolkersEdit from "./PetWolkersEdit";
import axios from "axios";
import fileDownload from "js-file-download";
import {ProfessionalActionTypes} from "../../../store/professionals/actionTypes";
import {Button} from "reactstrap";
import RemoveList from "../../../components/RemoveList/RemoveList";
import QRCodeView from "../../../components/QRCode/QRCodeView";
import ServiceList from "../../../components/ServiceListUpdate/ServiceList";
import {connect} from "react-redux";
import {getTypeTranslation} from "../../../store/translations/actions";
import {userRols} from "../../../helpers/userRols";
import {approveProfessional} from "../../../store/professionals/actions";
import WorkSchedule from "../../../components/WorkShedule/WorkSchedule";
import IDDocuments from "../../../components/IDDocuments/IDDocuments";
import {createProfessionalMetaHeadData} from "../../../store/metaHead/actions";
import ProfessionalEdit from "../../Professionals/List/ProfessionalEdit";
import dayjs from "dayjs";

class PetWalkersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpen: false,
      isRemoveModalOpen: false,
      isServiceEditModalOpen: false,
      isStatisticModalOpen: false,
      isWorkScheduleModalOpen: false,
      isIdCardOpen: false,
      idCardProfessional: null,
      removeProfessional: null,
      editProfessional: null,
      listData: [],
      defaultValues: {metadata: {services: [{serviceName: '', price: 0, duration: 20}]}},
      title: '',
      subTitle: '',
      status: ''
    };
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditSubmit = this.onEditSubmit.bind(this);
    this.onRemoveOpen = this.onRemoveOpen.bind(this);
    this.onRemoveSubmit = this.onRemoveSubmit.bind(this);
    this.specializationFileDownload =
      this.specializationFileDownload.bind(this);
    this.onDeclineAccount = this.onDeclineAccount.bind(this);
  }

  componentDidMount() {
    this.props.getTypeTranslation({type: userRols.petSitter.toLowerCase()});
  }

  onEditOpen(professional) {
    this.setState((prevState) => ({
      isEditModalOpen: !prevState.isEditModalOpen,
      editProfessional: professional,
    }));

    if(this.state.isEditModalOpen) {
      window.location.reload()
    }
  }

  specializationFileDownload(fileUrl, nameFile) {
    const fileName = nameFile;
    const fileExtention = fileUrl.substr(fileUrl.lastIndexOf(".") + 1);
    axios
      .get(fileUrl, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, `${fileName}.${fileExtention}`);
      });
  }

  onDeclineAccount(declineAccount) {
    this.props.approveProfessional(declineAccount)

  }

  onWorkScheduleOpen = (professional) => {
    this.setState({
      isWorkScheduleModalOpen: !this.state.isWorkScheduleModalOpen,
      editProfessional: professional
    })
  }
  // onIdCardOpen = (professional) => {
  //   this.setState({
  //     isIdCardOpen: !this.state.isIdCardOpen,
  //     editProfessional: professional,
  //     idCardProfessional: professional?.metadata?.idCard
  //   })
  // }

  onRemoveOpen(professional, title, subTitle, status) {
    this.setState((prevState) => ({
      isRemoveModalOpen: !prevState.isRemoveModalOpen,
      removeProfessional: professional,
      title,
      subTitle,
      status
    }));
  }

  onRemoveSubmit(professional) {
    this.props.onRemoveItem(professional);
    window.location.reload()
  }

  onApproveSubmit = (professional) => {
    this.props.onApproveItem(professional);
    window.location.reload()
  }

  onEditSubmit(professional) {
    this.props.onEditItem(professional);
    window.location.reload()
  }

  renderList = (data) => {
    if (data) {
      const listView = [];
      data.map((user, i) => {
        const userApproved = this.userStatuses(user.approved);
        const address = user.address
        listView.push(
          <Tr key={user._id}>
            <Th>{user.name}{' '}{user.surname}<br/><br/>{user.telephone}</Th>
            <Th>{user.email}
              <br/><br/>{user.metadata && user.metadata?.QRMetadata &&
                <QRCodeView qrcode={user.metadata?.QRMetadata} i={i}/>}</Th>

            <Th>{address?.country} {address?.city} {address?.address} {address?.address2} {address?.postalCode}
              <br/>
              <br/>
              {user?.address2 && Object.keys(user?.address2) ? `${user.address2?.country} ${user.address2?.city} ${user.address2?.address} ${user.address2?.address2} ${user.address2?.postalCode}` : ''}
            </Th>
            <Td>
              {!user.active ? user?.step : 6} / 6
            </Td>
            <Td>
              {user?.createdAt ? <div>
                Create:{dayjs(user.createdAt).format('DD.MM.YYYY')}
              </div> : ''}
              {user?.updatedAt ? <div>
                Update:{dayjs(user.updatedAt).format('DD.MM.YYYY')}
              </div> : ''}
              {user?.approvedDate ? <div>
                Approved:{dayjs(user.approvedDate).format('DD.MM.YYYY')}
              </div> : ''}
            </Td>
            <Th>{userApproved}
              <div>
                <Button
                  className="btn waves-effect waves-light"
                  onClick={() => {
                    user.approved = 'Pending'
                    this.onRemoveOpen(user,
                      'Do you want to \'Pending\' this Pet Sitter?',
                      'Disable Pet Sitter',
                      ProfessionalActionTypes.DISABLE);
                  }}
                ><i className="bx bxs-down-arrow font-size-16 align-middle"></i></Button>
              </div>
            </Th>
            <Td>
              <div className="button-items d-flex">
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    this.onEditOpen(user);
                  }}
                >
                  <i className="bx bxs-edit font-size-16 align-middle"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-warning waves-effect waves-light"
                  onClick={() => {
                    this.onServiceOpen(user);
                  }}
                >
                  <i className='bx bx-calendar font-size-16 align-middle'></i>
                </button>
                <button
                  type="button"
                  className="btn btn-warning waves-effect waves-light"
                  onClick={() => {
                    this.onWorkScheduleOpen(user);
                  }}
                >
                  <i className='bx bx-time font-size-16 align-middle'></i>
                </button>
                {/*<button*/}
                {/*  type="button"*/}
                {/*  className="btn btn-warning waves-effect waves-light"*/}
                {/*  onClick={() => {*/}
                {/*    this.onIdCardOpen(user);*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <i className='bx bx-id-card font-size-16 align-middle'></i>*/}
                {/*</button>*/}
                <button
                  type="button"
                  className="btn btn-danger waves-effect waves-light"
                  onClick={() => {
                    this.onRemoveOpen(user,
                      'Do you want to remove this Pet Sitter?',
                      'Remove Pet Sitter',
                      ProfessionalActionTypes.REMOVE);
                  }}
                >
                  <i className="bx bx-trash-alt font-size-16 align-middle"></i>
                </button>
              </div>
            </Td>
          </Tr>
        );
      });

      return listView;
    } else {
      return null;
    }
  }

  userStatuses = (status) => {
    let value;
    switch (status) {
      case true:
        value = "Yes";
        break;
      case false:
        value = "No";
        break;
      default:
        value = status;
    }
    return value;
  }

  onServiceOpen = (professional) => {
    let defaultServices = {metadata: {services: [{serviceName: '', price: 0, duration: 20}]}}
    if (professional && professional?.metadata && professional?.metadata.services.length) {
      defaultServices = {metadata: {services: professional?.metadata.services}}
    }
    this.setState({
      isServiceEditModalOpen: !this.state.isServiceEditModalOpen,
      editProfessional: professional,
      defaultValues: defaultServices
    });
  }

  render() {
    const {
      isEditModalOpen,
      isRemoveModalOpen,
      isServiceEditModalOpen,
      isWorkScheduleModalOpen,
      isIdCardOpen,
      idCardProfessional,
      defaultValues,
      removeProfessional,
      editProfessional,
      title,
      subTitle,
      status
    } =
      this.state;
    const {list} = this.props
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered"
          >
            <Thead>
              <Tr>
                <Th data-priority="1">Name </Th>
                <Th data-priority="3">Email</Th>
                <Th data-priority="3">Address / Address ID card</Th>
                <Th data-priority="3">Profile Step</Th>
                <Th data-priority="3">Info</Th>
                <Th data-priority="3">Approved</Th>
                <Th data-priority="3">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>{this.renderList(list)}</Tbody>
          </Table>
        </div>
        <RemoveList
          data={removeProfessional}
          onSubmit={status === ProfessionalActionTypes.DISABLE ? this.onEditSubmit : this.onRemoveSubmit}
          isRemoveModalOpen={isRemoveModalOpen}
          onCancel={this.onRemoveOpen}
          title={title}
          subTitle={subTitle}
          status={status}
        />
        {isEditModalOpen && (
          <ProfessionalEdit
            admin={this.props?.user}
            data={editProfessional}
            onSubmit={this.onApproveSubmit}
            onUpdate={this.onEditSubmit}
            createProfessionalMetaHeadData={this.props.createProfessionalMetaHeadData}
            onDeclineAccount={this.onDeclineAccount}
            isEditModalOpen={isEditModalOpen}
            onCancel={this.onEditOpen}
          />
        )}
        {isServiceEditModalOpen && <ServiceList
          user={editProfessional}
          type={userRols.petSitter.toLowerCase()}
          defaultValues={defaultValues}
          setServiceList={this.setState.bind(this)}
          isServiceEditModalOpen={isServiceEditModalOpen}
          onCancel={this.onServiceOpen}
          onUpdate={this.onEditSubmit}/>}
        {isWorkScheduleModalOpen &&
          <WorkSchedule professional={editProfessional}
                        isWorkScheduleModalOpen={isWorkScheduleModalOpen}
                        onCancel={this.onWorkScheduleOpen}/>}
        {/*{isIdCardOpen &&*/}
        {/*  <IDDocuments professional={editProfessional}*/}
        {/*               idCard={idCardProfessional}*/}
        {/*               isIdCardOpen={isIdCardOpen}*/}
        {/*               onCancel={this.onIdCardOpen}*/}
        {/*               onUpdate={this.onEditSubmit}/>}*/}
      </div>

    );
  }
}

export default connect(({Login}) => {
  return Login
}, {getTypeTranslation, approveProfessional, createProfessionalMetaHeadData})(PetWalkersList);
