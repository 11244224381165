import React, { Component } from "react";
import { Modal } from "reactstrap";

class AdminRemove extends Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onCancel() {
    this.props.onCancel();
  }

  onSubmit(admin) {
    this.props.onSubmit(admin);
    this.props.onCancel();
    window.location.reload();
  }

  render() {
    return (
      <Modal isOpen={this.props.isRemoveModalOpen} toggle={this.onCancel}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Remove user
          </h5>
          <button
            type="button"
            onClick={this.onCancel}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Do you want to remove this user?</h5>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={this.onCancel}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => { this.onSubmit(this.props.data._id) }}
            className="btn btn-danger waves-effect waves-light"
          >
            Yes
          </button>
        </div>
      </Modal>
    );
  }
}

export default AdminRemove;
