import { BlogBgActionTypes } from "./actionTypes"


export const getBlogsBg = () => {
    return {
        type: BlogBgActionTypes.BLOG_BG_LIST_GET,
        payload: null
    }
}

export const getBlogsBgSuccessful = (payload) => {
    return {
        type: BlogBgActionTypes.BLOG_BG_LIST_GET_SUCCESSFUL,
        payload: payload
    }
}

export const getBlogsBgError = (payload) => {
    return {
        type: BlogBgActionTypes.BLOG_BG_LIST_GET_ERROR,
        payload,
    }
}

export const addBlogBg = (blogBg) => {
    return {
        type: BlogBgActionTypes.BLOG_BG_ADD,
        payload: blogBg
    }
}

export const addBlogBgSuccessful = (payload) => {
    return {
        type: BlogBgActionTypes.BLOG_BG_ADD_SUCCESSFUL,
        payload
    }
}

export const addBlogBgError = (payload) => {
    return {
        type: BlogBgActionTypes.BLOG_BG_ADD_ERROR,
        payload
    }
}
export const showAddBlogBgModal = (showModal) => {
    return {
        type: BlogBgActionTypes.BLOG_BG_ADD_MODAL,
        payload: showModal
    }
}
export const showAddBlogBgModalSuccess = (showModal) => {
    return {
        type: BlogBgActionTypes.BLOG_BG_ADD_MODAL_SUCCESS,
        payload: showModal
    }
}

export const removeBlogBg = (blogBg) => {
    return {
        type: BlogBgActionTypes.BLOG_BG_REMOVE,
        payload: blogBg
    }
    
}
export const removeBlogBgSuccess = (blogBg) => {
    return {
        type: BlogBgActionTypes.BLOG_BG_REMOVE_SUCCESS,
        payload: blogBg
    }
}
export const removeBlogBgError = (blogBg) => {
    return {
        type: BlogBgActionTypes.BLOG_BG_REMOVE_ERROR,
        payload: blogBg
    }
}
export const editBlogBg = (blogBg) => {
    return {
        type: BlogBgActionTypes.BLOG_BG_EDIT,
        payload: blogBg
    }
}
export const editBlogBgSuccess = (blogBg) => {
    return {
        type: BlogBgActionTypes.BLOG_BG_EDIT_SUCCESS,
        payload: blogBg
    }
}
export const editBlogBgError = (blogBg) => {
    return {
        type: BlogBgActionTypes.BLOG_BG_EDIT_ERROR,
        payload: blogBg
    }
}
