import {CouponsActionTypes} from "./actionTypes";

export const getCoupons = (payload) => {
    return {
        type: CouponsActionTypes.COUPONS_LIST_GET,
        payload: payload
    }
}

export const getCouponsSuccessful = (payload) => {
    return {
        type: CouponsActionTypes.COUPONS_LIST_GET_SUCCESSFUL,
        payload: payload
    }
}

export const getCouponsError = (payload) => {
    return {
        type: CouponsActionTypes.COUPONS_LIST_GET_ERROR,
        payload,
    }
}

export const getCoupon = (payload) => {
    return {
        type: CouponsActionTypes.COUPON_GET,
        payload: payload
    }
}

export const getCouponSuccessful = (payload) => {
    return {
        type: CouponsActionTypes.COUPON_GET_SUCCESSFUL,
        payload: payload
    }
}

export const getCouponError = (payload) => {
    return {
        type: CouponsActionTypes.COUPONS_LIST_GET_ERROR,
        payload,
    }
}

export const createCoupon = (payload) => {
    return {
        type: CouponsActionTypes.COUPON_CREATE,
        payload: payload
    }
}

export const createCouponSuccessful = (payload) => {
    return {
        type: CouponsActionTypes.COUPON_CREATE_SUCCESSFUL,
        payload: payload
    }
}

export const createCouponError = (payload) => {
    return {
        type: CouponsActionTypes.COUPON_CREATE_ERROR,
        payload,
    }
}


export const updateCoupon = (payload) => {
    return {
        type: CouponsActionTypes.COUPON_UPDATE,
        payload: payload
    }
}

export const updateCouponSuccessful = (payload) => {
    return {
        type: CouponsActionTypes.COUPON_UPDATE_SUCCESSFUL,
        payload: payload
    }
}

export const updateCouponError = (payload) => {
    return {
        type: CouponsActionTypes.COUPON_UPDATE_ERROR,
        payload,
    }
}


export const disableCoupon = (payload) => {
    return {
        type: CouponsActionTypes.COUPON_DISABLE,
        payload: payload
    }
}

export const disableCouponSuccessful = (payload) => {
    return {
        type: CouponsActionTypes.COUPON_DISABLE_SUCCESSFUL,
        payload: payload
    }
}

export const disableCouponError = (payload) => {
    return {
        type: CouponsActionTypes.COUPON_DISABLE_ERROR,
        payload,
    }
}

