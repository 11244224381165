import React from 'react';
import {Form, Row, Col, Divider, Button, DatePicker, Typography} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

const {Title} = Typography

const SpecializationPractice = ({dateFormatYear}) => {
  return (<Row gutter={24}>
    <Col span={24}>
      <Title level={5} className='mb-2'>Years of practice</Title>
      <Form.List
        name={['metadata', 'yearsOfPractice']}
        >
        {(fields, {add, remove}, {errors}) => (
          <Row gutter={12} align='middle'>
            {fields.map(({key, name, ...restField}, index) => (
              <Col key={key} xs={22}>
                <Row gutter={12} align='middle'>
                  <Col xs={24} md={11}>
                    <Form.Item
                      {...restField}
                      name={[name, 'from']}
                      label={('Start Year')}
                      className='input-box'
                    >
                      <DatePicker
                        style={{width: '100%'}}
                        className='input'
                        picker='year'
                        format={dateFormatYear}
                        defaultValue={dayjs().subtract(4, 'y')}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={22} md={11}>
                    <Form.Item
                      {...restField}
                      name={[name, 'to']}
                      label={('End Year')}
                      className='input-box'

                    >
                      <DatePicker
                        style={{width: '100%'}}
                        className='input'
                        picker='year'
                        iso
                        format={dateFormatYear}
                        defaultValue={dayjs()}
                        onChange={(date) => {
                          console.log(date)
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {fields.length > 1 &&
                    <Col xs={2}>
                      <Button icon={
                        <MinusCircleOutlined
                          className='btn btn-remove'

                        />} onClick={() => remove(name)} />
                    </Col>}
                  {fields.length > 1 && fields.length - 1 !== index && (
                    <Divider className='fields-divider'/>
                  )}
                </Row>
              </Col>
            ))}
            <Col xs={2}>
              <Form.Item>
                <Button icon={<PlusOutlined/>} onClick={() => add()}/>
                <Form.ErrorList errors={errors}/>
              </Form.Item>
            </Col>
          </Row>

        )}
      </Form.List>
    </Col>
  <Col span={12}>

  </Col>


</Row>
  );
};

export default SpecializationPractice;
