import { PaymentsReportActionTypes } from "./actionTypes";

const initialState = {
    paymentsListError: null, message: null
}

const ProfPayments = (state = initialState, action) => {
    switch (action.type) {

        case PaymentsReportActionTypes.PROFREPORTS_LIST_GET:
            state = {
                ...state,
                invoicingList: null,
                invoicingListError: null
            }
            break;
        case PaymentsReportActionTypes.PROFREPORTS_LIST_GET_SUCCESSFUL:
            state = {
                ...state,
                invoicingList: action.payload,
                invoicingListError: null
            }
            break;
        case PaymentsReportActionTypes.PROFREPORTS_LIST_GET_ERROR:
            state = {
                ...state,
                invoicingList: null,
                invoicingListError: action.payload
            }
            break;


        case PaymentsReportActionTypes.PROFREPORTS_GET_FILTERED:
            state = {
                ...state,
                invoicingList: null,
                invoicingListError: null
            }
            break;
        case PaymentsReportActionTypes.PROFREPORTS_GET_FILTERED_SUCCESSFUL:
            state = {
                ...state,
                invoicingList: action.payload,
                invoicingListError: null
            }
            break;
        case PaymentsReportActionTypes.PROFREPORTS_GET_FILTERED_ERROR:
            state = {
                ...state,
                invoicingList: null,
                invoicingListError: action.payload
            }
            break;


        case PaymentsReportActionTypes.PROFREPORTS_CREATE:
            state = {
                ...state,
                isLoadingAddInvoicing: true
            }
            break;
        case PaymentsReportActionTypes.PROFREPORTS_CREATE_SUCCESS:
            state = {
                ...state,
                isLoadingAddInvoicing: false,
            }
            break;
        case PaymentsReportActionTypes.PROFREPORTS_CREATE_ERROR:
            state = {
                ...state,
                isLoadingAddInvoicing: false,
            }
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
}


export default ProfPayments;


