import {takeEvery, fork, put, all, call} from "redux-saga/effects";

import CouponService from "./service";
import {CouponsActionTypes} from "./actionTypes";
import {
  createCouponError,
  createCouponSuccessful,
  disableCouponError,
  disableCouponSuccessful,
  getCouponError,
  getCoupons,
  getCouponsError,
  getCouponsSuccessful,
  getCouponSuccessful,
  updateCouponError,
  updateCouponSuccessful
} from "./actions";


function* gets({payload}) {
  try {
    const response = yield call(CouponService.getCoupons, {...payload});
    yield put(getCouponsSuccessful(response));
  } catch (error) {
    yield put(getCouponsError(error));
  }
}

function* get({payload}) {
  try {
    const response = yield call(CouponService.getCoupon, {...payload});
    yield put(getCouponSuccessful(response));
  } catch (error) {
    yield put(getCouponError(error));
  }
}

function* create({payload: coupon}) {
  try {
    const response = yield call(CouponService.create, coupon);
    yield put(createCouponSuccessful(response));
    yield put(getCoupons({page: 1, search: 1}));
  } catch (error) {
    yield put(createCouponError(error));
  }
}

function* update({payload: coupon}) {
  try {
    const response = yield call(CouponService.update, coupon);
    yield put(updateCouponSuccessful(response));
    yield put(getCoupons({page: 1, search: 1}));
  } catch (error) {
    yield put(updateCouponError(error));
  }
}

function* disable(payload) {
  try {
    const response = yield call(CouponService.disable, {...payload.payload});
    yield put(disableCouponSuccessful(response));
    yield put(getCoupons({page: 1, search: 1}));
  } catch (error) {
    yield put(disableCouponError(error));
  }
}


export function* watchGetCoupons() {
  yield takeEvery(CouponsActionTypes.COUPONS_LIST_GET, gets);
}

export function* watchGetCoupon() {
  yield takeEvery(CouponsActionTypes.COUPON_GET, get);
}

export function* watchCreateCoupon() {
  yield takeEvery(CouponsActionTypes.COUPON_CREATE, create);
}

export function* watchUpdateCoupon() {
  yield takeEvery(CouponsActionTypes.COUPON_UPDATE, update);
}

export function* watchDisableCoupon() {
  yield takeEvery(CouponsActionTypes.COUPON_DISABLE, disable);
}

function* usersSaga() {
  yield all([
    fork(watchGetCoupons),
    fork(watchGetCoupon),
    fork(watchCreateCoupon),
    fork(watchUpdateCoupon),
    fork(watchDisableCoupon),
  ]);
}

export default usersSaga;
