import React from "react";
import {Modal} from "reactstrap";

const RemoveList = ({isRemoveModalOpen, onCancel, onSubmit, data, title, subTitle, status}) => {
  return (
    <Modal isOpen={isRemoveModalOpen} toggle={() => onCancel()}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {subTitle}
        </h5>
        <button
          type="button"
          onClick={() => onCancel()}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <h5>{title}</h5>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => onCancel()}
          className="btn btn-secondary waves-effect"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={() => {
            onSubmit(status === 'DISABLE' ? data : data._id)
            onCancel()
          }}
          className="btn btn-danger waves-effect waves-light"
        >
          Yes
        </button>
      </div>
    </Modal>
  )
}

export default RemoveList
