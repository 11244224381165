import React, {Component} from "react";
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import HotelEdit from "./HotelEdit";
import axios from "axios";
import fileDownload from "js-file-download";
import {ProfessionalActionTypes} from "../../../store/professionals/actionTypes";
import {Button} from "reactstrap";
import RemoveList from "../../../components/RemoveList/RemoveList";
import QRCodeView from "../../../components/QRCode/QRCodeView";
import ServiceList from "../../../components/ServiceListUpdate/ServiceList";
import {connect} from "react-redux";
import {getTypeTranslation} from "../../../store/translations/actions";
import HotelStatistic from "./HotelStatistic";
import {approveProfessional} from "../../../store/professionals/actions";
import {createProfessionalMetaHeadData} from "../../../store/metaHead/actions";
import ProfessionalEdit from "../../Professionals/List/ProfessionalEdit";
import dayjs from "dayjs";

class HotelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpen: false,
      isRemoveModalOpen: false,
      isServiceEditModalOpen: false,
      isStatisticModalOpen: false,
      removeHotel: null,
      editHotel: null,
      defaultValues: {metadata: {services: [{serviceName: '', price: 0, duration: 20}]}},
      hotelsListData: [],
      title: '',
      subTitle: '',
      status: ''
    };
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditSubmit = this.onEditSubmit.bind(this);
    this.onRemoveOpen = this.onRemoveOpen.bind(this);
    this.onRemoveSubmit = this.onRemoveSubmit.bind(this);
    this.specializationFileDownload =
      this.specializationFileDownload.bind(this);
    this.onDeclineAccount = this.onDeclineAccount.bind(this);
  }

  componentDidMount() {
    this.props.getTypeTranslation({type: 'hotel'});
  }

  onEditOpen(hotel) {
    this.setState((prevState) => ({
      isEditModalOpen: !prevState.isEditModalOpen,
      editHotel: hotel,
    }));
    if(this.state.isEditModalOpen) {
      window.location.reload()
    }
  }

  specializationFileDownload(fileUrl, nameFile) {
    const fileName = nameFile;
    const fileExtention = fileUrl.substr(fileUrl.lastIndexOf(".") + 1);
    axios
      .get(fileUrl, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, `${fileName}.${fileExtention}`);
      });
  }

  onDeclineAccount(declineAccount) {
    this.props.approveProfessional(declineAccount)

  }

  onRemoveOpen(professional, title, subTitle, status) {
    this.setState((prevState) => ({
      isRemoveModalOpen: !prevState.isRemoveModalOpen,
      removeHotel: professional,
      title,
      subTitle,
      status
    }));
  }

  onRemoveSubmit(hotel) {
    this.props.onRemoveItem(hotel);
    window.location.reload()

  }

  onApproveSubmit = (professional) => {
    this.props.onApproveItem(professional);
    window.location.reload()

  }

  onEditSubmit(hotel) {
    this.props.onEditItem(hotel);
    window.location.reload()

  }
  onStatisticOpen = (statistic) => {
    this.setState((prevState) => ({
      isStatisticModalOpen: !prevState.isStatisticModalOpen,
      editHotel: statistic,
    }));
  }
  getHotelsList(hotelsListData) {
    if (hotelsListData) {
      const hotelListView = [];
      hotelsListData.map((user, i) => {
        if (user.role === "HOTELS") {
          const userApproved = this.userStatuses(user.approved);

          hotelListView.push(
            <Tr key={user._id}>
              <Th>{user.name}{' '}{user.surname}<br/><br/>{user.telephone}</Th>
              <Th>{user.email}
                <br/><br/>{user.metadata && user.metadata?.QRMetadata &&
                  <QRCodeView qrcode={user.metadata?.QRMetadata} i={i}/>}</Th>

              <Th>{user.metadata?.businessName}</Th>
              <Th>{user.metadata?.companyName}</Th>
              <Th>{user.metadata?.siteUrl}</Th>
              <Th>{user.metadata?.visits ? user.metadata?.visits.counter : '-'}</Th>
              <Td>
                {!user.active ? user?.step : 4} / 4
              </Td>
              <Td>
                {user?.createdAt ? <div>
                  Create:{dayjs(user.createdAt).format('DD.MM.YYYY')}
                </div> : ''}
                {user?.updatedAt ? <div>
                  Update:{dayjs(user.updatedAt).format('DD.MM.YYYY')}
                </div> : ''}
                {user?.approvedDate ? <div>
                  Approved:{dayjs(user.approvedDate).format('DD.MM.YYYY')}
                </div> : ''}
              </Td>
              <Th>{userApproved}
                <div>
                  <Button
                    className="btn waves-effect waves-light"
                    onClick={() => {
                      user.approved = 'Pending'
                      this.onRemoveOpen(user,
                        'Do you want to \'Pending\' this Hotel?',
                        'Disable Hotel',
                        ProfessionalActionTypes.DISABLE);
                    }}
                  ><i className="bx bxs-down-arrow font-size-16 align-middle"></i></Button>
                </div>
              </Th>
              <Td>
                <div className="button-items d-flex">
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => {
                      this.onEditOpen(user);
                    }}
                  >
                    <i className="bx bxs-edit font-size-16 align-middle"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-info waves-effect waves-light"
                    onClick={() => {
                      this.onStatisticOpen(user?.metadata?.visits);
                    }}
                  >
                    <i className='bx bx-stats font-size-16 align-middle'></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-warning waves-effect waves-light"
                    onClick={() => {
                      this.onServiceOpen(user);
                    }}
                  >
                    <i className='bx bx-calendar font-size-16 align-middle'></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => {
                      this.onRemoveOpen(user,
                        'Do you want to remove this Hotel?',
                        'Remove Hotel',
                        ProfessionalActionTypes.REMOVE);
                    }}
                  >
                    <i className="bx bx-trash-alt font-size-16 align-middle"></i>
                  </button>
                </div>
              </Td>
            </Tr>
          );
        }
      });

      return hotelListView;
    } else {
      return null;
    }
  }

  userStatuses = (status) => {
    let value;
    switch (status) {
      case true:
        value = "Yes";
        break;
      case false:
        value = "No";
        break;
      default:
        value = status;
    }
    return value;
  }

  onServiceOpen = (professional) => {
    let defaultServices = {metadata: {services: [{serviceName: '', price: 0, duration: 20}]}}
    if (professional && professional?.metadata && professional?.metadata.services.length) {
      defaultServices = {metadata: {services: professional?.metadata.services}}
    }
    this.setState({
      isServiceEditModalOpen: !this.state.isServiceEditModalOpen,
      editHotel: professional,
      defaultValues: defaultServices
    });
  }

  render() {
    const {
      isEditModalOpen,
      isRemoveModalOpen,
      isServiceEditModalOpen,
      isStatisticModalOpen,
      removeHotel,
      editHotel,
      defaultValues,
      title,
      subTitle,
      status
    } =
      this.state;
    const {list, user} = this.props
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered"
          >
            <Thead>
              <Tr>
                <Th data-priority="1">Name </Th>
                <Th data-priority="3">Email</Th>
                <Th data-priority="3">Business name</Th>
                <Th data-priority="3">Company name</Th>
                <Th data-priority="3">Url Address</Th>
                <Th data-priority="3">Visits</Th>
                <Th data-priority="3">Profile Step</Th>
                <Th data-priority="3">Info</Th>
                <Th data-priority="3">Approved</Th>
                <Th data-priority="3">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>{this.getHotelsList(list)}</Tbody>
          </Table>
        </div>
        <RemoveList
          data={removeHotel}
          onSubmit={status === ProfessionalActionTypes.DISABLE ? this.onApproveSubmit : this.onRemoveSubmit}
          isRemoveModalOpen={isRemoveModalOpen}
          onCancel={this.onRemoveOpen}
          title={title}
          subTitle={subTitle}
          status={status}
        />
          <ProfessionalEdit
            admin={user}
            data={editHotel}
            onSubmit={this.onApproveSubmit}
            onUpdate={this.onEditSubmit}
            createProfessionalMetaHeadData={this.props.createProfessionalMetaHeadData}
            onDeclineAccount={this.onDeclineAccount}
            isEditModalOpen={isEditModalOpen}
            onCancel={this.onEditOpen}
            removeOrder={this.onRemoveOpen}

          />
        {isStatisticModalOpen && (
          <HotelStatistic
            data={editHotel}
            isStatisticModalOpen={isStatisticModalOpen}
            onCancel={this.onStatisticOpen}
          />
        )}
        {isServiceEditModalOpen && <ServiceList
          user={editHotel}
          type={'hotel'}
          setServiceList={this.setState.bind(this)}
          isServiceEditModalOpen={isServiceEditModalOpen}
          defaultValues={defaultValues}
          onCancel={this.onServiceOpen}
          onUpdate={this.onEditSubmit}/>}
      </div>
    );
  }
}

export default connect(({Login}) => {
  return Login
}, {getTypeTranslation, approveProfessional, createProfessionalMetaHeadData})(HotelList);
