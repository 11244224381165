export const BlogActionTypes = {
    BLOG_LIST_GET: 'BLOG_LIST_GET',
    BLOG_LIST_GET_SUCCESSFUL: 'BLOG_LIST_GET_SUCCESSFUL',
    BLOG_LIST_GET_ERROR: 'BLOG_LIST_GET_ERROR',


    BLOG_ADD: 'BLOG_ADD',
    BLOG_ADD_SUCCESSFUL: 'BLOG_ADD_SUCCESSFUL',
    BLOG_ADD_ERROR: 'BLOG_ADD_ERROR',

    BLOG_ADD_MODAL: 'BLOG_ADD_MODAL',
    BLOG_ADD_MODAL_SUCCESS: 'BLOG_ADD_MODAL_SUCCESS',

    BLOG_SHOW_REMOVE_MODAL: 'BLOG_SHOW_REMOVE_MODAL',
    BLOG_SHOW_REMOVE_MODAL_SUCCESS: 'BLOG_SHOW_REMOVE_MODAL_SUCCESS',

    BLOG_REMOVE: "BLOG_REMOVE",
    BLOG_REMOVE_SUCCESS: 'BLOG_REMOVE_SUCCESS',
    BLOG_REMOVE_ERROR: 'BLOG_REMOVE_ERROR',

    BLOG_EDIT: 'BLOG_EDIT',
    BLOG_EDIT_SUCCESS: 'BLOG_EDIT_SUCCESS',
    BLOG_EDIT_ERROR: 'BLOG_EDIT_ERROR'
}




