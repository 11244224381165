import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
    getAccountantsError,
    getAccountantsSuccessful,
} from "./actions";
import AccountantService from "./service";
import { AccountantActionTypes } from "./actionTypes";

function* get() {
    try {
        const response = yield call(AccountantService.getAccountants, {});
        yield put(getAccountantsSuccessful(response));
    } catch (error) {
        yield put(getAccountantsError(error));
    }
}

export function* watchGetAccountant() {
    yield takeEvery(AccountantActionTypes.ACCOUNTANT_LIST_GET, get);
}



function* accountantsSaga() {
    yield all([
        fork(watchGetAccountant)
    ]);
}

export default accountantsSaga;
