import {Label, Modal} from "reactstrap";
import React, {useEffect} from "react";
import {Col, Divider, Form, InputNumber, Row, Select} from "antd";
import {connect} from "react-redux";
import i18next from 'i18next';
const ServiceList = ({
                       user,
                       setServiceList,
                       isServiceEditModalOpen,
                       onCancel,
                       onUpdate,
                       translationList,
                       defaultValues,
                     }) => {
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue(defaultValues)
    if (user && user?.metadata && user?.metadata.services) {
      setServiceList({user})
    }
  }, [user])

  const onFinishFailed = async (formData) => {
    console.log(formData)
  }
  const onUpdateUser = (formData) => {
    user.metadata.services = form.getFieldValue(['metadata', 'services'])
    onUpdate(user);
    onCancel();
  }
  const renderServices = () => {
    if (defaultValues) {

      return <Form form={form}
                   layout='vertical'
                   name='editing'
                   autoComplete='off'
                   onFinish={onUpdateUser}
                   onFinishFailed={onFinishFailed}
                   initialValues={defaultValues}
      >
        <Form.List
          name={['metadata', 'services']}
          rules={[
            {
              validator: async (_, services) => {
                if (!services || services.length < 1) {
                  return Promise.reject(
                    new Error('Min service required is 1'),
                  )
                }
              },
            },
          ]}
        >

          {(fields, {add, remove}, {errors}) => {
            return <>
              {fields.map(({key, name, ...restField}, index) => (
                <Row key={key} gutter={12} align='middle'>
                  <Col xs={24} xl={12}>
                    <Form.Item
                      {...restField}
                      name={[name, 'serviceName']}
                      label={i18next.t('service_label')}
                      className='input-box'
                      rules={[
                        {
                          required: true,
                          message: 'please_provide_service_name',
                        },
                      ]}
                    >
                      <Select
                        placeholder={'service_placeholder'}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        optionFilterProp='children'
                        className='input'
                        showSearch
                      >
                        {
                          translationList?.translations?.map(({_id, key, translations}) => {
                            return <Select.Option key={key} value={key}>
                              {translations.en}
                            </Select.Option>
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12} xl={5}>
                    <Form.Item
                      {...restField}
                      name={[name, 'price']}
                      label={i18next.t('service_price')}
                      className='input-box'
                      rules={[
                        {
                          required: true,
                          type: 'number',
                          message: 'please_provide_price',
                        },
                      ]}
                    >
                      <InputNumber
                        className='input'
                        placeholder='25'
                        style={{width: '100%'}}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={11} xl={5}>
                    <Form.Item
                      {...restField}
                      name={[name, 'duration']}
                      label={i18next.t('service_minutes')}
                      className='input-box'
                      rules={[
                        {
                          required: true,
                          type: 'number',
                          message: 'please_provide_minutes',
                        },
                      ]}
                    >
                      <InputNumber
                        className='input'
                        placeholder='20'
                        style={{width: '100%'}}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={1}>
                    <Label htmlFor="validationCustom02" className="invisible">{name > 0 ? 'Remove' : 'Add'}</Label>
                    {name > 0 ? <button className="btn btn-danger d-flex justify-content-center"
                                        onClick={(e) => remove(name)}><i
                        className="bx bx-x font-size-16 font-weight-bolder"></i></button> :
                      <button className="btn btn-success d-flex justify-content-center"
                              onClick={(e) => add()}><i
                        className="bx bx-plus font-size-16 font-weight-bolder"></i></button>}
                  </Col>
                  {fields.length > 1 && fields.length - 1 !== index && (
                    <Divider className='fields-divider'/>
                  )}
                </Row>

              ))}

            </>
          }}
        </Form.List>
      </Form>
    }
  }
  return <Modal scrollable={true} style={{height: 700}} size="lg" isOpen={isServiceEditModalOpen}
                toggle={onCancel}>
    <div className="modal-header">
      <h5 className="modal-title mt-0">Update Services</h5>
      <button
        type="button"
        onClick={onCancel}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
      {renderServices()}
      {/*{haveWorkingSchedule && <WorkingDays user={user} setMetadata={setServiceList}/>}*/}
    </div>
    <div className="modal-footer d-flex align-items-center p-3">
      <div className="right">
        <button
          type="button"
          onClick={onCancel}
          className="btn btn-secondary waves-effect mr-1"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={onUpdateUser}
          className="btn btn-warning waves-effect waves-light"
        >
          Update
        </button>
      </div>
    </div>
  </Modal>
}

const mapStateToProps = ({Translations}) => {
  return Translations
}

export default connect(mapStateToProps)(ServiceList)
