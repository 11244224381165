import ApiService from "../../helpers/ApiHelper";

class ReportService {

    static getTransactions(payload) {

      const query = `?search=${JSON.stringify(payload.search)?JSON.stringify(payload.search):'1'}&page=${payload.page}`

      let url = `/api/v1/admins/invoices${query}`;
      return ApiService.request(url, "get", null);
    }


    static getFilteredTransactions(payload) {
      let url = `/api/v1/admins/invoices`;
      return ApiService.request(url, "post", payload);
    }

    static createInvoice(payload) {
      let url = `/api/v1/admins/createinvoice`;
      return ApiService.request(url, "post", payload);
    }

}

export default ReportService;
