import { takeEvery, fork,  all } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";



//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  // try {
  // if(process.env.REACT_APP_DEFAULTAUTH === "firebase")
  //         {
  //            const response = yield call(fireBaseBackend.forgetPassword, user.email);
  //            if (response) {
  //             yield put(
  //               userForgetPasswordSuccess(
  //                 "Reset link are sended to your mailbox, check there first"
  //               )
  //             );
  //           }
  //         }
  //         else
  //         {
  //            const response = yield call(postForgetPwd, '/forget-pwd', {email: user.email});
  //             if (response) {
  //             yield put(
  //               userForgetPasswordSuccess(
  //                 "Reset link are sended to your mailbox, check there first"
  //               )
  //             );
  //           }

  //         }
  // } catch (error) {
  //   yield put(userForgetPasswordError(error));
  // }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
