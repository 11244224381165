import {CouponsActionTypes} from "./actionTypes";
export const initialCoupon = {
    couponCode: '',
    description: '',
    type: 0,
    amount: 0,
    validDateFrom: '',
    validDateTo: '',
    serviceValidDateTo: '',
    services: [],
    excludeServices: [],
    professional: [],
    excludeProfessional: [],
    forProfessionals: [],
    forParents: [],
    couponLimit: 1,
    limitPerUser: 1,
    limitPerProfessional: 1,

}
const initialState = {
    usersListError: null, message: null, isLoadingAddUsers: false, isAddNewUsersOpen: false, couponsList: []}

const account = (state = initialState, action) => {
    switch (action.type) {
        case CouponsActionTypes.COUPONS_LIST_GET:
            state = {
                ...state,
                couponsListError: null
            }
            break;
        case CouponsActionTypes.COUPONS_LIST_GET_SUCCESSFUL:
            state = {
                ...state,
                couponsList: action.payload,
                couponsListError: null
            }
            break;
        case CouponsActionTypes.COUPONS_LIST_GET_ERROR:
            state = {
                ...state,
                couponsList: null,
                couponsListError: action.payload
            }
            break;
        case CouponsActionTypes.COUPON_GET:
            state = {
                ...state,
                couponsListError: null
            }
            break;
        case CouponsActionTypes.COUPON_GET_SUCCESSFUL:
            state = {
                ...state,
                couponsList: action.payload,
                couponsListError: null
            }
            break;
        case CouponsActionTypes.COUPON_GET_ERROR:
            state = {
                ...state,
                couponsList: null,
                couponsListError: action.payload
            }
            break;
        case CouponsActionTypes.COUPON_UPDATE:
            state = {
                ...state,
                couponsListError: null
            }
            break;
        case CouponsActionTypes.COUPON_UPDATE_SUCCESSFUL:
            state = {
                ...state,
                couponsListError: null
            }
            break;
        case CouponsActionTypes.COUPON_UPDATE_ERROR:
            state = {
                ...state,
                couponsListError: action.payload
            }
            break;
        case CouponsActionTypes.COUPON_DISABLE:
            state = {
                ...state,
                couponsListError: null
            }
            break;
        case CouponsActionTypes.COUPON_DISABLE_SUCCESSFUL:
            state = {
                ...state,
                couponsListError: null
            }
            break;
        case CouponsActionTypes.COUPON_DISABLE_ERROR:
            state = {
                ...state,
                couponsList: null,
                couponsListError: action.payload
            }
            break;
        case CouponsActionTypes.COUPON_CREATE:
            state = {
                ...state,
                couponsListError: null
            }
            break;
        case CouponsActionTypes.COUPON_CREATE_SUCCESSFUL:
            state = {
                ...state,
                couponsListError: null
            }
            break;
        case CouponsActionTypes.COUPON_CREATE_ERROR:
            state = {
                ...state,
                couponsList: null,
                couponsListError: action.payload
            }
            break;
    }
    return state;
}

export default account;
