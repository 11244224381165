import React from 'react'
import { Form, DatePicker } from 'antd'
import i18next from 'i18next'
import 'dayjs/locale/bg'
import dayjs from 'dayjs'

function IdCardValidTo() {


	dayjs.locale('bg')
	return <div className='textarea-holder'><Form.Item
		name={['metadata', 'idCard', 'idCardValidTo']}
		label={'ID card valid to'}
		className='input-box'
		rules={[
			{
				validator: async (_, idCardValidTo) => {
					if (!idCardValidTo || idCardValidTo.length < 1) {
						return Promise.reject(
							new Error(i18next.t('id_card_valid_to_required')),
						)
					}
				},
			}
		]}
	>
		<DatePicker
			className='input'
			format={'DD.MM.YYYY'}
		/>
	</Form.Item>
	</div>
}

export default IdCardValidTo
