import {takeEvery, put, call} from "redux-saga/effects";


// Login Redux States
import {AUTO_LOGIN, LOGIN_SUCCESS, LOGIN_USER, LOGOUT_USER} from "./actionTypes";
import {
    loginSuccess,
    apiError,
    logoutUserSuccess,
    autoLoginSuccess,
    logoutUserAuth
} from "./actions";

import {autoLoginMe, postLogin} from "helpers/backend_helper";

function* loginUser({payload: {user, history}}) {
    try {
        const response = yield call(postLogin, {
            email: user.email,
            password: user.password,
        });
        if (response) {
            localStorage.setItem("authUser", JSON.stringify(response.admin));
            localStorage.setItem("token", response.token);
            yield put(loginSuccess(response.user, response.token, response.role));
            history.history('/dashboard', {replace: true});
        }
    } catch (error) {
        yield put(apiError(error));
    }
}

function* autoLogin({payload: {history}}) {
    try {
        const response = yield call(autoLoginMe, {});

        yield put(autoLoginSuccess(response));
    } catch (error) {
        yield put(logoutUserAuth(history));
    }
}

function* logoutUser({payload: {history}}) {
    try {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        history("/login");
        yield put(logoutUserSuccess());
    } catch (error) {
        yield put(apiError(error));
    }
}

function* authSaga() {
    yield takeEvery(LOGIN_USER, loginUser);
    yield takeEvery(AUTO_LOGIN, autoLogin);
    yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
