import React from "react";
import {Modal} from "reactstrap";
import {Card, Col, Divider, Row, Statistic} from "antd";

const PetDetailedStatistics = ({isModalStatisticOpen, onCancel, petsArr, totalPetsDeleted}) => {
  const renderTypePets = () => {
    const types = [];
    if(petsArr) {
      petsArr.map((pet) => {
        if (!types.find(type => type.type === pet.type)) {
          types.push({type: pet.type, count: 1})
        } else {
          types.filter(type => type.type === pet.type)[0].count++
        }
      })

      return types.map((pet, i) => {
        return <Col span={12} key={i}>
          <Card className="statistic-card" bordered>
            <Statistic value={pet.count} title={pet.type}/>
            <div className="breeds"></div>
          </Card>
        </Col>
      })
    }
  }

  const breedTypes = (pet) => {
    const petBreeds = [{type: '', total: 0}]

  }

  return <Modal isOpen={isModalStatisticOpen} toggle={onCancel} >
    <Row justify="space-around" gutter={[16, 24]} className="statistic-row p-2" xs={24}>
      {renderTypePets()}
      <Divider orientation="center"></Divider>
      <Col className="col-12">
          <Card className="statistic-card" role="button" bordered>
            <Statistic value={totalPetsDeleted} title="Deleted Pets"/>
          </Card>
        </Col>
    </Row>
  </Modal>
}

export default PetDetailedStatistics
