export const ParentActionTypes = {
    PARENT_LIST_GET: 'PARENT_LIST_GET',
    PARENT_LIST_GET_SUCCESSFUL: 'PARENT_LIST_GET_SUCCESSFUL',
    PARENT_LIST_GET_ERROR: 'PARENT_LIST_GET_ERROR',


    PARENT_ADD: 'PARENT_ADD',
    PARENT_ADD_SUCCESSFUL: 'PARENT_ADD_SUCCESSFUL',
    PARENT_ADD_ERROR: 'PARENT_ADD_ERROR',

    PARENT_ADD_MODAL: 'PARENT_ADD_MODAL',
    PARENT_ADD_MODAL_SUCCESS: 'PARENT_ADD_MODAL_SUCCESS',

    PARENT_SHOW_REMOVE_MODAL: 'PARENT_SHOW_REMOVE_MODAL',
    PARENT_SHOW_REMOVE_MODAL_SUCCESS: 'PARENT_SHOW_REMOVE_MODAL_SUCCESS',

    PARENT_REMOVE: "PARENT_REMOVE",
    PARENT_REMOVE_SUCCESS: 'PARENT_REMOVE_SUCCESS',
    PARENT_REMOVE_ERROR: 'PARENT_REMOVE_ERROR',

    PARENT_EDIT: 'PARENT_EDIT',
    PARENT_EDIT_SUCCESS: 'PARENT_EDIT_SUCCESS',
    PARENT_EDIT_ERROR: 'PARENT_EDIT_ERROR'
}




