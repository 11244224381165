import { ProfessionalActionTypes } from "./actionTypes"


export const getProfessionals = (payload = {}) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_LIST_GET,
        payload: payload
    }
}

export const getProfessionalsSuccessful = (payload) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_LIST_GET_SUCCESSFUL,
        payload: payload
    }
}

export const getProfessionalsError = (payload) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_LIST_GET_ERROR,
        payload,
    }
}

export const addProfessional = (professional) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_ADD,
        payload: professional
    }
}

export const addProfessionalSuccessful = (payload) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_ADD_SUCCESSFUL,
        payload
    }
}

export const addProfessionalError = (payload) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_ADD_ERROR,
        payload
    }
}
export const showAddProfessionalModal = (showModal) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_ADD_MODAL,
        payload: showModal
    }
}
export const showAddProfessionalModalSuccess = (showModal) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_ADD_MODAL_SUCCESS,
        payload: showModal
    }
}

export const removeProfessional = (professional) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_REMOVE,
        payload: professional
    }

}
export const removeProfessionalSuccess = (professional) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_REMOVE_SUCCESS,
        payload: professional
    }
}
export const removeProfessionalError = (professional) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_REMOVE_ERROR,
        payload: professional
    }
}
export const editProfessional = (professional) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_EDIT,
        payload: professional
    }
}
export const editProfessionalSuccess = (professional) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_EDIT_SUCCESS,
        payload: professional
    }
}
export const editProfessionalError = (professional) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_EDIT_ERROR,
        payload: professional
    }
}
export const approveProfessional = (professional) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_APPROVE,
        payload: professional
    }
}
export const approveProfessionalSuccess = (professional) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_EDIT_SUCCESS,
        payload: professional
    }
}
export const approveProfessionalError = (professional) => {
    return {
        type: ProfessionalActionTypes.PROFESSIONAL_EDIT_ERROR,
        payload: professional
    }
}
