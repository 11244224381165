import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
    getBlogsBgError,
    getBlogsBgSuccessful,
    addBlogBgError,
    addBlogBgSuccessful,
    showAddBlogBgModalSuccess,
    removeBlogBgError,
    removeBlogBgSuccess,
    getBlogsBg,
    editBlogBgError,
    editBlogBgSuccess
} from "./actions";
import BlogService from "./service";
import { BlogBgActionTypes } from "./actionTypes";

function* get() {
    try {
        const response = yield call(BlogService.getBlogsBg, {});
        yield put(getBlogsBgSuccessful(response));
    } catch (error) {
        yield put(getBlogsBgError(error));
    }
}

function* addBlogBg({ payload: blogBg }) {
    try {
        const response = yield call(BlogService.addBlogBg, blogBg);
        yield put(addBlogBgSuccessful(response));
        yield put(getBlogsBg());
        
    } catch (error) {
        yield put(addBlogBgError(error));
    }
}

function* showAddBlogBg({ payload: showModal }) {
    yield put(showAddBlogBgModalSuccess(showModal));
}

function* removeBlogBg({ payload: blogBgId }) {
    try {
        const response = yield call(BlogService.removeBlogBg, blogBgId);
        yield put(removeBlogBgSuccess(response));
        yield put(getBlogsBg());
    } catch (error) {
        yield put(removeBlogBgError(error));
    }
}

function* edit({ payload: blogBg }) {
    try {
        const response = yield call(BlogService.editBlogBg, blogBg);
        yield put(editBlogBgSuccess(response));
        yield put(getBlogsBg());
    } catch (error) {
        yield put(editBlogBgError(error));
    }
}

export function* watchGetBlogBg() {
    yield takeEvery(BlogBgActionTypes.BLOG_BG_LIST_GET, get);
}

export function* watchAddBlogBg() {
    yield takeEvery(BlogBgActionTypes.BLOG_BG_ADD, addBlogBg);
}

export function* watchShowAddBlogBgModal() {
    yield takeEvery(BlogBgActionTypes.BLOG_BG_ADD_MODAL, showAddBlogBg);
}

export function* watchRemoveBlogBg() {
    yield takeEvery(BlogBgActionTypes.BLOG_BG_REMOVE, removeBlogBg);
}

export function* watchEditBlogBg() {
    yield takeEvery(BlogBgActionTypes.BLOG_BG_EDIT, edit);
}

function* blogsBgSaga() {
    yield all([
        fork(watchGetBlogBg),
        fork(watchAddBlogBg),
        fork(watchShowAddBlogBgModal),
        fork(watchRemoveBlogBg),
        fork(watchEditBlogBg)
    ]);
}

export default blogsBgSaga;
