import ApiService from "../../helpers/ApiHelper";

class InitiatedBookingService {
  static getInitiatedBookings(payload) {
    return ApiService.request(
      `/api/v1/booking-steps/${payload.currentPage}/${payload.currentDate}`,
      'get',
      {}
    )
  }

}
export default InitiatedBookingService
