import React, {Component} from "react";
import {Row, Col, Card, CardBody} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import {connect} from "react-redux";
import ClinicList from "../Clinics/List/ClinicList";
import {
  addProfessional, approveProfessional, editProfessional,
  getProfessionals,
  removeProfessional,
  showAddProfessionalModal
} from "../../store/professionals/actions";
import {userRols} from "../../helpers/userRols";

class Clinics extends Component {
  constructor(props) {
    super(props);

    this.onNewClinicModal = this.onNewClinicModal.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.props.getProfessionals({type: 'CLINIC'});
  }

  onNewClinicModal() {
    this.props.showAddClinicModal(!this.props.isAddNewClinicOpen);
  }

  onRemove(clinic) {
    this.props.removeProfessional(clinic, userRols.clinic);
  }

  onEdit(clinic) {
    this.props.editProfessional(clinic);
  }

  render() {
    const {professionalList, approveProfessional, history} = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Clinics" breadcrumbItem="Clinics"/>

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <ClinicList
                      list={professionalList}
                      onRemoveItem={this.onRemove}
                      onEditItem={this.onEdit}
                      onApproveItem={approveProfessional}
                      history={history}
                    />
                    {/*<ProfessionalList*/}
                    {/*  list={professionalList}*/}
                    {/*  onRemoveItem={this.onRemove}*/}
                    {/*  onEditItem={this.onEdit}*/}
                    {/*  onApproveItem={approveProfessional}*/}
                    {/*  history={history}*/}
                    {/*  serviceType={userRols.clinic}*/}
                    {/*/>*/}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = ({Professional}) => {
  return Professional;
};

export default connect(mapStatetoProps, {
  getProfessionals,
  addProfessional,
  approveProfessional,
  showAddProfessionalModal,
  removeProfessional,
  editProfessional
})(Clinics);
