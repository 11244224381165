export const PetActionTypes = {
    PET_LIST_GET: 'PET_LIST_GET',
    PET_LIST_GET_SUCCESSFUL: 'PET_LIST_GET_SUCCESSFUL',
    PET_LIST_GET_ERROR: 'PET_LIST_GET_ERROR',


    PET_ADD: 'PET_ADD',
    PET_ADD_SUCCESSFUL: 'PET_ADD_SUCCESSFUL',
    PET_ADD_ERROR: 'PET_ADD_ERROR',

    PET_ADD_MODAL: 'PET_ADD_MODAL',
    PET_ADD_MODAL_SUCCESS: 'PET_ADD_MODAL_SUCCESS',

    PET_SHOW_REMOVE_MODAL: 'PET_SHOW_REMOVE_MODAL',
    PET_SHOW_REMOVE_MODAL_SUCCESS: 'PET_SHOW_REMOVE_MODAL_SUCCESS',

    PET_REMOVE: "PET_REMOVE",
    PET_REMOVE_SUCCESS: 'PET_REMOVE_SUCCESS',
    PET_REMOVE_ERROR: 'PET_REMOVE_ERROR',

    PET_EDIT: 'PET_EDIT',
    PET_EDIT_SUCCESS: 'PET_EDIT_SUCCESS',
    PET_EDIT_ERROR: 'PET_EDIT_ERROR',

    PET_SHELTER_ADOPTED: 'PET_SHELTER_ADOPTED',
    PET_SHELTER_ADOPTED_SUCCESS: 'PET_SHELTER_ADOPTED_SUCCESS',
    PET_SHELTER_ADOPTED_ERROR: 'PET_SHELTER_ADOPTED_ERROR'
}




