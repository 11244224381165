import React, {useEffect, useState} from "react";
import {Label, FormGroup, Modal} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Input, Alert} from "antd";
import {connect} from "react-redux";
import {getTransaction, refundPayment} from "../../store/transactions/actions";

const Refund = ({data, isNewBookingModalOpen, isLoading, onCancel, refundPayment, getTransaction, transactionList}) => {
  const [refund, setRefund] = useState(data)
  const [error, setError] = useState('')

  useEffect(() => {
    getTransaction({bookingId: refund.id, stripePaymentId: refund.stripePaymentID})
    setTimeout(() => {
      setError('')
    }, 5000)
  }, [error])
  const handleChange = ({target}) => {
    const {name, value} = target;
    setRefund({...refund, [name]: value});
  }
  const refundTransaction = (e) => {
    e.preventDefault()
    let errorMessage = ''
    if(isNaN(Number(refund.price)) || Number(refund.price) < 0 || Number(refund.price) > Number(refund.currentPrice - transactionList?.refundPrice)) {
      errorMessage = 'Refund must be a positive number and must not exceed the price!'
    } else if(!refund.stripePaymentID) {
      errorMessage = 'Something went wrong missing stripe id!'
    } else if(refund.description.length < 3) {
      errorMessage = 'Description must be 3 or more characters!'
    }
    if(!errorMessage) {
      refundPayment({amount: refund.price, transactionId: transactionList.transactionId})
      onCancel()
      return
    }
    setError(errorMessage)
  }
  return (
    <Modal isOpen={isNewBookingModalOpen} toggle={onCancel}>
      <div className="modal-header">
        {error && <Alert
          className="position-fixed t-1 l-1 z-index-1"
          message="Error"
          description={error}
          type="error"
          showIcon
        />}
        <h5 className="modal-title mt-0">Refund</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <AvForm className="needs-validation">
          <FormGroup>
            <Label htmlFor="validationCustom01">Refund / max refund price {transactionList?.refundPrice ? refund.currentPrice - transactionList?.refundPrice : refund.currentPrice} bgn.</Label>
            <Input
              name="price"
              placeholder="Refund"
              type="text"
              suffix="BGN"
              onChange={handleChange}
              value={refund.price}
              validate={{required: {value: true}}}
              id={refund.id}
            />
          </FormGroup>
          {/*<FormGroup>*/}
          {/*  <Label htmlFor="validationCustom02">Reason</Label>*/}
          {/*  <Select*/}
          {/*    className="w-100"*/}
          {/*    value={refund.reason}*/}
          {/*    placeholder="Select Reason"*/}
          {/*    onChange={(e) => handleChange({target: {value: e, name: 'reason', id: refund.id}})}*/}
          {/*  >*/}
          {/*    <Option value="" disabled>Select Reason</Option>*/}
          {/*    <Option value="duplicate">Duplicate</Option>*/}
          {/*    <Option value="fraudulent">Fraudulent</Option>*/}
          {/*    <Option value="requested_by_customer">Requested by customer</Option>*/}
          {/*    <Option value="other">Other</Option>*/}
          {/*  </Select>*/}
          {/*</FormGroup>*/}
          {<FormGroup>
            <AvField
              name="description"
              placeholder={"Why is this payment is reason?"}
              type="textarea"
              rows={3}
              minLength="3"
              className="form-control"
              onChange={handleChange}
              value={refund.description}
            />
          </FormGroup>}
        </AvForm>
      </div>

      {isLoading && (
        <div className="modal-footer">
          <div className="spinner-border text-success m-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      {!isLoading && (
        <div className="modal-footer d-flex align-content-end">
          <button
            type="button"
            onClick={onCancel}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={refundTransaction}
            className="btn btn-primary waves-effect waves-light justify-content-center d-flex"
          >
            <i className='bx bx-undo font-size-18'></i> Refund
          </button>
        </div>
      )}
    </Modal>
  );
}
const mapStateToProps = (state) => {
  const {Transactions} = state;
  return Transactions;
};

export default connect(mapStateToProps, {
  refundPayment,
  getTransaction
})(Refund);
