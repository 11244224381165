import React from 'react';
import {Col, Form, Input, Row, Select} from "antd";
import {userRols} from "../../../helpers/userRols";

const ProfessionalPersonalData = ({languages, professionalRole}) => {
  const OPTIONS = ['english',
    'bulgarian',
    'german',
    'greek',
    'russian',
    'spanish',
    'italian',
    'french']

  return (
    <Row gutter={24}>
        {professionalRole !== userRols.clinic && <Col xs={24} md={12}>
            <Form.Item
                name="name"
                label="Name"
                rules={[{required: true, message: 'Please input your name!'}]}
            >
                <Input placeholder="Name"/>
            </Form.Item>
        </Col>}
        {professionalRole !== userRols.clinic && <Col xs={24} md={12}>
            <Form.Item
                name="surname"
                label="Surname"
                rules={[{required: true, message: 'Please input your surname!'}]}
            >
                <Input placeholder="Surname"/>
            </Form.Item>
        </Col>}
      {professionalRole !== userRols.clinic && <Col xs={24} md={12}>
        <Form.Item
          name="telephone"
          label="Phone"
          rules={[{required: true, message: 'Please input your telephone number!'}]}
        >
          <Input placeholder="Telephone"/>
        </Form.Item>
      </Col>}
      {professionalRole !== userRols.clinic && <Col xs={24} md={12}>
        <Form.Item
          name="email"
          label="Email"
          rules={[{required: true, message: 'Please input your email!', type: 'email'}]}
        >
          <Input placeholder="E-mail"/>
        </Form.Item>
      </Col>}
        {![userRols.shelter].includes(professionalRole) && <Col xs={24} md={24}>
            <Form.Item
                name={professionalRole === userRols.clinic ? ["metadata", "companyDescription"] : ["metadata", "bio"]}
                label={[userRols.hotel, userRols.clinic].includes(professionalRole) ? 'Company Description' : "Biography"}
            >
                <Input.TextArea
                    placeholder={[userRols.hotel, userRols.clinic].includes(professionalRole) ? 'Company Description' : "Biography"}
                    rows={8}/>
            </Form.Item>
        </Col>}
      <Col xs={24} md={12}>
        <Form.Item
          name="languages"
          label="Languages"
          initialValue={languages}
        >
          <Select
            mode="multiple"
            placeholder="Languages"
            options={OPTIONS.map(option => ({
              value: option,
              label: option
            }))}
          ></Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ProfessionalPersonalData;
