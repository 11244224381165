export const BookingActionTypes = {
    BOOKING_LIST_GET: 'BOOKING_LIST_GET',
    BOOKING_LIST_GET_SUCCESSFUL: 'BOOKING_LIST_GET_SUCCESSFUL',
    BOOKING_LIST_GET_ERROR: 'BOOKING_LIST_GET_ERROR',


    BOOKING_ADD: 'BOOKING_ADD',
    BOOKING_ADD_SUCCESSFUL: 'BOOKING_ADD_SUCCESSFUL',
    BOOKING_ADD_ERROR: 'BOOKING_ADD_ERROR',

    BOOKING_ADD_MODAL: 'BOOKING_ADD_MODAL',
    BOOKING_ADD_MODAL_SUCCESS: 'BOOKING_ADD_MODAL_SUCCESS',

    BOOKING_SHOW_REMOVE_MODAL: 'BOOKING_SHOW_REMOVE_MODAL',
    BOOKING_SHOW_REMOVE_MODAL_SUCCESS: 'BOOKING_SHOW_REMOVE_MODAL_SUCCESS',

    BOOKING_REMOVE: "BOOKING_REMOVE",
    BOOKING_REMOVE_SUCCESS: 'BOOKING_REMOVE_SUCCESS',
    BOOKING_REMOVE_ERROR: 'BOOKING_REMOVE_ERROR',

    BOOKING_EDIT: 'BOOKING_EDIT',
    BOOKING_EDIT_SUCCESS: 'BOOKING_EDIT_SUCCESS',
    BOOKING_EDIT_ERROR: 'BOOKING_EDIT_ERROR'
}




