import {takeEvery, fork, put, all, call} from "redux-saga/effects";

import {UserMetaHeadActionTypes} from "./actionTypes";
import {
  createProfessionalMetaHeadDataSuccessful,
  createProfessionalMetaHeadDataError,
  getProfessionalMetaHeadDataSuccessful,
  getProfessionalMetaHeadDataError,
} from "./actions";
import ProfessionalMetaHeadService from "./service";

function* getProfessionalMetaHead(payload) {
  try {
    const response = yield call(ProfessionalMetaHeadService.getProfessionalMetaHeadByParams, {...payload.payload});
    yield put(getProfessionalMetaHeadDataSuccessful(response));
  } catch (error) {
    yield put(getProfessionalMetaHeadDataError(error));
  }
}

function* createProfessionalMetaHead(payload) {
  try {
    const response = yield call(ProfessionalMetaHeadService.creteProfessionalMetaHead, {...payload.payload});
    yield put(createProfessionalMetaHeadDataSuccessful(response));
  } catch (error) {
    yield put(createProfessionalMetaHeadDataError(error));
  }
}


export function* watchGetProfessionalMetaHead() {
  yield takeEvery(UserMetaHeadActionTypes.USERS_PROFESSIONAL_META_HEAD_GET, getProfessionalMetaHead);
}

export function* watchCreateProfessionalMetaHead() {
  yield takeEvery(UserMetaHeadActionTypes.USERS_PROFESSIONAL_META_HEAD_CREATE, createProfessionalMetaHead);
}

function* metaHeadSaga() {
  yield all([
    fork(watchGetProfessionalMetaHead),
    fork(watchCreateProfessionalMetaHead),
  ]);
}

export default metaHeadSaga;
