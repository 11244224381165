import {Modal} from "reactstrap";
import React from "react";

const ShelterStatistic = ({data, isStatisticModalOpen, onCancel}) => {

  const renderWithParam = () => {
    if(data?.userRequestId) {
     return data.userRequestId.map((ur, i) => {
       return <div className="col-12" key={i}>
         <div className="row">
           <div className="col-4">{ur?.name} {ur?.surname}</div>
           <div className="col-4">{data?.ipAddress[i]}</div>
           <div className="col-4">{ur?.role}</div>
         </div>
       </div>
     })
    }
  }
  return <Modal scrollable={true} style={{height: 700}} size="lg" isOpen={isStatisticModalOpen}
         toggle={onCancel}>
    <div className="modal-header">
      <h5 className="modal-title mt-0">Statistic {data?.role}</h5>
      <button
        type="button"
        onClick={onCancel}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
      <div className="row">
        <div className="col-4">Users</div>
        <div className="col-4">Ip Address</div>
        <div className="col-4">Role</div>
        {renderWithParam()}
        <br/>
        <br/>
        <div className="offset-4 col-4">Total Visits</div>
        <div className="col-4">Visits Website: {data?.counterTypes[0]?.counter}</div>
        <div className="offset-4 col-4">{data?.counterTypes?.reduce((n, {counter}) => n + counter, 0)}</div>
        <div className="col-4">Make a Calls: {data?.counterTypes[1]?.counter} </div>
      </div>
    </div>
  </Modal>


}
export default ShelterStatistic
