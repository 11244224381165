import ApiService from "../../helpers/ApiHelper";

class BlogBgService {

    static addBlogBg(e, payload) {
        e.preventDefault()
        return ApiService.request(
            "/blogs/bg/",
            "post",
            payload
            
        )
    }

    static removeBlogBg(id) {
        return ApiService.request(
            `/blogs/bg/${id}`,
            "delete",
            null
        )
    }

    static editBlogBg(blogBg){
        return ApiService.request(
            `/blogs/bg/${blogBg.id}`,
            "patch",
            blogBg,
        )
    }
}

export default BlogBgService;