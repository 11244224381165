import React, { Component } from "react";
import { Label, FormGroup, Modal } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import FileUpload from "../../components/FileUpload/FileUpload";
import baseURL from "../../config";
import { Select, Radio } from "antd";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

const { Option } = Select;

class AddBlog extends Component {

  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onFileUploaded = this.onFileUploaded.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.rteChange = this.rteChange.bind(this);
    this.languageChange = this.languageChange.bind(this);
    this.onCategorySelect = this.onCategorySelect.bind(this);

    const newBlog = {
      title: "",
      authorName: "",
      description: "",
      category: "",
      media: "",
      language: "en",
      name: "",
      surname: "",
    };
    this.state = { newBlog };
  }

  onCancel() {
    this.setState({
      newBlog: {
        title: "",
        description: "",
        authorName: "",
        category: "",
        media: "null",
        name: "",
        surname: "",
      },
    });
    this.props.onCancel();
  }

  onSubmit(e) {
    const { newBlog } = this.state;
    e.preventDefault();
    this.props.onSubmit(newBlog)
  }

  onCategorySelect = (category) => {
    this.setState({
      newBlog: { ...this.state.newBlog, category: category },
    });
  };

  languageChange(e) {
    console.log("radio checked", e.target.value);
    this.setState({
      newBlog: {
        ...this.state.newBlog,
        language: e.target.value,
      },
    });
  }

  handleChange(e) {
    let { name, value } = e.target;
    this.setState({
      newBlog: {
        ...this.state.newBlog,
        [name]: value,
      },
    });
  }

  onFileUploaded(file) {
    if (file) {
      const { newBlog } = this.state;
      newBlog["media"] = file.url;
      this.setState({ newBlog });
    }
  }

  rteChange(value) {
    this.setState({ newBlog: { ...this.state.newBlog, description: value } });
  }

  render() {
    const { newBlog } = this.state;
    return (
      <Modal isOpen={this.props.isNewBlogModalOpen} toggle={this.onCancel}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add blog</h5>
          <button
            type="button"
            onClick={this.onCancel}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <AvForm className="needs-validation">
            <FormGroup>
              <Label htmlFor="validationCustom01">Language: </Label>
              <div>
                {" "}
                <Radio.Group onChange={this.languageChange} defaultValue={"en"}>
                  <Radio defaultChecked={newBlog.language} value={"en"}>
                    English
                  </Radio>
                  <Radio value={"bg"}>Bulgarian</Radio>
                </Radio.Group>
              </div>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="validationCustom01">Author name</Label>
              <AvField
                name="authorName"
                placeholder="Author name"
                type="text"
                errorMessage="Please write Author name"
                className="form-control"
                onChange={this.handleChange}
                value={newBlog.authorName}
                validate={{
                  required: { value: true },
                }}
                id="validationCustom01"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="validationCustom01">Title </Label>
              <AvField
                name="title"
                placeholder="Title"
                type="text"
                errorMessage="Please write title"
                className="form-control"
                onChange={this.handleChange}
                value={newBlog.title}
                validate={{
                  required: { value: true },
                }}
                id="validationCustom01"
              />
            </FormGroup>

            <SunEditor height={350} onChange={this.rteChange} setOptions={{
              buttonList: [
                [
                  'undo', 'redo',
                  'font', 'fontSize', 'formatBlock',
                  'paragraphStyle', 'blockquote',
                  'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
                  'fontColor', 'hiliteColor', 'textStyle',
                  'removeFormat',
                  'outdent', 'indent',
                  'align', 'horizontalRule', 'list', 'lineHeight',
                  'table', 'link', 'image', 'video', 'audio'
                  ]
              ]
            }}/>
            <FormGroup>
              <Select
                placeholder="Select Category"
                style={{ width: 160 }}
                onChange={this.onCategorySelect}
              >
                {/* <Option value="dog_cat">Dog & Cat</Option> */}
                <Option value="dog">Dog</Option>
                <Option value="cat">Cat</Option>
                <Option value="pet_care">Pet Care</Option>
                <Option value="pet_training">Pet Training</Option>
                <Option value="veterinary">Veterinary</Option>
              </Select>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="validationCustom02">
                Media "(Only jpg,png)"{" "}
              </Label>
              <FileUpload onFileUploaded={this.onFileUploaded}></FileUpload>
            </FormGroup>
          </AvForm>
        </div>

        {this.props.isLoading && (
          <div className="modal-footer">
            <div className="spinner-border text-success m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        {!this.props.isLoading && (
          <div className="modal-footer">
            <button
              type="button"
              onClick={this.onCancel}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.onSubmit}
              className="btn btn-success waves-effect waves-light"
            >
              Add
            </button>
          </div>
        )}
      </Modal>
    );
  }
}

export default AddBlog;
