import React, {useEffect, useState} from 'react';
import {Checkbox, Col, DatePicker, Form, InputNumber, Row, Select, Space, TimePicker, Typography} from "antd";
import dayjs from "dayjs";
import {Button} from "reactstrap";
const { Text } = Typography
const { Option } = Select
const FormWorkSchedule = ({professionalWorkSchedule, onSubmit, submitButtonRef}) => {
  const [form] = Form.useForm()

  const weeksOptions = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  const [duration, setDuration] = useState('10')
  const [addresses, setAddresses] = useState([])

  useEffect(() => {
    setAddresses([{address: professionalWorkSchedule.address}])
    // getLocations().then((res) => {
    //   if (res.status === 'success') {
    //     setAddresses((Array.isArray(res.addresses) ? res.addresses : [res.addresses]))
    //   }
    // })
  }, [])

  const updateDuration = (value) => {
    if (Number(value) >= 10) {
      form.setFieldsValue({ slotDurationMinutes: Math.ceil(Number(value) / 5) * 5 })
      setDuration((Math.ceil(Number(value) / 5) * 5).toString())
    }
  }
  const onChange = (weeks) => {
    const weekDays = []
    weeks.map(week => {
      let index = weeksOptions.indexOf(week)
      index < 6 ? index++ : index = 0
      weekDays.push(index)
    })
    form.setFieldsValue({ weekDays: weekDays })
  }
  const onFinishFailed = async (formData) => {
    console.log(formData)
  }
  const renderAddresses = () => {
    if (addresses.length) {

      return addresses.map((address, i) => {
        if (addresses.length === 1) {
          form.setFieldsValue({ address: JSON.stringify(address) })
        }
        if (address) {
          return <Option value={JSON.stringify(address)}
                         kay={i}>{address?.address?.country}, {address?.address?.city}, {address?.address?.address ? address?.address?.address : address?.address?.street}, {'Post code'}: {address?.address?.postalCode}</Option>
        }
      })
    }
  }
  return (
    <Form
      form={form}
      className='editing-form editing-form-steps'
      layout='vertical'
      name='editing'
      autoComplete='off'
      initialValues={
        {
          duration: duration,
          startTime: dayjs('07:00:00', 'HH:mm:ss'),
          endTime: dayjs('19:00:00', 'HH:mm:ss')
        }
      }
      onFinish={onSubmit}
      onFinishFailed={onFinishFailed}>
      <div className='input-box-container'>
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item
              name='address'
              className='inpu-box'
              label={'Address Clinic'}
              rules={[
                {
                  required: true,
                  message: 'Please Provide Address',
                },
              ]}
            >
              {addresses.length && addresses[0].address ? <Select
                className='input'
                defaultValue={'Select Address'}
              >
                {renderAddresses()}
              </Select> : <div>Not have address</div>}
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div>
        <Text className='input-box-title'>
          <span className='required'></span>{'Dashboard Calendar Period'}
        </Text>
      </div>
      <Row className={'text-center'} gutter={24}>
        <Col xs={12}>
          <Form.Item
            name='dateFrom'
            className='inpu-box'
            rules={[
              {
                required: true,
                message: 'Please Provide Starting Date',
              },
            ]}
          >
            <DatePicker format={'MMMM DD'} style={{ width: '100%' }}
                        className='input'
                        placeholder={'Dashboard Calendar Start'}
                        disabledDate={(current) => current.isBefore(dayjs().subtract(1, 'day'))}
                        onSelect={(value) => {
                          if (form.getFieldValue('dateTo')) {
                            form.setFieldsValue({ dateTo: dayjs(value).add(1, 'day') })
                          }
                        }}
            />
          </Form.Item>
        </Col>
        <Col xs={11} offset={1}>
          <Form.Item
            name='dateTo'
            className='inpu-box'
            rules={[
              {
                required: true,
                message: 'Please Provide Ending Date',
              },
            ]}
          >
            <DatePicker
              className='input'
              format={'MMMM DD'}
              style={{ width: '100%' }}
              placeholder={'dashboard-calendar:end'}
              disabledDate={(current) => current.isBefore(dayjs(form.getFieldValue('dateFrom')).add(1, 'day'))} />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='startTime'
            className='inpu-box'
            rules={[
              {
                required: true,
                message: 'Please Provide Starting Hours',
              },
            ]}
          >
            <TimePicker format={'HH:mm'} showNow={false} placeholder={'Dashboard Calendar Start'}
                        style={{ width: '100%' }} minuteStep={15}
                        popupClassName={'Work Schedule Timepicker'}
                        className='input'
                        onSelect={(value) => {
                          form.setFieldsValue({ startTime: value })
                        }}
            />
          </Form.Item>
        </Col>
        <Col xs={11} offset={1}>
          <Form.Item
            name='endTime'
            className='inpu-box'
            rules={[
              {
                required: true,
                message: 'Please Provide Ending Hours',
              },
            ]}
          >
            <TimePicker format={'HH:mm'} showNow={false} placeholder={'Dashboard Calendar End'}
                        style={{ width: '100%' }} minuteStep={15}
                        popupClassName={'Work Schedule Timepicker'}
                        className='input'
                        onSelect={(value) => {

                          if (Number(dayjs(form.getFieldValue('startTime')).format('HHmm')) >= Number(dayjs(value).format('HHmm'))) {
                            if (Number(dayjs(form.getFieldValue('startTime')).format('HHmm')) !== Number(dayjs(value).format('HHmm'))) {
                              form.setFieldsValue({ endTime: form.getFieldValue('startTime'), startTime: value })
                            }
                          } else {
                            form.setFieldsValue({ endTime: value })
                          }
                        }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>

        <Col xs={12}>
          <Form.Item
            name='weekDays'
            className='input-box'
            rules={[
              {
                required: true,
                message: 'Please Provide Working Days',
              },
            ]}
          >
            <Col xs={18}>
              <Text className='input-box-title'>
                <span className='required'></span>{'Dashboard Calendar'}
              </Text>
            </Col>
            <Checkbox.Group onChange={onChange}>
              <Row>
                {
                  weeksOptions.map((weeksOption, i) => {
                    return <Col xs={24} md={8} key={i+22}>
                      <Checkbox value={weeksOption}>{`${weeksOption}`}</Checkbox>
                    </Col>
                  })
                }
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            name='slotDurationMinutes'
            className='inpu-box'
            label={'Duration'}
            rules={[
              {
                required: true,
                message: 'Please Provide Duration',
              },
            ]}
          >
            <Space direction='vertical' size='middle' style={{
              width: '100%',
            }}>
              <Space.Compact
                style={{
                  width: '100%',
                }}
              >
                <InputNumber
                  min='10'
                  max='120'
                  step='5'
                  className='input'
                  stringMode
                  defaultValue='10'
                  value={duration}
                  pattern='\d*'
                  onChange={updateDuration}
                  style={{
                    width: '100%',
                  }}
                />

              </Space.Compact>
            </Space>
          </Form.Item>
        </Col>
      </Row>
        <input
          style={{display: 'none'}}
          type='submit'
          ref={submitButtonRef}

        />
    </Form>
  );
};

export default FormWorkSchedule;
