import React, {useEffect, useLayoutEffect} from 'react'
import {Outlet, useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {autoLogin} from "./store/auth/login/actions";

const Root = ({autoLogin}) => {
  const history = useNavigate()
  useLayoutEffect(() => {
    autoLogin(history)
  }, [])
    return (
        <>
            <Outlet/>
        </>
    )
}
const mapStateToProps = (state) => {
  return {}
};

export default connect(mapStateToProps, {autoLogin})(Root)
