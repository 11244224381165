import { BlogActionTypes } from "./actionTypes"


export const getBlogs = () => {
    return {
        type: BlogActionTypes.BLOG_LIST_GET,
        payload: null
    }
}

export const getBlogsSuccessful = (payload) => {
    return {
        type: BlogActionTypes.BLOG_LIST_GET_SUCCESSFUL,
        payload: payload
    }
}

export const getBlogsError = (payload) => {
    return {
        type: BlogActionTypes.BLOG_LIST_GET_ERROR,
        payload,
    }
}

export const addBlog = (blog) => {
    return {
        type: BlogActionTypes.BLOG_ADD,
        payload: blog
    }
}

export const addBlogSuccessful = (payload) => {
    return {
        type: BlogActionTypes.BLOG_ADD_SUCCESSFUL,
        payload
    }
}

export const addBlogError = (payload) => {
    return {
        type: BlogActionTypes.BLOG_ADD_ERROR,
        payload
    }
}
export const showAddBlogModal = (showModal) => {
    return {
        type: BlogActionTypes.BLOG_ADD_MODAL,
        payload: showModal
    }
}
export const showAddBlogModalSuccess = (showModal) => {
    return {
        type: BlogActionTypes.BLOG_ADD_MODAL_SUCCESS,
        payload: showModal
    }
}

export const removeBlog = (blog) => {
    return {
        type: BlogActionTypes.BLOG_REMOVE,
        payload: blog
    }
    
}
export const removeBlogSuccess = (blog) => {
    return {
        type: BlogActionTypes.BLOG_REMOVE_SUCCESS,
        payload: blog
    }
}
export const removeBlogError = (blog) => {
    return {
        type: BlogActionTypes.BLOG_REMOVE_ERROR,
        payload: blog
    }
}
export const editBlog = (blog) => {
    return {
        type: BlogActionTypes.BLOG_EDIT,
        payload: blog
    }
}
export const editBlogSuccess = (blog) => {
    return {
        type: BlogActionTypes.BLOG_EDIT_SUCCESS,
        payload: blog
    }
}
export const editBlogError = (blog) => {
    return {
        type: BlogActionTypes.BLOG_EDIT_ERROR,
        payload: blog
    }
}
