import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
    getAdminsError,
    getAdminsSuccessful,
    addAdminError,
    addAdminSuccessful,
    showAddAdminModalSuccess,
    removeAdminError,
    removeAdminSuccess,
    getAdmins,
    editAdminError,
    editAdminSuccess, getStatisticSuccessful, getStatisticError
} from "./actions";
import AdminService from "./service";
import { AdminActionTypes } from "./actionTypes";

function* get() {
    try {
        const response = yield call(AdminService.getAdmins, {});
        yield put(getAdminsSuccessful(response));
    } catch (error) {
        yield put(getAdminsError(error));
    }
}
function* getStatisticData({payload}) {
    try {
        const response = yield call(AdminService.statisticData, payload);
        yield put(getStatisticSuccessful(response));
    } catch (error) {
        yield put(getStatisticError(error));
    }
}
function* addAdmin({ payload: admin }) {
    try {
        const response = yield call(AdminService.addAdmin, admin);
        yield put(addAdminSuccessful(response));
        yield put(getAdmins());

    } catch (error) {
        yield put(addAdminError(error));
    }
}

function* showAddAdmin({ payload: showModal }) {
    yield put(showAddAdminModalSuccess(showModal));
}

function* removeAdmin({ payload: adminId }) {
    try {
        const response = yield call(AdminService.removeAdmin, adminId);
        yield put(removeAdminSuccess(response));
        yield put(getAdmins());
    } catch (error) {
        yield put(removeAdminError(error));
    }
}

function* edit({ payload: admin }) {
    try {
        const response = yield call(AdminService.editAdmin, admin);
        yield put(editAdminSuccess(response));
        yield put(getAdmins());
    } catch (error) {
        yield put(editAdminError(error));
    }
}

export function* watchGetAdmin() {
    yield takeEvery(AdminActionTypes.ADMIN_LIST_GET, get);
}
export function* watchGetAdminStatistic() {
    yield takeEvery(AdminActionTypes.ADMIN_STATISTIC_DATA, getStatisticData);
}
export function* watchAddAdmin() {
    yield takeEvery(AdminActionTypes.ADMIN_ADD, addAdmin);
}

export function* watchShowAddAdminModal() {
    yield takeEvery(AdminActionTypes.ADMIN_ADD_MODAL, showAddAdmin);
}

export function* watchRemoveAdmin() {
    yield takeEvery(AdminActionTypes.ADMIN_REMOVE, removeAdmin);
}

export function* watchEditAdmin() {
    yield takeEvery(AdminActionTypes.ADMIN_EDIT, edit);
}

function* adminsSaga() {
    yield all([
        fork(watchGetAdmin),
        fork(watchAddAdmin),
        fork(watchShowAddAdminModal),
        fork(watchRemoveAdmin),
        fork(watchEditAdmin),
        fork(watchGetAdminStatistic)
    ]);
}

export default adminsSaga;
