import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {RouterProvider} from "react-router-dom";
import './i18n';
import {Provider} from 'react-redux';
// Import Routes
import routerType from "./routes/";
import store from './store';
import InitApp from "./InitApp";

String.prototype.toBoolean = function () {
  const value = this.valueOf();
  return value === 'true' ? true : value === 'false' ? false : value;
};

const app = (

    <Provider store={store}>
        <InitApp />
    </Provider>

);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
