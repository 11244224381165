import {QRCode} from "antd";
import React, {useState} from "react";

const QRCodeView = ({qrcode, i}) => {
  const [qrCodeDownload, setQrCodeDownload] = useState(false)
  const loadCanvasToPng = (ev) => {
    setQrCodeDownload(true)
    ev.preventDefault()
    setTimeout(() => {
      const canvas = document.getElementsByClassName(`qr-${i}`)[0].children
      const ctx = canvas[0].getContext('2d')
      // ctx.drawImage(canvas[1].src, 30, 30, 10, 10)
      // Create a new Image
      let img = new Image();
      img.width = 75;
      img.height = 75;
      // Setting up a function with the code to run after the image is loaded
      img.onload = (e) => {
        const pom = document.createElement('a');
        pom.setAttribute('href', `data:application/octet-stream;base64,${encodeURIComponent(e.target.src.replace(/^data:image\/\w+;base64,/, ''))}`);
        pom.setAttribute('download', 'qr-code.png');
        pom.style.display = 'none';
        document.body.appendChild(pom);
        pom.click();
        pom.click();
        document.body.removeChild(pom);
        // document.getElementById(`generate${i}`).appendChild(e.target)
      }
      // Now that we have set up the image "onload" handeler, we can assign
      // an image URL to the image.

      img.src = ctx.canvas.toDataURL('img/png');
      img.loading = 'lazy'


    }, 1000)

  }


  return (
    <a onClick={loadCanvasToPng} id={'generate' + i} className="btn btn-primary">
      {qrCodeDownload && <QRCode {...qrcode} size={5000} iconSize={1500} bordered={false} status={"active"}
               className={`d-none qr-${i}`}/>}
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor"
              d="M3 11h8V3H3zm2-6h4v4H5zM3 21h8v-8H3zm2-6h4v4H5zm8-12v8h8V3zm6 6h-4V5h4zm-5.99 4h2v2h-2zm2 2h2v2h-2zm-2 2h2v2h-2zm4 0h2v2h-2zm2 2h2v2h-2zm-4 0h2v2h-2zm2-6h2v2h-2zm2 2h2v2h-2z"/>
      </svg>
    </a>
  )

}

export default QRCodeView
