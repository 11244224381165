export const PaymentActionTypes = {
    PAYMENT_LIST_GET: 'PAYMENT_LIST_GET',
    PAYMENT_LIST_GET_SUCCESSFUL: 'PAYMENT_LIST_GET_SUCCESSFUL',
    PAYMENT_LIST_GET_ERROR: 'PAYMENT_LIST_GET_ERROR',


    PAYMENT_ADD: 'PAYMENT_ADD',
    PAYMENT_ADD_SUCCESSFUL: 'PAYMENT_ADD_SUCCESSFUL',
    PAYMENT_ADD_ERROR: 'PAYMENT_ADD_ERROR',

    PAYMENT_ADD_MODAL: 'PAYMENT_ADD_MODAL',
    PAYMENT_ADD_MODAL_SUCCESS: 'PAYMENT_ADD_MODAL_SUCCESS',

    PAYMENT_SHOW_REMOVE_MODAL: 'PAYMENT_SHOW_REMOVE_MODAL',
    PAYMENT_SHOW_REMOVE_MODAL_SUCCESS: 'PAYMENT_SHOW_REMOVE_MODAL_SUCCESS',

    PAYMENT_REMOVE: "PAYMENT_REMOVE",
    PAYMENT_REMOVE_SUCCESS: 'PAYMENT_REMOVE_SUCCESS',
    PAYMENT_REMOVE_ERROR: 'PAYMENT_REMOVE_ERROR',

    PAYMENT_EDIT: 'PAYMENT_EDIT',
    PAYMENT_EDIT_SUCCESS: 'PAYMENT_EDIT_SUCCESS',
    PAYMENT_EDIT_ERROR: 'PAYMENT_EDIT_ERROR'
}




