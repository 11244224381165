import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  AUTO_LOGIN,
  AUTO_LOGIN_SUCCESS,
} from "./actionTypes";

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = (user, token, role) => {
  return {
    type: LOGIN_SUCCESS,
    payload: { user, token, role },
  };
};

export const autoLogin = (history) => {
  return {
    type: AUTO_LOGIN,
    payload: { history },
  };
};

export const autoLoginSuccess = ({user}) => {
  return {
    type: AUTO_LOGIN_SUCCESS,
    payload: { user: user },
  };
};

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserAuth = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
  };
};

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};
