import React, {Component} from "react";
import {Table, Thead, Tbody, Tr, Th} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import axios from "axios";
import baseURL from "../../../config";
import moment from "moment";
import {DatePicker, Space, Form, Input} from "antd";
import exportFromJSON from "export-from-json";
import fileDownload from "js-file-download";

// const
function disabledDate(future) {
  let customDate = moment().subtract(1, "months");
  return future && future > moment(customDate, "MMM Do YYYY");
}

class AccountantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountantListData: [],
      datePick: "",
      email: "",
      date: "",
      reportType: "",
    };

  }

  onDatepicker = (date, dateString) => {
    console.log(date, dateString);
    this.setState({datePick: dateString});
    // if (dateString) {
    //   this.state.filterListData = this.state.accountantListData.filter(accountant=>{
    //     console.log(moment(dateString).isSame(moment(accountant.createdAt)), moment(dateString), moment(accountant.createdAt).format("YYYY-MM"))
    //   return  moment(dateString).isSame(moment(accountant.createdAt).format("YYYY-MM"))
    //   })
    // }
  };

  onSubmit = (e, newAccountant) => {
    e.preventDefault();
    const payload = {
      email: this.state.email,
      date: this.state.datePick,
      reportType: "EMAIL",
    };
    console.log(payload);
    axios
      .post(`${baseURL}/api/v1/paymentReports/`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onDownload = (e, newAccountant) => {
    e.preventDefault();
    const filename = this.state.datePick + ".xml";
    const payload = {
      date: this.state.datePick,
      reportType: "DOWNLOAD",
    };
    axios
      .post(`${baseURL}/api/v1/paymentReports/`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        fileDownload(res.data, filename);
        console.log(res, filename, res);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleChange = (e) => {
    const payload = [this.state.email, this.state.date, this.state.reportType];
    console.log(payload);
    const {value} = e.target;
    this.setState({
      email: value,
    });
    this.setState({
      date: this.state.datePick,
    });
    this.setState({
      reportType: "EMAIL",
    });
  }

  firstToUpperCase = (str) => {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
  }


  getAccountantsList = (listData) => {
    if (listData) {
      const filterListView = [];
      listData.map((accountant, index) => {
        filterListView.push(
          <Tr key={accountant._id}>
            <Th>{accountant.nr_order}</Th>
            <Th>{Number(accountant.price).toFixed(2)}</Th>
            <Th>{this.firstToUpperCase(accountant.id_payment.kind.replace(/Id/g, '').replace('stripe', ''))}</Th>
            <Th>{accountant.description}</Th>
            <Th>{moment(accountant.createdAt).format("DD/MM/YYYY")}</Th>
            <Th>{accountant.booking?.professionalID?.name} {accountant.booking?.professionalID?.surname}</Th>
            <Th>{accountant.booking?.parentID?.name} {accountant.booking?.parentID?.surname}</Th>
          </Tr>
        );
      });
      return filterListView;
    } else {
      return null;
    }
  }

  render() {
    const {list} = this.props
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Form.Item
            onChange={this.handleChange}
            name="email"
            label="Email"
            value={this.state.email}
            rules={[
              {
                type: "email",
              },
            ]}
          >
            <Input style={{width: "200px"}}/>
            <button
              className="btn btn-success waves-effect waves-light"
              type="button"
              onClick={this.onSubmit}
              style={{marginLeft: "1rem", height: "34px"}}
            >
              Send
            </button>
          </Form.Item>
          <div>
            {" "}
            <Space direction="horizental" style={{paddingBottom: "1rem"}}>
              <DatePicker
                disabledDate={disabledDate}
                onChange={this.onDatepicker}
                picker="month"
                placeholder="Select month"
              />
            </Space>
            <button
              className="btn btn-success waves-effect waves-light"
              type="button"
              onClick={this.onDownload}
              style={{marginLeft: "2%", height: "34px"}}
            >
              Download XML
            </button>
          </div>
        {/*  <div></div>*/}
        {/*  <Table*/}
        {/*    id="tech-companies-1"*/}
        {/*    className="table table-striped table-bordered"*/}
        {/*  >*/}
        {/*    <Thead>*/}
        {/*      <Tr>*/}
        {/*        <Th data-priority="1">Payment receipt ID </Th>*/}
        {/*        <Th data-priority="1">Amount with VAT</Th>*/}
        {/*        <Th data-priority="6">VAT percentage</Th>*/}
        {/*        <Th data-priority="6">Service</Th>*/}
        {/*        <Th data-priority="6">Date</Th>*/}
        {/*        <Th data-priority="6">Professional Fullname</Th>*/}
        {/*        <Th data-priority="6">Parent Fullname</Th>*/}
        {/*      </Tr>*/}
        {/*    </Thead>*/}
        {/*    <Tbody>{this.getAccountantsList(list)}</Tbody>*/}
        {/*  </Table>*/}
        </div>
      </div>
    );
  }
}

export default AccountantList;
