import ReactFlagsSelect from 'react-flags-select';
import {Form, Input, Row, Col, Typography} from 'antd';
import countries from '../../../helpers/countries';
import React, {useEffect, useState} from "react";

const {Title} = Typography;

const ProfessionalAddress = ({typeAddress, form}) => {
  const [countrySelectCode, setCountrySelectCode] = useState("BG")

  useEffect(() => {
    handleCountryCode()
  }, [typeAddress])

  const handleCountryCode = () => {

    const countryCode = countries.find(
      (el) => el.name === form?.getFieldsValue()[typeAddress]?.country,
    )
    if (countryCode?.code) {
      handleCountrySelection(countryCode?.code)
    } else {
      handleCountrySelection("BG")
    }


  }
  const handleCountrySelection = (country) => {
    const countryName = countries.find((c) => c.code === country)
    form?.setFieldValue([typeAddress, 'country'], countryName.name)
    setCountrySelectCode(countryName.code)
  }

  return <Row gutter={24}>
    <Col xs={24}>
      <Title level={5}>{typeAddress === 'address2' ? 'Address ID card' : 'Address'}</Title>
    </Col>
    <Col span={12}>
      <Form.Item
        label="Country"
        name={[typeAddress, "country"]}
        rules={[{ required: true, message: 'Please write country' }]}
      >
        <ReactFlagsSelect
          selectButtonClassName={'custom-country-select'}
          selected={countrySelectCode}
          onSelect={(code) => handleCountrySelection(code)}
          placeholder="Country"
        />
      </Form.Item>
    </Col>
    <Col span={12}>
      <Form.Item
        label="Neighborhood"
        name={[typeAddress, "address2"]}
        // rules={[{ required: true, message: 'Please write neighborhood' }]}
      >
        <Input
          placeholder="Neighborhood"
        />
      </Form.Item>
    </Col>
    <Col span={12}>
      <Form.Item
        label="Address"
        name={[typeAddress, "address"]}
        // rules={[{ required: true, message: 'Please write address' }]}
      >
        <Input
          placeholder="Address"
        />
      </Form.Item>
    </Col>
    <Col span={12}>
      <Form.Item
        label="City"
        name={[typeAddress, "city"]}
        // rules={[{ required: true, message: 'Please write city' }]}
      >
        <Input
          placeholder="City"
        />
      </Form.Item>
    </Col>
    <Col span={12}>
      <Form.Item
        label="Post code"
        name={[typeAddress, "postalCode"]}
        // rules={[{ required: true, message: 'Please write post code' }]}
      >
        <Input
          placeholder="Post code"
        />
      </Form.Item>
    </Col>
  </Row>
}

export default ProfessionalAddress
