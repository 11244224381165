import React from 'react';
import {Form, Input, AutoComplete, Row, Col, Typography, Select} from 'antd';
import DocumentUpload from "../../../helpers/DocumentsUpload";

/**
 * A dynamic field set component that renders a set of form fields based on the provided configuration.
 *
 * @param {Object} DynamicFieldSet - The configuration object for the dynamic field set.
 * @param {Object[]} documents - The array of documents.
 * @param {string} title - The title of the first input field.
 * @param {string} titleTwo - The title of the second input field.
 * @param {string[]} keyName - The key name array.
 * @param {string} subKey - The sub key.
 * @param {string} subKeyTwo - The second sub key.
 * @param {string} documentTitle - The title of the document field.
 * @param {Object[]} metadataSpecializationOptions - The array of metadata specialization options.
 * @param {number} index - The index.
 * @param {boolean} inputVisible - Indicates if the first input field is visible (default: true).
 * @param {boolean} inputTwoVisible - Indicates if the second input field is visible (default: false).
 * @param {boolean} isRequired - Indicates if the fields are required (default: false).
 * @returns {JSX.Element} The rendered dynamic field set.
 */

const DynamicFieldSet = ({
                           documents,
                           title,
                           titleTwo,
                           keyName,
                           subKey,
                           subKeyTwo,
                           documentTitle,
                           metadataSpecializationOptions,
                           index,
                           inputVisible = true,
                           inputTwoVisible = false,
                           isRequired = false,
                           fieldKey,
                           form,
                         }) => {
  const name = [...keyName, subKey]
  const name2 = [...keyName, subKeyTwo]
  return (
    documents.map((item, i) => (
      <Row key={i} gutter={16}>
        {inputVisible && <Col span={12}>
          <Form.Item
            name={name}
            label={title}
            required={isRequired}
            rules={isRequired ? [{required: true, message: `Please write ${title}`}] : []}
          >
            {title !== 'Specialization Name' ?
              <Input
                placeholder={title}
              />
              : (
                <Select
                  options={metadataSpecializationOptions.map(option => ({
                    value: option.value,
                    label: option.label
                  }))}
                  filterOption={(inputValue, option) => {
                    return option?.value.toLowerCase() === inputValue.toLowerCase()
                  }
                  }></Select>
              )}
          </Form.Item>
        </Col>}
        {inputTwoVisible && <Col span={12}>
          <Form.Item
            name={name2}
            label={titleTwo}

            required={isRequired}
            rules={isRequired ? [{required: true, message: `Please write ${title}`}] : []}
          >
            {title !== 'Specialization Name' ?
              <Input
                placeholder={title}
              />
              : (
                <Select
                  options={metadataSpecializationOptions.map(option => ({
                    value: option.value,
                    label: option.label
                  }))}
                  filterOption={(inputValue, option) => {
                    return option?.value.toLowerCase() === inputValue.toLowerCase()
                  }
                  }></Select>
              )}
          </Form.Item>
        </Col>}
        <Col span={12}>
          <Form.Item
            label={documentTitle}
            name={[...keyName, 'document']}>
            <Row>
              <Col span={18}>
                <DocumentUpload document={item} index={index} preview={true}
                                onChange={(dok) => {
                                  const value = form.getFieldValue(fieldKey)
                                  form.setFieldValue(fieldKey, {...value, ['document']: dok.document, ['fileName']: dok.fileName})
                                }}/>
              </Col>

            </Row>
          </Form.Item>
        </Col>
      </Row>
    ))
  );
};

export default DynamicFieldSet;
