import React, { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { connect } from "react-redux";
import {
  addProfessional, approveProfessional, editProfessional,
  getProfessionals,
  removeProfessional,
  showAddProfessionalModal
} from "../../store/professionals/actions";
import {userRols} from "../../helpers/userRols";
import ProfessionalList from "../Professionals/List/ProfessionalList";

class Groomers extends Component {
  constructor(props) {
    super(props);

    this.onNewGroomerModal = this.onNewGroomerModal.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.props.getProfessionals({type: 'GROOMER'});
  }

  onNewGroomerModal() {
    this.props.showAddGroomerModal(!this.props.isAddNewGroomerOpen);
  }

  onRemove(groomer) {
    this.props.removeProfessional(groomer, userRols.groomer);
  }

  onEdit(groomer) {
    this.props.editProfessional(groomer);
  }

  render() {
    const { professionalList, approveProfessional, history } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Groomers" breadcrumbItem="Groomers" />

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <ProfessionalList
                      list={professionalList}
                      onRemoveItem={this.onRemove}
                      onEditItem={this.onEdit}
                      onApproveItem={approveProfessional}
                      history={history}
                      serviceType={userRols.groomer}

                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = ({Professional}) => {
  return Professional;
};

export default connect(mapStatetoProps, {
  getProfessionals,
  addProfessional,
  approveProfessional,
  showAddProfessionalModal,
  removeProfessional,
  editProfessional
  })(Groomers);
