import {UserActionTypes} from "./actionTypes";

export const getUsersProfessionals = (payload) => {
    return {
        type: UserActionTypes.USERS_PROFESSIONAL_LIST_GET,
        payload: payload
    }
}

export const getUsersProfessionalsSuccessful = (payload) => {
    return {
        type: UserActionTypes.USERS_PROFESSIONAL_LIST_GET_SUCCESSFUL,
        payload: payload
    }
}

export const getUsersProfessionalsError = (payload) => {
    return {
        type: UserActionTypes.USERS_PROFESSIONAL_LIST_GET_ERROR,
        payload,
    }
}

export const getUsersParents = (payload) => {
    return {
        type: UserActionTypes.USERS_PARENT_LIST_GET,
        payload: payload
    }
}

export const getUsersParentSuccessful = (payload) => {
    return {
        type: UserActionTypes.USERS_PARENT_LIST_GET_SUCCESSFUL,
        payload: payload
    }
}

export const getUsersParentError = (payload) => {
    return {
        type: UserActionTypes.USERS_PARENT_LIST_GET_ERROR,
        payload,
    }
}

export const downloadUsersToExel = (payload) => {
    return {
        type: UserActionTypes.USERS_PARENT_LIST_GET_EXEL,
        payload: payload
    }
}

export const getUsersParentExelSuccessful = (payload) => {
    return {
        type: UserActionTypes.USERS_PARENT_LIST_GET_EXEL_SUCCESSFUL,
        payload: payload
    }
}

export const getUsersParentExelError = (payload) => {
    return {
        type: UserActionTypes.USERS_PARENT_LIST_GET_EXEL_ERROR,
        payload,
    }
}

