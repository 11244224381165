import React from 'react';
import DynamicFieldSet from "../../../pages/Professionals/List/DynamicFieldSet";
import {Button, Col, Divider, Form, Input, Row} from "antd";

import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {metadataSpecializationOptions} from "../../../helpers/specializations";



const SpecializationsType = ({user, form}) => {
  return (<Row gutter={24}>
    <Col span={24}>
      <Form.List
        name={['metadata', 'specializations']}
        rules={[
          {
            validator: async (_, professionalQualifications) => {
              if (
                !professionalQualifications ||
                professionalQualifications.length < 1
              ) {
                return Promise.reject(
                  new Error('Please write university'),
                )
              }
            },
          },
        ]}
      >
        {(fields, {add, remove}, {errors}) => (
          <Row gutter={12} align='middle'>
            {fields?.map(({key, name, ...restField}, index) => (
              <Col key={key} xs={22}>
                <Row gutter={12} align='middle'>
                  <Col xs={22}>

                    <DynamicFieldSet fieldKey={['metadata', 'specializations', restField.fieldKey]} keyName={[name]}
                                     subKey={'specializationName'}
                                     title={'Specialization Name'}
                                     documentTitle={'Specialization Document'}
                                     metadataSpecializationOptions={metadataSpecializationOptions}
                                     documents={[user?.metadata?.specializations?.[index]]}
                                     form={form}/>

                  </Col>

                  {fields.length > 1 &&
                    <Col xs={2}>
                      <Button icon={
                        <MinusCircleOutlined
                          className='btn btn-remove'

                        />} onClick={() => remove(name)}/>
                    </Col>}
                  {fields.length > 1 && fields.length - 1 !== index && (
                    <Divider className='fields-divider'/>
                  )}
                </Row>
              </Col>
            ))}
            <Col xs={2}>
              <Form.Item>
                <Button icon={<PlusOutlined/>} onClick={() => add()}/>
                <Form.ErrorList errors={errors}/>
              </Form.Item>
            </Col>
          </Row>

        )}
      </Form.List>
    </Col>
    <Col span={12}>

    </Col>


  </Row>);
};

export default SpecializationsType;
