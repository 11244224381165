import ApiService from "../../helpers/ApiHelper";

class CouponService {

  static getCoupons({page, search}) {

    const query = `?search=${search ? search : '1'}&page=${page}`
    return ApiService.request(
      `/api/v2/coupon${query}`,
      "get",
      {}
    )
  }

  static getCoupon({id}) {

    return ApiService.request(
      `/api/v2/coupon/${id}`,
      "get",
      {}
    )
  }

  static create(coupon) {

    return ApiService.request(
      `/api/v2/coupon/add`,
      "post",
      coupon
    )
  }

  static update(coupon) {

    return ApiService.request(
      `/api/v2/coupon/update/${coupon._id}`,
      "put",
      coupon
    )
  }

  static disable({id}) {
    return ApiService.request(
      `/api/v2/coupon/disable/${id}`,
      "delete",
      {}
    )
  }

}

export default CouponService;
