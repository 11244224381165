import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects";
import {
    getTransactions,
    getTransactionsSuccessful,
    getTransactionsError,
    getFilteredTransactions,
    getFilteredTransactionsSuccessful,
    getFilteredTransactionsError,
    createInvoice,
    createInvoiceError,
    createInvoiceSuccess,
    getInvoices,
    getInvoicesError,
    getInvoicesSuccessful
} from "./actions";
import ProfPaymentsService from "./service";
import { PaymentsActionTypes } from "./actionTypes";

function* getAllTransactions({payload}) {
    try {
        const response = yield call(ProfPaymentsService.getTransactions, payload);
        const rsp = getTransactionsSuccessful(response);
        yield put(rsp);
    } catch (error) {
        yield put(getTransactionsError(error));
    }
}

function* getAllInvoices({payload}) {
    try {
        const response = yield call(ProfPaymentsService.getInvoices, payload);
        const rsp = getInvoicesSuccessful(response);
        yield put(rsp);
    } catch (error) {
        yield put(getInvoicesError(error));
    }
}

export function* watchGetInvoicing() {
    yield takeEvery(PaymentsActionTypes.PROFPAY_LIST_GET, getAllTransactions);
}


export function* watchGetProfPayments() {
    yield takeEvery(PaymentsActionTypes.PROF_LIST_GET, getAllInvoices);
}


function* getFiltered({payload}) {
    try {
        const response = yield call(ProfPaymentsService.getFilteredTransactions, { professionalID: payload });
        const rsp = getFilteredTransactionsSuccessful(response);
        yield put(rsp);
    } catch (error) {
        yield put(getFilteredTransactionsError(error));
    }
}
export function* watchFilterInvoicing() {
    yield takeEvery(PaymentsActionTypes.PROFPAY_GET_FILTERED, getFiltered);
}


function* createInvoiceSaga({payload}) {
    try {
        const response = yield call(ProfPaymentsService.createInvoice, { transactions: payload });
        const rsp = createInvoiceSuccess(response);
        yield put(rsp);
    } catch (error) {
        yield put(createInvoiceError(error));
    }
}
export function* watchCreateInvoice() {
    yield takeEvery(PaymentsActionTypes.PROFPAY_CREATE, createInvoiceSaga);
}



function* profPaymentsSaga() {
    yield all([
        fork(watchGetInvoicing),
        fork(watchFilterInvoicing),
        fork(watchCreateInvoice),
        fork(watchGetProfPayments),
    ]);
}



export default profPaymentsSaga;
