import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import axios from "axios";
import baseURL from "../../config";

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: [],
      isUploading: false,
      type: null,
      uploadedFile: null,
    };
  }

  handleAcceptedFiles = (files) => {
    this.setState({ isUploading: true });

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
    const token = (axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`);

    const formData = new FormData();
    formData.append("file", files[0]);
    axios({
      crossdomain: true,
      method: "post",
      url: `${baseURL}/api/v1/upload`,
      data: formData,
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:4242/api/v1/upload",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (response.data.imageUrl) {
          this.setState({
            uploadedFile: response.data.imageUrl,
            isUploading: false,
          });

          const reponseData = {
            url: response.data.imageUrl,
            type: this.props.type,
          };
          return this.props.onFileUploaded(reponseData);
        } else {
        }
      })
      .catch((error) => {});
  };
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  render() {
    const { isUploading, uploadedFile, selectedFiles } = this.state;

    return (
      <div>
        <Dropzone
          maxFiles={1}
          multiple={false}
          onDrop={(acceptedFiles) => this.handleAcceptedFiles(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone text-center">
              <div className="needsclick mt-1 px-5" {...getRootProps()}>
                <input {...getInputProps()} />
                {/* <div className="mb-3">
                  <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                </div> */}
                <div><i className='bx bx-upload font-size-24'></i></div>
              </div>
            </div>
          )}
        </Dropzone>
        {true && (
          <div className="dropzone-previews mt-3" id="file-previews">
            {selectedFiles.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        {f.preview && f.name.includes("mp4") ? (
                          <video controls src={f.preview} width="100%"></video>
                        ) : (
                          <img
                            data-dz-thumbnail=""
                            width="40%"
                            alt={f.name}
                            src={f.preview}
                          />
                        )}
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default FileUpload;
