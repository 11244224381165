export const ReceivedActionTypes = {
    RECEIVED_LIST_GET: 'RECEIVED_LIST_GET',
    RECEIVED_LIST_GET_SUCCESSFUL: 'RECEIVED_LIST_GET_SUCCESSFUL',
    RECEIVED_LIST_GET_ERROR: 'RECEIVED_LIST_GET_ERROR',

}




