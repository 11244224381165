import { BookingActionTypes } from "./actionTypes";


const initialState = {
    bookingListError: null, loadingBookings: false, message: null, isLoadingAddBooking: false, isAddNewBookingOpen: false
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case BookingActionTypes.BOOKING_LIST_GET:
            state = {
                ...state,
                bookingList: null,
                loadingBookings: true,
                bookingListError: null
            }
            break;
        case BookingActionTypes.BOOKING_LIST_GET_SUCCESSFUL:
            state = {
                ...state,
                bookingList: action.payload,
                loadingBookings: false,
                bookingListError: null
            }
            break;
        case BookingActionTypes.BOOKING_LIST_GET_ERROR:
            state = {
                ...state,
                bookingList: null,
                loadingBookings: false,
                bookingListError: action.payload
            }
            break;
        case BookingActionTypes.BOOKING_ADD_MODAL:
            state = {
                ...state,
                isAddNewBookingOpen: action.payload,
            }
            break;
        case BookingActionTypes.BOOKING_ADD_MODAL_SUCCESS:
            state = {
                ...state,
                isAddNewBookingOpen: action.payload,
            }
            break;
        case BookingActionTypes.BOOKING_ADD:
            state = {
                ...state,
                isLoadingAddBooking: true
            }
            break;
        case BookingActionTypes.BOOKING_ADD_SUCCESSFUL:
            state = {
                ...state,
                isLoadingAddBooking: false,
                isAddNewBookingOpen: false,
            }
            break;
        case BookingActionTypes.BOOKING_ADD_ERROR:
            state = {
                ...state,
                isLoadingAddBooking: false,
                isAddNewBookingOpen: false,
            }
            break;
        case BookingActionTypes.BOOKING_REMOVE_SUCCESS:
            state = {
                ...state,
                isLoadingAddBooking: false,
                isAddNewBookingOpen: false,
            }
            break;
        case BookingActionTypes.BOOKING_REMOVE_ERROR:
            state = {
                ...state,
                isLoadingAddBooking: false,
                isAddNewBookingOpen: false,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default account;