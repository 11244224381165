export const AdminActionTypes = {
    ADMIN_LIST_GET: 'ADMIN_LIST_GET',
    ADMIN_LIST_GET_SUCCESSFUL: 'ADMIN_LIST_GET_SUCCESSFUL',
    ADMIN_LIST_GET_ERROR: 'ADMIN_LIST_GET_ERROR',


    ADMIN_ADD: 'ADMIN_ADD',
    ADMIN_ADD_SUCCESSFUL: 'ADMIN_ADD_SUCCESSFUL',
    ADMIN_ADD_ERROR: 'ADMIN_ADD_ERROR',

    ADMIN_ADD_MODAL: 'ADMIN_ADD_MODAL',
    ADMIN_ADD_MODAL_SUCCESS: 'ADMIN_ADD_MODAL_SUCCESS',

    ADMIN_SHOW_REMOVE_MODAL: 'ADMIN_SHOW_REMOVE_MODAL',
    ADMIN_SHOW_REMOVE_MODAL_SUCCESS: 'ADMIN_SHOW_REMOVE_MODAL_SUCCESS',

    ADMIN_REMOVE: "ADMIN_REMOVE",
    ADMIN_REMOVE_SUCCESS: 'ADMIN_REMOVE_SUCCESS',
    ADMIN_REMOVE_ERROR: 'ADMIN_REMOVE_ERROR',

    ADMIN_EDIT: 'ADMIN_EDIT',
    ADMIN_EDIT_SUCCESS: 'ADMIN_EDIT_SUCCESS',
    ADMIN_EDIT_ERROR: 'ADMIN_EDIT_ERROR',

    ADMIN_STATISTIC_DATA: 'ADMIN_STATISTIC_DATA',
    ADMIN_STATISTIC_DATA_SUCCESS: 'ADMIN_STATISTIC_DATA_SUCCESS',
    ADMIN_STATISTIC_DATA_ERROR: 'ADMIN_STATISTIC_DATA_ERROR',
}




