import React, { Component } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import AdminRemove from "./AdminRemove";
import AdminEdit from "./AdminEdit";

import axios from 'axios';
import baseURL from "../../../config";

class AdminList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpen: false,
      isRemoveModalOpen: false,
      removeAdmin: null,
      editAdmin: null,
      adminListData: []
    };
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditSubmit= this.onEditSubmit.bind(this);
    this.onRemoveOpen = this.onRemoveOpen.bind(this);
    this.onRemoveSubmit = this.onRemoveSubmit.bind(this);
  }

  onEditOpen(Admin) {
    this.setState((prevState) => ({
      isEditModalOpen: !prevState.isEditModalOpen,
      editAdmin: Admin,
    }));
  }

  componentDidMount(){
    //  get users 
    axios.get(`${baseURL}/api/v1/users/`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
        let response = res?.data
        this.setState({adminListData: response})
    })
    .catch((error) =>{
        console.log(error)
    })  
  }


  onRemoveOpen(Admin) {
    this.setState((prevState) => ({
      isRemoveModalOpen: !prevState.isRemoveModalOpen,
      removeAdmin: Admin,
    }));
  }

  onRemoveSubmit(Admin) {
    this.props.onRemoveItem(Admin);
  }

  onEditSubmit(Admin) {
    this.props.onEditItem(Admin);
  }

  getAdminsList(adminListData) {
    if (adminListData) {

      const adminListView = [];
      adminListData.data?.users.map((user) => {
        if(user.role === 'ADMIN'){
          adminListView.push(
            <tr key={user._id}>
              <td>{user.name}</td>
              <td>{user.surname}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                <div className="button-items">
                  <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => {
                      this.onRemoveOpen(user);
                    }}
                  >
                    <i className="bx bx-trash-alt font-size-16 align-middle"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => {
                      this.onEditOpen(user);
                    }}
                  >
                    <i className="bx bxs-edit font-size-16 align-middle"></i>
                  </button>
                </div>
              </td>
            </tr>
          );
        }
      });

      return adminListView;
    } else {
      return null;
    }
  }

  render() {
    const {
      isEditModalOpen,
      isRemoveModalOpen,
      removeAdmin,
      editAdmin,
    } = this.state;
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered"
          >
            <Thead>
              <Tr>
                <Th data-priority="1">First Name </Th>
                <Th data-priority="3">Last Name</Th>
                <Th data-priority="3" colSpan="3">Email</Th>
              </Tr>
            </Thead>
            <Tbody>{this.getAdminsList(this.state.adminListData)}</Tbody>
          </Table>
        </div>
        <AdminRemove
          data={removeAdmin}
          onSubmit={this.onRemoveSubmit}
          isRemoveModalOpen={isRemoveModalOpen}
          onCancel={this.onRemoveOpen}
        />
        {editAdmin && (
          <AdminEdit
            data={editAdmin}
            onSubmit={this.onEditSubmit}
            isEditModalOpen={isEditModalOpen}
            onCancel={this.onEditOpen}
          />
        )}
      </div>
    );
  }
}

export default AdminList;
