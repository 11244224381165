import React, {useState} from "react";
import {QRCode} from 'antd';

const GenerateQRCode = ({QRMetadata, user, setUserMetaQRCode}) => {
  const [qrcode, setQRCode] = useState(QRMetadata)

  const generateQRCode = (e) => {
    e.preventDefault()
    const qrCodeData = {
      errorLevel:"H",
      value:`https://happypetnet.com/professionals/${user.id}`,
      icon: '/images/happypet-logo-orange.svg'
    }
    setQRCode(qrCodeData)
    user.metadata.QRMetadata = qrCodeData
    setUserMetaQRCode({user})
  }

  return (
    <div className="row">
      <div className="col-12">
        {qrcode &&   <QRCode {...qrcode}/>}
        {<button className="btn btn-primary waves-effect waves-light" onClick={(e) => generateQRCode(e)}>Create QR Code</button>}
      </div>
    </div>
  )

}

export default GenerateQRCode
