import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getReceived} from "../../store/received/actions";
import ReceivedList from "./List/ReceivedList";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Paging from "../../components/Pagination/Pagination";
import {Space} from "antd";
import {Button, Card, CardBody, Col, Row} from "reactstrap";

const Receiveds = ({receivedList, getReceived}) => {

    const [page, setPage] = useState(1)


    useEffect(() => {

        getReceived()
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Received" breadcrumbItem="Received"/>

                    <Row>
                        <Col>
                            <Card>
                                <div className="d-inline-flex ant-row-space-between align-items-center">
                                    <Paging setPage={setPage} currentPage={page} pages={receivedList ? Math.ceil(receivedList.length / 20) : 1}/>
                                    {/*<Space direction="horizontal" className="mr-4">*/}
                                    {/*    <Button type={'button'} className='btn btn-info waves-effect waves-light search-btn'*/}
                                    {/*            onClick={() => this.setFilterModalOpen(!filterModalOpen)}><i*/}
                                    {/*        className="bx bx-filter-alt align-middle"></i></Button>*/}
                                    {/*    {(search.text || Object.keys(search.tagsFilters).length > 0) &&*/}
                                    {/*        <Button type={'button'} className='btn btn-dark waves-effect waves-light search-btn'*/}
                                    {/*                onClick={this.refreshPage}><i*/}
                                    {/*            className='bx bx-revision align-middle'></i></Button>}*/}
                                    {/*</Space>*/}
                                </div>
                                <CardBody>
                                    <ReceivedList receivedList={receivedList} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};


const mapStateToProps = ({Received}) => {
    return Received;
}

export default connect(mapStateToProps, {getReceived})(Receiveds);