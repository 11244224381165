import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

// // layouts
import VerticalLayout from "./components/VerticalLayout/";

// Import scss
import "./assets/scss/theme.scss";
import {useLocation, Outlet, useNavigate} from "react-router-dom";
import {autoLogin} from "./store/auth/login/actions";

// Activating fake backend

const App = () => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (pathname === '/') {
      navigate('/dashboard', {replace: true})
    }
  }, [])
  return (
    <VerticalLayout location={ pathname.split('/').pop()}><Outlet/></VerticalLayout>

  );
}

export default App;
