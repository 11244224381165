import React, { Component } from "react";
import { Row, Col, Label, FormGroup, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import FileUpload from "../../components/FileUpload/FileUpload";
import axios from "axios";
import baseURL from "../../config";

class AddParent extends Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    const newParent = {
      name: "",
      surname: "",
      email: "",
      password: "",
      passwordConfirm: "",
    };

    const messageError = {
      messageAlert: "",
    };

    this.state = { newParent, messageError };
  }

  onCancel() {
    const { newParent } = this.state;
    newParent.name = "";
    newParent.surname = "";
    newParent.email = "";
    newParent.password = "";
    this.state.value = [];
    this.props.onCancel();
  }

  onSubmit(e) {
    const { newParent, messageError } = this.state;
    // if (newParent.password !== newParent.confirmPassword) {
    //   alert("Confirm password should be the same with password!");
    // }

    e.preventDefault();
    fetch(`${baseURL}/api/v1/users/signup`, {
      method: "POST",
      body: JSON.stringify(newParent),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((response) => {
      response.json().then((data) => {
        this.setState(newParent);
        this.onCancel();
        window.location.reload()
      });
    });
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { newParent } = this.state;
    newParent[name] = value;
    this.setState({ newParent });
  }

  render() {
    const { newParent } = this.state;
    return (
      <Modal isOpen={this.props.isNewParentModalOpen} toggle={this.onCancel}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add user</h5>
          <button
            type="button"
            onClick={this.onCancel}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <AvForm className="needs-validation">
            <FormGroup>
              <Label htmlFor="validationCustom01">Name </Label>
              <AvField
                name="name"
                placeholder="Name"
                type="text"
                errorMessage="Please write title"
                className="form-control"
                onChange={this.handleChange}
                value={newParent.name}
                validate={{ required: { value: true } }}
                id="validationCustom01"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="validationCustom02">Surname</Label>
              <AvField
                name="surname"
                placeholder="Surname"
                type="text"
                errorMessage="Please write surname"
                className="form-control"
                onChange={this.handleChange}
                value={newParent.surname}
                validate={{ required: { value: true } }}
                id="validationCustom02"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="validationCustom02">Email</Label>
              <AvField
                name="email"
                placeholder="Email"
                type="email"
                errorMessage="Please write email"
                className="form-control"
                onChange={this.handleChange}
                value={newParent.email}
                validate={{ required: { value: true } }}
                id="validationCustom02"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="validationCustom02">Password</Label>
              <AvField
                name="password"
                placeholder="Password"
                type="password"
                errorMessage="Please write Password"
                className="form-control"
                onChange={this.handleChange}
                value={newParent.password}
                validate={{ required: { value: true } }}
                id="validationCustom02"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="validationCustom02">Confirm Password</Label>
              <AvField
                name="passwordConfirm"
                placeholder="Password Confirm"
                type="password"
                errorMessage="Please write correct confirm password"
                className="form-control"
                onChange={this.handleChange}
                value={newParent.passwordConfirm}
                validate={{ required: { value: true } }}
                id="validationCustom02"
              />
            </FormGroup>
          </AvForm>
        </div>

        {this.props.isLoading && (
          <div className="modal-footer">
            <div className="spinner-border text-success m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        {!this.props.isLoading && (
          <div className="modal-footer">
            <button
              type="button"
              onClick={this.onCancel}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.onSubmit}
              className="btn btn-success waves-effect waves-light"
            >
              Add
            </button>
          </div>
        )}
      </Modal>
    );
  }
}

export default AddParent;
