import React, {useState} from 'react';
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import PetRemove from "../../Pets/List/PetRemove";
import dayjs from "dayjs";
import {userRols} from "../../../helpers/userRols";
import {adoptedShelterPet} from "../../../store/pet/actions";
import {Button} from "reactstrap";

const ShelterPetList = ({petListData, onRemoveSubmit, adoptedShelterPet}) => {
    const [removePet, setRemovePet] = useState();
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)

    const getPetsList = (petListData) => {
        if (petListData) {
            return petListData?.pets.map((pet, i) => {
                return <Tr key={i}>
                    <Th>{pet?.parentID?.name} {pet?.parentID?.surname}</Th>
                    <Th>{pet?.name}</Th>
                    <Th>{pet?.type}</Th>
                    <Th>{pet?.breed}</Th>
                    <Th>{pet?.gender}</Th>
                    <Th>{dayjs().diff(dayjs(pet?.dateOfBirth), 'y')}</Th>
                    <Th>{pet?.parentID?.email}</Th>
                    <Th>{dayjs(pet?.createdAt).format('DD/MM/YYYY')}</Th>
                    <Td>
                        <div className="button-items">
                            <Button color={!pet?.adopted ? 'danger' : 'success'} className='ml-1'
                                    onClick={() => adoptedShelterPet(pet?._id)}>{!pet?.adopted ? <i
                                className="bx bxs-dislike font-size-16 align-middle"></i> : <i
                                className="bx bxs-like font-size-16 align-middle"></i>}</Button>
                            <button
                                type="button"
                                className="btn btn-danger waves-effect waves-light"
                                onClick={() => {
                                    setRemovePet(pet);
                                    setIsRemoveModalOpen(true);
                                }}
                            >
                                <i className="bx bx-trash-alt font-size-16 align-middle"></i>
                            </button>
                        </div>
                    </Td>
                </Tr>
            });
        } else {
            return null;
        }
    }

    return (
        <div className="table-rep-plugin">
            <div className="table-responsive mb-0" data-pattern="priority-columns">
                <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                >
                    <Thead>
                        <Tr>
                            <Th data-priority="1">Parent Name</Th>
                            <Th data-priority="1">Pet Name</Th>
                            <Th data-priority="3">Type</Th>
                            <Th data-priority="3">Breed</Th>
                            <Th data-priority="3">Gender</Th>
                            <Th data-priority="3">Age</Th>
                            <Th data-priority="1">Email</Th>
                            <Th data-priority="3">Created At</Th>
                            <Th data-priority="3">Remove</Th>
                        </Tr>
                    </Thead>
                    <Tbody>{getPetsList(petListData)}</Tbody>
                </Table>
            </div>
            <PetRemove
                data={removePet}
                onSubmit={(petId) => {
                    onRemoveSubmit(petId)
                    setIsRemoveModalOpen(false)
                }}
                isRemoveModalOpen={isRemoveModalOpen}
                onCancel={() => setIsRemoveModalOpen(false)}
            />
        </div>
    );
};

export default ShelterPetList;