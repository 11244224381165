export const InvoicingActionTypes = {
    INVOICING_LIST_GET: 'INVOICING_LIST_GET',
    INVOICING_LIST_GET_SUCCESSFUL: 'INVOICING_LIST_GET_SUCCESSFUL',
    INVOICING_LIST_GET_ERROR: 'INVOICING_LIST_GET_ERROR',

    INVOICE_LIST_GET: 'INVOICE_LIST_GET',
    INVOICE_LIST_GET_SUCCESSFUL: 'INVOICE_LIST_GET_SUCCESSFUL',
    INVOICE_LIST_GET_ERROR: 'INVOICE_LIST_GET_ERROR',

    INVOICING_GET_FILTERED: 'INVOICING_GET_FILTERED',
    INVOICING_GET_FILTERED_SUCCESSFUL: 'INVOICING_GET_FILTERED_SUCCESSFUL',
    INVOICING_GET_FILTERED_ERROR: 'INVOICING_GET_FILTERED_ERROR',

    INVOICING_CREATE: 'INVOICING_CREATE',
    INVOICING_CREATE_SUCCESS: 'INVOICING_CREATE_SUCCESS',
    INVOICING_CREATE_ERROR: 'INVOICING_CREATE_ERROR'
}




