import { RouterProvider} from "react-router-dom";
import {connect} from "react-redux";
import {router} from "./routes";

const InitApp = ({user}) => {
  return <RouterProvider router={router}/>;
};

export default connect(({Login}) => {
  return Login
})(InitApp);
