import {Modal} from "reactstrap";
import React from "react";

const HotelStatistic = ({data, isStatisticModalOpen, onCancel}) => {

  const renderWithParam = () => {
    if(data.userRequestId) {
     return data.userRequestId.map((ur, i) => {
       return <div className="col-12" key={i}>
         <div className="row">
           <div className="col-4">{ur.name} {ur.surname}</div>
           <div className="col-4">{data.ipAddress[i]}</div>
           <div className="col-4">{ur.role}</div>
         </div>
       </div>
     })
    }
  }
  return <Modal scrollable={true} style={{height: 700}} size="lg" isOpen={isStatisticModalOpen}
         toggle={onCancel}>
    <div className="modal-header">
      <h5 className="modal-title mt-0">Statistic {data.role}</h5>
      <button
        type="button"
        onClick={onCancel}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
      <div className="row">
        <div className="col-4">User</div>
        <div className="col-4">Ip Address</div>
        <div className="col-4">Role</div>
            {renderWithParam()}
      </div>
    </div>
  </Modal>


}
export default HotelStatistic
