import { AdminActionTypes } from "./actionTypes";

const initStatisticData = {
    totalParents: 0,
    notApprovedParents: 0,
    totalVet: 0,
    notApprovedVet: 0,
    totalClinic: 0,
    notApprovedClinic: 0,
    totalPet: 0,
    totalGroomer: 0,
    notApprovedGroomer: 0,
    totalPetsDeleted: 0,
    pets: []
}

const initialState = {
    adminListError: null, message: null, isLoadingAddAdmin: false, isAddNewAdminOpen: false, statisticData: initStatisticData
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case AdminActionTypes.ADMIN_LIST_GET:
            state = {
                ...state,
                adminList: null,
                adminListError: null
            }
            break;
        case AdminActionTypes.ADMIN_LIST_GET_SUCCESSFUL:
            state = {
                ...state,
                adminList: action.payload,
                adminListError: null
            }
            break;
        case AdminActionTypes.ADMIN_LIST_GET_ERROR:
            state = {
                ...state,
                adminList: null,
                adminListError: action.payload
            }
            break;
        case AdminActionTypes.ADMIN_STATISTIC_DATA:
            state = {
                ...state,
                statisticData: null,
                adminListError: null
            }
            break;
        case AdminActionTypes.ADMIN_STATISTIC_DATA_SUCCESS:
            state = {
                ...state,
                statisticData: action.payload,
                adminListError: null
            }
            break;
        case AdminActionTypes.ADMIN_STATISTIC_DATA_ERROR:
            state = {
                ...state,
                adminList: null,
                statisticData: action.payload
            }
            break;
        case AdminActionTypes.ADMIN_ADD_MODAL:
            state = {
                ...state,
                isAddNewAdminOpen: action.payload,
            }
            break;
        case AdminActionTypes.ADMIN_ADD_MODAL_SUCCESS:
            state = {
                ...state,
                isAddNewAdminOpen: action.payload,
            }
            break;
        case AdminActionTypes.ADMIN_ADD:
            state = {
                ...state,
                isLoadingAddAdmin: true
            }
            break;
        case AdminActionTypes.ADMIN_ADD_SUCCESSFUL:
            state = {
                ...state,
                isLoadingAddAdmin: false,
                isAddNewAdminOpen: false,
            }
            break;
        case AdminActionTypes.ADMIN_ADD_ERROR:
            state = {
                ...state,
                isLoadingAddAdmin: false,
                isAddNewAdminOpen: false,
            }
            break;
        case AdminActionTypes.ADMIN_REMOVE_SUCCESS:
            state = {
                ...state,
                isLoadingAddAdmin: false,
                isAddNewAdminOpen: false,
            }
            break;
        case AdminActionTypes.ADMIN_REMOVE_ERROR:
            state = {
                ...state,
                isLoadingAddAdmin: false,
                isAddNewAdminOpen: false,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default account;
