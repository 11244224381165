import {TransactionTypes} from "./actionTypes";

const initialState = {
    transactionListError: null, message: null, transactionList: {}
}

const transaction = (state = initialState, action) => {
    switch (action.type) {
        case TransactionTypes.TRANSACTION_GET:
            state = {
                ...state,
                transactionList: null,
                transactionListError: null
            }
            break;
        case TransactionTypes.TRANSACTION_SUCCESSFUL:
            state = {
                ...state,
                transactionList: action.payload,
                transactionListError: null
            }
            break;
        case TransactionTypes.TRANSACTION_ERROR:
            state = {
                ...state,
                transactionList: null,
                transactionListError: action.payload
            }
            break;
        case TransactionTypes.REFUND:
            state = {
                ...state,
                transactionList: null,
                transactionListError: null
            }
            break;
        case TransactionTypes.REFUND_SUCCESS:
            state = {
                ...state,
                transactionList: action.payload,
                transactionListError: null
            }
            break;
        case TransactionTypes.REFUND_ERROR:
            state = {
                ...state,
                transactionList: null,
                transactionListError: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default transaction;
