import { AccountantActionTypes } from "./actionTypes"


export const getAccountants = () => {
    return {
        type: AccountantActionTypes.ACCOUNTANT_LIST_GET,
        payload: null
    }
}

export const getAccountantsSuccessful = (payload) => {
    return {
        type: AccountantActionTypes.ACCOUNTANT_LIST_GET_SUCCESSFUL,
        payload: payload
    }
}

export const getAccountantsError = (payload) => {
    return {
        type: AccountantActionTypes.ACCOUNTANT_LIST_GET_ERROR,
        payload,
    }
}
