import ApiService from "../../helpers/ApiHelper";

class ProfessionalMetaHeadService {

    static getProfessionalMetaHeadByParams({professionalId}) {

        return ApiService.request(
          `/api/v2/meta-head-data/${professionalId}`,
          "get",
          {}

        )
    }

  static creteProfessionalMetaHead(payload) {
      return ApiService.request(
      `/api/v2/meta-head-data/add`,
      "patch",
      payload

    )
  }
}

export default ProfessionalMetaHeadService;
