import React from 'react';
import {Spin} from "antd";
import {Card} from "reactstrap";

const LoadingCard = () => {
    return (
        <Card>
            <div className='loading' style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '150px'
            }}>
                <Spin tip="Loading" size="large">
                </Spin>
            </div>
        </Card>
    );
};

export default LoadingCard;