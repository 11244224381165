import { ParentActionTypes } from "./actionTypes";


const initialState = {
    parentListError: null, message: null, isLoadingAddParent: false, isAddNewParentOpen: false
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case ParentActionTypes.PARENT_LIST_GET:
            state = {
                ...state,
                parentList: null,
                parentListError: null
            }
            break;
        case ParentActionTypes.PARENT_LIST_GET_SUCCESSFUL:
            state = {
                ...state,
                parentList: action.payload,
                parentListError: null
            }
            break;
        case ParentActionTypes.PARENT_LIST_GET_ERROR:
            state = {
                ...state,
                parentList: null,
                parentListError: action.payload
            }
            break;
        case ParentActionTypes.PARENT_ADD_MODAL:
            state = {
                ...state,
                isAddNewParentOpen: action.payload,
            }
            break;
        case ParentActionTypes.PARENT_ADD_MODAL_SUCCESS:
            state = {
                ...state,
                isAddNewParentOpen: action.payload,
            }
            break;
        case ParentActionTypes.PARENT_ADD:
            state = {
                ...state,
                isLoadingAddParent: true
            }
            break;
        case ParentActionTypes.PARENT_ADD_SUCCESSFUL:
            state = {
                ...state,
                isLoadingAddParent: false,
                isAddNewParentOpen: false,
            }
            break;
        case ParentActionTypes.PARENT_ADD_ERROR:
            state = {
                ...state,
                isLoadingAddParent: false,
                isAddNewParentOpen: false,
            }
            break;
        case ParentActionTypes.PARENT_REMOVE_SUCCESS:
            state = {
                ...state,
                isLoadingAddParent: false,
                isAddNewParentOpen: false,
            }
            break;
        case ParentActionTypes.PARENT_REMOVE_ERROR:
            state = {
                ...state,
                isLoadingAddParent: false,
                isAddNewParentOpen: false,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default account;
