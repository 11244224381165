import { AdminActionTypes } from "./actionTypes";

export const getAdmins = () => {
  return {
    type: AdminActionTypes.ADMIN_LIST_GET,
    payload: null,
  };
};

export const getAdminsSuccessful = (payload) => {
  return {
    type: AdminActionTypes.ADMIN_LIST_GET_SUCCESSFUL,
    payload: payload,
  };
};

export const getAdminsError = (payload) => {
  return {
    type: AdminActionTypes.ADMIN_LIST_GET_ERROR,
    payload,
  };
};

export const addAdmin = (admin) => {
  return {
    type: AdminActionTypes.ADMIN_ADD,
    payload: admin,
  };
};

export const addAdminSuccessful = (payload) => {
  return {
    type: AdminActionTypes.ADMIN_ADD_SUCCESSFUL,
    payload,
  };
};

export const addAdminError = (payload) => {
  return {
    type: AdminActionTypes.ADMIN_ADD_ERROR,
    payload,
  };
};
export const showAddAdminModal = (showModal) => {
  return {
    type: AdminActionTypes.ADMIN_ADD_MODAL,
    payload: showModal,
  };
};
export const showAddAdminModalSuccess = (showModal) => {
  return {
    type: AdminActionTypes.ADMIN_ADD_MODAL_SUCCESS,
    payload: showModal,
  };
};

export const removeAdmin = (admin) => {
  return {
    type: AdminActionTypes.ADMIN_REMOVE,
    payload: admin,
  };
};
export const removeAdminSuccess = (admin) => {
  return {
    type: AdminActionTypes.ADMIN_REMOVE_SUCCESS,
    payload: admin,
  };
};
export const removeAdminError = (admin) => {
  return {
    type: AdminActionTypes.ADMIN_REMOVE_ERROR,
    payload: admin,
  };
};
export const editAdmin = (admin) => {
  return {
    type: AdminActionTypes.ADMIN_EDIT,
    payload: admin,
  };
};
export const editAdminSuccess = (admin) => {
  return {
    type: AdminActionTypes.ADMIN_EDIT_SUCCESS,
    payload: admin,
  };
};
export const editAdminError = (admin) => {
  return {
    type: AdminActionTypes.ADMIN_EDIT_ERROR,
    payload: admin,
  };
};

export const getStatistic = (payload) => {
  return {
    type: AdminActionTypes.ADMIN_STATISTIC_DATA,
    payload: payload,
  };
};

export const getStatisticSuccessful = (payload) => {
  return {
    type: AdminActionTypes.ADMIN_STATISTIC_DATA_SUCCESS,
    payload: payload,
  };
};

export const getStatisticError = (payload) => {
  return {
    type: AdminActionTypes.ADMIN_STATISTIC_DATA_ERROR,
    payload,
  };
};
