import { BlogActionTypes } from "./actionTypes";


const initialState = {
    blogListError: null, message: null, isLoadingAddBlog: false, isAddNewBlogOpen: false
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case BlogActionTypes.BLOG_LIST_GET:
            state = {
                ...state,
                blogList: null,
                blogListError: null
            }
            break;
        case BlogActionTypes.BLOG_LIST_GET_SUCCESSFUL:
            state = {
                ...state,
                blogList: action.payload,
                blogListError: null
            }
            break;
        case BlogActionTypes.BLOG_LIST_GET_ERROR:
            state = {
                ...state,
                blogList: null,
                blogListError: action.payload
            }
            break;
        case BlogActionTypes.BLOG_ADD_MODAL:
            state = {
                ...state,
                isAddNewBlogOpen: action.payload,
            }
            break;
        case BlogActionTypes.BLOG_ADD_MODAL_SUCCESS:
            state = {
                ...state,
                isAddNewBlogOpen: action.payload,
            }
            break;
        case BlogActionTypes.BLOG_ADD:
            state = {
                ...state,
                isLoadingAddBlog: true
            }
            break;
        case BlogActionTypes.BLOG_ADD_SUCCESSFUL:
            state = {
                ...state,
                isLoadingAddBlog: false,
                isAddNewBlogOpen: false,
            }
            break;
        case BlogActionTypes.BLOG_ADD_ERROR:
            state = {
                ...state,
                isLoadingAddBlog: false,
                isAddNewBlogOpen: false,
            }
            break;
        case BlogActionTypes.BLOG_REMOVE_SUCCESS:
            state = {
                ...state,
                blogList: state?.blogList.filter(blog => blog._id !== action.payload),
                isLoadingAddBlog: false,
                isAddNewBlogOpen: false,
            }
            break;
        case BlogActionTypes.BLOG_REMOVE_ERROR:
            state = {
                ...state,
                isLoadingAddBlog: false,
                isAddNewBlogOpen: false,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default account;