import { PetActionTypes } from "./actionTypes";


const initialState = {
    petListError: null, message: null, isLoadingAddPet: false, isAddNewPetOpen: false
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case PetActionTypes.PET_LIST_GET:
            state = {
                ...state,
                petList: null,
                petListError: null
            }
            break;
        case PetActionTypes.PET_LIST_GET_SUCCESSFUL:
            state = {
                ...state,
                petList: action.payload,
                petListError: null
            }
            break;
        case PetActionTypes.PET_LIST_GET_ERROR:
            state = {
                ...state,
                petList: null,
                petListError: action.payload
            }
            break;
        case PetActionTypes.PET_ADD_MODAL:
            state = {
                ...state,
                isAddNewPetOpen: action.payload,
            }
            break;
        case PetActionTypes.PET_ADD_MODAL_SUCCESS:
            state = {
                ...state,
                isAddNewPetOpen: action.payload,
            }
            break;
        case PetActionTypes.PET_ADD:
            state = {
                ...state,
                isLoadingAddPet: true
            }
            break;
        case PetActionTypes.PET_ADD_SUCCESSFUL:
            state = {
                ...state,
                isLoadingAddPet: false,
                isAddNewPetOpen: false,
            }
            break;
        case PetActionTypes.PET_ADD_ERROR:
            state = {
                ...state,
                isLoadingAddPet: false,
                isAddNewPetOpen: false,
            }
            break;
        case PetActionTypes.PET_REMOVE_SUCCESS:
            state = {
                ...state,
                isLoadingAddPet: false,
                isAddNewPetOpen: false,
            }
            break;
        case PetActionTypes.PET_REMOVE_ERROR:
            state = {
                ...state,
                isLoadingAddPet: false,
                isAddNewPetOpen: false,
            }
            break;
        case PetActionTypes.PET_SHELTER_ADOPTED_SUCCESS:
            state = {
                ...state,
                isLoadingAddPet: false,
                isAddNewPetOpen: false,
            }
            break;
        case PetActionTypes.PET_SHELTER_ADOPTED_ERROR:
            state = {
                ...state,
                isLoadingAddPet: false,
                isAddNewPetOpen: false,
                petListError: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default account;
