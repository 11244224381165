import React, {useEffect, useRef, useState} from 'react';
import FormWorkSchedule from "./form";
import {Modal} from "reactstrap";
import dayjs from "dayjs";
import axios from "axios";
import baseURL from "../../config";
import WorkScheduleDates from "./WorkScheduleDates";
import {Alert, Col, Row} from "antd";

const WorkSchedule = ({professional, isWorkScheduleModalOpen, onCancel}) => {
  const submitButtonRef = useRef();
  const [professionalWorkSchedule, setProfessionalWorkSchedule] = useState(null)
  const [error, setError] = useState({type: '', message: ''})

  useEffect(() => {
    // getProfessional()
    setProfessionalWorkSchedule(professional)
  }, [professional])

  useEffect(() => {
    setTimeout(() => {
      setError({type: '', message: ''})
    }, 5000)
  }, [error]);

  const getProfessional = async () => {
    // const data = await getWorkScheduleByProfessional(professionalId)
    // setProfessionalWorkSchedule(data.workSchedule)
  }
  const onSubmit = async (formData) => {
    formData.address = JSON.parse(formData.address).address
    if (new Date(formData.dateFrom) >= new Date(formData.dateTo)) {
      setError({type: 'error', message: 'Date cannot be less than or equal to the start date'})
      setTimeout(() => {
        setError({type: '', message: ''})
      }, 5000)
    }
    formData.dateRange = {
      from: dayjs(formData.dateFrom).format('YYYY-MM-DD'),
      to: dayjs(formData.dateTo).format('YYYY-MM-DD'),
    }
    formData.startTime = Number(dayjs(formData.startTime).format('HHmm'))
    formData.endTime = Number(dayjs(formData.endTime).format('HHmm'))
    formData.roleFromForm = professional.role
    try {
      const res = await axios({
        crossdomain: true,
        method: "post",
        url: `${baseURL}/api/v1/work-schedule/${professional?._id}`,
        data: formData,
        headers: {
          "Access-Control-Allow-Origin": `http://localhost:4242/api/v1/work-schedule/${professional._id}`,
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then(res => res.data)
      if (res.status === 'success') {
        onCancel()
      }
    } catch (e) {
      if (e.request.status === 416) {
        setError({type: 'error', message: e.request.response})
      } else {
        setError({type: 'error', message: 'Something went wrong, please contact office support.'})
      }
    }

  }

  return (
    <Modal scrollable={true} style={{height: 700, width: '70%'}} size="lg" isOpen={isWorkScheduleModalOpen}
           toggle={onCancel}>
      <Alert type={error.type} message={error.message} className={"position-absolute"} />
      <div className="modal-header">
        <h5 className="modal-title mt-0">Update Services</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {professionalWorkSchedule ?
          <div>
            <FormWorkSchedule {...{professionalWorkSchedule, onSubmit, submitButtonRef}} />

            <Row>
              <Col xs={24}>
                <WorkScheduleDates professionalId={professional._id} setError={setError}/>
              </Col>
            </Row>
          </div> :
          <div className='loading'>Loading...</div>}

      </div>
      <div className="modal-footer d-flex align-items-center p-3">
        <div className="right">
          <button
            type="button"
            onClick={onCancel}
            className="btn btn-secondary waves-effect mr-1"
            data-dismiss="modal"
          >
            Cancel
          </button>
          {professionalWorkSchedule && <button
            type="button"
            onClick={() => submitButtonRef.current.click()}
            className="btn btn-warning waves-effect waves-light"
          >
            Update
          </button>}
        </div>
      </div>
    </Modal>
  );
};

export default WorkSchedule;
