import React from 'react'
import IdNumber from './idNumber'
import IdCardNumber from './idCardNumber'
import IdCardValidTo from './idCardValidTo'
import DocumentPicture from './documentPicture'

function IdCardComponents() {
	return (
		<div>
			<IdNumber />
			<IdCardNumber />
			<IdCardValidTo />
			<DocumentPicture />
		</div>
	)
}

export default IdCardComponents
