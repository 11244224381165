import { BlogBgActionTypes } from "./actionTypes";


const initialState = {
    blogListError: null, message: null, isLoadingAddBlog: false, isAddNewBlogOpen: false
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case BlogBgActionTypes.BLOG_BG_LIST_GET:
            state = {
                ...state,
                blogBgList: null,
                blogBgListError: null 
            }
            break;
        case BlogBgActionTypes.BLOG_BG_LIST_GET_SUCCESSFUL:
            state = {
                ...state,
                blogBgList: action.payload,
                blogBgListError: null
            }
            break;
        case BlogBgActionTypes.BLOG_BG_LIST_GET_ERROR:
            state = {
                ...state,
                blogBgList: null,
                blogBgListError: action.payload
            }
            break;
        case BlogBgActionTypes.BLOG_BG_ADD_MODAL:
            state = {
                ...state,
                isAddNewBlogBgOpen: action.payload,
            }
            break;
        case BlogBgActionTypes.BLOG_BG_ADD_MODAL_SUCCESS:
            state = {
                ...state,
                isAddNewBlogBgOpen: action.payload,
            }
            break;
        case BlogBgActionTypes.BLOG_BG_ADD:
            state = {
                ...state,
                isLoadingAddBlogBg: true
            }
            break;
        case BlogBgActionTypes.BLOG_BG_ADD_SUCCESSFUL:
            state = {
                ...state,
                isLoadingAddBlogBg: false,
                isAddNewBlogBgOpen: false,
            }
            break;
        case BlogBgActionTypes.BLOG_BG_ADD_ERROR:
            state = {
                ...state,
                isLoadingAddBlogBg: false,
                isAddNewBlogBgOpen: false,
            }
            break;
        case BlogBgActionTypes.BLOG_BG_REMOVE_SUCCESS:
            state = {
                ...state,
                blogBgList: state?.blogBgList.filter(blogBg => blogBg._id !== action.payload),
                isLoadingAddBlog: false,
                isAddNewBlogBgOpen: false,
            }
            break;
        case BlogBgActionTypes.BLOG_BG_REMOVE_ERROR:
            state = {
                ...state,
                isLoadingAddBlogBg: false,
                isAddNewBlogBgOpen: false,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default account;