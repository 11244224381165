import React from "react";
import {Modal} from "reactstrap";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import i18next from "i18next";
import moment from "moment";

const ViewUsedCoupon = ({viewCoupon, showUsedCoupon, onCancel}) => {


  const renderTable = () => {
    const {usedCoupon} = viewCoupon
    return usedCoupon.map((uc, i) => {
      const professional = uc.bookingId?.professionalID
      const parent = uc.bookingId?.parentID
      const pet = uc.bookingId?.petID
      return <Tr key={i}>
        <Td>{uc?.originalAmount}</Td>
        <Td>{professional.role} {professional.name} {professional.surname} {professional.telephone}</Td>
        <Td>{moment(uc.bookingId.time).format('DD/MM/YYYY HH:mm')}</Td>
        <Td>{i18next.t(uc.bookingId.description)}</Td>
        <Td>{pet.name}</Td>
        <Td>{parent.role} {parent.name} {parent.surname} {parent.telephone}</Td>
      </Tr>
    })
  }

  return <Modal isOpen={showUsedCoupon} toggle={onCancel} style={{maxWidth: '75%', margin: '5px auto'}}>
    <div className="modal-header">
      <h5 className="modal-title mt-0 text-center">{viewCoupon?.code}</h5>
      <button
        type="button"
        onClick={onCancel}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
      {viewCoupon?.usedCoupon.length ? <Table className="table">
          <Thead>
            <Tr>
              <Th>Original Amount</Th>
              <Th>Professional</Th>
              <Th>Service Date</Th>
              <Th>Service</Th>
              <Th>Pet</Th>
              <Th>Parent</Th>
            </Tr>
          </Thead>
          <Tbody>
            {renderTable()}
          </Tbody>
        </Table> :
        <div className="text-center">No data for this voucher!</div>}
    </div>
  </Modal>
}

export default ViewUsedCoupon
