import { post, get } from "./api_helper";
import ApiService from "./ApiHelper";

const postLogin = data => post("/api/v1/admins/login", data);
const autoLoginMe = (payload) => {
  return ApiService.request(
    "/api/v1/users/me",
    "get",
    payload

  )
};



export {
  postLogin,
  autoLoginMe
};
