import Breadcrumbs from "../../components/Common/Breadcrumb";
import React, {useEffect, useState} from "react";
import {Card, Col, Row, Space} from "antd";
import {Button, CardBody, CardHeader} from "reactstrap";
import AddCoupons from "./AddCoupons";
import ListCoupons from "./List/ListCoupons";
import Paging from "../../components/Pagination/Pagination";
import {connect} from "react-redux";
import {getCoupons} from "../../store/coupons/actions";
import Search from "../../components/Search/Search";
import ViewUsedCoupon from "./List/ViewUsedCoupon";

const Coupons = ({couponsList, getCoupons}) => {

  const options = [
    {keySearch: 'couponCode', titleSearch: 'Code'},
    {keySearch: 'forProfessionals.name_forProfessionals.surname', titleSearch: 'Professional Name'},
    {keySearch: 'forParents.name_forParents.surname', titleSearch: 'Parent Name'},
    {keySearch: 'validDateFrom', titleSearch: 'Date From'},
    {keySearch: 'validDateTo', titleSearch: 'Date To'},
  ]

  const [onNewCouponModal, setOnNewCouponModal] = useState(false)
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [currentCoupon, setCurrentCoupon] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [viewUsedCoupon, setViewUsedCoupon] = useState(null)
  const [showUsedCoupon, setShowUsedCoupon] = useState(false)
  const [search, setSearch] = useState({text: '', filter: '', tagsFilters: {}})

  useEffect(() => {
    getCoupons({page: 1})
  }, [])

  const refreshPage = (e) => {
    e.preventDefault()
    setSearch({text: '', filter: '', tagsFilters: {}})
    getCoupons({page: 1})
  }
  const onSubmit = (e, search) => {
    e.preventDefault()
    if ((search.text && search.filter)) {
      getCoupons({search: JSON.stringify(search), page: 1});
    } else {
      getCoupons({page: 1});
    }
    setFilterModalOpen(!filterModalOpen)
    setCurrentPage(1)
    setSearch(search)
  }


  return <React.Fragment>
    <div className="page-content w-100">
      <div className="container-fluid">
        <Breadcrumbs title="Coupons" breadcrumbItem="Coupons"/>

        <Row>
          <Col>
            <Card>
              <CardHeader>
                <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light"
                  onClick={() => {
                    setOnNewCouponModal(!onNewCouponModal)
                    setCurrentCoupon({})
                  }}
                >
                  {" "}
                  <i className=""> </i> Add Coupon
                </Button>
              </CardHeader>
              <div className="d-inline-flex ant-row-space-between align-items-center w-100">
                <Paging setPage={setCurrentPage} currentPage={currentPage} pages={couponsList?.pages}/>
                <Space direction="horizontal" className="mr-4">
                  <Button type={'button'} className='btn btn-info waves-effect waves-light search-btn'
                          onClick={() => setFilterModalOpen(!filterModalOpen)}><i
                    className="bx bx-filter-alt align-middle"></i></Button>
                  {(search.text || Object.keys(search.tagsFilters).length > 0) &&
                    <Button type={'button'} className='btn btn-dark waves-effect waves-light search-btn'
                            onClick={refreshPage}><i
                      className='bx bx-revision align-middle'></i></Button>}
                </Space>
              </div>
              <CardBody>

                <ListCoupons setCouponEdit={setCurrentCoupon}
                             setOnEditCouponModalOpen={setOnNewCouponModal}
                             showUsedCoupon={(usedCoupon) => {
                               setViewUsedCoupon(usedCoupon)
                               setShowUsedCoupon(!showUsedCoupon)
                             }}
                             couponsList={couponsList?.coupons}
                             used={couponsList?.usedCouponCount}/>

              </CardBody>
              <div className="d-inline-flex ant-row-space-between align-items-center">
                <Paging setPage={setCurrentPage} currentPage={currentPage} pages={couponsList?.pages}/>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
    <AddCoupons currentCoupon={currentCoupon} isNewCouponModalOpen={onNewCouponModal}
                onCancel={() => setOnNewCouponModal(!onNewCouponModal)}/>
    {showUsedCoupon ? <ViewUsedCoupon viewCoupon={viewUsedCoupon} showUsedCoupon={showUsedCoupon} onCancel={() => setShowUsedCoupon(false)}/> : null}
    <Search searchModalOpen={filterModalOpen}
            options={options}
            filterData={['validDateFrom', 'validDateTo']}
            searchVar={search}
            tags={[]}
            onSubmit={onSubmit}
            onCancel={() => setFilterModalOpen(false)}/>
  </React.Fragment>
}
const mapStateToProps = (state) => {
  const {Coupons} = state
  return Coupons
}
export default connect(mapStateToProps, {getCoupons})(Coupons)
