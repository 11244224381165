import {TranslationTypes} from "./actionTypes";
import {ProfessionalActionTypes} from "../professionals/actionTypes";

const initialState = {
    translationListError: null,
    message: null,
    translationList: null,
    isLoadingAddTranslation: false,
    isAddNewTranslationOpen: false
}

const translation = (state = initialState, action) => {

    switch (action.type) {
        case TranslationTypes.TRANSLATION_GET:
            state = {
                ...state,
                translationList: null,
                translationListError: null
            }
            break;
        case TranslationTypes.TRANSLATION_SUCCESSFUL:
            state = {
                ...state,
                translationList: action.payload,
                translationListError: null
            }
            break;
        case TranslationTypes.TRANSLATION_ERROR:
            state = {
                ...state,
                translationList: null,
                translationListError: action.payload
            }
            break;
        case TranslationTypes.TRANSLATION_GET_TYPE:
            state = {
                ...state,
                translationList: null,
                translationListError: null
            }
            break;
        case TranslationTypes.TRANSLATION_TYPE_SUCCESSFUL:
            state = {
                ...state,
                translationList: action.payload,
                translationListError: null
            }
            break;
        case TranslationTypes.TRANSLATION_TYPE_ERROR:
            state = {
                ...state,
                translationList: null,
                translationListError: action.payload
            }
            break;
        case TranslationTypes.TRANSLATION_ADD:
            state = {
                ...state,
                translationList: null,
                translationListError: null
            }
            break;
        case TranslationTypes.TRANSLATION_ADD_SUCCESSFUL:
            state = {
                ...state,
                translationList: action.payload,
                translationListError: null
            }
            break;
        case TranslationTypes.TRANSLATION_ADD_ERROR:
            state = {
                ...state,
                translationList: null,
                translationListError: action.payload
            }
            break;
        case TranslationTypes.TRANSLATION_EDIT:
            state = {
                ...state,
                translationList: null,
                translationListError: null
            }
            break;
        case TranslationTypes.TRANSLATION_EDIT_SUCCESSFUL:
            state = {
                ...state,
                translationList: action.payload,
                translationListError: null
            }
            break;
        case TranslationTypes.TRANSLATION_EDIT_ERROR:
            state = {
                ...state,
                translationList: null,
                translationListError: action.payload
            }
            break;
        case TranslationTypes.TRANSLATION_REMOVE:
            state = {
                ...state,
                translationList: action.payload,
                translationListError: null
            }
            break;
        case TranslationTypes.TRANSLATION_REMOVE_SUCCESSFUL:
            state = {
                ...state,
                translationList: action.payload,
                translationListError: null
            }
            break;
        case TranslationTypes.TRANSLATION_REMOVE_ERROR:
            state = {
                ...state,
                translationList: null,
                translationListError: null
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default translation;
