import {UserActionTypes} from "./actionTypes";
import fileDownload from "js-file-download";

const initialState = {
    usersListError: null, message: null, isLoadingAddUsers: false, isAddNewUsersOpen: false, usersProfessionalList: [], usersParentList: []
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case UserActionTypes.USERS_PROFESSIONAL_LIST_GET:
            state = {
                ...state,
                usersList: null,
                usersListError: null
            }
            break;
        case UserActionTypes.USERS_PROFESSIONAL_LIST_GET_SUCCESSFUL:
            state = {
                ...state,
                usersProfessionalList: action.payload,
                usersListError: null
            }
            break;
        case UserActionTypes.USERS_PROFESSIONAL_LIST_GET_ERROR:
            state = {
                ...state,
                usersList: null,
                usersListError: action.payload
            }
            break;
        case UserActionTypes.USERS_PARENT_LIST_GET:
            state = {
                ...state,
                usersList: null,
                usersListError: null
            }
            break;
        case UserActionTypes.USERS_PARENT_LIST_GET_SUCCESSFUL:
            state = {
                ...state,
                usersParentList: action.payload,
                usersListError: null
            }
            break;
        case UserActionTypes.USERS_PARENT_LIST_GET_ERROR:
            state = {
                ...state,
                usersList: null,
                usersListError: action.payload
            }
            break;case UserActionTypes.USERS_PARENT_LIST_GET_EXEL:
            state = {
                ...state,
                usersList: null,
                usersListError: null
            }
            break;
        case UserActionTypes.USERS_PARENT_LIST_GET_EXEL_SUCCESSFUL:
            const filename = new Date().toISOString() + ".xml";
            console.log(action)
            fileDownload(action.payload, filename);
            break;
        case UserActionTypes.USERS_PARENT_LIST_GET_EXEL_ERROR:
            state = {
                ...state,
                usersListError: action.payload
            }
            break;
    }
    return state;
}

export default account;
