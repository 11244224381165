import React, {Component} from "react";
import {Row, Col, Label, FormGroup, Modal} from "reactstrap";
import {AvForm, AvField} from "availity-reactstrap-validation";
import FileUpload from "../../../components/FileUpload/FileUpload";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {Select} from "antd";
import SunEditor from "suneditor-react";

const {Option} = Select;

function decodeHTMLEntities(text) {
  var entities = [
    ["amp", "&"],
    ["apos", "'"],
    ["#x27", "'"],
    ["#x2F", "/"],
    ["#39", "'"],
    ["#47", "/"],
    ["lt", "<"],
    ["gt", ">"],
    ["nbsp", " "],
    ["quot", '"'],
  ];

  for (var i = 0, max = entities.length; i < max; ++i)
    text = text.replace(
      new RegExp("&" + entities[i][0] + ";", "g"),
      entities[i][1]
    );

  return text;
}

class BlogEdit extends Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onFileUploaded = this.onFileUploaded.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
    this.onCategorySelect = this.onCategorySelect.bind(this);


    let blog = {
      id: "",
      title: "",
      authorName: "",
      category: "",
      description: "",
      media: "",
      language: "",
      name: "",
      surname: "",
    };
    this.state = {blog};
  }

  componentDidMount() {
    const blogData = this.props.data;
    let {blog} = this.state;
    blog = {
      id: blogData._id,
      title: blogData.title,
      authorName: blogData.authorName,
      category: blogData.category,
      description: decodeHTMLEntities(blogData.description),
      media: blogData.media,
      language: blogData.language,
      name: blogData.name,
      surname: blogData.surname,
    };
    this.setState({blog});
  }

  onCancel() {
    this.props.onCancel();
  }

  onFileUploaded(file) {
    if (file) {
      const {blog} = this.state;
      blog["media"] = file.url;
      this.setState({blog});
    }
  }

  onCategorySelect = (category) => {
    this.setState({
      blog: {...this.state.blog, category: category},
    });
  };

  onSubmit() {
    const {blog} = this.state;
    this.props.onSubmit(blog);
    this.props.onCancel();
  }

  handleQuillChange(e) {
    const {blog} = this.state;
    blog.description = e;
    this.setState({blog});
  }

  handleChange(e) {
    const {name, value} = e.target;
    const {blog} = this.state;
    blog[name] = value;
    this.setState({blog});
  }

  render() {
    const {blog} = this.state;
    return (
      <Modal isOpen={this.props.isEditModalOpen} toggle={this.onCancel}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Blog</h5>
          <button
            type="button"
            onClick={this.onCancel}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <AvForm className="needs-validation">
            <FormGroup>
              <Label htmlFor="validationCustom01">Author name</Label>
              <AvField
                name="authorName"
                placeholder="Author name"
                type="text"
                errorMessage="Please write Author name"
                className="form-control"
                onChange={this.handleChange}
                value={blog.authorName}
                validate={{required: {value: true}}}
                id="validationCustom01"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="validationCustom01">Title</Label>
              <AvField
                name="title"
                placeholder="Title"
                type="text"
                errorMessage="Please write title"
                className="form-control"
                onChange={this.handleChange}
                value={blog.title}
                validate={{required: {value: true}}}
                id="validationCustom01"
              />
            </FormGroup>
            <FormGroup>
              <Select
                value={blog.category}
                placeholder="Select Category"
                style={{width: 160}}
                onChange={this.onCategorySelect}
              >
                {/* <Option value="dog_cat">Dog & Cat</Option> */}
                <Option value="dog">Dog</Option>
                <Option value="cat">Cat</Option>
                <Option value="pet_care">Pet Care</Option>
                <Option value="pet_training">Pet Training</Option>
                <Option value="veterinary">Veterinary</Option>
              </Select>
            </FormGroup>
            <SunEditor height={400} onChange={this.handleQuillChange}
                       name="description"

                       setOptions={
                         {
                           buttonList: [
                             [
                               'undo', 'redo',
                               'font', 'fontSize', 'formatBlock',
                               'paragraphStyle', 'blockquote',
                               'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
                               'fontColor', 'hiliteColor', 'textStyle',
                               'removeFormat',
                               'outdent', 'indent',
                               'align', 'horizontalRule', 'list', 'lineHeight',
                               'table', 'link', 'image', 'video', 'audio', 'codeView'
                             ]
                           ]
                         }
                       }
                       defaultValue={blog.description}
                       style={{height: "400px", paddingBottom: "100px"}}
            />

            <FormGroup>
              <Label>Media</Label>
              <Row>
                <img className="rounded mr-2" width="30%" src={blog.media}/>
                <FileUpload
                  onChange={this.handleChange}
                  onFileUploaded={this.onFileUploaded}
                  type="media"
                ></FileUpload>
              </Row>
            </FormGroup>
          </AvForm>
        </div>

        {this.props.isLoading && (
          <div className="modal-footer">
            <div className="spinner-border text-success m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        {!this.props.isLoading && (
          <div className="modal-footer">
            <button
              type="button"
              onClick={this.onCancel}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.onSubmit}
              className="btn btn-success waves-effect waves-light"
            >
              Accept
            </button>
          </div>
        )}
      </Modal>
    );
  }
}

export default BlogEdit;
