import React, {useEffect} from "react";
import {createBrowserRouter} from "react-router-dom";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Blog from "../pages/Blogs/Blogs";
import Admins from "../pages/Admins/Admins";
import Payment from "../pages/Payment/Payments";
import Professional from "../pages/Professionals/Professionals";
import Parents from "../pages/Parents/Parents";
import Bookings from "../pages/Bookings/Bookings";
import Pets from "../pages/Pets/Pets";
import Accountants from "../pages/Accountant/Accountants";
import Clinics from "../pages/Clinics/Clinics";
import Groomers from "../pages/Groomers/Groomers";
import Root from "../Root";
import App from "../App";
import InitiatedBookings from "../pages/InitiatedBookings/InitiatedBookings";
import Logs from "../pages/Logs/Logs";
import FAQ from "pages/FAQ/FAQ";
import Coupons from "../pages/Coupons/Coupons";
import Translations from "../pages/Translations/Translations";
import Hotels from "../pages/Hotels/Hotels";
import PetSitters from "../pages/PetSitters/PetSitters";
import PetWalkers from "../pages/PetWalker/PetWolkers";
import Invoicing from "../pages/Invoicing/Invoicing";
import ProfPayments from "pages/ProfPayments/ProfPayments";
import ProfReport from "pages/ProfReports/ProfReport";
import Invoices from "../pages/Invoicing/Invoices";
import Receiveds from "../pages/Receiveds/Receiveds";
import BookingsPrepare from "../pages/BookingsPrepare/BookingsPrepare";
import BookingsSubscription from "../pages/BookingsSubscription/BookingsSubscription";
import {userRols} from "../helpers/userRols";
import Shelters from "../pages/Shelter/Shelters";
import ShelterPet from "../pages/ShelterPet/ShelterPet";


export const routerType = ({role}) => {

    // switch (role) {
    //   case userRols.admin:
    //   case userRols.superAdmin:
    //     return router
    //   case userRols.accounting:
    //     return halfRouter
    //   default:
    return router
    // }

}

const halfRouter = createBrowserRouter([
    {
        path: '/',
        element: <Root/>,
        // errorElement: <ErrorPage />,
        children: [{
            path: '',
            element: <App/>,
            children: [{
                path: 'dashboard',
                element: <Dashboard/>

            }, {
                path: 'professional',
                children: [{
                    path: 'vets',
                    element: <Professional/>
                }, {
                    path: 'clinics',
                    element: <Clinics/>
                }]

            }],

        },
            {
                path: "/login",
                element: <Login/>
            },
            {
                path: '/register',
                element: <Register/>
            },
            {
                path: "/logout",
                element: <Logout/>
            },
        ],
    }])


export const router = createBrowserRouter([
    {
        path: '/',
        element: <Root/>,
        // errorElement: <ErrorPage />,
        children: [{
            path: '',
            element: <App/>,
            children: [{
                path: 'dashboard',
                element: <Dashboard/>

            }, {
                path: 'professional',
                children: [{
                    path: 'vets',
                    element: <Professional/>
                }, {
                    path: 'clinics',
                    element: <Clinics/>
                }, {
                    path: 'groomers',
                    element: <Groomers/>
                }, {
                    path: 'hotels',
                    element: <Hotels/>
                }, {
                    path: 'pet-sitters',
                    element: <PetSitters/>
                }, {
                    path: 'pet-walkers',
                    element: <PetWalkers/>
                }, {
                    path: 'services',
                    element: <Translations/>
                }
                ]
            }, {
                path: 'pet-owners',
                children: [{
                    path: 'parents',
                    element: <Parents/>
                }, {
                    path: 'pets',
                    element: <Pets/>
                }]
            }, {
                path: 'shelters',
                children: [{
                    path: '',
                    element: <Shelters/>
                }, {
                    path: 'pets',
                    element: <ShelterPet/>
                }]
            }, {
                path: 'bookings',
                children: [{
                    path: '',
                    element: <Bookings/>
                }, {
                    path: 'pending',
                    element: <BookingsPrepare/>
                }, {
                    path: 'subscription',
                    element: <BookingsSubscription/>
                }, {
                    path: 'coupons',
                    element: <Coupons/>
                }, {
                    path: 'initiated-bookings',
                    element: <InitiatedBookings/>
                }, {
                    path: 'payment',
                    element: <Payment/>
                }, {
                    path: 'accountants',
                    element: <Accountants/>
                }, {
                    path: 'invoicing',
                    element: <Invoicing/>
                }, {
                    path: 'invoices',
                    element: <Invoices/>
                }],
            }, {
                path: 'accounting',
                children: [
                    {
                        path: 'accountants',
                        element: <Accountants/>
                    }, {
                        path: 'invoicing',
                        element: <Invoicing/>
                    }, {
                        path: 'profpayments',
                        element: <ProfPayments/>
                    }, {
                        path: 'report',
                        element: <ProfReport/>
                    }, {
                        path: 'invoices',
                        element: <Invoices/>
                    }, {
                        path: 'received',
                        element: <Receiveds/>
                    }],
            },
                {
                    path: 'blog',
                    element: <Blog/>
                }, {
                    path: 'admins',
                    element: <Admins/>
                },
                {
                    path: "/faq",
                    element: <FAQ/>
                },
                {
                    path: 'logs',
                    element: <Logs/>
                }]
        }],

    },
    {
        path: "/login",
        element: <Login/>
    },
    {
        path: '/register',
        element: <Register/>
    }, {
        path: '/forgot-password',
        element: <ForgetPwd/>
    },
    {
        path: "/logout",
        element: <Logout/>
    },
])

