import ApiService from "../../helpers/ApiHelper";

class ProfessionalService {

    static getProfessionals({type= ''}) {
        return ApiService.request(
          `/api/v1/users/${type? `role/${type}` : type}`,
          "get",
          {}

        )
    }

    static addProfessional(e, payload) {
        e.preventDefault()
        return ApiService.request(
            "/api/v1/blogs/",
            "post",
            payload

        )
    }

    static removeProfessional(id) {
        return ApiService.request(
            `/api/v1/users/${id}`,
            "delete",
            null
        )
    }

    static editProfessional(professional){
        professional.id = professional?.id ? professional?.id : professional?._id
        return ApiService.request(
            `/api/v1/users/${professional.id}`,
            "patch",
            professional,
        )
    }

    static approveProfessional(professional){
        const professionalId = professional.id ? professional.id : professional._id
        return ApiService.request(
            `/api/v1/users/approve/${professionalId}`,
            "patch",
          {status: professional.status, approvedDate: professional.approvedDate},
        )
    }
}

export default ProfessionalService;
