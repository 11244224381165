import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import React, {useState} from "react";
import {connect} from "react-redux";
import moment from "moment";
import {disableCoupon} from "../../../store/coupons/actions";
import {Button} from "reactstrap";
import RemoveList from "../../../components/RemoveList/RemoveList";

const ListCoupons = ({couponsList, used, setCouponEdit, setOnEditCouponModalOpen, showUsedCoupon, disableCoupon}) => {

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [data, setData] = useState(null)

  const removeModalOpen = (coupon) => {
    setIsRemoveModalOpen(!isRemoveModalOpen)

    setData(coupon)
  }

  function renderCoupons() {
    if (couponsList) {
      return couponsList.map((cl, i) => {
        const usedCoupon = used.filter(u => u?.couponId?._id === cl._id);
        return <Tr key={i}>
          <Td data-priority="1">{cl.couponCode}</Td>
          <Td data-priority="3">{cl.description.substring(0, 150)} {cl.description.length > 150 ? '...' : ''}</Td>
          <Td data-priority="1">{cl.amount}{cl.type === 1 ? '%' : 'lv'}</Td>
          <Td data-priority="3">{moment(cl.validDateFrom).format('DD-MM-YYYY')}</Td>
          <Td data-priority="3">{moment(cl.validDateTo).format('DD-MM-YYYY')}</Td>
          <Td
            data-priority="2">{`
            ${usedCoupon.length} / ${cl.couponLimit > 0 ? cl.couponLimit : '\u221E'}`}</Td>

          <Td data-priority="1">{cl.active ? 'Yes' : 'No'}</Td>
          <Td>
            <div className="d-flex ant-row-space-between">
              <Button color='primary' className='ml-1' onClick={() => {
                setCouponEdit(cl);
                setOnEditCouponModalOpen(true)
              }}><i
                className="bx bxs-edit font-size-16 align-middle"></i></Button>
              <Button color='warning' className='ml-1' onClick={() => showUsedCoupon({usedCoupon, code: cl.couponCode})}><i
                className="bx bxs-discount font-size-16 align-middle"></i></Button>
              <Button color={cl.active ? 'danger' : 'success'} className='ml-1'
                      onClick={() => removeModalOpen(cl)}>{cl.active ? <i
                className="bx bxs-dislike font-size-16 align-middle"></i> : <i
                className="bx bxs-like font-size-16 align-middle"></i>}</Button>
            </div>
          </Td>
        </Tr>
      });
    }
  }

  return <div className="table-rep-plugin">
    <div className="table-responsive mb-0" data-pattern="priority-columns">
      <Table
        id="tech-companies-1"
        className="table table-striped table-bordered"
      >
        <Thead>
          <Tr>
            <Th data-priority="1" style={{minWidth: '100px'}}>Code </Th>
            <Th data-priority="3">Description</Th>
            <Th data-priority="3" style={{minWidth: '100px'}}>Amount</Th>
            <Th data-priority="3" style={{minWidth: '150px'}}>Valid from</Th>
            <Th data-priority="3" style={{minWidth: '150px'}}>Valid to</Th>
            <Th data-priority="3" style={{minWidth: '100px'}}>Usage</Th>
            <Th data-priority="1" style={{minWidth: '100px'}}>Active</Th>
            <Th data-priority="3" style={{minWidth: '170px'}}>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {renderCoupons()}
        </Tbody>
      </Table>
    </div>
    <RemoveList isRemoveModalOpen={isRemoveModalOpen}
                title={`Do you want to ${data?.active ? 'deactivate' : 'activate'} the coupon?`}
                subTitle={data?.active ? 'Deactivate' : 'Activate'}
                data={data?._id}
                status={'DISABLE'}
                onCancel={() => setIsRemoveModalOpen(false)}
                onSubmit={(id) => {
                  disableCoupon({id})
                  setIsRemoveModalOpen(false)
                }}/>
  </div>
}

export default connect(() => {
  return {}
}, {disableCoupon})(ListCoupons)
