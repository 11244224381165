import {useEffect, useState} from "react";
import {Button, CardFooter, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import GeneralTab from "./GeneralTab";
import RestrictionTab from "./RestrictionTab";
import {connect} from "react-redux";
import {getTranslation} from "../../../store/translations/actions";
import LimitTab from "./LimitTab";
import {getUsersProfessionals, getUsersParents} from "../../../store/users/actions";
import moment from "moment";
import {createCoupon, updateCoupon} from "../../../store/coupons/actions";
import {initialCoupon} from "../../../store/coupons/reducer";

const CouponsTabs = ({couponEdit, usersProfessionalList, usersParentList, onCancel, getTranslation, getUsersProfessionals, getUsersParents, createCoupon, updateCoupon}) => {
  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  // State Coupon
  const [coupon, setCoupon] = useState({...initialCoupon, ...couponEdit});
  const categoriesOptions = ['Groomer', 'Vet', 'Clinic', 'Hotels']

  useEffect(() => {
    getTranslation({type: 'normal'})
    const addCouponIfNotCompleted = JSON.parse(localStorage.getItem('coupon'))
    if (addCouponIfNotCompleted) {
      setCoupon(addCouponIfNotCompleted);
    }
  }, [])
  // Set State Coupon
  const handleChange = ({target}) => {
    let {name, value} = target
    const mustBeANumber = ['amount',
      'couponLimit',
      'limitPerUser']
    if (
      (mustBeANumber.includes(name))
      && isNaN(Number(value))) {
      console.error(`${name} must be a number`)
      return
    } else if (mustBeANumber.includes(name)) {
      value = Number(value)
    }
    if(name === 'validDateTo') {
      coupon.serviceValidDateTo = moment(value.toString()).add(14, 'd').toDate()
    }
    coupon[name] = value
    setCoupon({...coupon})
  }

  const getFiltratedProfessionalOptions = (data) => {
    if (data.length >= 3) {
      const {professional, excludeProfessional} = coupon
      let role = 'professionals'
      if(professional && professional.length > 0) {
        role = professional.map(p => p.toUpperCase());
      } else if(excludeProfessional && excludeProfessional.length > 0 ) {
        role = categoriesOptions.filter(p => !excludeProfessional.map(exp => exp.toLowerCase()).includes(p.toLowerCase())).map(p => p.toUpperCase())
      }
      getUsersProfessionals({role, searchChars: data})
    }
  }
  const getFiltratedParentsOptions = (data) => {
    if (data.length >= 3) {
      getUsersParents({role: 'PARENT', searchChars: data})
    }
  }

  // Toggle active state for Tab
  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }
  const resetButton = (e) => {
    e.preventDefault()
    localStorage.removeItem('coupon')
    setCoupon(initialCoupon)
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (currentActiveTab < 3) {
      toggle((Number(currentActiveTab) + 1).toString())
      localStorage.setItem('coupon', JSON.stringify(coupon))
    } else {
      localStorage.removeItem('coupon')
      if(coupon.forProfessionals.length) {
        if (Array.isArray(coupon.forProfessionals) && typeof coupon.forProfessionals[0] === 'string') {
          coupon.forProfessionals = coupon.forProfessionals.map(pl => pl.split(' ').filter(Boolean).pop())
        } else if (coupon.forProfessionals[0]._id) {
          coupon.forProfessionals = coupon.forProfessionals.map(fp => fp._id)
        }
      }
      if(coupon.forParents.length) {
        if (Array.isArray(coupon.forParents) && typeof coupon.forParents[0] === 'string') {
          coupon.forParents = coupon.forParents.map(pl => pl.split(' ').filter(Boolean).pop())
        } else if (coupon.forParents[0]._id) {
          coupon.forParents = coupon.forParents.map(fp => fp._id)
        }
      }
      if(coupon._id) {
        updateCoupon(coupon)
      } else {
        createCoupon(coupon)
      }
      onCancel()
    }
  }
  return <div>
    <Nav tabs>
      <NavItem>
        <NavLink
          className={currentActiveTab === '1' ? 'active' : ''}
          onClick={() => {
            toggle('1');
          }}
        >
          General
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={currentActiveTab === '2' ? 'active' : ''}
          onClick={() => {
            toggle('2');
          }}
        >
          Restrictions
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={currentActiveTab === '3' ? 'active' : ''}
          onClick={() => {
            toggle('3');
          }}
        >
          Usage
        </NavLink>
      </NavItem>
    </Nav>
    <TabContent activeTab={currentActiveTab} className={'mt-3'}>
      <TabPane tabId="1">
        <Row>
          <Col sm="12">
            <GeneralTab {...coupon} handleChange={handleChange}/>
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="2">
        <Row>
          <Col sm="12">
            <RestrictionTab {...coupon}
                            handleChange={handleChange}
                            categoriesOptions={categoriesOptions}
                            usersProfessionalList={usersProfessionalList}
                            usersParentList={usersParentList}
                            getFiltratedParentsOptions={getFiltratedParentsOptions}
                            getFiltratedProfessionalOptions={getFiltratedProfessionalOptions}
                            />
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="3">
        <Row>
          <Col sm="12">
            <LimitTab {...coupon} handleChange={handleChange}/>
          </Col>
        </Row>
      </TabPane>
    </TabContent>
    <CardFooter className='d-flex justify-content-end bg-white border-top'>
      <Button color='danger' onClick={resetButton} className='mr-2'>Reset</Button>
      <Button color='primary' onClick={handleSubmit}>{currentActiveTab < 3 ? 'Next' : 'Save'}</Button>
    </CardFooter>
  </div>
}
const mapStateToProps = (state) => {
  const {Users} = state
  return Users
}

export default connect(mapStateToProps, {getTranslation, getUsersProfessionals, getUsersParents, createCoupon, updateCoupon})(CouponsTabs)
