import React, { Component } from "react";
import { Row, Col, Card, CardBody, Button, CardHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import {
  getPayments,
  addPayment,
  showAddPaymentModal,
  removePayment,
  editPayment,
} from "../../store/payment/actions";
import { connect } from "react-redux";
import PaymentList from "./List/PaymentList";
import AddPayment from "./AddPayment";

class Payments extends Component {
  constructor(props) {
    super(props);

    this.onNewPaymentModal = this.onNewPaymentModal.bind(this);
    this.onNewPaymentSubmit = this.onNewPaymentSubmit.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.props.getPayments();
  }

  onNewPaymentModal() {
    this.props.showAddPaymentModal(!this.props.isAddNewPaymentOpen);
  }

  onNewPaymentSubmit(payment) {
    this.props.addPayment(payment);
  }

  onRemove(payment) {
    this.props.removePayment(payment);
  }

  onEdit(payment) {
    this.props.editPayment(payment);
  }

  render() {
    const { paymentList, isLoadingAddPayment, isAddNewPaymentOpen } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Payments" breadcrumbItem="Payments" />

            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <Button
                      color="success"
                      className="btn btn-success waves-effect waves-light"
                      onClick={this.onNewPaymentModal}
                    >
                      {" "}
                      <i className=""> </i> Add Payment
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <PaymentList
                      list={paymentList}
                      onRemoveItem={this.onRemove}
                      onEditItem={this.onEdit}
                      history={this.props.history}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <AddPayment
          isLoading={isLoadingAddPayment}
          isNewPaymentModalOpen={isAddNewPaymentOpen}
          onSubmit={this.onNewPaymentSubmit}
          onCancel={this.onNewPaymentModal}
        />
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Payment } = state;
  return Payment;
};

export default
  connect(mapStatetoProps, {
    getPayments,
    addPayment,
    showAddPaymentModal,
    removePayment,
    editPayment,
  })(Payments);
