import React, { Component } from "react";
import { Row, Col, Label, FormGroup, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

class AdminEdit extends Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    let user = {
      id: "",
      name: "",
      surname: "",
    };
    this.state = { user };
  }

  componentDidMount() {
    const userData = this.props.data;
    let { user } = this.state;
    user = {
      id: userData._id,
      name: userData.name,
      surname: userData.surname,
    };
    this.setState({ user });
  }

  onCancel() {
    this.props.onCancel();
  }



  onSubmit() {
    const { user } = this.state;
    this.props.onSubmit(user);
    this.props.onCancel();
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { user } = this.state;
    user[name] = value;
    this.setState({ user });
  }

  render() {
    const { user } = this.state;
    return (
      <Modal isOpen={this.props.isEditModalOpen} toggle={this.onCancel}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Edit Admin</h5>
          <button
            type="button"
            onClick={this.onCancel}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <AvForm className="needs-validation">
            <FormGroup>
              <Label htmlFor="validationCustom01">Name</Label>
              <AvField
                name="name"
                placeholder="Name"
                type="text"
                errorMessage="Please write name"
                className="form-control"
                onChange={this.handleChange}
                value={user.name}
                validate={{ required: { value: true } }}
                id="validationCustom01"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="validationCustom02">Surname</Label>
              <AvField
                name="surname"
                placeholder="surname"
                type="text"
                errorMessage="Please write surname"
                className="form-control"
                onChange={this.handleChange}
                value={user.surname}
                validate={{ required: { value: true } }}
                id="validationCustom02"
              />
            </FormGroup>
          </AvForm>
        </div>

        {this.props.isLoading && (
          <div className="modal-footer">
            <div className="spinner-border text-success m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        {!this.props.isLoading && (
          <div className="modal-footer">
            <button
              type="button"
              onClick={this.onCancel}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.onSubmit}
              className="btn btn-success waves-effect waves-light"
            >
              Accept
            </button>
          </div>
        )}
      </Modal>
    );
  }
}

export default AdminEdit;
