import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import React from "react";
import moment from "moment";

const LogList = ({list}) => {
  const getLogsList = (list) => {
    if(list) {
      return list.map(log => {
        return <Tr key={log._id}>
          <Td>
            {log?.userID ? `${log.userID.name} ${log.userID.surname}`: log.userID}
          </Td>
          <Td>
            {log.ipAddress}
          </Td>
          <Td>
            {moment(log.createdAt).format('DD/MM/YYYY HH:mm')}
          </Td>
          <Td>
            {log.actionModel}
          </Td>
          <Td>
            {log.description}
          </Td>
        </Tr>
      })
    }
    return undefined;
  };
  return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered"
          >
            <Thead>
              <Tr>
                <Th data-priority="1">Name</Th>
                <Th data-priority="1">IP Address </Th>
                <Th data-priority="3">Created at</Th>
                <Th data-priority="1">Action</Th>
                <Th data-priority="3">Description</Th>
              </Tr>
            </Thead>
            <Tbody>{getLogsList(list)}</Tbody>
          </Table>
        </div>
      </div>
    );
}

export default LogList
