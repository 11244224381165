import React, { Component } from "react";
import { Row, Col, Label, FormGroup, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import FileUpload from "../../components/FileUpload/FileUpload";
import axios from "axios";
import baseURL from "../../config";

class AddPet extends Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    const newPet = {
      name: "",
      breed: "",
      type: "",
      age: "",
      gender: "",
      weight: "",
      dateOfBirth: "",
      castrated:"",
    };
    
    const messageError = {
      messageAlert: ""
    }

    this.state = { newPet, messageError };
  }

  onCancel() {
    const { newPet } = this.state;
    newPet.name = "";
    newPet.breed = "";
    newPet.type = "";
    newPet.age = "";
    newPet.gender = "";
    newPet.weight = "";
    newPet.dateOfBirth = "";
    newPet.castrated = "";
    this.state.value = [];
    this.props.onCancel();
  }

  onSubmit(e) {
    const { newPet, messageError } = this.state;
    if (newPet.password !== newPet.confirmPassword) {
    }

    e.preventDefault();
    fetch(`${baseURL}/api/v1/pets/`, {
      method: "POST",
      body: JSON.stringify(newPet),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }).then((response) => {
      response.json().then((data) => {
        this.setState(newPet);
        this.onCancel();
        window.location.reload();
      });
    });
  }

  handleChange(e) {
    const { name, value } = e.target;
    const { newPet } = this.state;
    newPet[name] = value;
    this.setState({ newPet });
  }

  render() {
    const { newPet } = this.state;
    return (
      <Modal isOpen={this.props.isNewPetModalOpen} toggle={this.onCancel}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add Pet</h5>
          <button
            type="button"
            onClick={this.onCancel}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <AvForm className="needs-validation">
            <FormGroup>
              <Label htmlFor="validationCustom01">Name </Label>
              <AvField
                name="name"
                placeholder="Name"
                type="text"
                errorMessage="Please write name"
                className="form-control"
                onChange={this.handleChange}
                value={newPet.name}
                validate={{ required: { value: true } }}
                id="validationCustom01"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="validationCustom02">Breed</Label>
              <AvField
                name="breed"
                placeholder="breed"
                type="text"
                errorMessage="Please write breed of pet"
                className="form-control"
                onChange={this.handleChange}
                value={newPet.breed}
                validate={{ required: { value: true } }}
                id="validationCustom02"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="validationCustom02">Type</Label>
              <AvField
                name="type"
                placeholder="Type"
                type="Text"
                errorMessage="Please write type of pet"
                className="form-control"
                onChange={this.handleChange}
                value={newPet.type}
                validate={{ required: { value: true } }}
                id="validationCustom02"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="validationCustom02">Age</Label>
              <AvField
                name="age"
                placeholder="age"
                type="number"
                errorMessage="Please write age"
                className="form-control"
                onChange={this.handleChange}
                value={newPet.password}
                validate={{ required: { value: true } }}
                id="validationCustom02"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="validationCustom02">Gender</Label>
              <AvField
                name="gender"
                placeholder="Gender"
                type="text"
                errorMessage="Please write gender of pet"
                className="form-control"
                onChange={this.handleChange}
                value={newPet.gender}
                validate={{ required: { value: true } }}
                id="validationCustom02"
              />
            </FormGroup>
          </AvForm>
        </div>

        {this.props.isLoading && (
          <div className="modal-footer">
            <div className="spinner-border text-success m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}

        {!this.props.isLoading && (
          <div className="modal-footer">
            <button
              type="button"
              onClick={this.onCancel}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={this.onSubmit}
              className="btn btn-success waves-effect waves-light"
            >
              Add
            </button>
          </div>
        )}
      </Modal>
    );
  }
}

export default AddPet;
