import React, { useEffect, useState } from 'react'
import { Button, Input, Modal } from 'antd'

const DeleteModal = ({title, buttonName, close, open, setReasons, disableReasons=false }) => {
	const [text, setText] = useState('')
	const [disableDelete, setDisableDelete] = useState(!disableReasons)


	return (
		<Modal open={open} onOk={close} onCancel={close} footer={null}>
				<div style={{ margin: '15px 0' }}>
					<p
						style={{
							fontWeight: 600,
							fontSize: '21px',
							color: '#224977',
							marginBottom: '40px',
						}}
					>
						{title}
					</p>
					<div style={{ marginTop: '20px' }}></div>
					{!disableReasons ? <Input.TextArea
						rows={4}
						value={text}
						onChange={(e) => {
							setText(e.target.value)
							setDisableDelete(e.target.value.length < 5)
						}}

						allowClear
						showCount
						maxLength={700}
						placeholder={'Leave a message'}
					/> : null}
				</div>

				<Button
					disabled={disableDelete && !disableReasons}
					style={{
						width: '100%',
						height: '50px',
						padding: '10px 25px',
						backgroundColor: 'white',
						color: '#ED4C5C',
						fontSize: '16px',
						fontWeight: 700,
						border: '1px solid #ED4C5C',
						borderRadius: '7px',
						marginTop: '40px',
						opacity: !disableReasons && disableDelete ? '0.5' : '1',
					}}
					onClick={() => {
						setReasons(text)
						setText('')
						close()
					}}
					variant="primary"

					size="large"
				>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<p>{buttonName}</p>
					</div>
				</Button>
		</Modal>
	)
}

export default DeleteModal
