import CouponsTabs from "../Coupons/CouponsTabs/CouponsTabs";
import {FormGroup, Label, Modal} from "reactstrap";
import React, {useEffect, useState} from "react";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Alert, Checkbox, Select} from "antd";
import {connect} from "react-redux";
import {addTranslation} from "../../store/translations/actions";

const {Option} = Select
const AddTranslation = ({isNewTranslationModalOpen, onCancel, addTranslation}) => {

  const [service, setService] = useState({key: '', translations: {en: '', bg: ''}, active: false})
  const [error, setError] = useState({type: '', message: ''})

  const handleChange = ({target}) => {
    const {name, lang, value} = target
    const result = {};
    if (name && lang) {
      service[name][lang] = value
      if (lang === 'en') {
        service.key = value.toLowerCase().trim().replace(/\W+/g, '_')
      }
    } else {
      service[name] = value
    }
    setService(prevState => ({...prevState, ...service}))
  }
  const submitForm = (e) => {
    e.preventDefault()
    const {key, typeOfService, translations} = service
    if(!key || !typeOfService || !translations.en || !translations.bg) {
      setError({type: 'error', message: 'Please write all required fields!'})
      setTimeout(() => {
        setError({type: '', message: ''})
      }, 5000)
      return
    }
    addTranslation(service)
    onCancel()
  }
  return <Modal isOpen={isNewTranslationModalOpen} toggle={onCancel}>
    <Alert {...error} style={{position: "fixed", top: '5px', right: '5px', width: 'auto'}}/>
    <div className="modal-header">
      <h5 className="modal-title mt-0">Add service translation</h5>
      <button
        type="button"
        onClick={onCancel}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
      <AvForm className="needs-validation row">
        <FormGroup className="col-ms-12 col-md-6">
          <Label htmlFor="validationCustom01">Key* </Label>
          <AvField
            disabled
            name="key"
            placeholder="Key"
            type="text"
            style={{backgroundColor: '#D3D3D3'}}
            errorMessage="Please write key"
            className="form-control"
            value={service.key}
            validate={{required: {value: true}}}
            id="validationCustom01"
          />
        </FormGroup>
        <FormGroup className="col-ms-12 col-md-6">
          <Label htmlFor="validationCustom01">Type Service* </Label>
          <Select
            placeholder="Select Type Service"
            style={{width: '100%'}}
            errorMessage="Please type service"
            validate={{required: {value: true}}}
            onChange={(value) => handleChange({target: {name: 'typeOfService', value}})}
            value={service?.typeOfService}
          >
            <Option value="normal">Normal</Option>
            <Option value="grooming">Grooming</Option>
            <Option value="hotel">Hotel</Option>
            <Option value="pet_sitter">Pet Sitter</Option>
            <Option value="pet_walker">Pet Walker</Option>
          </Select>
        </FormGroup>
        <FormGroup className="col-ms-12 col-md-6">
          <Label htmlFor="validationCustom01">Translation EN*</Label>
          <AvField
            name="translations.en"
            placeholder="Translation EN"
            type="text"
            errorMessage="Please write en translation"
            className="form-control"
            onChange={(e) => handleChange({target: {name: 'translations', lang: 'en', value: e.target.value}})}
            value={service?.translations?.en}
            validate={{required: {value: true}}}
            id="validationCustom01"
          />
        </FormGroup>
        <FormGroup className="col-ms-12 col-md-6">
          <Label htmlFor="validationCustom01">Translation BG*</Label>
          <AvField
            name="translations.bg"
            placeholder="Translation BG"
            type="text"
            errorMessage="Please write bg translation"
            className="form-control"
            onChange={(e) => handleChange({target: {name: 'translations', lang: 'bg', value: e.target.value}})}
            value={service?.translations?.bg}
            validate={{required: {value: true}}}
            id="validationCustom01"
          />
        </FormGroup>
        <FormGroup className="col-ms-12 col-md-6">
          <Checkbox name='active' onChange={(e) => handleChange({
            target: {
              name: e.target.name,
              value: e.target.checked
            }
          })}>Active</Checkbox>
        </FormGroup>
      </AvForm>
      <div className="d-flex align-items-center justify-content-end">
        <button
          type="button"
          className="btn btn-success waves-effect waves-light"
          onClick={submitForm}
        >
          Save
        </button>
      </div>
    </div>
  </Modal>
}
const mapStateToProps = () => {
  return {}
}
export default connect( mapStateToProps, {addTranslation})(AddTranslation);
