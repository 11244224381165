import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import UsersService from "./service";
import {UserActionTypes} from "./actionTypes";
import {
    getUsersParentError, getUsersParentExelError, getUsersParentExelSuccessful,
    getUsersParentSuccessful,
    getUsersProfessionalsError,
    getUsersProfessionalsSuccessful
} from "./actions";

function* getProfessionals(payload) {
    try {
        const response = yield call(UsersService.getUsersByParams, {...payload.payload});
        yield put(getUsersProfessionalsSuccessful(response));
    } catch (error) {
        yield put(getUsersProfessionalsError(error));
    }
}
function* getParents(payload) {
    try {
        const response = yield call(UsersService.getUsersByParams, {...payload.payload});
        yield put(getUsersParentSuccessful(response));
    } catch (error) {
        yield put(getUsersParentError(error));
    }
}

function* getParentsExel() {
    try {
        const response = yield call(UsersService.getParentsExel, {});
        yield put(getUsersParentExelSuccessful(response));
    } catch (error) {
        yield put(getUsersParentExelError(error));
    }
}


export function* watchGetProfessionalsUsers() {
    yield takeEvery(UserActionTypes.USERS_PROFESSIONAL_LIST_GET, getProfessionals);
}

export function* watchGetParentUsers() {
    yield takeEvery(UserActionTypes.USERS_PARENT_LIST_GET, getParents);
}
export function* watchGetParentExel() {
    yield takeEvery(UserActionTypes.USERS_PARENT_LIST_GET_EXEL, getParentsExel);
}

function* usersSaga() {
    yield all([
        fork(watchGetProfessionalsUsers),
        fork(watchGetParentUsers),
        fork(watchGetParentExel),
    ]);
}

export default usersSaga;
