export const userRols = {
  parent: 'PARENT',
  vet: 'VET',
  clinic: 'CLINIC',
  groomer: 'GROOMER',
  hotel: 'HOTELS',
  petSitter: 'PET_SITTER',
  petWalker: 'PET_WALKER',
  admin: 'ADMIN',
  superAdmin: 'SUPER-ADMIN',
  accounting: 'ACCOUNTING',
  accountingVet: 'ACCOUNTING-VET',
  shelter: 'SHELTER',
}

export const translationPrefix = {
  bg: {
    mr: 'Г-н',
    ms: 'Г-ца',
    mrs: 'Г-жа',
    doctor: 'Доктор',
    assoc_prof: 'Доцент',
    professor: 'Професор',
    prof: 'Професор',
    phd: 'Д-р',
    dr: 'Д-р',
  },
  en: {
    mr: 'Mr',
    ms: 'Miss',
    mrs: 'Mrs',
    doctor: 'Doctor',
    assoc_prof: 'Assoc prof.',
    professor: 'Professor',
    prof: 'Professor',
    phd: 'PhD',
    dr: 'PhD',
  }
};
