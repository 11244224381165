import React, {Component} from "react";
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import moment from "moment";
import i18next from "i18next";
import {Button} from "antd";
import dayjs from "dayjs";

class BookingList extends Component {
  constructor(props) {
    super(props);
  }


  tableRowElement(bookings) {
    if (bookings) {
      return bookings?.map((booking) => {
        booking.refund = {
          price: 0,
          reason: '',
          description: '',
          id: booking?._id,
          stripePaymentID: booking?.stripePaymentID,
          currentPrice: booking?.price
        }

        return (
          <Tr key={booking._id}>
            <Td>{booking?.parentID?.name} {booking?.parentID?.surname}</Td>
            <Td>{i18next.t(booking.description)}</Td>
            <Td>{booking.status} / {booking.meetingType}</Td>
            <Td>{i18next.t(booking.paymentStatus)}</Td>
            <Td>{booking?.invoicing[0]?.bookingState} <br/>
                Fee: {booking?.invoicing[0]?.hpnAmount} BGN
                {booking?.invoicing[0]?.hpnInvoiceNumber>0 && (
                  <React.Fragment>
                    <br/>
                    Inv: <b>{booking?.invoicing[0].hpnInvoiceNumber}</b><br/>
                    {dayjs(booking?.invoicing[0].hpnInvoiceDate).format('DD.MM.YYYY')}
                  </React.Fragment>
                ) }

              </Td>
            <Td>
              <br/> Paid to proff: <br/>
                <b>
                  {booking.invoicing[0]?.hpnPaid && (
                    <React.Fragment>
                      Yes <br/>
                      {dayjs(booking.invoicing[0].hpnPaidDate).format('DD.MM.YYYY')}
                    </React.Fragment>
                  )}
                  {!booking.invoicing[0]?.hpnPaid && ('N/A')}
                </b>
              </Td>
            <Td className='text-center'>
              {booking.paymentStatus === 'succeeded' && booking.transactions.reduce((partialSum, refund) => partialSum + refund.price, 0) < Number(booking.price) ?
                <Button onClick={() => {
                  this.props.onRefundOpen(booking)
                  this.props.setRefundModalOpen(true)
                }}>Refund</Button> : '-'
              }
            </Td>
            <Td>{!isNaN(Number(booking.price)) ? Number(booking.price).toFixed(2) : '-'}
              {booking.transactions.length > 0 &&
                <div
                  className="text-danger">Refund: {booking.transactions.reduce((partialSum, refund) => partialSum + refund.price, 0).toFixed(2)}</div>}
            </Td>
            <Td>{booking.professionalID?.role}: {booking.professionalID?.name} {booking.professionalID?.surname}</Td>
            <Td>
              {moment(booking?.time).format("DD MMM YYYY")} {moment(booking.time).format("HH:mm")}
            </Td>
            <Td>
              {moment(booking?.createdAt).format('DD MMM YYYY')}
            </Td>
          </Tr>
        );
      })
    }
  }

  render() {
    const {list} = this.props
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered"
          >
            <Thead>
              <Tr>
                <Th data-priority="1">Parent</Th>
                <Th data-priority="3">Service</Th>
                <Th data-priority="3">Status</Th>
                <Th data-priority="1">Payment Status</Th>
                <Th data-priority="1">Appointment Status</Th>
                <Th data-priority="3">Contragent Data</Th>
                <Th data-priority="3">Refund</Th>
                <Th data-priority="3">Price BGN</Th>
                <Th data-priority="1">Professional</Th>
                <Th data-priority="3">Appointment Date</Th>
                <Th data-priority="3">Created At</Th>
              </Tr>
            </Thead>
            <Tbody>{this.tableRowElement(list)}</Tbody>
          </Table>
        </div>

      </div>
    );
  }
}

export default BookingList;
